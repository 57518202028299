import { HttpMethod } from '../../constants'

function replaceInvoiceId(invoiceId: string): string {
  return this.path.replace(':invoiceId', invoiceId)
}

export const billingEndpoints = {
  [HttpMethod.Get]: {
    Invoice: {
      path: '/invoices/:invoiceId',
      route: replaceInvoiceId
    },
    InvoicePdf: {
      path: '/invoices/:invoiceId/pdf',
      route: replaceInvoiceId
    }
  }
}

export default billingEndpoints
