import faker from 'faker/locale/en'
import { times } from 'lodash'
import { PlanThirdPartyIntegration } from '@commutifi/models/Plan'
import {
  DetailedPlan,
  PlanBookingType,
  PlanCostType,
  Countries,
  PlanBillingCancellationPolicy,
  PlanFrequency,
  PlanBillingMethod,
  PlanBookingFieldField,
  PlanBookingFieldCategory,
  ServiceType
} from 'api/modules/commuterPlans'
import {
  fakeRegionPoint,
  generatePlanPricing,
  generateBookingSoldOut,
  generateBookingAlwaysAvailable,
  fakePlanBookingFields,
  generateBookingWithPartialDailyAvailability,
  generateBookingWhereCompanyAllocationWasReached,
  fakePlan,
  IMockPlan
} from '../modules/plans/fakers'
import { fakeCommutifiEmail, fakeFullAddress, generateMD, generateNextDaysDatesFromNow, randomIn } from '../_utils'
import { fakeAccountTimezone } from '../config/constants'

faker.seed(1222)
/**
 * Cases we should cover for the costs:
 *
 * ```
 * If plan.costType is free
 * display: Free
 * If plan.costType is variable
 * display: Variable
 * if plan.costType is Fixed (or maybe called singular or other word ?)
 * 3.1. If bookingType is recurrent
 * 3.1.1. frequency yearly: $x per year
 * 3.1.2. frequency monthly: $x per month
 * 3.2. If bookingType is fixed/singular
 * 3.2.1. frequency daily: $x per day
 * 3.2.2. frequency per trip: $x per trip
 * 3.3. If bookingType is standard or unique
 * display: $x
 * ```
 *
 * BOTH for subsidized plan or not
 */
export const featuredPlanCases: Partial<DetailedPlan>[] = [
  {
    costType: PlanCostType.FREE,
    bookingType: PlanBookingType.SINGULAR,
    canBuyMany: false,
    regions: [
      {
        id: faker.datatype.uuid(),
        point: fakeRegionPoint(),
        countryCode: faker.address.countryCode() as Countries,
        address: fakeFullAddress(),
        createdAt: faker.date.past().toUTCString(),
        updatedAt: faker.date.past().toUTCString()
      }
    ],
    imageUrl: undefined,
    thirdPartyIntegration: undefined,
    isFeatured: true,
    shortDescription: 'Test shorter description',
    supportContact: {
      email: fakeCommutifiEmail()
    },
    isTermValidationRequired: false,
    isSignatureRequired: false,
    inventory: generateBookingSoldOut()
  },
  {
    costType: PlanCostType.FIXED,
    bookingType: PlanBookingType.RECURRENT,
    billingCancellationPolicy: PlanBillingCancellationPolicy.FLEXIBLE,
    frequency: PlanFrequency.YEARLY,
    costAmount: 250,
    pricing: generatePlanPricing(250, true),
    isFeatured: true,
    canBuyMany: false,
    thirdPartyIntegration: undefined,
    regions: [],
    planFaqs: [],
    service: {
      provider: {
        website: undefined,
        name: undefined
      }
    },
    terms: {
      content: 'Test terms content'
    },
    isTermValidationRequired: true,
    supportContact: { email: undefined },
    isBookingConfirmationRequired: true,
    inventory: generateBookingAlwaysAvailable(),
    billingMethod: PlanBillingMethod.STRIPE,
    planBookingFields: fakePlanBookingFields()
  },
  {
    costType: PlanCostType.FIXED,
    bookingType: PlanBookingType.RECURRENT,
    billingCancellationPolicy: PlanBillingCancellationPolicy.MODERATE,
    frequency: PlanFrequency.MONTHLY,
    costAmount: 50,
    pricing: generatePlanPricing(50),
    isFeatured: true,
    billingMethod: PlanBillingMethod.STRIPE,
    canBuyMany: false,
    billingDetails: generateMD(),
    inventory: generateBookingAlwaysAvailable(),
    thirdPartyIntegration: undefined,
    planBookingFields: [
      {
        id: faker.datatype.uuid(),
        field: PlanBookingFieldField.START_DATE,
        category: PlanBookingFieldCategory.BOOKING,
        createdAt: faker.date.past().toUTCString(),
        updatedAt: faker.date.past().toUTCString()
      },
      {
        id: faker.datatype.uuid(),
        field: PlanBookingFieldField.PHONE_NUMBER,
        category: PlanBookingFieldCategory.ACCOUNT,
        createdAt: faker.date.past().toUTCString(),
        updatedAt: faker.date.past().toUTCString()
      }
    ]
  },
  {
    costType: PlanCostType.FIXED,
    bookingType: PlanBookingType.RECURRENT,
    billingCancellationPolicy: PlanBillingCancellationPolicy.MODERATE_NOTICE,
    frequency: PlanFrequency.DAILY,
    costAmount: 25,
    pricing: generatePlanPricing(25, true),
    isFeatured: true,
    canBuyMany: false,
    thirdPartyIntegration: undefined,
    inventory: generateBookingAlwaysAvailable(),
    billingMethod: PlanBillingMethod.STRIPE,
    planBookingFields: [
      {
        id: faker.datatype.uuid(),
        field: PlanBookingFieldField.START_DATE,
        category: PlanBookingFieldCategory.BOOKING,
        createdAt: faker.date.past().toUTCString(),
        updatedAt: faker.date.past().toUTCString()
      }
    ]
  },
  {
    costType: PlanCostType.FIXED,
    bookingType: PlanBookingType.RECURRENT,
    billingCancellationPolicy: PlanBillingCancellationPolicy.STRICT,
    frequency: PlanFrequency.PER_TRIP,
    costAmount: 5,
    pricing: generatePlanPricing(5, true),
    isFeatured: true,
    canBuyMany: false,
    thirdPartyIntegration: undefined,
    inventory: generateBookingAlwaysAvailable()
  },
  {
    costType: PlanCostType.FIXED,
    bookingType: PlanBookingType.STANDARD,
    costAmount: 28,
    pricing: generatePlanPricing(28),
    isFeatured: true,
    planBookingFields: [],
    isTermValidationRequired: true,
    billingMethod: PlanBillingMethod.STRIPE,
    thirdPartyIntegration: undefined,
    canBuyMany: true,
    inventory: generateNextDaysDatesFromNow(fakeAccountTimezone).reduce((acc, { startDate }, index) => {
      const coinToss = index === 0 ? true : faker.datatype.boolean()
      return {
        ...acc,
        // Make sure the user can book at least one day in the next 30
        [startDate]: {
          isSoldOut: coinToss,
          companyAllocationReached: coinToss,
          isUnavailableForAccount: false,
          availableQuantity: coinToss ? faker.datatype.number(randomIn({ min: 1, max: 50 })) : undefined
        }
      }
    }, {})
  },
  {
    costType: PlanCostType.FIXED,
    costAmount: 1,
    costActivationFees: 0,
    costTransactionFees: -4.5,
    costVariable: undefined,
    pricing: generatePlanPricing(1),
    bookingType: PlanBookingType.RECURRENT,
    billingCancellationPolicy: PlanBillingCancellationPolicy.STRICT_NOTICE,
    billingDetails: undefined,
    billingMethod: PlanBillingMethod.STRIPE,
    terms: undefined,
    bookingInstructions: undefined,
    canBuyMany: false,
    frequency: PlanFrequency.YEARLY,
    isFeatured: true,
    isBookingConfirmationRequired: false,
    isTermValidationRequired: false,
    isTerminationConfirmationRequired: false,
    isSignatureRequired: false,
    shortDescription:
      'Commutifi has partnered with OneTreePlanted to plant trees in Colorado. Subscribe now to offset your commute...',
    longDescription:
      "Commutifi has partnered with OneTreePlanted to plant trees in Colorado. Subscribe now to offset your commute and receive a certificate of authorization. Your contribution will help to plant various tree species (including Aspen, Englemann Spruce and Douglas Fir) specifically designated to promote watershed restoration, insect recovery, wildfire prevention and restoration and biodiversity. To ensure sapling health and survival, Colorado planting season is June to October. If you miss this season, your trees will be included in the next season of the project. Upon a project's start and completion, you will receive a full pre- and post-report stating the project's social, environmental, and biodiversity benefits. Have any questions? Read more on our [FAQ](https://offset.commutifi.com/#faq).",
    supportContact: undefined,
    terminationInstructions: undefined,
    planBookingFields: [
      {
        id: faker.datatype.uuid(),
        field: PlanBookingFieldField.START_DATE,
        category: PlanBookingFieldCategory.BOOKING,
        createdAt: faker.date.past().toUTCString(),
        updatedAt: faker.date.past().toUTCString()
      }
    ],
    imageUrl:
      'https://i.guim.co.uk/img/media/d143e03bccd1150ef52b8b6abd7f3e46885ea1b3/0_182_5472_3283/master/5472.jpg?width=1200&quality=85&auto=format&fit=max&s=d5a74a011c3fef1ad9c1c962721d221d',
    inventory: generateBookingAlwaysAvailable(),
    service: {
      type: ServiceType.CARBON_OFFSET,
      name: 'Plant Trees in Colorado Offset your Commute',
      category: undefined,
      mode: undefined,
      isInventoryRestricted: false,
      provider: {
        name: 'OneTreePlanted',
        description: undefined,
        website: 'https://onetreeplanted.org/'
      }
    }
  }
]

export const notFeaturedPlanCases: Partial<DetailedPlan>[] = [
  {
    costType: PlanCostType.VARIABLE,
    bookingType: PlanBookingType.SINGULAR,
    regions: [],
    canBuyMany: true,
    isFeatured: false,
    shortDescription:
      'Test longer short description to see if we display the ellipsis after three lines of text. ' +
      'This should be enough. Or maybe a little bit longer indeed.',
    imageUrl: 'https://fakeimg.pl/300/'
  },
  {
    costType: PlanCostType.FIXED,
    bookingType: PlanBookingType.RECURRENT,
    frequency: PlanFrequency.YEARLY,
    billingMethod: PlanBillingMethod.EXTERNAL,
    // A yearly fixed price commutifi card plan doesn't make much sense, but it works for tests
    thirdPartyIntegration: PlanThirdPartyIntegration.commutifiCard,
    regions: [],
    isFeatured: false,
    imageUrl: undefined,
    inventory: generateBookingAlwaysAvailable(),
    planBookingFields: [
      {
        id: faker.datatype.uuid(),
        field: PlanBookingFieldField.START_DATE,
        category: PlanBookingFieldCategory.BOOKING,
        createdAt: faker.date.past().toUTCString(),
        updatedAt: faker.date.past().toUTCString()
      }
    ],
    isTermValidationRequired: false,
    isBookingConfirmationRequired: false,
    canBuyMany: false
  },
  {
    costType: PlanCostType.FIXED,
    bookingType: PlanBookingType.RECURRENT,
    frequency: PlanFrequency.MONTHLY,
    billingCancellationPolicy: PlanBillingCancellationPolicy.MODERATE_NOTICE,
    billingMethod: PlanBillingMethod.STRIPE,
    costAmount: 130,
    pricing: generatePlanPricing(130, true),
    isFeatured: false,
    canBuyMany: false,
    isBookingConfirmationRequired: false,
    isTermValidationRequired: true,
    thirdPartyIntegration: undefined,
    inventory: generateBookingAlwaysAvailable(),
    planBookingFields: [
      {
        id: faker.datatype.uuid(),
        field: PlanBookingFieldField.START_DATE,
        category: PlanBookingFieldCategory.BOOKING,
        createdAt: faker.date.past().toUTCString(),
        updatedAt: faker.date.past().toUTCString()
      },
      ...times(2, () => ({
        id: faker.datatype.uuid(),
        field: PlanBookingFieldField.ACCOUNT_VEHICLE_ID,
        category: PlanBookingFieldCategory.ACCOUNT_VEHICLES_BOOKINGS,
        createdAt: faker.date.past().toUTCString(),
        updatedAt: faker.date.past().toUTCString()
      }))
    ]
  },
  {
    costType: PlanCostType.FIXED,
    bookingType: PlanBookingType.RECURRENT,
    frequency: PlanFrequency.DAILY,
    isFeatured: false,
    inventory: generateBookingAlwaysAvailable(),
    isTermValidationRequired: false,
    billingMethod: PlanBillingMethod.STRIPE,
    thirdPartyIntegration: undefined,
    planBookingFields: [
      {
        id: faker.datatype.uuid(),
        field: PlanBookingFieldField.START_DATE,
        category: PlanBookingFieldCategory.BOOKING,
        createdAt: faker.date.past().toUTCString(),
        updatedAt: faker.date.past().toUTCString()
      },
      ...times(2, () => ({
        id: faker.datatype.uuid(),
        field: PlanBookingFieldField.ACCOUNT_VEHICLE_ID,
        category: PlanBookingFieldCategory.ACCOUNT_VEHICLES_BOOKINGS,
        createdAt: faker.date.past().toUTCString(),
        updatedAt: faker.date.past().toUTCString()
      }))
    ],
    canBuyMany: false
  },
  {
    costType: PlanCostType.FIXED,
    bookingType: PlanBookingType.SINGULAR,
    frequency: PlanFrequency.PER_TRIP,
    isFeatured: false,
    canBuyMany: false,
    billingMethod: PlanBillingMethod.EXTERNAL,
    inventory: generateBookingWithPartialDailyAvailability(),
    planBookingFields: [
      {
        id: faker.datatype.uuid(),
        field: PlanBookingFieldField.START_DATE,
        category: PlanBookingFieldCategory.BOOKING,
        createdAt: faker.date.past().toUTCString(),
        updatedAt: faker.date.past().toUTCString()
      }
    ],
    isTermValidationRequired: false,
    requiresCheckoutPayment: true,
    thirdPartyIntegration: undefined
  },
  {
    costType: PlanCostType.FIXED,
    bookingType: PlanBookingType.STANDARD,
    costAmount: 1111,
    pricing: generatePlanPricing(1111, true),
    isFeatured: false,
    isBookingConfirmationRequired: false,
    thirdPartyIntegration: undefined,
    inventory: generateBookingSoldOut(),
    canBuyMany: false
  },
  {
    costType: PlanCostType.VARIABLE,
    bookingType: PlanBookingType.UNIQUE,
    canBuyMany: false,
    isFeatured: false,
    isSignatureRequired: false,
    isTermValidationRequired: false,
    planBookingFields: [],
    isBookingConfirmationRequired: true,
    thirdPartyIntegration: undefined,
    inventory: generateBookingAlwaysAvailable(),
    requiresCheckoutPayment: false
  },
  {
    costType: PlanCostType.FIXED,
    bookingType: PlanBookingType.STANDARD,
    costAmount: 1111,
    pricing: generatePlanPricing(1111, true),
    isFeatured: false,
    isBookingConfirmationRequired: false,
    thirdPartyIntegration: undefined,
    inventory: generateBookingWhereCompanyAllocationWasReached(),
    canBuyMany: false
  }
]

export const featuredPlans = featuredPlanCases.map((plan) => fakePlan(plan))
export const notFeaturedPlans = notFeaturedPlanCases.map((plan) => fakePlan(plan))

export const allPlans: IMockPlan[] = [...featuredPlans, ...notFeaturedPlans]
export const enterprisePlans = allPlans
  .filter((plan) => plan.costType === PlanCostType.FIXED && !plan.pricing?.subsidy)
  .map((plan) => ({ ...plan, planPermissions: [] }))
export const enterpriseExternalPlanIndex = enterprisePlans.length - 2
