import { BookingStatus, ConfirmationStatus } from '@commutifi/models/Bookings'
import { PasseTypes } from '@commutifi/models/Passes'
import { PlanBookingType } from '@commutifi/models/Plan'
import faker from 'faker/locale/en'
import { times } from 'lodash'
import moment from 'moment-timezone'
import { PlanBookingFieldField } from 'api/modules/commuterPlans/types'
import { STATUS as SUBSCRIPTIONS_STATUS } from 'constants/subscriptions'
import { randomIn } from '../../_utils'
import { foodCardBookingExternalId, mobilityCardBookingExternalId } from '../commutifiCards'
import { fakeAccountTimezone } from '../../config/constants'
import { fakePaymentMethod } from '../paymentMethods/fakers'
import { fakePlanBookingFields } from '../plans/fakers'
import {
  fakeCommutifiCardPlan,
  fakeDailyParkingPassPlan,
  fakeEmptyBookingFieldsPlan,
  fakeExternalPlanWithImg,
  fakeFoodCardPlan,
  fakeOffsetPlan,
  fakePlanWithRegion,
  fakeStripeBusServicePlan,
  fakeStripePlanWithRegion
} from '../plans/data'
import { fakeAccountVehicles } from '../vehicles/data'
import { fakeBooking, fakeNonRecurrentBooking, fakeRecurrentBooking } from './fakers'
import { enterprisePlans } from '../../shared/data'

export const recurrentBookingActiveSince = fakeRecurrentBooking({
  status: BookingStatus.active,
  startDate: moment.tz(fakeAccountTimezone).subtract(2, 'months').startOf('day').utc().toISOString(),
  endDate: undefined,
  planId: fakePlanWithRegion.id,
  plan: {
    ...fakePlanWithRegion,
    planBookingFields: fakePlanBookingFields([
      PlanBookingFieldField.START_DATE,
      PlanBookingFieldField.ACCOUNT_VEHICLE_ID,
      PlanBookingFieldField.PASS_ACCESS_CARD
    ])
  },
  accountVehiclesBookings: [
    { accountVehicleId: fakeAccountVehicles[0].id },
    { accountVehicleId: fakeAccountVehicles[1].id }
  ],
  passes: [
    { id: faker.datatype.uuid(), passId: faker.datatype.uuid(), type: PasseTypes.RESERVED_SPACE },
    { id: faker.datatype.uuid(), passId: faker.datatype.uuid(), type: PasseTypes.ACCESS_CARD }
  ]
})

export const recurrentBookingActiveUntil = fakeRecurrentBooking({
  status: BookingStatus.active,
  startDate: moment.tz(fakeAccountTimezone).subtract(1, 'year').startOf('month').utc().toISOString(),
  endDate: moment.tz(fakeAccountTimezone).add(1, 'month').endOf('month').utc().toISOString(),
  planId: fakeStripePlanWithRegion.id,
  plan: fakeStripePlanWithRegion,
  paymentMethodId: '27c9a78b-a8e6-43b5-8943-a040d1cdff0e',
  paymentMethod: fakePaymentMethod({
    id: '27c9a78b-a8e6-43b5-8943-a040d1cdff0e'
  }),
  accountVehiclesBookings: [{ accountVehicleId: fakeAccountVehicles[2].id }]
})

export const recurrentBookingInactive = fakeRecurrentBooking({
  status: BookingStatus.completed,
  startDate: moment.tz(fakeAccountTimezone).subtract(1, 'year').startOf('month').utc().toISOString(),
  endDate: moment.tz(fakeAccountTimezone).subtract(1, 'month').endOf('month').utc().toISOString(),
  phoneNumber: '+14505555555x1234',
  planId: fakeExternalPlanWithImg.id,
  plan: fakeExternalPlanWithImg
})

const recurrentBookingActiveOn = fakeRecurrentBooking({
  status: BookingStatus.active,
  startDate: moment.tz(fakeAccountTimezone).add(1, 'month').startOf('month').utc().toISOString(),
  endDate: undefined,
  planId: fakeStripeBusServicePlan.id,
  plan: fakeStripeBusServicePlan
})

export const mobilityCardBooking = fakeRecurrentBooking({
  externalBookingId: mobilityCardBookingExternalId,
  status: BookingStatus.processing,
  confirmationStatus: ConfirmationStatus.PROCESSING,
  startDate: moment.tz(fakeAccountTimezone).startOf('month').utc().toISOString(),
  endDate: undefined,
  planId: fakeCommutifiCardPlan.id,
  plan: fakeCommutifiCardPlan
})

export const foodCardBooking = fakeRecurrentBooking({
  externalBookingId: foodCardBookingExternalId,
  status: BookingStatus.active,
  startDate: moment.tz(fakeAccountTimezone).startOf('month').utc().toISOString(),
  endDate: undefined,
  planId: fakeFoodCardPlan.id,
  plan: fakeFoodCardPlan
})

export const mostRelevantRecurrentBookings = [
  recurrentBookingActiveSince,
  recurrentBookingActiveUntil,
  recurrentBookingInactive,
  recurrentBookingActiveOn,
  mobilityCardBooking
]

const nonRecurrentParkingBooking = fakeNonRecurrentBooking({
  status: BookingStatus.processing,
  bookingType: PlanBookingType.SINGULAR,
  confirmationStatus: ConfirmationStatus.PROCESSING,
  startDate: undefined,
  endDate: undefined,
  planId: fakeDailyParkingPassPlan.id,
  plan: fakeDailyParkingPassPlan,
  accountVehiclesBookings: [{ accountVehicleId: fakeAccountVehicles[1].id }]
})

const nonRecurrentOffsetBooking = fakeNonRecurrentBooking({
  status: BookingStatus.completed,
  quantity: 10,
  startDate: undefined,
  endDate: undefined,
  bookingType: PlanBookingType.STANDARD,
  planId: fakeOffsetPlan.id,
  plan: fakeOffsetPlan
})

const nonRecurrentCompletedSingularBooking = fakeNonRecurrentBooking({
  status: BookingStatus.completed,
  bookingType: PlanBookingType.SINGULAR,
  startDate: moment.tz(fakeAccountTimezone).subtract(1, 'month').startOf('month').utc().toISOString(),
  endDate: moment.tz(fakeAccountTimezone).subtract(1, 'month').endOf('month').utc().toISOString(),
  planId: fakeDailyParkingPassPlan.id,
  plan: fakeDailyParkingPassPlan,
  accountVehiclesBookings: [{ accountVehicleId: fakeAccountVehicles[0].id }]
})

const nonRecurrentCanceledBooking = fakeNonRecurrentBooking({
  status: BookingStatus.cancelled,
  bookingType: PlanBookingType.SINGULAR,
  confirmationStatus: ConfirmationStatus.REJECTED,
  endDate: undefined,
  paymentMethodId: '27c9a78b-a8e6-43b5-8943-a040d1code0e',
  paymentMethod: fakePaymentMethod({
    id: '27c9a78b-a8e6-43b5-8943-a040d1code0e'
  }),
  planId: fakeEmptyBookingFieldsPlan.id,
  plan: fakeEmptyBookingFieldsPlan
})

export const mostRelevantNonRecurrentBookings = [
  nonRecurrentParkingBooking,
  nonRecurrentOffsetBooking,
  nonRecurrentCompletedSingularBooking,
  nonRecurrentCanceledBooking
]

export const staticCommutifiCardBookings = [mobilityCardBooking, foodCardBooking]

export const fakeLegacySubscriptions = [
  { id: '3', plan: { name: 'Name 1' }, status: SUBSCRIPTIONS_STATUS.Active },
  { id: '4', plan: { name: 'Name 2' }, status: SUBSCRIPTIONS_STATUS.Processing }
]

export const fakeLegacyTerminatedSubscriptions = [
  { id: '1', plan: { name: 'Magic Plan 1' }, status: SUBSCRIPTIONS_STATUS.Completed },
  { id: '2', plan: { name: 'Magic Plan 2' }, status: SUBSCRIPTIONS_STATUS.Completed }
]

export const fakeEnterpriseBookings = [
  ...mostRelevantRecurrentBookings,
  ...mostRelevantNonRecurrentBookings,
  ...times(19, (index) => {
    const plan =
      enterprisePlans[
        index > enterprisePlans.length - 1 ? randomIn({ min: 0, max: enterprisePlans.length - 1 }) : index
      ]
    return fakeBooking({
      planId: plan.id,
      plan
    })
  })
]
