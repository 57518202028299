import { DEFAULT_UNITS_BY_MEASURE_TYPE } from '@commutifi-fe/constants'
import { convertToUnit } from '@commutifi-fe/utils'
import { createSelector } from 'reselect'
import { getLocaleUnits } from 'store/modules/localSettings/selectors'
import { isNumber } from 'utils/helpers'

const COST = 'cost'
const CARBON = 'carbon'
const DURATION = 'duration'
const CARBON_UNIT = 'mass'

const _convertMetrics = (factors, metrics, localeUnits) =>
  factors.reduce((userFactors, f) => {
    switch (f) {
      case COST:
        return { ...userFactors, [f]: { value: metrics[f], unit: '$' } }
      case CARBON:
        return {
          ...userFactors,
          [f]: !isNumber(metrics[f])
            ? { value: null, unit: localeUnits[CARBON_UNIT] }
            : {
                value: convertToUnit(
                  `${metrics[f]} ${DEFAULT_UNITS_BY_MEASURE_TYPE[CARBON_UNIT]}`,
                  localeUnits[CARBON_UNIT]
                ),
                unit: localeUnits[CARBON_UNIT]
              }
        }
      case DURATION:
        return {
          ...userFactors,
          [f]: metrics[f] ? { value: metrics[f], unit: 's' } : { value: null, unit: 's' }
        }
      default:
        return userFactors
    }
  }, {})

export const getMetrics = ({ metrics }) => metrics.account
export const getEntities = createSelector(getMetrics, (metrics) => metrics.entities)
export const getIsFetching = createSelector(getMetrics, (metrics) => metrics.isFetching)
export const getIsLoaded = createSelector(
  getEntities,
  (entitiesKeys) => entitiesKeys && Object.keys(entitiesKeys).length > 0
)

/**
 * User metrics format:
 * { cost: 821.966, carbon: 138.596, duration: 2195, commutifiScore: 29.5, offsets: { carbon: { absolute: 0, relative: 100 } } }
 */
export const getUserMetrics = createSelector([getEntities], (entities) => entities.account || {})
export const getRegionMetrics = createSelector([getEntities], (entities) => entities.region || {})

export const makeGetMetrics = (entity) => createSelector([getEntities], (entities) => entities[entity] || {})
export const makeGetScore = (entity) => createSelector([makeGetMetrics(entity)], (metrics) => metrics.commutifiScore)

/**
 * Return the factors handled in the order defined by the product manager
 */
export const getScoreFactors = () => ['cost', 'duration', 'carbon']

export const makeGetConvertedScoreFactors = (entity) =>
  createSelector([makeGetMetrics(entity), getScoreFactors, getLocaleUnits], (metrics, factors, localeUnits) =>
    _convertMetrics(factors, metrics, localeUnits)
  )
