import {
  InfiniteData,
  QueryKey,
  UseInfiniteQueryOptions,
  useInfiniteQuery,
  useMutation,
  useQuery
} from '@tanstack/react-query'
import { omit } from 'lodash'
import { AccountWalletProps, AccountWalletStatus } from '@commutifi/models/AccountsWallets'
import { CardProps } from '@commutifi/models/Cards'
import { Account, GetResponse, RequestParams } from '@commutifi-fe/interfaces'
import { CommutifiError, RegisterFormPayload } from 'shared/interfaces'
import {
  ExtractFnReturnType,
  MutationConfig,
  MutationConfigPickRes,
  QueryConfig,
  QueryConfigPickRes,
  QueryResult
} from '../../reactQuery'
import { BookingProps } from '../bookings'
import { WalletBalance } from '../wallets'
import {
  AccountBookingQueryParams,
  PlanQueryParams,
  PlanSuggestionsQueryParams,
  addGroupToAccount,
  batchUpdateAccounts,
  createEnterpriseSurveyAccount,
  fetchAccountBookings,
  fetchAccountCards,
  fetchAccountKCI,
  fetchAccountPaymentMethods,
  fetchAccountRegionMetrics,
  fetchAccountScores,
  fetchAccountSubscriptions,
  fetchAccountWalletEnrolments,
  fetchAccountWalletEntries,
  fetchPlans,
  fetchPlansSuggestions,
  patchAccountsGroups,
  postAccountPaymentMethods,
  postEmailVerification,
  postEmailVerificationEmail
} from './api'
import endpoints from './endpoints'
import { PostPaymentMethodBody } from '../paymentMethods'
import { Primitive } from 'utility-types'

export const accountsQueryKeys = {
  getAccountPrefix: (accountId: string | undefined | null) => `accounts/${accountId}`,
  getAccountsWallets: (
    accountId: string | undefined | null,
    queryParams?: Parameters<typeof fetchAccountWalletEnrolments>[1]
  ) =>
    [
      `${accountsQueryKeys.getAccountPrefix(accountId)}/accounts-wallets`,
      ...(queryParams ? [queryParams] : [])
    ] as const,
  getAccountsWalletsForHeader: (accountId: string | undefined | null) =>
    [...accountsQueryKeys.getAccountsWallets(accountId), 'header'] as const,
  getAccountCards: (accountId: string | undefined | null, queryParams?: RequestParams) =>
    [`${accountsQueryKeys.getAccountPrefix(accountId)}/cards`, ...(queryParams ? [queryParams] : [])] as const
}

export const usePostEmailVerificationEmail = (options?: MutationConfig<typeof postEmailVerificationEmail>) =>
  useMutation<undefined, unknown, { accountId?: string; email?: string }>({
    mutationFn: ({ accountId, email }) => {
      if (!accountId && !email) {
        throw new Error('Missing account information to send verification Email')
      }
      return postEmailVerificationEmail({ accountId, email })
    },
    ...options
  })

export const usePostEmailVerification = (
  options?: MutationConfig<
    typeof postEmailVerification,
    Parameters<typeof postEmailVerification>[1] & { accountId: string }
  >
) =>
  useMutation({
    mutationFn: ({ accountId, emailVerificationToken }) => {
      if (!accountId || !emailVerificationToken) {
        throw new Error('Missing account information to verify email')
      }
      return postEmailVerification(accountId, { emailVerificationToken })
    },
    ...options
  })

export const useCreateEnterpriseSurveyAccount = (
  enterpriseSurveyShortlink: string,
  options?: MutationConfig<typeof createEnterpriseSurveyAccount, RegisterFormPayload>
) =>
  useMutation({
    mutationFn: (accountPayload) => {
      if (!enterpriseSurveyShortlink) {
        throw new Error('Cannot call createEnterpriseSurveyAccount without a shortlinkId')
      }
      return createEnterpriseSurveyAccount({ ...accountPayload, enterpriseSurveyShortlink })
    },
    mutationKey: ['accounts', enterpriseSurveyShortlink],
    ...options
  })

export const useAddGroupToAccount = (groupId: string, options?: MutationConfig<typeof addGroupToAccount, string>) =>
  useMutation({
    mutationFn: (accountId) => addGroupToAccount(groupId, accountId),
    mutationKey: ['accounts/groups', groupId],
    ...options
  })

export const useBatchUpdateAccounts = (
  accountIds: string[],
  options?: MutationConfig<typeof batchUpdateAccounts, Partial<Account> | undefined>
) =>
  useMutation({
    mutationFn: (body) => {
      if (!body) {
        throw new Error('Missing account ids to update, or the data to patch')
      }
      return batchUpdateAccounts(accountIds, body)
    },
    ...options
  })

export function useLegacySubscriptions(
  accountId: string | undefined,
  options?: QueryConfig<typeof fetchAccountSubscriptions>
) {
  return useQuery({
    queryKey: ['/_/accounts', accountId, 'subscriptions'],
    queryFn: async () => {
      if (!accountId) {
        throw Error('Missing account id')
      }

      return fetchAccountSubscriptions(accountId)
    },
    ...options
  })
}

const planListRequiredFields = [
  'id',
  'name',
  'shortDescription',
  'canBuyMany',
  'costType',
  'costAmount',
  'isFeatured',
  'frequency',
  'bookingType',
  'imageUrl',
  'regions.id',
  'regions.point',
  'service.id',
  'service.mode',
  'service.category',
  'thirdPartyIntegration',
  'merchant.id',
  'merchant.currency',
  'merchant.termsOfService',
  'merchant.termsOfServiceLink'
]
const planListRequiredRelation = ['regions', 'service', 'merchant']

export const usePlans = (
  accountId: string,
  queryParams: PlanQueryParams,
  options?: QueryConfigPickRes<typeof fetchPlans, 'records'>
) =>
  useQuery({
    queryKey: ['plans', accountId, queryParams],
    queryFn: async () => {
      const { records } = await fetchPlans(accountId, {
        ...queryParams,
        fields: planListRequiredFields,
        relations: planListRequiredRelation
      })
      return records
    },
    ...options
  })

export const usePlansSuggestions = (
  accountId: string,
  queryParams: PlanSuggestionsQueryParams = {},
  options?: QueryConfigPickRes<typeof fetchPlansSuggestions, 'records'>
) =>
  useQuery({
    queryKey: ['plans/suggestions', accountId, queryParams],
    queryFn: async () => {
      const { records } = await fetchPlansSuggestions(accountId, queryParams)
      return records
    },
    ...options
  })

export const accountBookingsListDefaultQuery = {
  relations: ['plan', 'plan.service', 'plan.regions'],
  fields: [
    'id',
    'createdAt',
    'startDate',
    'endDate',
    'bookingType',
    'timezone',
    'quantity',
    'plan.id',
    'plan.name',
    'plan.imageUrl',
    'plan.thirdPartyIntegration',
    'plan.regions.id',
    'plan.regions.point',
    'plan.service.id',
    'plan.service.mode',
    'plan.service.category'
  ]
}

/**
 * Identifier of useAccountBookings query hook
 * @param accountId - Account id
 * @returns : React Query key excluding specific parameters like the queryParams
 */
export function useAccountBookingsInfinite(
  accountId: string | undefined,
  queryParams: AccountBookingQueryParams,
  options: Omit<
    UseInfiniteQueryOptions<
      GetResponse<BookingProps>,
      CommutifiError,
      InfiniteData<GetResponse<BookingProps>, number | undefined>,
      GetResponse<BookingProps>,
      QueryKey,
      number | undefined
    >,
    'queryKey' | 'queryFn'
  >
) {
  return useInfiniteQuery({
    queryKey: [endpoints.GET.AccountBookings.route(accountId || ''), queryParams],
    queryFn: async ({ pageParam = 0 }) => {
      if (!accountId) {
        throw Error('Missing Account id')
      }

      return fetchAccountBookings(accountId, { ...accountBookingsListDefaultQuery, ...queryParams, offset: pageParam })
    },
    ...options
  })
}

export function useAccountBookings<T = BookingProps[]>(
  accountId: string | undefined,
  queryParams: AccountBookingQueryParams,
  options?: QueryConfigPickRes<typeof fetchAccountBookings, 'records', T>
) {
  return useQuery({
    queryKey: [endpoints.GET.AccountBookings.route(accountId || ''), queryParams],
    queryFn: async () => {
      if (!accountId) {
        throw Error('Missing Account id')
      }

      const { records } = await fetchAccountBookings(accountId, { ...accountBookingsListDefaultQuery, ...queryParams })
      return records
    },
    ...options
  })
}

export const useAccountEnabledWalletEnrolmentsByWalletId = (
  accountId: string,
  queryParams: Parameters<typeof fetchAccountWalletEnrolments>[1] = {},
  options?: QueryConfigPickRes<
    typeof fetchAccountWalletEnrolments,
    'records',
    Record<string, AccountWalletProps & WalletBalance>
  >
): QueryResult<Record<string, AccountWalletProps & WalletBalance>> => {
  const defaultQueryParams = {
    fields: ['id', 'status', 'walletId', 'wallet.type'],
    relations: ['wallet']
  }
  const params = {
    ...defaultQueryParams,
    ...queryParams,
    status: [AccountWalletStatus.Active, AccountWalletStatus.Processing]
  }
  return useQuery({
    queryKey: [`accounts/${accountId}/accounts-wallets`, params],
    queryFn: async () => {
      if (!accountId) {
        throw Error('Missing account id')
      }

      const { records } = await fetchAccountWalletEnrolments(accountId, params)
      return records
    },
    ...options,
    select: (enrolments) =>
      enrolments.reduce<Record<string, AccountWalletProps & WalletBalance>>(
        (byWalletId, enrolment) => ({
          ...byWalletId,
          [enrolment.walletId || '']: enrolment
        }),
        {}
      )
  })
}

export const useAccountWalletEnrolments = <Res = ExtractFnReturnType<typeof fetchAccountWalletEnrolments>['records']>(
  accountId: string,
  queryParams: Parameters<typeof fetchAccountWalletEnrolments>[1] = {},
  options?: QueryConfig<typeof fetchAccountWalletEnrolments, Res>
) =>
  useQuery({
    select: (res) => res.records as Res,
    queryKey: options?.queryKey || accountsQueryKeys.getAccountsWallets(accountId, queryParams),
    queryFn: async () => {
      if (!accountId) {
        throw Error('Missing account id')
      }

      return fetchAccountWalletEnrolments(accountId, queryParams)
    },
    ...options
  })

export const useAccountWalletEnrolmentsForHeader = <
  Res = ExtractFnReturnType<typeof fetchAccountWalletEnrolments>['records']
>(
  accountId: string,
  queryParams: Parameters<typeof fetchAccountWalletEnrolments>[1] = {},
  options?: QueryConfig<typeof fetchAccountWalletEnrolments, Res>
) =>
  useAccountWalletEnrolments(
    accountId,
    { fields: ['id'], ...queryParams },
    { ...options, queryKey: accountsQueryKeys.getAccountsWalletsForHeader(accountId) }
  )

export const useWalletTransactions = (
  accountId: string,
  queryParams: RequestParams & {
    'gte:authorizationDate'?: string
    'lte:authorizationDate'?: string
    walletId?: string
  },
  options?: QueryConfig<typeof fetchAccountWalletEntries>
) =>
  useQuery({
    queryKey: [`accounts/${accountId}/wallet-entries`, omit(queryParams, ['fields', 'relations'])],
    queryFn: async () => {
      if (!accountId) {
        throw Error('Cannot fetch account wallet entries without an accountId parameter')
      }

      return fetchAccountWalletEntries(accountId, queryParams)
    },
    ...options
  })

export function useAccountCards<Res>(
  accountId: string | undefined | null,
  queryParams: RequestParams & Partial<Record<keyof CardProps, Primitive>> = {},
  options?: QueryConfigPickRes<typeof fetchAccountCards, 'records', Res>
) {
  return useQuery({
    queryKey: accountsQueryKeys.getAccountCards(accountId, queryParams),
    queryFn: async () => {
      if (!accountId) {
        throw Error('Cannot fetch account cards without an accountId parameter')
      }

      const { records } = await fetchAccountCards(accountId, queryParams)
      return records
    },
    enabled: Boolean(accountId),
    ...options
  })
}
export function useAccountCardsMutation(
  accountId: string | undefined | null,
  options?: MutationConfigPickRes<
    typeof fetchAccountCards,
    'records',
    RequestParams & Partial<Record<keyof CardProps, Primitive | string[]>>
  >
) {
  return useMutation({
    mutationFn: async (queryParams) => {
      if (!accountId) {
        throw Error('Cannot fetch account cards without an accountId parameter')
      }

      const { records } = await fetchAccountCards(accountId, queryParams)
      return records
    },
    ...options
  })
}

export const usePatchAccountsGroups = (
  enterpriseId: string,
  options?: MutationConfig<typeof patchAccountsGroups, Parameters<typeof patchAccountsGroups>[1]>
) =>
  useMutation({
    mutationFn: (body) => {
      body.forEach((group) => {
        if (!group.groupId || !group.accountIds) {
          throw new Error('Missing account ids, group id or enterprise id')
        }
        if (!group.operation) {
          throw new Error('Missing operation or operation type not found')
        }
      })

      return patchAccountsGroups(enterpriseId, body)
    },
    ...options
  })

export const useAccountKCI = (
  accountId: string,
  queryParams: RequestParams = {},
  options?: QueryConfig<typeof fetchAccountKCI>
) =>
  useQuery({
    queryKey: ['accounts', accountId, 'kci', queryParams],
    queryFn: async () => fetchAccountKCI(accountId, queryParams),
    ...options
  })

export const useAccountRegionMetrics = (
  accountId: string,
  queryParams: RequestParams = {},
  options?: QueryConfig<typeof fetchAccountRegionMetrics>
) =>
  useQuery({
    queryKey: ['accounts', accountId, 'region-metrics', queryParams],
    queryFn: async () => fetchAccountRegionMetrics(accountId, queryParams),
    ...options
  })

export const useAccountScores = (
  accountId: string,
  queryParams: RequestParams = {},
  options?: QueryConfig<typeof fetchAccountScores>
) =>
  useQuery({
    queryKey: ['accounts', accountId, 'scores/variation-graph', queryParams],
    queryFn: async () => fetchAccountScores(accountId, queryParams),
    ...options
  })

export const useAccountPaymentMethods = (
  accountId: string | undefined,
  queryParams: RequestParams = {},
  options?: QueryConfigPickRes<typeof fetchAccountPaymentMethods, 'records'>
) =>
  useQuery({
    queryKey: ['paymentMethods', accountId, queryParams],
    queryFn: async () => {
      if (!accountId) {
        throw new Error('Missing account id')
      }

      const { records } = await fetchAccountPaymentMethods(accountId, queryParams)
      return records
    },
    ...options
  })

export const usePostAccountPaymentMethod = (
  accountId: string | undefined,
  options?: MutationConfig<typeof postAccountPaymentMethods, PostPaymentMethodBody>
) =>
  useMutation({
    mutationFn: (body) => {
      if (!accountId) {
        throw new Error('Missing account id')
      }
      return postAccountPaymentMethods(accountId, body)
    },
    ...options
  })
