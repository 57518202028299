import { createContext, useContext } from 'react'
import { ThemeProviderProps } from '@nivo/core'
import { ModeCategory } from '@commutifi/modes'
import { ChartsTokens, dynamicColors } from '../theme/index'

export interface ChartsThemeContextValue {
  customPalettes?: {
    blue: string[]
    blueDescending: string[]
    perModeCategory: Record<ModeCategory, string[]>
  }
  colorScheme?: string
  theme?: ThemeProviderProps['theme']
  tokens: ChartsTokens
}

export const defaultConfig: ChartsThemeContextValue = {
  customPalettes: {
    blue: [],
    blueDescending: [],
    perModeCategory: {
      [ModeCategory.Bike]: [],
      [ModeCategory.Carpool]: [],
      [ModeCategory.Drive]: [],
      [ModeCategory.PublicTransit]: [],
      [ModeCategory.Remote]: [],
      [ModeCategory.SharedRide]: [],
      [ModeCategory.Walk]: [],
      [ModeCategory.None]: []
    }
  },
  tokens: dynamicColors
}

export const ChartsThemeContext = createContext<Omit<ChartsThemeContextValue, 'children'>>(defaultConfig)
export const useChartsTheme = () => {
  const context = useContext(ChartsThemeContext)
  return context
}
