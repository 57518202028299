import { uuidPattern } from "@commutifi-fe/constants";

const uuidRegex = new RegExp(uuidPattern);

export const isUuid = (str: string | number | undefined): boolean =>
  Boolean(str && typeof str === "string" && uuidRegex.test(str));

export const pathnameMatchesPath = (
  path: string,
  pathname: string,
  options?: { isExact?: boolean }
): boolean => {
  if (!path || !pathname) {
    return false;
  }

  const { isExact } = { isExact: true, ...options };

  const pathPatternParts = path.split("/");
  const pathnameParts = pathname.split("/");

  if (
    (isExact && pathPatternParts.length !== pathnameParts.length) ||
    pathnameParts.length < pathPatternParts.length
  ) {
    return false;
  }

  for (let i = 0; i < pathPatternParts.length; i++) {
    const pathPart = pathPatternParts[i];
    if (pathPart.startsWith(":") && pathPart.toLowerCase().includes("id")) {
      if (!isUuid(pathnameParts[i])) {
        return false;
      }
    } else if (pathPart !== pathnameParts[i]) {
      return false;
    }
  }

  return true;
};

import { Location } from "history";
import qs, { IParseOptions, IStringifyOptions } from "qs";

export enum CommonQueryParams {
  integration = "integration",
  success = "success",
  error = "error",
}

export function parse<T extends Record<string, any>>(
  q: string | undefined,
  options?: IParseOptions
): T | undefined {
  return q
    ? (qs.parse(q, { ignoreQueryPrefix: true, ...options }) as T)
    : undefined;
}
export const stringify = (
  q: string | Record<string, any>,
  options?: IStringifyOptions
) => qs.stringify(q, options);

export const getURLCommonParam = <T = string | number>(
  location: Location,
  param: CommonQueryParams
): T | undefined => {
  const parsed = parse((location || {}).search || window.location.search);
  return parsed?.[param];
};

export const getSuccessParam = (location: Location): string | undefined =>
  getURLCommonParam(location, CommonQueryParams.success);
export const getErrorParam = (location: Location): string | undefined =>
  getURLCommonParam(location, CommonQueryParams.error);

export default {
  parse,
  stringify,
};
