import isFinite from 'lodash/isFinite'
import { MetricProps } from '@commutifi/models/Metrics'
import convert from 'convert-units'
import { ServiceProps } from '@commutifi/models/Service'
import { DEFAULT_UNITS_BY_MEASURE_TYPE, MeasureType } from '@commutifi-fe/constants'
import { convertToUnit, formatToTimeString } from '@commutifi-fe/utils'

const NOT_AVAILABLE = NaN

/**
 * Return the sum of metrics based on the specified key factor
 * As soon as a route metrics is not available we return NaN so we
 * don't get weird numbers on the UI
 * @param key -       key factor (carbon, time, cost)
 * @param frequency - perMonth or perTrip are supported
 */
const sumMetrics = (metrics, key, frequency) =>
  metrics.reduce((sum, metric) => {
    const factorKey = `${key}Factors`
    return (sum +=
      !Number.isNaN(sum) && metric?.[factorKey] && isFinite(metric[factorKey][frequency])
        ? metric[factorKey][frequency]
        : NOT_AVAILABLE)
  }, 0)

/**
 * Returns the average metrics considering the nb of values summed
 * @param total - Metric total
 * @param nb - Number of values summed
 */
const averageMetrics = (total: number, nb: number | undefined) => (Number.isNaN(total) ? total : nb ? total / nb : 0)

/**
 * Used to format metrics from endpoint json schema. This function
 * only retrieves the metric total values. It also format time in minutes
 * The format of values returned is:
 * \{value: '', unit: ''\}
 * @param metrics - Route metrics json received from endpoint
 */
export const formatMetrics = (metrics, ...args) => {
  const metricsToSum = args ? [metrics, ...args] : [metrics]
  return {
    carbon: sumMetrics(metricsToSum, 'carbon', 'perMonth'),
    cost: sumMetrics(metricsToSum, 'cost', 'perMonth'),
    time: averageMetrics(sumMetrics(metricsToSum, 'time', 'perTrip'), metricsToSum.length)
  }
}

/**
 * Return the routes ids classified by their groupId.
 * Ex. \{groupId1: [routeId1, routeId2], groupId2: [routeId3]\}
 * @param routes - Routes to merge by their groupId
 */
export const mergeByGroupId = (routes) => {
  const byGroupId = {}
  routes.forEach((r) => {
    const existingSuggestion = byGroupId[r.groupId]
    if (existingSuggestion) {
      byGroupId[r.groupId] = [...byGroupId[r.groupId], r.id]
    } else if (r.groupId) {
      byGroupId[r.groupId] = [r.id]
    }
  })
  return byGroupId
}

/**
 * Return leg metrics converted in the best fitted units. This function handle
 * the distance and duration.
 * The output is an array of metric objects:
 * [\{value: 123, unit: 'min'\}, \{value: 456, unit: 'm'\}]
 * @param metrics - Leg metrics
 */
export const convertLegMetrics = (
  metrics: Partial<MetricProps> | undefined,
  localUnits: Record<MeasureType, convert.Unit | undefined>
): { value: number | string | undefined; unit: convert.Unit | '' | undefined }[] => {
  const legMetrics: ReturnType<typeof convertLegMetrics> = []
  if (!metrics) return legMetrics
  metrics.distance &&
    legMetrics.push({
      value: convertToUnit(`${metrics.distance} ${DEFAULT_UNITS_BY_MEASURE_TYPE.distance}`, localUnits.distance),
      unit: localUnits.distance
    })

  // Time has more than one metric (could be 1h 15min) so to display with component (value - unit) we set the unit as an empty string
  metrics.duration && legMetrics.push({ value: formatToTimeString(metrics.duration), unit: '' })
  return legMetrics
}

/**
 * Returns the service mode, which is the activity when there is no mode
 * @param service - service from whom we extract the mode/activity
 */
export const getServiceMode = (service: ServiceProps & { activity?: string }) =>
  service.mode ? service.mode : service.activity
