export enum StatusColorMapping {
  // eslint-disable-next-line @typescript-eslint/naming-convention -- We use the booking.status prop that is in lower case... To update this enum we also need to change how we map the color
  active = '#2AA367',
  // eslint-disable-next-line @typescript-eslint/naming-convention -- See first key-value comment
  processing = '#7A648F',
  // eslint-disable-next-line @typescript-eslint/naming-convention -- See first key-value comment
  completed = '#58626D',
  // eslint-disable-next-line @typescript-eslint/naming-convention -- See first key-value comment
  cancelled = '#939393',
  // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-duplicate-enum-values -- See first key-value comment
  unknown = '#939393'
}

export const STATUS: Record<string, 'processing' | 'active' | 'completed' | 'cancelled'> = {
  Processing: 'processing',
  Active: 'active',
  Completed: 'completed',
  Cancelled: 'cancelled'
}

export const PAGE_LIMIT = 15
