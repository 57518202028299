import config from 'config/index'
import { accountEndpoints } from 'api/modules/accounts/endpoints'
import { aiEndpoints } from 'api/modules/aiGuide/endpoints'
import { analyticEndpoints, apiAnalyticEndpoints } from 'api/modules/analytics/endpoints'
import { announcementEndpoints } from 'api/modules/announcements/endpoints'
import { arcEndpoints } from 'api/modules/arcLeed/endpoints'
import { authEndpoints } from 'api/modules/authentication/endpoints'
import { billingEndpoints } from 'api/modules/invoices/endpoints'
import { bookingEndpoints } from 'api/modules/bookings/endpoints'
import { commuteProfileEndpoints } from 'api/modules/commuteProfiles/endpoints'
import { commuterGuideEndpoints } from 'api/modules/commuterGuides/endpoints'
import { commuterPlanEndpoints } from 'api/modules/commuterPlans/endpoints'
import { commutifiCardEndpoints } from 'api/modules/commutifiCards/endpoints'
import { complianceEndpoints } from 'api/modules/compliance/endpoints'
import { enterpriseCreationEndpoints } from 'api/modules/enterprisesRegistrations/endpoints'
import { groupEndpoints } from 'api/modules/groups/endpoints'
import { incentiveProgramEndpoints } from 'api/modules/incentivePrograms/endpoints'
import { merchantEndpoints } from 'api/modules/merchants/endpoints'
import { mobilityServiceEndpoints } from 'api/modules/mobilityServices/endpoints'
import { officeEndpoints } from 'api/modules/offices/endpoints'
import { organizationEndpoints } from 'api/modules/enterprises/endpoints'
import { routeEndpoints } from 'api/modules/routes/endpoints'
import { internalStripeEndpoints } from 'api/modules/stripe/endpoints'
import { subdomainEndpoints } from 'api/modules/subdomains/endpoints'
import { surveyEndpoints } from 'api/modules/survey/endpoints'
import { tripEndpoints } from 'api/modules/trips/endpoints'
import { vehicleEndpoints } from 'api/modules/vehicles/endpoints'
import { walletEndpoints } from 'api/modules/wallets/endpoints'
import flattenDeep from 'lodash/flattenDeep'

export { delay, http, HttpResponse, passthrough } from 'msw'
export type { PathParams } from 'msw'
export const devtoolConfigKey = 'commutifiDevToolConfig'
export const serverBaseUrl = config.dashboardApiUrl
export const fakeAccountTimezone = 'America/Denver'

export const endpointsModules = {
  accountEndpoints,
  aiEndpoints,
  analyticEndpoints,
  apiAnalyticEndpoints,
  announcementEndpoints,
  arcEndpoints,
  authEndpoints,
  billingEndpoints,
  bookingEndpoints,
  commuteProfileEndpoints,
  commuterGuideEndpoints,
  commuterPlanEndpoints,
  commutifiCardEndpoints,
  complianceEndpoints,
  enterpriseCreationEndpoints,
  groupEndpoints,
  incentiveProgramEndpoints,
  merchantEndpoints,
  mobilityServiceEndpoints,
  officeEndpoints,
  organizationEndpoints,
  routeEndpoints,
  internalStripeEndpoints,
  subdomainEndpoints,
  surveyEndpoints,
  tripEndpoints,
  vehicleEndpoints,
  walletEndpoints
}

/**
 * Get all the endpoints from each modules and each Http method but omit
 * the path and route object since the configuration to activate or deactivate
 * endpoints won't mention path or route.
 */
type NestedKeyOfEndpoints<ObjectType extends object> = {
  [Key in keyof ObjectType & (string | number)]: ObjectType[Key] extends object
    ? ObjectType[Key] extends { path: string; route: any }
      ? `${Key}`
      : `${Key}${NestedKeyOfEndpoints<ObjectType[Key]>}`
    : `${Key}`
}[keyof ObjectType & (string | number)]

const endpointModuleNames = Object.keys(endpointsModules).sort()
export const flattenEndpointNames = flattenDeep(
  endpointModuleNames.map((module) => {
    const moduleEndpointsByMethod = endpointsModules[module]
    const endpointsByMethod = Object.keys(moduleEndpointsByMethod).map((method) => {
      const endpoints = Object.keys(moduleEndpointsByMethod[method] || {})
      return endpoints?.map((endpoint) => `${module}${method}${endpoint}`)
    })
    return endpointsByMethod
  })
)

export type MockedEndpointsKeys = NestedKeyOfEndpoints<typeof endpointsModules>
export type MockedEndpointsConfig = Partial<Record<MockedEndpointsKeys, boolean>>
