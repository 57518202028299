import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { resetAllFilters } from '../actions'

const initialState: { selectedOffices: string[] | undefined } = {
  selectedOffices: undefined
}

export const officesSlice = createSlice({
  name: 'offices',
  initialState,
  reducers: {
    setSelectedOffices: (state, action: PayloadAction<string[]>) => {
      state.selectedOffices = action.payload
    },
    resetSelectedOffices: (state) => {
      state.selectedOffices = initialState.selectedOffices
    }
  },
  extraReducers(builder) {
    builder.addCase(resetAllFilters, (state) => {
      state.selectedOffices = initialState.selectedOffices
    })
  }
})

const { setSelectedOffices, resetSelectedOffices } = officesSlice.actions
const officesReducer = officesSlice.reducer

export { setSelectedOffices, resetSelectedOffices, officesReducer }
