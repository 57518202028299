import { HttpMethod } from '../../constants'

function returnPath(): string {
  return this.path
}

export const aiEndpoints = {
  [HttpMethod.Get]: {
    PromptSuggestions: {
      path: '/prompts/suggestions',
      route: returnPath
    }
  },
  [HttpMethod.Patch]: {
    PromptFeedback: {
      path: '/prompts/:promptId/feedback',
      route(promptId: string): string {
        return this.path.replace(':promptId', promptId)
      }
    }
  }
}

export default aiEndpoints
