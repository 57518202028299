import { create, unitDependencies } from 'mathjs'
import { Required } from 'utility-types'

const config = {
  // optionally, you can specify configuration
}

// Create just the functions we need
const { unit } = create(
  {
    unitDependencies
  },
  config
) as Required<Partial<math.MathJsInstance>, 'unit'>

export { unit }
