import { HttpMethod } from '../../constants'

function replaceGroupId(groupId: string): string {
  return this.path.replace(':groupId', groupId)
}
function returnPath(): string {
  return this.path
}

export const groupEndpoints = {
  [HttpMethod.Get]: {
    GroupPlans: {
      path: '/groups/:groupId/plans',
      route: replaceGroupId
    },
    Group: {
      path: '/groups/:groupId',
      route: replaceGroupId
    },
    EnterpriseGroups: {
      path: '/groups/enterprises/:enterpriseId',
      route(enterpriseId: string): string {
        return this.path.replace(':enterpriseId', enterpriseId)
      }
    }
  },
  [HttpMethod.Post]: {
    Group: {
      path: '/groups',
      route: returnPath
    },
    GroupSimulateRules: {
      path: '/groups/simulate-rules',
      route: returnPath
    },
    EnterpriseGroupsPlans: {
      path: '/groups/plans',
      route: returnPath
    }
  },
  [HttpMethod.Put]: {
    Group: {
      path: '/groups/:groupId',
      route: replaceGroupId
    }
  },
  [HttpMethod.Delete]: {
    Group: {
      path: '/groups/:groupId',
      route: replaceGroupId
    }
  }
}

export default groupEndpoints
