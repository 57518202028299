import { createSelector } from 'reselect'
import { providersSelectors } from 'store/modules/providers'
import { servicesSelectors } from 'store/modules/services'

export const getById = ({ legs }) => legs.byId
export const makeGetLegProvider = (id) =>
  createSelector(
    [getById, servicesSelectors.getById, providersSelectors.getById],
    (legsById, servicesById, providersById) => {
      const leg = legsById[id]
      if (!leg) return
      const service = servicesById[leg.serviceId]
      if (!service) return

      const provider = service.providerId ? providersById[service.providerId] : undefined
      return provider
    }
  )
