import faker from 'faker/locale/en'
import { times, isNil } from 'lodash'
import moment from 'moment-timezone'
import { PeriodPriceBreakdownOperation } from '@commutifi/models/PriceBreakdown'
import { PlanInventory } from '@commutifi/models/PlanInventory'
import { PlanBillingCancellationPolicy, PlanWithPricingProps } from '@commutifi/models/Plan'
import { fakeFullAddress, fakeTimezone, generateMD, generateNextDaysDatesFromNow, randomIn } from '../../_utils'
import { fakeCheckout } from '../paymentMethods/fakers'
import {
  DetailedPlan,
  ListPlan,
  PeriodPriceBreakdownProps,
  PlanBillingFirstMonthPolicy,
  PlanBillingMethod,
  PlanBookingField,
  PlanBookingFieldCategory,
  PlanBookingFieldField,
  PlanBookingType,
  PlanCostType,
  PlanFrequency,
  PlanPermissionProps,
  Countries,
  ServiceMode,
  ServiceCategory,
  ServiceType,
  ServiceRegionDefinition
} from 'api/modules/commuterPlans'

const longDescription = `### Description
Did you know active commutes, like biking, reduce stress and increase self wellbeing? To encourage active commuting, we’re offering a 50% subsidy on the new Vanmoof rent-to-own program. For only $39.50 per month, you can have a brand new Vanmoof electric bike to get to and from the office. 
If you forgo your parking spot, you will receive an additional credit on your monthly price reducing its cost to just $20 per month.
[See the 2 available Vanmoof models](https://www.google.com)
`

export type IMockPlan = ListPlan & DetailedPlan

const fakeAccountTimezone = fakeTimezone()

export const generateBookingSoldOut = (): PlanInventory =>
  generateNextDaysDatesFromNow(fakeAccountTimezone).reduce(
    (acc, { startDate }) => ({
      ...acc,
      [startDate]: {
        isSoldOut: true,
        companyAllocationReached: false,
        isUnavailableForAccount: true,
        availableQuantity: 0
      }
    }),
    {}
  )

export const generateUnavailableForUser = (): PlanInventory =>
  generateNextDaysDatesFromNow(fakeAccountTimezone).reduce(
    (acc, { startDate }) => ({
      ...acc,
      [startDate]: {
        isSoldOut: false,
        companyAllocationReached: false,
        isUnavailableForAccount: true,
        availableQuantity: 0
      }
    }),
    {}
  )
export const generateBookingWhereCompanyAllocationWasReached = (): PlanInventory =>
  generateNextDaysDatesFromNow(fakeAccountTimezone).reduce(
    (acc, { startDate }) => ({
      ...acc,
      [startDate]: {
        isSoldOut: false,
        companyAllocationReached: true,
        isUnavailableForAccount: true,
        availableQuantity: 0
      }
    }),
    {}
  )

export const generateBookingAlwaysAvailable = (): PlanInventory =>
  generateNextDaysDatesFromNow(fakeAccountTimezone).reduce(
    (acc, { startDate }) => ({
      ...acc,
      [startDate]: {
        isSoldOut: false,
        companyAllocationReached: false,
        isUnavailableForAccount: false,
        availableQuantity: faker.datatype.number(randomIn({ min: 1, max: 50 }))
      }
    }),
    {}
  )

export const generateBookingWithPartialDailyAvailability = (): PlanInventory =>
  generateNextDaysDatesFromNow(fakeAccountTimezone).reduce(
    (acc, { startDate }) => ({
      ...acc,
      [startDate]: {
        isSoldOut: faker.datatype.boolean(),
        companyAllocationReached: false,
        isUnavailableForAccount: faker.datatype.boolean(),
        availableQuantity: randomIn({ min: 1, max: 50 })
      }
    }),
    {}
  )

/**
 * @param amount - amount with taxes in DOLLARS (for readability)
 * @param isSubsidized - To know if we add subsidy object and total to 0
 */
export const generatePlanPricing = (amount: number, isSubsidized = false): PlanWithPricingProps['pricing'] => {
  const baseAmount = amount * 100
  return {
    baseAmountWithTaxes: {
      amountWithoutTaxes: baseAmount,
      amountWithTaxes: baseAmount,
      taxes: 0
    },
    isTaxEstimated: false,
    totalAmount: isSubsidized ? 0 : baseAmount,
    isMissingTaxes: false,
    ...(isSubsidized && {
      subsidy: {
        isFullSubsidy: true,
        allowedBy: {
          walletId: faker.datatype.uuid(),
          walletName: faker.finance.accountName(),
          walletEnterpriseId: faker.datatype.uuid()
        }
      }
    })
  }
}

export const fakePlanBookingFields = (fields?: (PlanBookingFieldField | null)[]): PlanBookingField[] =>
  (fields ?? [...Object.values(PlanBookingFieldField), PlanBookingFieldField.ACCOUNT_VEHICLE_ID])
    .filter((f) => !isNil(f))
    .map((field: PlanBookingFieldField) => ({
      id: faker.datatype.uuid(),
      question: field === PlanBookingFieldField.START_DATE ? 'Start date' : faker.lorem.sentence(),
      category:
        field === PlanBookingFieldField.ACCOUNT_VEHICLE_ID || field === PlanBookingFieldField.HAS_EV_CHARGING
          ? PlanBookingFieldCategory.ACCOUNT_VEHICLES_BOOKINGS
          : field === PlanBookingFieldField.PHONE_NUMBER
            ? PlanBookingFieldCategory.ACCOUNT
            : field === PlanBookingFieldField.START_DATE
              ? PlanBookingFieldCategory.BOOKING
              : PlanBookingFieldCategory.CUSTOM,
      field,
      createdAt: faker.date.past().toUTCString(),
      updatedAt: faker.date.past().toUTCString()
    }))
export const fakeRegionPoint = () => {
  const lng = parseInt(faker.address.longitude())
  const lat = parseInt(faker.address.latitude())
  return {
    lng,
    lat,
    toJSON: () => ({ lng, lat })
  }
}

export const fakeInventoryForNextDays = (numberOfDays = 30): PlanInventory => {
  const nowMoment = moment.tz(moment.now(), fakeAccountTimezone)
  return times(numberOfDays, () => nowMoment.add(1, 'day').toISOString()).reduce(
    (accInventory, date) => ({
      ...accInventory,
      [date]: {
        isSoldOut: faker.datatype.boolean(),
        companyAllocationReached: false,
        isUnavailableForAccount: faker.datatype.boolean()
      }
    }),
    {}
  )
}

export const fakePlan = (basePlan: any = {}): IMockPlan => {
  const planCost = basePlan.costAmount || randomIn({ max: 1000 })
  const bookingType = basePlan.bookingType || faker.random.arrayElement(Object.values(PlanBookingType))
  const costType = basePlan.costType
    ? basePlan.costType
    : bookingType === PlanBookingType.STANDARD || bookingType === PlanBookingType.UNIQUE
      ? PlanCostType.FIXED
      : faker.random.arrayElement(Object.values(PlanCostType))

  return {
    ...basePlan,
    id: basePlan.id || faker.datatype.uuid(),
    isEnabled: true,
    isPublic: true,
    thirdPartyIntegration: basePlan.thirdPartyIntegration || undefined,
    isRestrictedByRegion: faker.datatype.boolean(),
    serviceId: basePlan.serviceId || faker.datatype.uuid(),
    isFeatured: isNil(basePlan.isFeatured) ? faker.datatype.boolean() : basePlan.isFeatured,
    canBuyMany: isNil(basePlan.canBuyMany) ? faker.datatype.boolean() : basePlan.canBuyMany,
    name: basePlan.name || faker.lorem.words(randomIn({ min: 2, max: 4 })),
    shortDescription: basePlan.shortDescription || faker.lorem.words(randomIn({ min: 5, max: 25 })),
    longDescription: basePlan.longDescription || longDescription,
    imageUrl: Object.prototype.hasOwnProperty.call(basePlan, 'imageUrl')
      ? basePlan.imageUrl
      : faker.datatype.boolean()
        ? faker.image.imageUrl()
        : undefined,
    supportContact:
      basePlan.supportContact || isNil(basePlan.supportContact)
        ? basePlan.supportContact
        : faker.datatype.boolean()
          ? {
              email: faker.internet.email(faker.name.firstName(), faker.name.lastName(), 'commutifi'),
              phone: faker.phone.phoneNumber()
            }
          : undefined,
    terms: basePlan.terms ?? { content: generateMD(), ...basePlan.terms },
    bookingType,
    frequency: basePlan.frequency
      ? basePlan.frequency
      : bookingType === PlanBookingType.RECURRENT
        ? faker.random.arrayElement([PlanFrequency.MONTHLY, PlanFrequency.YEARLY])
        : bookingType === PlanBookingType.SINGULAR
          ? faker.random.arrayElement([PlanFrequency.PER_TRIP, PlanFrequency.DAILY])
          : undefined,
    bookingInstructions: basePlan.bookingInstructions ?? generateMD(),
    activationInstructions: basePlan.activationInstructions ?? generateMD(),
    terminationInstructions: basePlan.terminationInstructions ?? generateMD(),
    billingMethod: basePlan.billingMethod || faker.random.arrayElement(Object.values(PlanBillingMethod)),
    billingDetails: basePlan.billingDetails ?? faker.lorem.text(),
    billingFirstMonthPolicy:
      basePlan.billingFirstMonthPolicy || faker.random.arrayElement(Object.values(PlanBillingFirstMonthPolicy)),
    billingCancellationPolicy:
      basePlan.billingCancellationPolicy || faker.random.arrayElement(Object.values(PlanBillingCancellationPolicy)),
    costType,
    costAmount: costType === PlanCostType.FREE ? undefined : planCost,
    pricing: basePlan.pricing || {
      baseAmountWithTaxes: {
        amountWithoutTaxes: planCost * 100,
        amountWithTaxes: planCost * 100,
        taxes: 0
      }
    },
    costActivationFees: basePlan.costActivationFees ?? randomIn({ min: 0, max: 4.5 }),
    costTransactionFees: basePlan.costTransactionFees ?? randomIn({ min: 0, max: 3 }),
    isBookingConfirmationRequired: basePlan.isBookingConfirmationRequired ?? faker.datatype.boolean(),
    isTerminationConfirmationRequired: basePlan.isTerminationConfirmationRequired ?? faker.datatype.boolean(),
    isTermValidationRequired: basePlan.isTermValidationRequired ?? faker.datatype.boolean(),
    isSignatureRequired: basePlan.isSignatureRequired ?? faker.datatype.boolean(),
    regions:
      basePlan.regions ||
      times(randomIn({ min: 0, max: 3 }), () => ({
        id: faker.datatype.uuid(),
        postalCode: faker.datatype.boolean() ? faker.address.zipCode() : undefined,
        address: fakeFullAddress(),
        point: faker.datatype.boolean() ? fakeRegionPoint() : undefined,
        polygon: faker.datatype.boolean()
          ? {
              type: 'Polygon',
              coordinates: [[]]
            }
          : undefined,
        countryCode: faker.address.countryCode() as Countries,
        displayLabel: faker.address.state(),
        serviceId: faker.datatype.uuid(),
        createdAt: faker.date.past().toUTCString(),
        updatedAt: faker.date.past().toUTCString()
      })),
    service: {
      id: faker.datatype.uuid(),
      isEnabled: true,
      isInventoryRestricted: faker.datatype.boolean(),
      mode: faker.random.arrayElement(Object.values(ServiceMode)),
      category: faker.random.arrayElement(Object.values(ServiceCategory)),
      name: faker.lorem.words(randomIn({ min: 1, max: 5 })),
      providerId: faker.datatype.uuid(),
      available: true,
      type: faker.random.arrayElement([ServiceType.PROGRAM, ServiceType.BENEFIT]),
      location: { lat: parseInt(faker.address.latitude()), lng: parseInt(faker.address.longitude()) },
      address: faker.address.city(),
      regionDefinition: faker.random.arrayElement(Object.values(ServiceRegionDefinition)),
      backgroundUrl: faker.image.imageUrl(),
      displayName: faker.lorem.words(randomIn({ min: 1, max: 4 })),
      createdAt: faker.date.past().toUTCString(),
      updatedAt: faker.date.past().toUTCString(),
      ...(basePlan.service || {}),
      provider: {
        id: faker.datatype.uuid(),
        name: faker.lorem.word(),
        description: faker.lorem.paragraphs(),
        logoUrl: faker.image.imageUrl(),
        website: faker.internet.url(),
        createdAt: faker.date.past().toUTCString(),
        updatedAt: faker.date.past().toUTCString(),
        ...(basePlan.service?.provider || {})
      }
    },
    planFaqs:
      basePlan.planFaqs ||
      times(4, () => ({
        id: faker.datatype.uuid(),
        question: faker.lorem.sentence(),
        answer: faker.lorem.paragraph(),
        createdAt: faker.date.past().toUTCString(),
        updatedAt: faker.date.past().toUTCString()
      })),
    planBookingFields: basePlan.planBookingFields,
    inventory: basePlan.inventory || fakeInventoryForNextDays(),
    createdAt: faker.date.past().toUTCString(),
    updatedAt: faker.date.past().toUTCString()
  }
}

export const fakeCheckoutFromPlan = (
  plan: IMockPlan | undefined,
  { quantity, disableSubsidy }: { quantity?: number; disableSubsidy?: boolean }
) => {
  const breakdown: PeriodPriceBreakdownProps['breakdown'] = [
    {
      operation: PeriodPriceBreakdownOperation.BaseCost,
      amount: Math.floor((plan?.costAmount || 0) * 100),
      priceBefore: 0,
      priceAfter: Math.floor((plan?.costAmount || 0) * 100)
    }
  ]
  const priceBefore = breakdown[breakdown.length - 1].priceAfter
  breakdown.push({
    operation: PeriodPriceBreakdownOperation.ActivationFee,
    // Apply fee of 0 if none are set on the plan so it's easier to validate test cases results
    amount: Math.floor((plan?.costActivationFees || 0) * 100),
    priceBefore,
    priceAfter: priceBefore + Math.floor((plan?.costActivationFees || 0) * 100)
  })

  return fakeCheckout(
    {
      quantity: quantity ?? 1,
      breakdown
    },
    {
      applySubsidy: 'subsidy' in (plan?.pricing || {}) && !disableSubsidy,
      bookingType: plan?.bookingType || PlanBookingType.RECURRENT
    }
  )
}

export const fakePlanPermissions = (base: Partial<PlanPermissionProps> = {}): PlanPermissionProps => ({
  ...base,
  planId: base.planId || faker.datatype.uuid(),
  enterpriseId: base.enterpriseId || faker.datatype.uuid(),
  inventoryAllocation: base.inventoryAllocation ?? randomIn({ min: 1, max: 200 }),
  createdAt: faker.date.past().toUTCString(),
  updatedAt: faker.date.past().toUTCString()
})
