import { postCommuteProfile } from 'api/modules/commuteProfiles'
import * as api from 'api/modules/routes'
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects'
import { plansActions } from 'store/modules/plans'
import { providersActions } from 'store/modules/providers'
import { makeGetServicesProvidersIds } from 'store/modules/services/selectors'
import * as actions from './actions'
import { makeGetMergedRoutePlansIds, makeGetRouteServicesIds, makeGetRouteWithLegs } from './selectors'
import types from './types'

import isNil from 'lodash/isNil'
import mapValues from 'lodash/mapValues'
import pick from 'lodash/pick'
import uniq from 'lodash/uniq'
import values from 'lodash/values'
import { normalize } from 'normalizr'
import * as schemas from 'store/schemas'

export const routesSagas = [
  takeEvery(types.FETCH_USER_ROUTES, getUserRoutes),
  takeEvery(types.FETCH_SUGGESTION_ROUTES, getSuggestionRoutes),
  takeLatest(types.FETCH_ROUTE_PROVIDERS, fetchRouteProviders),
  takeLatest(types.FETCH_ROUTE_PLANS, fetchRoutePlans),
  takeLatest(types.ADD_NEW_USER_ROUTE_REQUEST, addNewUserRoute)
]

export const processSelfProvidedLegs = (legsById) => {
  return mapValues(legsById, (leg) => ({
    ...leg,
    ...(leg.mode && {
      serviceId: leg.serviceId || `SELF_PROVIDED_${leg.mode.toUpperCase()}`,
      service: leg.service || `SELF_PROVIDED_${leg.mode.toUpperCase()}`
    })
  }))
}

export function* getUserRoutes({ accountId }) {
  try {
    yield put(actions.fetchRoutesRequest(accountId))
    const res = yield call(api.fetchRoutes, accountId)
    const normalizedRoutes = yield normalize(res, schemas.arrayOfRoutes)
    const processedNormalizedRoutes = {
      ...normalizedRoutes,
      entities: {
        ...normalizedRoutes.entities,
        leg: processSelfProvidedLegs(normalizedRoutes.entities.leg)
      }
    }
    yield put(actions.fetchRoutesSuccess(processedNormalizedRoutes))

    // On fetch user route success we fetch the plans to have all
    // the information we need about user routes
    const planIds = values(processedNormalizedRoutes.entities.leg)
      .map((l) => l.planId)
      .filter((planId) => planId)
    const uniqPlanIds = uniq(planIds)
    yield put(plansActions.fetchPlans(uniqPlanIds))
  } catch (error) {
    yield put(actions.fetchRoutesFailure(error))
  }
}

export function* getSuggestionRoutes({ userRouteId }) {
  try {
    yield put(actions.fetchSuggestionRoutesRequest(userRouteId))
    const res = yield call(api.fetchSuggestionRoutes, userRouteId)
    const normalizedRoutes = yield normalize(res, schemas.arrayOfRoutes)
    const processedNormalizedRoutes = {
      ...normalizedRoutes,
      entities: {
        ...normalizedRoutes.entities,
        leg: processSelfProvidedLegs(normalizedRoutes.entities.leg)
      }
    }
    yield put(actions.fetchSuggestionRoutesSuccess(userRouteId, processedNormalizedRoutes))

    // On fetch user route success we fetch the plans to have all
    // the information we need about user routes
    const planIds = values(processedNormalizedRoutes.entities.leg)
      .map((l) => l.planId)
      .filter((planId) => planId)
    const uniqPlanIds = uniq(planIds)
    yield put(plansActions.fetchPlans(uniqPlanIds))
  } catch (error) {
    yield put(actions.fetchSuggestionRoutesFailure(userRouteId, error))
  }
}

/**
 * On route selection we fetch the providers informations needed
 * to display the logo and more on the map
 */
export function* fetchRouteProviders({ routeId }) {
  try {
    const servicesIdsSelector = makeGetRouteServicesIds(routeId)
    const servicesIds = yield select(servicesIdsSelector)
    const providersIdsSelector = makeGetServicesProvidersIds(servicesIds)
    const providersIds = yield select(providersIdsSelector)
    yield put(providersActions.fetchProviders(providersIds))
  } catch (error) {
    console.error(error)
  }
}

/**
 * On route selection we fetch the plans informations needed
 * to display the prices available
 */
export function* fetchRoutePlans({ routeId, promise }) {
  try {
    const planIdsSelector = makeGetMergedRoutePlansIds(routeId)
    const plansIds = yield select(planIdsSelector)
    yield put(plansActions.fetchPlans(plansIds))
    if (promise) promise.resolve()
  } catch (error) {
    console.error(error)
    if (promise) promise.reject()
  }
}

export function* addNewUserRoute({ newRouteId, promise }) {
  try {
    const suggestionRoute = yield select(makeGetRouteWithLegs(newRouteId))
    const suggestionLegs = suggestionRoute.legs || []
    const legWithVehicle = suggestionLegs.find((leg) => !!leg.vehicleId)
    const activites = suggestionLegs.map((leg) => leg.activity).filter((leg) => !isNil(leg))
    yield call(postCommuteProfile, {
      body: {
        accountId: suggestionRoute.accountId,
        activities: activites,
        arriveOfficeTime: suggestionRoute.arriveAtOffice,
        days: [],
        endAddress: suggestionRoute.endAddress,
        leaveOfficeTime: suggestionRoute.leaveOffice,
        legs: suggestionLegs.map((leg) =>
          pick(leg, ['mode', 'activity', 'type', 'startLocation', 'startAddress', 'endLocation', 'endAddress'])
        ),
        startAddress: suggestionRoute.startAddress,
        vehicleId: legWithVehicle ? legWithVehicle.vehicleId : null,
        weeklyFrequency: 0,
        timezone: suggestionRoute.timezone
      }
    })

    yield put(actions.addNewUserRouteSuccess())
    if (promise) yield call(promise.resolve)
  } catch (error) {
    yield put(actions.addNewUserRouteFailure(error))
    if (promise) yield call(promise.reject, error)
  }
}
