import React from 'react'
import { ErrorCodes, SuccessCodes } from '@commutifi-fe/constants'
import { IntlMessageKeys, useComponentIntl } from '../locales'
import { getCommutifiErrorCode, getHttpStatusFromError } from '@commutifi-fe/utils'

// This object is defined so we can use typescript to give an error if a value
// is added to the enum but not here
const errorKeyObj: Record<ErrorCodes, IntlMessageKeys<'errors'>> = {
  [ErrorCodes.InvalidGrant]: 'error.stripe.authorize.invalidGrant',
  [ErrorCodes.AccessDenied]: 'error.stripe.authorize.accessDenied',
  [ErrorCodes.ArcOAuthError]: 'settings.integrations.leed.connect.error',
  [ErrorCodes.StripeOAuthError]: 'settings.integrations.stripeConnect.notifications.error'
}
const errorKeyMap = new Map<string, IntlMessageKeys<'errors'>>(Object.entries(errorKeyObj))

// This object is defined so we can use typescript to give an error if a value
// is added to the enum but not here
const successKeyObj: Record<SuccessCodes, IntlMessageKeys<'errors'>> = {
  [SuccessCodes.ArcOauth]: 'settings.integrations.leed.connect.success'
}
const successKeyMap = new Map<string, IntlMessageKeys<'errors'>>(Object.entries(successKeyObj))

export const useCommutifiErrorMessages = () => {
  const intl = useComponentIntl('errors')
  const translate = React.useCallback(
    (errorCode?: string | number) =>
      intl.formatMessage({
        // @ts-expect-error (Because we have a defaultMessage defined)
        id: `error.commutifi.${errorCode ?? 500}`,
        defaultMessage: intl.formatMessage({ id: 'error.commutifi.500' })
      }),
    [intl]
  )

  const translateCommutifiSuccess = React.useCallback(
    (success: string | null, fallbackMessage?: string) =>
      /**
       * Handle success string that are most probably from the URL search params
       */
      success && successKeyMap.get(success)
        ? intl.formatMessage({
            id: successKeyMap.get(success)
          })
        : fallbackMessage,
    [intl]
  )

  const translateCommutifiError = React.useCallback(
    (error: unknown, fallbackMessage?: string) => {
      /**
       * Handle error strings that are most probably from the URL search params
       */
      const errorStr = typeof error === 'string' ? error : null
      if (errorStr) {
        return intl.formatMessage({
          id: errorKeyMap.get(errorStr) || 'error.commutifi.500'
        })
      }

      const errorCode = getCommutifiErrorCode(error) ?? getHttpStatusFromError(error) ?? 500

      return errorCode === 500 && fallbackMessage
        ? fallbackMessage
        : intl.formatMessage({
            // @ts-expect-error (Because we have a defaultMessage defined)
            id: `error.commutifi.${errorCode}`,
            defaultMessage: intl.formatMessage({ id: 'error.commutifi.500' })
          })
    },
    [intl]
  )

  return {
    translate,
    getCommutifiErrorCode,
    translateCommutifiError,
    translateCommutifiSuccess
  }
}
