import type { GuideCategoryNames } from '@commutifi/constants/Guides'
import {
  CommuterGuideRepositoryImpl,
  CommuterGuideResourcesRepositoryImpl
} from '@commutifi-fe/commutifi-specific/Data/Repositories/CommuterGuide'
import {
  GetCommuterGuideRequest,
  GetCommuterGuideResourcesRequest
} from '@commutifi-fe/commutifi-specific/Domain/Repositories/CommuterGuide'
import type { RequestParams } from 'shared/interfaces/Api'
import { AxiosHttpInstance } from '../../index'
import endpoints from './endpoints'

export interface CommuterGuidesQueryParams extends RequestParams {
  enterpriseId?: string | null
  buildingId?: string | null
}

interface ResourcesQueryParams extends RequestParams {
  categories?: GuideCategoryNames[]
  enterpriseId?: string | null
  buildingId?: string | null
  isVisible?: boolean
}

export type CommuterGuideRequest = GetCommuterGuideRequest<CommuterGuidesQueryParams>
export const makeCommuterGuideRequest = (
  query: CommuterGuideRequest['queryParams'],
  options: CommuterGuideRequest['queryOptions']
) => new GetCommuterGuideRequest<CommuterGuidesQueryParams>(query, options)

export const makeCommuterGuideRepository = () =>
  CommuterGuideRepositoryImpl<CommuterGuidesQueryParams>(endpoints.GET.CommuterGuides.route(), AxiosHttpInstance)

export type CommuterGuideResourcesRequest = GetCommuterGuideResourcesRequest<ResourcesQueryParams>
export const makeCommuterGuideResourcesRequest = (
  query: CommuterGuideResourcesRequest['queryParams'],
  options?: CommuterGuideResourcesRequest['queryOptions']
) => new GetCommuterGuideResourcesRequest<ResourcesQueryParams>(query, options)

export const makeCommuterGuideResourcesRepository = () =>
  CommuterGuideResourcesRepositoryImpl<ResourcesQueryParams>(
    {
      getResources: endpoints.GET.Resources.route(),
      postResourceSms: (resourceId) => endpoints.POST.ResourcesSms.route(resourceId)
    },
    AxiosHttpInstance
  )
