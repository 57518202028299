import { castArray, isNil } from 'lodash'
import { FeatureFlagResponse, FeatureFlags } from '@commutifi/models/FeatureFlag'

export const isFeatureEnabled =
  (featureFlags: FeatureFlagResponse | null | undefined) => (flag: FeatureFlags | FeatureFlags[] | undefined) => {
    const flags = (isNil(flag) ? flag : castArray(flag)) as FeatureFlags[] | undefined | null
    return (
      (featureFlags && flags && flags.length > 0 && flags.some((flag) => featureFlags[flag] === true)) ||
      flags === undefined
    )
  }
