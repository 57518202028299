import { EnterpriseKind } from '@commutifi/models/Enterprises'
import { HttpMethod } from '../../constants'

function replaceEnterpriseId(enterpriseId: string | undefined): string {
  if (enterpriseId === undefined) {
    throw new Error('enterpriseId is required')
  }
  return this.path.replace(':enterpriseId', enterpriseId)
}

export const analyticEndpoints = {
  [HttpMethod.Get]: {
    AnalyticReports: {
      path: '/analytic-reports',
      route: () => '/analytic-reports'
    },
    AnalyticReport: {
      path: '/analytic-reports/:analyticReportId',
      route: (analyticReportId: string) => `/analytic-reports/${analyticReportId}`
    },
    HeatmapScores: {
      path: '/:kind/:entityId/heatmaps/scores',
      route(kind: EnterpriseKind, entityId: string): string {
        return this.path
          .replace(':kind', kind === EnterpriseKind.organization ? 'organizations' : 'enterprises')
          .replace(':entityId', entityId)
      }
    },
    HeatmapScoresLegend: {
      path: '/:kind/:entityId/heatmaps/scores/legend',
      route(kind: EnterpriseKind, entityId: string): string {
        return this.path
          .replace(':kind', kind === EnterpriseKind.organization ? 'organizations' : 'enterprises')
          .replace(':entityId', entityId)
      }
    },
    MetabaseDashboard: {
      path: '/organizations/:enterpriseId/metabase/dashboard',
      route: replaceEnterpriseId
    },
    MetabaseCustomDashboard: {
      path: '/organizations/:enterpriseId/metabase/custom',
      route: replaceEnterpriseId
    },
    OfficesFilters: {
      path: '/analytics/filters/offices',
      route(): string {
        return this.path
      }
    },
    CitiesFilters: {
      path: '/analytics/filters/cities',
      route(): string {
        return this.path
      }
    },
    AttributesFilters: {
      path: '/analytics/filters/attributes',
      route(): string {
        return this.path
      }
    }
  }
}

/**
 * Define all endpoints directed to api-analytics-rs. All route functions should have the same parameters
 * so it's easy to dynamically process those. This is an abstraction we are trying to do to make it easier
 * and faster to create new endpoints with hooks for analytics. We might need to refactor this in the future!
 */
export const apiAnalyticEndpoints = {
  [HttpMethod.Get]: {
    OfficesCommuteIntervals: {
      path: '/analytics/offices/commute-intervals',
      route(): string {
        return this.path
      }
    },
    MapAccountsData: {
      path: '/analytics/accounts/map',
      route(): string {
        return this.path
      }
    },
    CommuteDistanceDistribution: {
      path: '/analytics/distributions/distances',
      route(): string {
        return this.path
      }
    },
    CommuteTimeDistribution: {
      path: '/analytics/distributions/times',
      route(): string {
        return this.path
      }
    },
    EvSplit: {
      path: '/analytics/ev-split',
      route(): string {
        return this.path
      }
    },
    VehicleOccupantsCount: {
      path: '/analytics/number-vehicle-occupants',
      route(): string {
        return this.path
      }
    },
    AvgCommuteMetrics: {
      path: '/analytics/average-commute-metrics/all',
      route(): string {
        return this.path
      }
    },
    ModeSplit: {
      path: '/analytics/category-mode-split',
      route(): string {
        return this.path
      }
    },
    SOVRate: {
      path: '/analytics/rates/sov',
      route(): string {
        return this.path
      }
    },
    TotalAnnualEmissions: {
      path: '/analytics/total-annual-emissions-commuting',
      route(): string {
        return this.path
      }
    },
    AverageAnnualEmissionPerEmployee: {
      path: '/analytics/average-annual-emission-per-employee',
      route(): string {
        return this.path
      }
    },
    EnterpriseAccountsCount: {
      path: '/enterprises/:enterpriseId/accounts/count',
      route: replaceEnterpriseId
    },
    DailyModeDistribution: {
      path: '/analytics/mode-distribution/daily',
      route(): string {
        return this.path
      }
    },
    OfficeCapacityVsRemote: {
      path: '/analytics/office-capacity-remote-vs-commuting',
      route(): string {
        return this.path
      }
    },
    NumberOfRemoteDays: {
      path: '/analytics/number-of-remote/daily',
      route(): string {
        return this.path
      }
    }
  }
}

export default analyticEndpoints
