import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import './styles.scss'
import { colors } from '../../../style/constants'
import { useLocaleReceiver } from '../../../locales/LocaleReceiver'

function ErrorBoundaryFallbackComponent() {
  const intl = useLocaleReceiver('FallbackError')
  return (
    <div className="app-fallback-error">
      <FontAwesomeIcon
        icon={faExclamationCircle}
        color={colors.feedbackDestructive400}
        size="lg"
        className="u-margin-right--small"
      />
      <span>{intl.formatMessage({ id: 'title' }, { link: intl.formatMessage({ id: 'linkText' }) })}</span>
    </div>
  )
}

export default ErrorBoundaryFallbackComponent
