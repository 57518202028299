import {
  init,
  getCurrentScope,
  getDefaultIntegrations,
  reactRouterV5BrowserTracingIntegration,
  replayIntegration,
  captureConsoleIntegration
} from '@sentry/react'
import config from 'config/index'
import pkg from '../package.json'
import { setHeader } from './api/index'
import history from './browserHistory'
import { AdminRouting, CommonRouting, CommuterRouting, LegacyRouting, PublicRouting } from './routing'
import * as logRocket from './utils/logRocket'

function iterateRoutes<T extends Record<string, any>, S>(obj: T, callback: (value: T[keyof T]) => S) {
  const returnedValues: NonNullable<S>[] = []
  Object.keys(obj).forEach((key) => {
    const callbackValue = callback(obj[key])
    if (callbackValue) {
      returnedValues.push(callbackValue)
    }

    if (typeof obj[key] === 'object' && obj[key] !== null) {
      iterateRoutes(obj[key], callback)
    }
  })

  return returnedValues
}

const initSentry = (options?: { hidePersonalData: boolean }) => {
  if ((process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging') && config.sentryDSN) {
    const appRoutes = iterateRoutes(
      { ...CommuterRouting, ...AdminRouting, ...CommonRouting, ...PublicRouting, ...LegacyRouting },
      (value) => (value.path ? { path: value.path } : undefined)
    )
    init({
      dsn: config.sentryDSN,
      // Using a tunnel helps prevent ad blockers
      tunnel: `${config.dashboardApiUrl}/sentry/tunnel`,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
      // All unhandled exceptions are automatically captured by Sentry
      integrations: [
        ...getDefaultIntegrations({}),
        reactRouterV5BrowserTracingIntegration({ history, routes: appRoutes }),
        captureConsoleIntegration(),
        replayIntegration()
      ],

      // We recommend adjusting this value in production, or using tracesSampler
      // for finer control
      tracesSampleRate: 1.0,
      release: `${pkg.name}@${pkg.version}`,
      environment: process.env.NODE_ENV
    })

    if (options?.hidePersonalData) {
      const scope = getCurrentScope()
      scope.setUser({
        ip_address: 'not-your-business'
      })
    }
  }
}

const initLogRocket = (options?: { hidePersonalData: boolean }) => {
  if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging') {
    logRocket.init(options)

    const sessionURL = logRocket.getSessionUrl()
    if (sessionURL && config.sentryDSN) {
      const scope = getCurrentScope()
      scope.setExtra('sessionURL', sessionURL)
      setHeader('X-LogRocket-URL', sessionURL)
    }
  }
}

let setupDone = false
export const initialSetup = (options: { hidePersonalData: boolean } = { hidePersonalData: true }) => {
  if (setupDone) {
    return
  }
  initLogRocket(options)
  initSentry(options)

  if (!options.hidePersonalData) {
    gtag('config', 'G-D5P4WLSG6J', { anonymize_ip: false })
    grantGAConsent()
  }
  setupDone = true
}

const grantGAConsent = () => {
  window.gtag('consent', 'update', {
    ad_storage: 'granted',
    analytics_storage: 'granted'
  })
}
