import React from 'react'
import { Skeleton as AntdSkeleton } from 'antd'
import { SkeletonProps } from 'antd/es/skeleton'
import { SkeletonParagraphProps } from 'antd/es/skeleton/Paragraph'
import { SkeletonInputProps } from 'antd/es/skeleton/Input'
import cx from 'classnames'
import './styles.scss'

export type { SkeletonProps, SkeletonParagraphProps }

const PrefixCls = 'app-skeleton'

/**
 * This class is a wrap around [antd Skeleton](https://ant.design/components/skeleton/) component extending some of
 * the properties to follow our styling guidelines.
 *
 * Please refer to antd documentation for more details.
 *
 */
export function Skeleton({ className, ...props }: SkeletonProps) {
  return <AntdSkeleton {...props} className={cx(PrefixCls, className)} />
}

export function SingleLineText({
  className,
  fontSize = 14,
  width = '100%',
  style,
  ...props
}: SkeletonInputProps & {
  // Used to set the height of the skeleton. Number will assume pixels, string will be used as is.
  fontSize?: string | number
  width?: string | number
}) {
  return (
    <AntdSkeleton.Input
      style={{
        height: typeof fontSize === 'number' ? `${fontSize}px` : fontSize,
        minWidth: '15px',
        width: typeof width === 'number' ? `${width}px` : width,
        ...style
      }}
      {...props}
      className={cx(PrefixCls, 'app-skeleton-single-line-text', className)}
    />
  )
}

Skeleton.Avatar = AntdSkeleton.Avatar
Skeleton.Button = AntdSkeleton.Button
Skeleton.Image = AntdSkeleton.Image
Skeleton.Input = AntdSkeleton.Input
Skeleton.Node = AntdSkeleton.Node
Skeleton.SingleLineText = SingleLineText
Skeleton.displayName = AntdSkeleton.displayName
Skeleton.defaultProps = AntdSkeleton.defaultProps
Skeleton.contextTypes = AntdSkeleton.contextTypes
