import { differenceBy, filter, memoize, shuffle } from 'lodash'
import { faCircleExclamation, faCreditCard, faIdBadge, faTicketAlt, faParking } from '@fortawesome/free-solid-svg-icons'
import { faMug } from '@fortawesome/pro-solid-svg-icons'
import {
  CommutifiIconDefinition,
  faBike,
  faBikeshare,
  faCarpool,
  faCarshare,
  faCoinAlt,
  faElectricPlug,
  faStars,
  faSubway,
  faRemote,
  faRideshare,
  faBus,
  faVanpool,
  faWalk
} from '@commutifi-fe/custom-icons/fontawesome'
import { GuideCategoryNames } from '@commutifi/constants/Guides'
import { CommuterGuide, GetOneSectionFunc, GetRandomSectionsFunc } from '../../Models/CommuterGuide'

/**
 * Get one commuter guide based on an id.
 * We do a find because there should be only few commute profiles
 */
export const getSectionByIdMemoized = memoize(
  (commuterGuide: CommuterGuide | undefined): GetOneSectionFunc =>
    (sectionId) => {
      if (!commuterGuide) {
        return
      }
      return commuterGuide.guideSections.find((guideSection) => guideSection.id === sectionId)
    }
)

/**
 * Get a random number of guides. This function simply return X (nb param) guides randomly
 * to allow showing different guides each time the page is accessed. We can also optionally
 * exclude one or more specific categories.
 *
 * Featured sections have priority. This means we will first return all featured sections,
 * in a random order and if there still is space append some non-featured sections.
 *
 * @param commuterGuides - all commuter guides available that we previously fetched
 * @param count - number of randomly chosen guide to return
 * @param excludeCategories - categories we should avoid to return the guide if it's in this array
 *
 * @returns X count of randomly chosen commuter guides or less if we can't find the
 *         count requested (or if there is guides filtered out by some excluded category)
 */
export const getRandomSectionsMemoized = memoize(
  (commuterGuide: CommuterGuide | undefined): GetRandomSectionsFunc =>
    (count, excludeCategories = []) => {
      if (!commuterGuide?.guideSections || commuterGuide.guideSections.length === 0 || !count) {
        return []
      }
      // Remove the excluded categories
      const filteredSections = differenceBy(
        commuterGuide.guideSections,
        excludeCategories.map((category) => ({ category })),
        'category'
      )
      // Split the array into featured and non-featured sections
      const featuredSections = filter(filteredSections, 'isFeatured')
      const nonFeaturedSections = filter(filteredSections, ['isFeatured', false])

      // Shuffle all featured sections and take either all of them or the count sent
      const shuffledFeaturedSections = shuffle(featuredSections).slice(0, Math.min(count, featuredSections.length))
      // If we have enough sections from the featured sections alone, return the shuffled sections
      if (shuffledFeaturedSections.length === count) {
        return shuffledFeaturedSections
      }

      // Else fill the array with the remaining non-featured sections
      const randomSections = shuffle(nonFeaturedSections).slice(
        0,
        Math.min(count - shuffledFeaturedSections.length, nonFeaturedSections.length)
      )

      return shuffledFeaturedSections.concat(randomSections)
    }
)

/**
 * Categories used to build a commuter guide with different sections.
 * Each guide category maps to a specific icon and can also be useful
 * for other sections like the ETRP suggestions, which is why you will
 * see 'extended' categories that are not in the helpers library for now
 */
export const guideCategoryIconName: Record<string, CommutifiIconDefinition> = {
  [GuideCategoryNames.Amenities]: faMug,
  [GuideCategoryNames.Bike]: faBike,
  [GuideCategoryNames.Bikeshare]: faBikeshare,
  [GuideCategoryNames.Carpool]: faCarpool,
  [GuideCategoryNames.Carshare]: faCarshare,
  [GuideCategoryNames.CommuterBenefits]: faCoinAlt,
  [GuideCategoryNames.ElectricVehicles]: faElectricPlug,
  [GuideCategoryNames.EmergencyRideHome]: faCircleExclamation,
  [GuideCategoryNames.Incentives]: faStars,
  [GuideCategoryNames.MobilityCard]: faCreditCard,
  [GuideCategoryNames.OfficeAccess]: faIdBadge,
  [GuideCategoryNames.Parking]: faParking,
  [GuideCategoryNames.PublicTransit]: faSubway,
  [GuideCategoryNames.RemoteWork]: faRemote,
  [GuideCategoryNames.Rideshare]: faRideshare,
  [GuideCategoryNames.Shuttle]: faBus,
  transitPass: faTicketAlt,
  [GuideCategoryNames.Vanpool]: faVanpool,
  [GuideCategoryNames.Walk]: faWalk
}

export const getGuideCategoryTranslationKey = (guideCategory?: GuideCategoryNames | null) => {
  switch (guideCategory) {
    case GuideCategoryNames.Amenities:
      return 'guide.categories.amenities'
    case GuideCategoryNames.Bike:
      return 'guide.categories.bicycling'
    case GuideCategoryNames.Bikeshare:
      return 'guide.categories.bikesharing'
    case GuideCategoryNames.Carpool:
      return 'guide.categories.carpooling'
    case GuideCategoryNames.Carshare:
      return 'guide.categories.carsharing'
    case GuideCategoryNames.CommuterBenefits:
      return 'guide.categories.commuterBenefits'
    case GuideCategoryNames.MobilityCard:
      return 'guide.categories.commutifiCard'
    case GuideCategoryNames.ElectricVehicles:
      return 'guide.categories.electricVehicle'
    case GuideCategoryNames.EmergencyRideHome:
      return 'guide.categories.emergencyRide'
    case GuideCategoryNames.Incentives:
      return 'guide.categories.incentives'
    case GuideCategoryNames.OfficeAccess:
      return 'guide.categories.incentives'
    case GuideCategoryNames.Parking:
      return 'guide.categories.parking'
    case GuideCategoryNames.PublicTransit:
      return 'guide.categories.public_transit'
    case GuideCategoryNames.RemoteWork:
      return 'guide.categories.remote'
    case GuideCategoryNames.Rideshare:
      return 'guide.categories.ridesharing'
    case GuideCategoryNames.Shuttle:
      return 'guide.categories.shuttle'
    case GuideCategoryNames.Vanpool:
      return 'guide.categories.vanpool'
    case GuideCategoryNames.Walk:
      return 'guide.categories.walking'
    default:
      return 'guide.categories.unknown'
  }
}
