export const LOGIN_SUCCESS = 'auth/LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'auth/LOGIN_FAILURE'
export const LOGIN_REQUEST = 'auth/LOGIN_REQUEST'

export const LOGOUT = 'auth/LOGOUT'
export const LOGOUT_SUCCESS = 'auth/LOGOUT_SUCCESS'
export const UNAUTHORIZE_USER = 'auth/UNAUTHORIZE_USER'
export const CHANGE_USER = 'auth/CHANGE_USER'
export const CLEAR_USER = 'auth/CLEAR_USER'
export const STAY_SIGNED_IN = 'auth/STAY_SIGNED_IN'
export const VERIFY_HAS_PASSWORD = 'auth/VERIFY_HAS_PASSWORD'

export const CREATE_PASSWORD_SUCCESS = 'auth/CREATE_PASSWORD_SUCCESS'
export const CREATE_PASSWORD_FAILURE = 'auth/CREATE_PASSWORD_FAILURE'
export const CREATE_PASSWORD_REQUEST = 'auth/CREATE_PASSWORD_REQUEST'
