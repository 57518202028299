// Refer to url below for options to define your schema
// https://github.com/paularmstrong/normalizr/blob/master/docs/api.md#entitykey-definition---options--

import { NormalizedSchema, schema, SchemaObject } from 'normalizr'
import { BuildingProps } from '@commutifi/models/Buildings'
import { EnterpriseProps } from '@commutifi/models/Enterprises'
import { SurveyProps } from '@commutifi/models/Surveys'
import { FeatureFlagResponse } from '@commutifi/models/FeatureFlag'
import { Account } from '@commutifi-fe/interfaces'

export { normalize } from 'normalizr'

export type NormalizedData<T> = Record<string, T>

export type NormalizerResultForFeatureFlags = NormalizedSchema<
  { featureFlags: NormalizedData<FeatureFlagResponse> },
  string[]
>
export type NormalizerResultForAccounts = NormalizedSchema<{ account: NormalizedData<Account | undefined> }, string>
export type NormalizerResultForOrganizations = NormalizedSchema<
  { organizations: NormalizedData<EnterpriseProps> | null; enterprises: NormalizedData<EnterpriseProps> | null },
  { enterprise: string | null; organization: string | null }
>
export type NormalizedOrganization = SchemaObject<EnterpriseProps>

export const provider = new schema.Entity('providers')
export const service = new schema.Entity('services', {
  provider
})
export const plan = new schema.Entity('plans', {
  services: [service]
})
export const arrayOfPlans = [plan]

export const account = new schema.Entity('account')
export const enterprise = new schema.Entity<EnterpriseProps>('enterprises')
export const arrayOfEnterprises = [enterprise]

export const office = new schema.Entity('offices')
export const arrayOfOffices = [office]

export const leg = new schema.Entity('leg', {
  service
})

export const routeMetrics = new schema.Entity('metrics')
export const route = new schema.Entity('route', {
  legs: [leg],
  metrics: routeMetrics
})
export const arrayOfRoutes = new schema.Array(route)

export const organization = new schema.Entity<EnterpriseProps>('organizations')

export const organizationOrEnterprise = new schema.Object({
  enterprise,
  organization
})

export const subscription = new schema.Entity('subscriptions', {
  account,
  plan
})
export const arrayOfSubscriptions = [subscription]

export const question = new schema.Entity('questions')
export const survey = new schema.Object({
  questions: [question]
})
export const enterpriseSurvey = new schema.Object<{
  id: string
  accountId: string | null
  account: Account | null
  buildingId: string | null
  building: BuildingProps | null
  enterpriseId: string | null
  enterprise: EnterpriseProps | null
  organizationId: string | null
  organization: EnterpriseProps | null
  survey: SurveyProps
}>({
  survey
})

export const featureFlag = new schema.Entity<FeatureFlagResponse>('featureFlags', {}, { idAttribute: 'enterpriseId' })
export const arrayOfFeatureFlags = new schema.Array(featureFlag)
