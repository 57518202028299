import faker from 'faker/locale/en'
import { times } from 'lodash'
import { AccountActivationStatus } from '@commutifi/models/Accounts'
import { Account } from '@commutifi-fe/interfaces'
import type { ExtendedAccount } from 'shared/interfaces/Account'
import { fakeAddress, fakeCommutifiEmail, randomIn } from '../_utils'
import { fakeGroups } from '../modules/groups/data'
import { fakeOffice } from '../modules/offices/fakers'

export const fakeAccount = (base: Partial<ExtendedAccount | Account> = {}): Account => {
  const firstName = faker.name.firstName()
  const lastName = faker.name.lastName()
  const office = base.office || fakeOffice()
  return {
    id: base.id || faker.datatype.uuid(),
    createdAt: faker.date.past().toISOString(),
    updatedAt: faker.date.past().toISOString(),
    activationStatus: base.activationStatus || faker.random.arrayElement(Object.values(AccountActivationStatus)),
    commuteProfileIds: [faker.datatype.uuid()],
    email: fakeCommutifiEmail(),
    employeeId: base.employeeId || faker.datatype.uuid(),
    enterpriseId: base.enterpriseId || faker.datatype.uuid(),
    organizationId: base.organizationId || faker.datatype.uuid(),
    hasDashboardAccess: true,
    identityProviderProfile: null,
    isDeactivated: false,
    isEmailVerified: true,
    isOnboarded: true,
    lastInvitationDate: null,
    lastLogin: faker.date.past().toISOString(),
    location: { lat: parseFloat(faker.address.latitude()), lng: parseFloat(faker.address.longitude()) },
    managedEnterprises: [],
    name: `${firstName} ${lastName}`,
    office,
    officeId: office.id || faker.datatype.uuid(),
    phoneNumber: faker.phone.phoneNumber('+1#######'),
    groups: base.groups || times(randomIn({ min: 1, max: 3 }), (i) => fakeGroups[i]),
    ...fakeAddress()
  }
}

export const fakeUuid = () => faker.datatype.uuid()
