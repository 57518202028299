import { UnknownAction } from 'redux'
import { call, put } from 'redux-saga/effects'

export interface IFetchAction<Result, Payload extends Record<string, any>> extends UnknownAction {
  type: string
  types: [string, string, string] | undefined
  callAPI: (...args: any[]) => any
  promise?: {
    resolve: typeof Promise.resolve
    reject: typeof Promise.reject
  } | null
  normalize: (data: any) => Result
  payload?: Payload
}

// Source: https://redux.js.org/recipes/reducing-boilerplate
// Thanks for the inspiration. Note that the middleware taken from the
// source stated above was converted to work with sagas
export function* callApiSaga<Result, Payload extends Record<string, any>>({
  types,
  callAPI,
  payload,
  promise = null,
  normalize = (data: Result) => data
}: IFetchAction<Result, Payload>) {
  if (!types) {
    throw new Error('This function should be called to reduce REQUEST, SUCCESS, FAILURE pattern boilerplate.')
  }

  if (!Array.isArray(types) || !types.every((type) => typeof type === 'string')) {
    throw new Error('Expected an array of three string types that define your request, success and failure actions.')
  }

  if (typeof callAPI !== 'function') {
    throw new Error('Expected callAPI to be a function.')
  }

  const [beginAction, successAction, failAction] = types
  yield put({
    payload,
    type: beginAction
  })

  try {
    const response: unknown = yield call(callAPI)
    const resPayload = {
      payload,
      response: normalize(response)
    }
    yield put({
      ...resPayload,
      type: successAction
    })
    if (promise) {
      return promise.resolve(resPayload)
    }
    return
  } catch (error) {
    const errorPayload = {
      payload,
      error
    }
    yield put({
      ...errorPayload,
      type: failAction
    })
    if (promise) {
      return promise.reject(errorPayload)
    }
  }
  return undefined
}
