import { ModeCategory, ModeName, Stop } from '@commutifi/modes'
import { DaysValueEnum, Metric, RouteLeg, RouteLegWithMetrics, Score, Vehicle } from 'shared/interfaces/Commute'
import { GetResponse, RequestParams } from 'shared/interfaces/Api'
import { apiCall, dashboardApiServer } from '../../index'
import endpoints from './endpoints'

const apiDashboardServer = apiCall(dashboardApiServer)

export interface CommuteProfile {
  accountId: string
  activities?: Stop[] | null
  arriveOfficeTime: string | null
  days?: DaysValueEnum[]
  endAddress?: string
  id: string
  leaveOfficeTime: string | null
  legs: Partial<RouteLeg>[]
  startAddress?: string
  vehicleId?: string | null
  weeklyFrequency: number
  isReturn: boolean
  timezone?: string | null
  commuteCosts?: CommuteCost[]
  accountVehicleId?: string
  createdAt?: string
  updatedAt?: string
}

export interface CommuteProfileProcessed extends CommuteProfile {
  orderedModes: ModeName[]
  scores: Score
  metrics: { daily: Metric; monthly: Metric }
  goingLegs?: RouteLegWithMetrics[]
  returnLegs?: RouteLegWithMetrics[]
  vehicle?: Partial<Vehicle>
}

export interface CommuteCost {
  id: string
  cost: number
  paymentFrequency: string
  mode?: ModeName
  category?: ModeCategory
  stop?: Stop
  createdAt?: string
  updatedAt?: string
}

export type PostCommuteProfileBody =
  | (Omit<CommuteProfile, 'id' | 'isReturn'> & {
      commuteCosts?: CommuteCost[]
    })
  // Or if we send 'assumeMissingValues' and 'assumedTimestampRef' query params we will omit more details
  | Omit<CommuteProfile, 'id' | 'isReturn' | 'arriveOfficeTime' | 'leaveOfficeTime' | 'weeklyFrequency'>

export interface PostCommuteProfileQueryParams {
  assumeMissingValues?: boolean
  assumedTimestampRef?: string
}

interface PutCommuteProfileBody extends CommuteProfileProcessed {
  commuteCosts?: CommuteCost[]
}

// --- GET ---
export const fetchCommuteProfiles = (
  queryParams: { accountId?: string | null } & RequestParams = {}
): Promise<GetResponse<CommuteProfileProcessed>> =>
  apiDashboardServer(endpoints.GET.CommuteProfiles.route(), {
    method: 'get',
    queryParams
  })

export const fetchCommuteProfile = (
  commuteProfileId: string,
  queryParams: RequestParams = {}
): Promise<CommuteProfileProcessed> =>
  apiDashboardServer(endpoints.GET.CommuteProfileSummary.route(commuteProfileId), {
    method: 'get',
    queryParams
  })

// --- UPDATE ---
export interface PatchRequestParams {
  id: string
  previousId?: string
  day: DaysValueEnum | undefined
  data: Partial<CommuteProfile>
}
export const patchCommuteProfile = (params: PatchRequestParams) =>
  apiDashboardServer(endpoints.PATCH.CommuteProfile.route(params.id), {
    method: 'patch',
    data: params.data
  })

export interface PutRequestParams {
  data: PutCommuteProfileBody
}
export const putCommuteProfile = (params: PutRequestParams): Promise<CommuteProfile> =>
  apiDashboardServer(endpoints.PUT.CommuteProfile.route(params.data.id), {
    method: 'put',
    data: params.data
  })

// --- POST ---
export const postCommuteProfile = ({
  body: data,
  queryParams
}: {
  body: PostCommuteProfileBody
  queryParams?: PostCommuteProfileQueryParams
}): Promise<CommuteProfile> =>
  apiDashboardServer(endpoints.POST.CommuteProfiles.route(), {
    method: 'post',
    queryParams,
    data
  })

// --- DELETE ---
export const deleteCommuteProfile = (commuteProfileId: string) =>
  apiDashboardServer(endpoints.DELETE.CommuteProfile.route(commuteProfileId), {
    method: 'delete'
  })
