import { takeEvery, takeLatest } from 'redux-saga/effects'
import { setLocaleHeader } from 'api/index'
import { FetchMeSuccessAction, UpdateAccountAction } from 'store/modules/accounts/actions'
import * as accountTypes from 'store/modules/accounts/types'
import { ChangeLocaleAction } from './actions'
import * as types from './types'

/**
 * This will be used in offline contexts so we update the headers to make
 * sure we communicate our locale preferences to our API.
 */
export function* updateLocale({ locale }: ChangeLocaleAction) {
  yield setLocaleHeader(locale)
}

export function* syncAccountUpdateWithAccountLocale({ payload }: UpdateAccountAction) {
  if (payload?.preferredLocale) {
    yield setLocaleHeader(payload.preferredLocale)
  }
}
export function* syncFetchMeWithAccountLocale({ payload }: FetchMeSuccessAction) {
  const me = payload.entities.account[payload.result]
  if (me?.preferredLocale) {
    yield setLocaleHeader(me.preferredLocale)
  }
}

export const localSettingsSagas = [
  takeEvery(types.CHANGE_LOCALE, updateLocale),
  takeLatest([accountTypes.UPDATE_ACCOUNT_REQUEST], syncAccountUpdateWithAccountLocale),
  takeLatest([accountTypes.FETCH_ME_SUCCESS], syncFetchMeWithAccountLocale)
]
