import { Row as AntRow } from 'antd'
import type { RowProps } from 'antd/es/grid'
import cx from 'classnames'
import React from 'react'
import './styles.scss'

export type { RowProps }

const AppColClassName = 'app-row'

/**
 * This class is a wrap around [antd Col](https://ant.design/components/grid/) component extending some of
 * the properties to follow our styling guidelines.
 *
 * Please refer to antd documentation for more details.
 *
 */
export const Row = React.forwardRef<HTMLDivElement, RowProps>(({ className, ...props }: RowProps, ref) => (
  <AntRow ref={ref} {...props} className={cx(AppColClassName, className)} />
))

Row.displayName = 'Row'
