import { UnknownAction } from 'redux'
import { Account } from '@commutifi-fe/interfaces'
import { NormalizerResultForAccounts } from 'store/schemas'
import * as types from './types'

export interface FetchAccountAction extends UnknownAction {
  type: typeof types.FETCH_ACCOUNT_REQUEST
  promise?: {
    resolve: typeof Promise.resolve
    reject: typeof Promise.reject
  }
}

export interface FetchAccountSuccessAction extends UnknownAction {
  type: typeof types.FETCH_ACCOUNT_SUCCESS
  response: NormalizerResultForAccounts
}

export interface FetchAccountFailureAction extends UnknownAction {
  type: typeof types.FETCH_ACCOUNT_FAILURE
  error: any
}

export interface UpdateAccountAction extends UnknownAction {
  type: typeof types.UPDATE_ACCOUNT_REQUEST
  payload?: Partial<Account>
  promise?: {
    resolve: typeof Promise.resolve
    reject: typeof Promise.reject
  }
}

export interface UpdateAccountSuccessAction extends UnknownAction {
  type: typeof types.UPDATE_ACCOUNT_SUCCESS
  response: NormalizerResultForAccounts
}

export interface UpdateAccountFailureAction extends UnknownAction {
  type: typeof types.UPDATE_ACCOUNT_FAILURE
  error: {
    error: any
    payload: Partial<Account>
  }
}

export interface FetchMeAction extends UnknownAction {
  type: typeof types.FETCH_ME_REQUEST
  promise?: {
    resolve: typeof Promise.resolve
    reject: typeof Promise.reject
  }
}

export interface FetchMeSuccessAction extends UnknownAction {
  type: typeof types.FETCH_ME_SUCCESS
  payload: NormalizerResultForAccounts
}

export interface FetchMeFailureAction extends UnknownAction {
  type: typeof types.FETCH_ME_FAILURE
  error: any
}

export type AccountActionTypes =
  | FetchMeAction
  | FetchMeSuccessAction
  | FetchMeFailureAction
  | FetchAccountAction
  | FetchAccountSuccessAction
  | FetchAccountFailureAction
  | UpdateAccountAction
  | UpdateAccountSuccessAction
  | UpdateAccountFailureAction

export const fetchMeSuccess = (payload: NormalizerResultForAccounts): FetchMeSuccessAction => ({
  type: types.FETCH_ME_SUCCESS,
  payload
})

export const fetchMeFailure = (error: any): FetchMeFailureAction => ({
  type: types.FETCH_ME_FAILURE,
  error
})

export const fetchMeRequest = (promise?: {
  resolve: typeof Promise.resolve
  reject: typeof Promise.reject
}): FetchMeAction => ({
  type: types.FETCH_ME_REQUEST,
  promise
})
