import FETCH_STATUS from 'constants/global'
import { combineReducers } from 'redux'
import types from './types'

const subsidiesByPlanId = (state = {}, action = {}) => {
  switch (action.type) {
    case types.FETCH_PLAN_SUBSIDIES_SUCCESS:
      // Output should be:
      // We wrap subsidies in an array cause we were summing them. But
      // then we decided to apply only the highest subsidy when there is many for one plan
      // To minimize the impact of changes on the frontend (we are in a big prod deploy)
      // we decided to keep an array even if there is only one subsidy
      // {
      //    planId1: [maxSubsidy]
      //    planId2: [maxSubsidy]
      //    ...
      // }
      return action.payload
        ? action.payload.reduce((subsidiesByPlanId, planSubsidy) => {
            const existingSubsidies = subsidiesByPlanId[planSubsidy.planId]
            return existingSubsidies
              ? { ...subsidiesByPlanId, [planSubsidy.planId]: [Math.min(existingSubsidies[0], planSubsidy.subsidy)] }
              : { ...subsidiesByPlanId, [planSubsidy.planId]: [planSubsidy.subsidy] }
          }, state)
        : state
    default:
      return state
  }
}

const fetchStatus = (state = {}, action = {}) => {
  switch (action.type) {
    case types.FETCH_PLAN_SUBSIDIES_SUCCESS:
      return {
        ...state,
        ...action.planIds.reduce((byPlanId, planId) => ({ ...byPlanId, [planId]: FETCH_STATUS.LOADED }), {})
      }
    case types.FETCH_PLAN_SUBSIDIES_FAILURE:
      return {
        ...state,
        ...action.planIds.reduce((byPlanId, planId) => ({ ...byPlanId, [planId]: FETCH_STATUS.FAILED }), {})
      }
    default:
      return state
  }
}

export default combineReducers({
  subsidiesByPlanId,
  fetchStatus
})
