const TOGGLE_IS_OPEN = 'header/TOGGLE_IS_OPEN'
const SET_IS_OPEN = 'header/SET_IS_OPEN'

export const toggleIsOpen = () => ({
  type: TOGGLE_IS_OPEN
})

export const setIsOpen = (opened: boolean) => ({
  type: SET_IS_OPEN,
  opened
})

interface ToggleOpenAction {
  type: typeof TOGGLE_IS_OPEN
}

interface SetIsOpenAction {
  type: typeof SET_IS_OPEN
  opened: boolean
}

type HeaderActionTypes = ToggleOpenAction | SetIsOpenAction

const initialState = {
  opened: false
}

export function headerReducer(state = initialState, action: HeaderActionTypes = {} as any) {
  switch (action.type) {
    case TOGGLE_IS_OPEN:
      return {
        ...state,
        opened: !state.opened
      }
    case SET_IS_OPEN:
      return {
        ...state,
        opened: action.opened
      }
    default:
      return state
  }
}

export const getIsOpen = ({ header }: { header: { opened: boolean } }) => header.opened

export type HeaderState = ReturnType<typeof headerReducer>
export default headerReducer
