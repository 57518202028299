import types from './types'
import { combineReducers } from 'redux'
import FETCH_STATUS from 'constants/global'
import plansTypes from 'store/modules/plans/types'

export const byIdInitialState = {
  SELF_PROVIDED: {
    id: 'SELF_PROVIDED',
    name: 'SELF_PROVIDED',
    logo: null,
    description: 'Service that is provided by the user itself',
    website: null
  }
}

const byId = (state = byIdInitialState, action = {}) => {
  switch (action.type) {
    case types.FETCH_PROVIDERS_SUCCESS:
    case plansTypes.FETCH_PLANS_SUCCESS:
      return {
        ...state,
        ...action.payload.entities.providers
      }
    default:
      return state
  }
}

// Avoid trying to do API calls to fetch the "self provided" provider
// since we already hard code all the necessary information in the byIdInitialState
export const fetchStatusInitialState = {
  SELF_PROVIDED: FETCH_STATUS.LOADED
}

const fetchStatus = (state = fetchStatusInitialState, action = {}) => {
  switch (action.type) {
    case types.FETCH_PROVIDERS_REQUEST:
      return { ...state, [action.id]: FETCH_STATUS.LOADING }
    case types.FETCH_PROVIDERS_SUCCESS:
      return { ...state, [action.id]: FETCH_STATUS.LOADED }
    case types.FETCH_PROVIDERS_FAILURE:
      return { ...state, [action.id]: FETCH_STATUS.FAILED }
    case plansTypes.FETCH_PLANS_SUCCESS:
      return {
        ...state,
        ...Object.keys(action.payload.entities.providers || {}).reduce(
          (providersFetchStatus, providerId) => ({ ...providersFetchStatus, [providerId]: FETCH_STATUS.LOADED }),
          {}
        )
      }
    default:
      return state
  }
}

export default combineReducers({
  byId,
  fetchStatus
})
