import { AccountWalletProps, AccountWalletStatus, ConfirmationStatus } from '@commutifi/models/AccountsWallets'
import { DaysOfTheWeek, SpendingRuleType } from '@commutifi/models/WalletSpendingRules'
import { WalletType } from '@commutifi/models/Wallets'
import type { WalletProps } from '@commutifi/models/Wallets'
import faker from 'faker/locale/en'
import { chunk, flatten, times } from 'lodash'
import type { Required } from 'utility-types'
import type { ExtendedWalletSpendingRule, WalletBalance } from 'api/modules/wallets'
import { randomIn } from '../../_utils'
import { fakeEnterpriseGroupsPlans, fakeGroups } from '../groups/data'
import { fakeWallet, fakeWalletEnrolment, fakeGroupWallet, fakeAccount } from './fakers'
import { CurrencyCode } from 'constants/settings'

export const commonWalletId = 'a3db42b2-ea55-48e5-be67-3e4eabda7188'
export const anotherWalletId = '7a18d03e-e661-11ec-8fea-0242ac120002'

export type WalletWithRuleProps = WalletProps & {
  spendingRules: ExtendedWalletSpendingRule[]
}

export const walletsBase: Required<Partial<WalletWithRuleProps>, 'id' | 'name'>[] = [
  {
    ...fakeWallet({ id: commonWalletId, spendingRules: [] }),
    id: commonWalletId,
    name: 'Variety Wallet',
    accountsWallets: times(4, () => fakeWalletEnrolment()),
    type: WalletType.CardMonthlyAllocation,
    monthlyTotalAllocation: 10000,
    offersMobilityCard: true,
    spendingRules: [
      {
        dailyLimit: undefined,
        monthlyLimit: undefined,
        transactionLimit: undefined,
        weekDaysLimit: undefined,
        startTimeLimit: undefined,
        endTimeLimit: undefined,
        type: SpendingRuleType.StripeMerchant,
        value: '24422444',
        cardMerchant: { name: 'Joe Baker' },
        walletId: commonWalletId
      },
      {
        monthlyLimit: undefined,
        transactionLimit: undefined,
        weekDaysLimit: undefined,
        startTimeLimit: undefined,
        endTimeLimit: undefined,
        dailyLimit: 1500,
        type: SpendingRuleType.StripeCategory,
        value: 'bus_lines',
        walletId: commonWalletId
      },
      {
        monthlyLimit: undefined,
        transactionLimit: undefined,
        weekDaysLimit: [DaysOfTheWeek.Thu, DaysOfTheWeek.Fri],
        startTimeLimit: '08:00:00',
        endTimeLimit: '18:00:00',
        dailyLimit: undefined,
        type: SpendingRuleType.Wallet,
        value: undefined,
        walletId: commonWalletId
      },
      {
        monthlyLimit: undefined,
        transactionLimit: undefined,
        weekDaysLimit: undefined,
        startTimeLimit: undefined,
        endTimeLimit: undefined,
        dailyLimit: undefined,
        type: SpendingRuleType.CommutifiPlan,
        value: fakeEnterpriseGroupsPlans[0].planId,
        walletId: commonWalletId
      }
    ]
  },
  {
    id: '751cf22a-e661-11ec-8fea-0242ac129992',
    name: 'Wall-e.T',
    accountsWallets: times(200, () => fakeWalletEnrolment()),
    monthlyTotalAllocation: 8000,
    type: WalletType.CardMonthlyAllocation,
    isConfirmationRequired: false,
    spendingRules: [
      {
        dailyLimit: 1000,
        monthlyLimit: undefined,
        transactionLimit: undefined,
        weekDaysLimit: undefined,
        startTimeLimit: undefined,
        endTimeLimit: undefined,
        type: SpendingRuleType.StripeMerchant,
        value: '24422444',
        cardMerchant: { name: 'Joe Baker' },
        walletId: commonWalletId
      }
    ]
  },
  {
    id: '819eb21a-e661-11ec-8fea-0242ac120002',
    name: 'My money, it folds',
    accountsWallets: [],
    monthlyTotalAllocation: undefined,
    type: WalletType.FullySubsidized,
    spendingRules: [],
    isConfirmationRequired: false
  },
  {
    id: '354rg23f-u344-22gt-2rth-6531er324449',
    name: 'All commutifi plans',
    accountsWallets: [],
    monthlyTotalAllocation: undefined,
    type: WalletType.FullySubsidized,
    // Make sure last enterprise plan has no wallet to be able to test that case
    spendingRules: fakeEnterpriseGroupsPlans.slice(0, fakeEnterpriseGroupsPlans.length - 1).map((groupPlan) => ({
      monthlyLimit: undefined,
      transactionLimit: undefined,
      weekDaysLimit: undefined,
      startTimeLimit: undefined,
      endTimeLimit: undefined,
      dailyLimit: undefined,
      type: SpendingRuleType.CommutifiPlan,
      value: groupPlan.planId,
      walletId: '354rg23f-u344-22gt-2rth-6531er324449'
    }))
  }
]
// Insure some use cases for test purpose (all different statuses)
const baseData: Partial<
  AccountWalletProps & { balance?: WalletBalance['balance']; monthlyTotalAllocation?: number }
>[] = [
  {
    // Expect status to be Pending
    walletId: anotherWalletId,
    wallet: fakeWallet({ ...walletsBase[1], id: anotherWalletId }),
    status: AccountWalletStatus.Processing,
    confirmationStatus: ConfirmationStatus.Processing,
    startDate: undefined,
    endDate: undefined
  },
  {
    // Expect status to be Active
    walletId: commonWalletId,
    wallet: fakeWallet({ ...walletsBase[0], id: commonWalletId }),
    // This is needed so when we fake some wallets for the mocked account we will get
    // one that is already enrolled if we properly use the common wallet id
    accountId: fakeAccount.id,
    status: AccountWalletStatus.Active,
    confirmationStatus: ConfirmationStatus.Confirmed,
    startDate: faker.date.past().toISOString(),
    endDate: undefined,
    balance: {
      amountAvailable: 8256,
      spent: 1744
    },
    monthlyTotalAllocation: 10000
  },
  {
    // Expect status to be Terminated
    walletId: anotherWalletId,
    wallet: fakeWallet({ ...walletsBase[1], id: anotherWalletId }),
    status: AccountWalletStatus.Terminated,
    confirmationStatus: ConfirmationStatus.Confirmed,
    startDate: faker.date.past().toISOString(),
    endDate: faker.date.past().toISOString()
  },
  {
    // Expect status to be Rejected
    walletId: anotherWalletId,
    wallet: fakeWallet({ ...walletsBase[1], id: anotherWalletId }),
    status: AccountWalletStatus.Rejected,
    confirmationStatus: ConfirmationStatus.Rejected,
    rejectionDate: faker.date.past().toISOString(),
    startDate: undefined,
    endDate: undefined
  }
]

// 2 pages or generated data for tests purpose
export const fakeEnrolmentsData = times(2 * 15, (i) => {
  const randomWallet = walletsBase[randomIn({ min: 0, max: walletsBase.length - 1 })]
  return fakeWalletEnrolment(
    baseData[i]
      ? {
          ...baseData[i],
          walletName: baseData[i].wallet?.name || faker.name.jobTitle()
        }
      : {
          walletId: randomWallet.id,
          walletName: randomWallet.name,
          // Make sure we have some enrolment pending that we can Accept | Reject
          ...(Boolean(i % 4 === 0) && {
            status: AccountWalletStatus.Processing,
            wallet: { isConfirmationRequired: Boolean(i % 4 === 0) }
          })
        }
  )
})

export const fakeEuEnrolmentsData = times(2 * 15, (i) => {
  const randomWallet = walletsBase[randomIn({ min: 0, max: walletsBase.length - 1 })]
  return fakeWalletEnrolment(
    baseData[i]
      ? {
          ...baseData[i],
          walletName: baseData[i].wallet?.name || faker.name.jobTitle(),
          wallet: { currency: CurrencyCode.EUR }
        }
      : {
          walletId: randomWallet.id,
          walletName: randomWallet.name,
          // Make sure we have some enrolment pending that we can Accept | Reject
          ...(Boolean(i % 4 === 0) && {
            status: AccountWalletStatus.Processing,
            wallet: { isConfirmationRequired: Boolean(i % 4 === 0), currency: CurrencyCode.EUR }
          })
        }
  )
})

// The active enrolment with the common wallet id (second entry in baseData)
export const commonWalletEnrolment = fakeEnrolmentsData[1]
export const enterpriseWallets = walletsBase.map((w) => fakeWallet(w))
export const accountsWallets = [
  fakeWallet(walletsBase[0] /* The one with the common wallet id */),
  fakeWallet(walletsBase[1])
]

export const fakeEnterpriseWalletGroups = flatten(
  chunk(fakeGroups, 3).map((groupChunk, i) =>
    groupChunk.map((group) => fakeGroupWallet({ walletId: enterpriseWallets[i].id, group }))
  )
)

export const fakeEmployeeSingleWalletEnrollment = fakeWalletEnrolment()
