import * as api from 'api/modules/commuterPlans'
import { call, fork, put, takeLatest } from 'redux-saga/effects'
import { getPlansSubsidies } from 'store/modules/benefits/sagas'
import * as actions from './actions'
import types from './types'

import { normalize } from 'normalizr'
import * as schemas from 'store/schemas'

export const plansSagas = [takeLatest(types.FETCH_PLANS, getPlans)]

export function* getPlans({ planIds }) {
  try {
    if (planIds.length <= 0) return yield put(actions.fetchPlansCancel())
    yield put(actions.fetchPlansRequest(planIds))
    const res = yield call(api.fetchPlansLegacy, planIds)
    const normalizedPlans = yield normalize(Array.isArray(res.data) ? res.data : [res.data], schemas.arrayOfPlans)

    // LEGACY: But if we get to work on that: find a way to get subsidies from a plan id so enterprises can access them for a subscription plan details
    if (normalizedPlans && normalizedPlans.result && normalizedPlans.result.length > 0) {
      // Fetch result plans subsidies if there is any plan
      yield fork(getPlansSubsidies, normalizedPlans.result)
    }

    yield put(actions.fetchPlansSuccess(normalizedPlans.result, normalizedPlans))
  } catch (error) {
    console.error(error)
    yield put(actions.fetchPlansFailure(planIds, error))
  }
}
