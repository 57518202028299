import { StripePostalAddress } from '@commutifi/models/shared/StripePostalAddress'
import addressFormatter from '@fragaria/address-formatter'
import flatten from 'lodash/flatten'
import { Address, Route } from 'shared/interfaces'

interface LegacyVehicle {
  color: string
  plate: string
  plateState: string
  model: string
  state: string
}

/**
 * Returns the formatted vehicles with the names matching the db columns
 * @param vehicles - Arrays of unformatted vehicles
 * @param fieldNames - Field name to use to access vehicle color, plate, state and model
 * @example \{color: 'vehicleColor', plate: 'vehiclePlate', ...\}
 */
const defaultVehicleFieldNames: Partial<Record<'color' | 'plate' | 'state' | 'model', keyof LegacyVehicle>> = {
  color: 'color',
  plate: 'plate',
  state: 'state',
  model: 'model'
}
export const formatVehicles = (
  vehicles: LegacyVehicle[] | undefined,
  fieldNames: Partial<Record<'color' | 'plate' | 'state' | 'model', keyof LegacyVehicle>>
) => {
  const fieldsMapping = { ...defaultVehicleFieldNames, ...fieldNames }
  const descriptionInfo = vehicles?.reduce((acc, v, i) => {
    const nb = i === 0 ? '' : i + 1 // pattern will be: vehicleColor, vehicleColor2
    return {
      ...acc,
      [`vehicleColor${nb}`]: fieldsMapping.color ? v[fieldsMapping.color] || null : null,
      [`licensePlate${nb}`]: fieldsMapping.plate ? v[fieldsMapping.plate] || null : null,
      [`state${nb}`]: fieldsMapping.state ? v[fieldsMapping.state] || null : null
    }
  }, {})
  // Add array of vehicle model uuids
  const vehicleUuids = vehicles ? vehicles.map((v) => (fieldsMapping.model ? v[fieldsMapping.model] : null)) : []
  return { ...descriptionInfo, vehicleUuids: vehicleUuids.filter((v) => v !== null) as string[] }
}

export const formatSearchQuery = (searchQuery: string | null | undefined) =>
  searchQuery && searchQuery !== '' ? { searchQuery } : {}

const isStripePostalAddress = (address: Partial<Address> | StripePostalAddress): address is StripePostalAddress =>
  Object.prototype.hasOwnProperty.call(address, 'line1')
/**
 * See \@fragaria/address-formatter
 * Inline is the default format and you can pass
 * an option to display a multiline address (format: array).
 * To add the country use option country (appendCountry: true)
 * For more details please see their doc https://github.com/fragaria/address-formatter
 */
export function formatAddress<T extends 'array' | 'string' = 'string'>(
  address: Partial<Address> | StripePostalAddress | undefined,
  options: addressFormatter.CommonOptions & { output?: T } = {}
): T extends 'array' ? string[] : string {
  if (!address) {
    return options.output === 'array' ? [''] : ('' as any)
  }

  const normalizedAddress: Partial<Address> = isStripePostalAddress(address)
    ? {
        street: address.line1,
        city: address.city || '',
        postalCode: address.postal_code || '',
        country: address.country,
        state: address.state || ''
      }
    : address
  return addressFormatter.format(
    {
      houseNumber: normalizedAddress.streetNumber,
      road: normalizedAddress.street,
      city: normalizedAddress.city,
      postcode: normalizedAddress.postalCode,
      state: normalizedAddress.state,
      countryCode: normalizedAddress.country
    },
    // They have 2 time the same function (overwrite of params) defined which confuses typescript
    // @ts-expect-error -- Function overwrites confuses typescript
    { output: 'string', fallbackCountryCode: 'US', ...options }
  )
}

/**
 * If the user autocompleted his commute with an external service (like google) we need to flatten those
 * transit legs into our route existing legs. We also want to remove categories if modes or defined before sending
 * the data to the backend
 * @param route - Standard commutifi route (see Route interface)
 */
export const formatRouteTransitLegs = (route: Route) => ({
  ...route,
  legs: flatten(
    // For each leg
    route.legs?.map((leg) => ({
      ...leg,
      // Check if the have a transit "route" associated with it, if it does, get the transit legs and
      // format them
      ...(leg.transit?.legs
        ? {
            preferredShape: leg.transit.legs.map((transitLeg) => ({
              mode: transitLeg.mode,
              transitLine: transitLeg.name
            }))
          }
        : {}),
      category: leg.mode ? undefined : leg.category
    }))
  )
})
