import { useMutation } from '@tanstack/react-query'
import { MutationConfig } from '../../reactQuery'
import { LoginBody, PasswordResetBody, requestPasswordReset, resetPassword } from './api'

export const useRequestPasswordReset = (
  options?: MutationConfig<typeof requestPasswordReset, Pick<LoginBody, 'email'>>
) =>
  useMutation({
    mutationKey: ['forgot-password'],
    mutationFn: (user) => requestPasswordReset(user),
    ...options
  })

export const usePasswordReset = (options?: MutationConfig<typeof resetPassword, PasswordResetBody>) =>
  useMutation({
    mutationKey: ['reset-password'],
    mutationFn: (auth) => resetPassword(auth),
    ...options
  })
