import { takeLatest } from 'redux-saga/effects'
import { FeatureFlagResponse } from '@commutifi/models/FeatureFlag'
import { GetResponse } from '@commutifi-fe/interfaces'
import { fetchFeatureFlags } from 'api/modules/enterprises'
import { fetchEnterprise } from 'api/modules/user'
import { callApiSaga, IFetchAction } from 'store/callApiSaga'
import {
  arrayOfFeatureFlags,
  normalize,
  NormalizerResultForFeatureFlags,
  NormalizerResultForOrganizations,
  organizationOrEnterprise
} from 'store/schemas'
import logger from 'utils/logRocket'
import {
  FETCH_FEATURE_FLAGS_FAILURE,
  FETCH_FEATURE_FLAGS_REQUEST,
  FETCH_FEATURE_FLAGS_SUCCESS,
  FETCH_FEATURE_FLAGS_TRIGGER,
  FETCH_ORGANIZATION_FAILURE,
  FETCH_ORGANIZATION_REQUEST,
  FETCH_ORGANIZATION_SUCCESS,
  FETCH_ORGANIZATION_TRIGGER
} from './types'

export const triggerFetchFeatureFlags = (
  enterpriseId: string | null,
  queryParams?: Record<string, any>,
  promise?: { resolve: typeof Promise.resolve; reject: typeof Promise.reject }
): IFetchAction<NormalizerResultForFeatureFlags, Record<string, any>> => ({
  type: FETCH_FEATURE_FLAGS_TRIGGER,
  types: [FETCH_FEATURE_FLAGS_REQUEST, FETCH_FEATURE_FLAGS_SUCCESS, FETCH_FEATURE_FLAGS_FAILURE],
  callAPI: () =>
    enterpriseId
      ? fetchFeatureFlags(enterpriseId, queryParams)
      : logger.warn('Cannot fetch feature flags without an enterpriseId'),
  payload: queryParams,
  // We store all feature flags by the original requested enterprise id since
  // those might fallback to a default value without any enterprise id
  normalize: (data: GetResponse<FeatureFlagResponse>): NormalizerResultForFeatureFlags =>
    normalize(
      data.records.map((feature) => ({ ...feature, enterpriseId })),
      arrayOfFeatureFlags
    ),
  promise
})

export const triggerFetchOrganization = (
  organizationId: string
): IFetchAction<NormalizerResultForOrganizations, Record<string, any>> => ({
  type: FETCH_ORGANIZATION_TRIGGER,
  types: [FETCH_ORGANIZATION_REQUEST, FETCH_ORGANIZATION_SUCCESS, FETCH_ORGANIZATION_FAILURE],
  callAPI: () => fetchEnterprise(organizationId),
  normalize: (data: any): NormalizerResultForOrganizations => normalize(data, organizationOrEnterprise)
})

export const enterprisesSagas = [
  takeLatest(FETCH_FEATURE_FLAGS_TRIGGER, callApiSaga),
  takeLatest(FETCH_ORGANIZATION_TRIGGER, callApiSaga)
]
