import cx from 'classnames'
import { isFinite } from 'lodash'
import React from 'react'
import { useMediaQuery } from 'react-responsive'
import { Col, ColProps, CostDisplay, Row, RowProps, Skeleton, Typography } from '@commutifi-fe/ui'
import { convertToUnit, formatToTimeString, getLocaleMeasurementUnits } from '@commutifi-fe/utils'
import { DEFAULT_UNITS_BY_MEASURE_TYPE, Factors, LocalesType, MeasureTypes, screenSizes } from '@commutifi-fe/constants'
import { PlanCostType, PlanFrequency } from '@commutifi/models/Plan'
import { getCurrencyFromLocale } from '@commutifi/constants/Locale'
import { FrequencyDisplay } from '@commutifi-fe/commutifi-specific/Presentation/FrequencyDisplay'
import { DEFAULT_LOCALE, FormatNumberOptions, useComponentIntl } from '../../locales'
import { MetricsCardContextValue, MetricsContext } from './MetricsContext'
import { MetricLabel } from './MetricLabel'
import styles from './styles.module.scss'

const { Text } = Typography

interface MetricsProps {
  locale: LocalesType | undefined
  isLoading?: boolean
  children: React.ReactNode
  // eslint-disable-next-line react/no-unused-prop-types -- Not true
  rowProps?: RowProps
  // eslint-disable-next-line react/no-unused-prop-types -- Not true
  colProps?: ColProps
}

type CostProps = NumberMetric &
  FormatNumberOptions & {
    // Either fixed cost, variable or free.
    // There is some business logic based on this cost type
    costType?: PlanCostType
    children?: React.ReactNode
  }

interface MetricProps<T> {
  value?: T | null
  className?: string
}
export type NumberMetric = MetricProps<number>

function DescriptionItemLoading() {
  return (
    <ItemLabel className="u-margin-top--small">
      <Skeleton.Input style={{ minWidth: 75, width: 75 }} active size="small" />
    </ItemLabel>
  )
}

export function MetricsLocalizer({ isLoading = false, children, locale: l }: MetricsProps) {
  const locale = l || DEFAULT_LOCALE
  const currency = getCurrencyFromLocale(locale)
  const localeMeasurementUnits = getLocaleMeasurementUnits(locale)
  const value = React.useMemo(
    () => ({ isLoading, locale, currency, localeMeasurementUnits }),
    [isLoading, locale, currency, localeMeasurementUnits]
  )
  return <MetricsContext.Provider value={value}>{children}</MetricsContext.Provider>
}

export function MetricsCard({ isLoading = false, children, rowProps, colProps, locale: l }: MetricsProps) {
  const locale = l || DEFAULT_LOCALE
  const isSmallAndDown = useMediaQuery({ maxWidth: screenSizes.smMaxWidth })
  const currency = getCurrencyFromLocale(locale)
  const localeMeasurementUnits = getLocaleMeasurementUnits(locale)
  const value = React.useMemo(
    () => ({ isLoading, locale, currency, localeMeasurementUnits }),
    [isLoading, locale, currency, localeMeasurementUnits]
  )
  return (
    <MetricsContext.Provider value={value}>
      <Row gutter={[20, 20]} {...rowProps} data-testid={isLoading ? 'metrics-loading' : 'metrics'}>
        {React.Children.map(children, (child) => (
          <Col xs={isSmallAndDown ? 12 : 6} {...colProps}>
            {child}
          </Col>
        ))}
      </Row>
    </MetricsContext.Provider>
  )
}

const useMetricsCardContext = (opts?: { shouldThrow: boolean }) => {
  const context = React.useContext(MetricsContext)
  if (!context) {
    if (opts?.shouldThrow === false) {
      return {} as MetricsCardContextValue
    }

    throw new Error('components cannot be rendered outside Metrics or MetricsCard component')
  }
  return context
}

function ItemLabel({ className, children }: { children: React.ReactNode; className?: string }) {
  return <span className={cx('u-font-24 u-font-weight-semi u-color--neutral-600', className)}>{children}</span>
}

function Score({ value, className }: NumberMetric) {
  const intl = useComponentIntl('Metrics')
  const { isLoading } = useMetricsCardContext()
  return (
    <div className={cx(styles['metrics-item-score'], className)}>
      <Text strong="semi-bold" type="secondary">
        <MetricLabel metricType={Factors.Score} />
      </Text>
      {isLoading ? (
        <DescriptionItemLoading />
      ) : (
        // @ts-expect-error -- isFinite will ensure value is a number
        <ItemLabel>{isFinite(value) ? intl.formatNumber(Math.round(value)) : '—'}</ItemLabel>
      )}
    </div>
  )
}

function Carbon({ value, className, ...formatOptions }: MetricProps<number | string> & FormatNumberOptions) {
  const { localeMeasurementUnits } = useMetricsCardContext()
  const intl = useComponentIntl('Metrics')

  const carbonUnit = localeMeasurementUnits[MeasureTypes.Mass]
  const defaultUnit = DEFAULT_UNITS_BY_MEASURE_TYPE[MeasureTypes.Mass]
  const localeCarbon = React.useMemo(() => {
    if (typeof value === 'string' && Number.isNaN(parseFloat(value))) {
      return value
    }

    if (value && defaultUnit !== carbonUnit) {
      return convertToUnit(`${value} ${defaultUnit}`, carbonUnit)
    }
    return value
  }, [defaultUnit, carbonUnit, value])

  return isFinite(value) ? (
    <>
      {intl.formatNumber(parseFloat((localeCarbon as number).toFixed(2)), {
        maximumFractionDigits: 0,
        ...formatOptions
      })}{' '}
      {carbonUnit}
    </>
  ) : (
    <span className={className}>—</span>
  )
}

function CarbonItem({
  value,
  className,
  frequency,
  children,
  ...formatOptions
}: NumberMetric & FormatNumberOptions & { frequency?: PlanFrequency; children?: React.ReactNode }) {
  const { isLoading } = useMetricsCardContext()
  return (
    <div className={cx(styles['metrics-item-carbon'], className)}>
      <Text strong="semi-bold" type="secondary">
        <MetricLabel metricType={Factors.Carbon} /> {frequency ? <FrequencyDisplay frequency={frequency} /> : null}
      </Text>
      {isLoading ? (
        <DescriptionItemLoading />
      ) : (
        <ItemLabel>
          <Carbon value={value} {...formatOptions} />
        </ItemLabel>
      )}
      {children}
    </div>
  )
}

function Time({ value, className }: NumberMetric) {
  return (
    <span className={className}>
      {isFinite(value)
        ? // @ts-expect-error -- isFinite will ensure value is a number
          formatToTimeString(value, value >= 60 * 60 ? 'h[_] m' : 'h[_] m[_]')
        : '—'}
    </span>
  )
}

function TimeItem({
  value,
  className,
  frequency,
  children
}: NumberMetric & { frequency?: PlanFrequency; children?: React.ReactNode }) {
  const { isLoading } = useMetricsCardContext()
  return (
    <div className={cx(styles['metrics-item-time'], className)}>
      <Text strong="semi-bold" type="secondary">
        <MetricLabel metricType={Factors.Time} /> {frequency ? <FrequencyDisplay frequency={frequency} /> : null}
      </Text>
      {isLoading ? (
        <DescriptionItemLoading />
      ) : (
        <ItemLabel>
          <Time value={value} />
        </ItemLabel>
      )}
      {children}
    </div>
  )
}

/**
 * Display a localized cost properly formatted
 * @param props - See interface definition {@link CostProps}
 *
 * @returns JSX Element representing the formatted subsidized cost or cost:
 *            1. If you pass a param 'subsidy' and we can apply that to the cost : display the subsidized cost
 *            2. In all other cases, if the cost is defined or as a type we should return a text we
 *               display it and fallback to a dash
 */
function Cost({ value, costType, currency, children, ...options }: CostProps) {
  const { currency: contextCurrency } = useMetricsCardContext({ shouldThrow: !currency })
  const currencyProp = currency || contextCurrency

  return (
    <CostDisplay value={value} costType={costType} currency={currencyProp} {...options}>
      {children}
    </CostDisplay>
  )
}

/**
 * Very similar to Cost component but actually display the formatted cost
 * over a colored background with a label 'Cost' over the formatted value.
 *
 * @param props - See interface definition {@link CostProps}
 */
function CostItem({ frequency, className, children, ...props }: CostProps & { frequency?: PlanFrequency }) {
  const { isLoading } = useMetricsCardContext()
  return (
    <div className={cx(styles['metrics-item-cost'], className)}>
      <Text strong="semi-bold" type="secondary">
        <MetricLabel metricType={Factors.Cost} /> {frequency ? <FrequencyDisplay frequency={frequency} /> : null}
      </Text>
      {isLoading ? (
        <DescriptionItemLoading />
      ) : (
        <ItemLabel>
          <Cost {...props} />
        </ItemLabel>
      )}
      {children}
    </div>
  )
}

type DistanceProps = NumberMetric & FormatNumberOptions

function Distance({ value, ...formatNumberProps }: DistanceProps) {
  const { localeMeasurementUnits } = useMetricsCardContext()
  const intl = useComponentIntl('Metrics')

  const distanceUnit = localeMeasurementUnits[MeasureTypes.Distance]
  const defaultUnit = DEFAULT_UNITS_BY_MEASURE_TYPE[MeasureTypes.Distance]
  const localeDistance = React.useMemo(() => {
    if (typeof value === 'string' && Number.isNaN(parseInt(value))) {
      return value
    }

    if (value && defaultUnit !== distanceUnit) {
      return convertToUnit(`${value} ${defaultUnit}`, distanceUnit)
    }
    return value
  }, [defaultUnit, distanceUnit, value])
  return isFinite(localeDistance) ? (
    <>
      {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- We check if finite number prior to this line */}
      {intl.formatNumber(localeDistance!, {
        maximumFractionDigits: localeDistance && localeDistance > 100 ? 0 : 2,
        ...formatNumberProps
      })}{' '}
      {distanceUnit}
    </>
  ) : (
    '—'
  )
}

MetricsCard.Score = Score
MetricsCard.Carbon = CarbonItem
MetricsCard.Time = TimeItem
MetricsCard.Cost = CostItem

MetricsLocalizer.Distance = Distance
MetricsLocalizer.Carbon = Carbon
MetricsLocalizer.Time = Time
MetricsLocalizer.Cost = Cost
export default MetricsLocalizer
