import { HttpMethod } from '../../constants'

export const arcEndpoints = {
  [HttpMethod.Get]: {
    Salt: {
      path: '/auth/oauth2/salt/',
      route(): string {
        return this.path
      }
    }
  },
  [HttpMethod.Post]: {
    LeedAuth: {
      path: '/organizations/:enterpriseId/leed/auth',
      route(id: string): string {
        return this.path.replace(':enterpriseId', id)
      }
    }
  },
  [HttpMethod.Delete]: {
    LeedAuth: {
      path: '/organizations/:enterpriseId/leed/auth',
      route(id: string): string {
        return this.path.replace(':enterpriseId', id)
      }
    }
  }
}

export default arcEndpoints
