/**
 * @example See input and output below
 * Input:
 * const address = {
 *   name: 'Address 1',
 *   address: {
 *     street: {name: 'Test Street', no: 123}
 *   }
 * };
 * Output:
 * {
 *   'address.street.name': 'Test Street'
 *   'address.street.no': 123
 *   'name': 'Address 1'
 * }
 */
export function toFlatPropertyMap<T extends Record<string, any>>(ob: T) {
  var toReturn = {} as T

  for (var i in ob) {
    if (!ob.hasOwnProperty(i)) continue

    if (typeof ob[i] == 'object' && ob[i] !== null) {
      var flatObject = toFlatPropertyMap(ob[i])
      for (var x in flatObject) {
        if (!flatObject.hasOwnProperty(x)) continue
        // @ts-expect-error -- Yes, string can be used to index type T
        toReturn[i + '.' + x] = flatObject[x]
      }
    } else {
      toReturn[i] = ob[i]
    }
  }
  return toReturn
}
