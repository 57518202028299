import routesTypes from '../routes/types'
import { combineReducers } from 'redux'

const byId = (state = {}, action = {}) => {
  switch (action.type) {
    case routesTypes.FETCH_USER_ROUTES_SUCCESS:
    case routesTypes.FETCH_SUGGESTION_ROUTES_SUCCESS:
      return {
        ...state,
        ...action.payload.entities.leg
      }
    default:
      return state
  }
}

export default combineReducers({
  byId
})
