import { PersistPartial } from 'redux-persist/es/persistReducer'
import { useDispatch, useSelector as useReduxSelector } from 'react-redux'
import createStore from './createStore'
import type { RootState } from './reducer'

declare global {
  interface Window {
    ___INITIAL_STATE__: Record<string, any> & PersistPartial
  }
}
const initialState = window.___INITIAL_STATE__
const store = createStore(initialState)

function useSelector<T = RootState, Result = unknown>(...args: Parameters<typeof useReduxSelector<T, Result>>) {
  return useReduxSelector<T, Result>(...args)
}
export { RootState, useSelector }
export type AppDispatch = typeof store.dispatch
export const useAppDispatch: () => AppDispatch = useDispatch
export type Store = typeof store

export default store
