import { fromUrl, parseDomain, ParseResultType } from 'parse-domain'
import { difference } from 'lodash'

interface IParseDomainOptions {
  reservedSubDomains?: string[]
}

const SUBDOMAIN_RESERVED = [
  'app',
  'qa-app',
  'dashboard',
  'qa-dashboard',
  'pentest-dashboard',
  'blue-dashboard',
  // commutifi-fe subdomains are just temporary while we setup monorepo deployments
  'qa-commutifi-fe-public-ai',
  'commutifi-fe-public-ai',
  'qa-ai',
  'ai',
  'qa-commutifi-fe-web',
  'commutifi-fe-web'
]

/**
 * Extract a list of subDomains present in the parsed URL
 * @param url - URL to parse
 */
export const extractSubDomains = (url: string | null) => {
  if (!url) return []
  const parsedDomain = parseDomain(fromUrl(url))
  const subdomains = parsedDomain.type === ParseResultType.Listed ? parsedDomain.subDomains : []
  return subdomains
}

/**
 * Extract a list of subDomains that are not reserved for our app
 * @param url - URL to parse
 */
export const getUnreservedSubDomains = (
  url: string | null,
  options: IParseDomainOptions = { reservedSubDomains: SUBDOMAIN_RESERVED }
): string[] => {
  if (!url) {
    return []
  }

  const subdomains = extractSubDomains(url)
  return subdomains.length > 0 ? difference(subdomains, options.reservedSubDomains || []) : []
}

export const isReservedSubDomain = (url: string) => getUnreservedSubDomains(url).length === 0
