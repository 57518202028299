import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/pro-regular-svg-icons'
import { Input as AntdInput } from 'antd'
import { SizeType } from 'antd/es/config-provider/SizeContext'
import { InputProps, InputRef, PasswordProps, TextAreaProps } from 'antd/es/input'
import cx from 'classnames'
import React from 'react'
import type { TextAreaRef } from 'antd/es/input/TextArea'
import { colors } from '../style/constants'
import './styles.scss'

export type { TextAreaRef, InputRef }

const { Group, TextArea, Password } = AntdInput

type AppInputSize = SizeType

// props allow you to overwrite any antd Select properties.
// Please refer to their doc: https://ant.design/components/input/
export interface AppInputProps extends Omit<InputProps, 'bordered'> {
  size?: AppInputSize
  /**
   * Use this to display an input value without the input borders or styling.
   * This makes it easier to work with input in forms that have an 'edit mode'.
   * Ex. fields shows input only when edit is toggled on.
   */
  displayMode?: boolean
  ref?: React.ForwardedRef<InputRef> | React.RefObject<InputRef> | React.MutableRefObject<InputRef> | null
}

export interface AppSearchProps extends AppInputProps {
  inputPrefixCls?: string
  onSearch?: (
    value: string,
    event?: React.ChangeEvent<HTMLInputElement> | React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLInputElement>
  ) => void
  enterButton?: React.ReactNode
  loading?: boolean
}

export interface AppPasswordInputProps extends Omit<PasswordProps, 'bordered'> {
  size?: AppInputSize
}
interface AppInputContainerProps extends AppInputProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Too complex to fix for now
  component: any
}

interface AppInputComposition {
  Search: typeof SearchContainer
  Group: typeof Group
  TextArea: typeof TextAreaContainer
  Password: typeof PasswordContainer
  displayName: string
}

type InputCompoundType = React.JSXElementConstructor<AppInputProps> & AppInputComposition

const InputContainer = React.forwardRef<InputRef, AppInputContainerProps>(
  (
    {
      component: Component,
      className,
      placeholder,
      size = 'middle',
      displayMode,
      value,
      ...rest
    }: AppInputContainerProps,
    ref
  ) => (
    <Component
      ref={ref}
      className={cx('app-input', !value && 'app-input-empty', displayMode && 'app-input--display', className)}
      size={size}
      readOnly={displayMode}
      value={displayMode ? (value ?? '—') : value}
      {...rest}
      placeholder={displayMode ? null : placeholder || ' '}
    />
  )
)
InputContainer.displayName = 'InputContainer'

/**
 * This class is a wrap around [antd Button](https://ant.design/components/input/) component extending some of
 * the properties to follow our styling guidelines.
 *
 * Please refer to antd documentation for more details.
 *
 * Enhancements? better support for different sizes and different type of text (Lead Body vs. Body from our style guide)
 */
// eslint-disable-next-line react/display-name -- Has a display name
export const Input = React.forwardRef<InputRef, AppInputProps>((props: AppInputProps, ref) => (
  <InputContainer ref={ref} {...props} component={AntdInput} />
)) as unknown as InputCompoundType

const AppSearch = React.forwardRef<InputRef, AppInputProps>((props: AppInputProps, ref) => (
  <Input ref={ref} prefix={<FontAwesomeIcon icon={faSearch} color={colors.neutral500} />} {...props} />
))
AppSearch.displayName = 'Search'

const SearchContainer = React.forwardRef<InputRef, AppInputProps>((props: AppSearchProps, ref) => (
  <InputContainer ref={ref} {...props} component={AppSearch} />
))
SearchContainer.displayName = 'SearchContainer'

const PasswordContainer = React.forwardRef<InputRef, AppPasswordInputProps>((props: AppPasswordInputProps, ref) => (
  <InputContainer ref={ref} {...props} component={Password} />
))
PasswordContainer.displayName = 'Password'

const TextAreaContainer = React.forwardRef<TextAreaRef, TextAreaProps & { displayMode?: boolean }>(
  ({ displayMode, className, ...props }, ref) => (
    <TextArea
      ref={ref}
      className={cx(displayMode && 'app-input-text--display', className)}
      {...props}
      {...(displayMode && { readOnly: true, variant: 'borderless' })}
    />
  )
)
TextAreaContainer.displayName = 'TextArea'

Input.displayName = 'Input'
Input.Search = SearchContainer
Input.Group = Group
Input.TextArea = TextAreaContainer
Input.Password = PasswordContainer
