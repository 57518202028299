import { createSelector } from 'reselect'
import FETCH_STATUS from 'constants/global'

export const getById = ({ providers }) => providers.byId
export const getFetchStatus = ({ providers }) => providers.fetchStatus
export const makeGetProviderById = (id) => createSelector(getById, (byId) => byId[id])
export const makeGetProvidersIdsLoaded = (ids) =>
  createSelector(getFetchStatus, (fetchStatus) => {
    const definedIds = ids.filter((id) => id)
    for (let i = 0; i < definedIds.length; ++i) {
      if (fetchStatus[definedIds[i]] !== FETCH_STATUS.LOADED) return false
    }
    return true
  })
