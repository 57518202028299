export const CREATE_ACCOUNT_SUCCESS = 'accounts/CREATE_ACCOUNT_SUCCESS'
export const CREATE_ACCOUNT_FAILURE = 'accounts/CREATE_ACCOUNT_FAILURE'
export const CREATE_ACCOUNT_REQUEST = 'accounts/CREATE_ACCOUNT_REQUEST'

export const FETCH_ME_SUCCESS = 'accounts/FETCH_ME_SUCCESS'
export const FETCH_ME_FAILURE = 'accounts/FETCH_ME_FAILURE'
export const FETCH_ME_REQUEST = 'accounts/FETCH_ME_REQUEST'

export const FETCH_ACCOUNT_TRIGGER = 'accounts/FETCH_ACCOUNT_TRIGGER'
export const FETCH_ACCOUNT_REQUEST = 'accounts/FETCH_ACCOUNT_REQUEST'
export const FETCH_ACCOUNT_SUCCESS = 'accounts/FETCH_ACCOUNT_SUCCESS'
export const FETCH_ACCOUNT_FAILURE = 'accounts/FETCH_ACCOUNT_FAILURE'

export const UPDATE_ACCOUNT_TRIGGER = 'accounts/UPDATE_ACCOUNT_TRIGGER'
export const UPDATE_ACCOUNT_SUCCESS = 'accounts/UPDATE_ACCOUNT_SUCCESS'
export const UPDATE_ACCOUNT_FAILURE = 'accounts/UPDATE_ACCOUNT_FAILURE'
export const UPDATE_ACCOUNT_REQUEST = 'accounts/UPDATE_ACCOUNT_REQUEST'
