import * as qs from 'utils/queryParams'
import { apiCall, JSON_HEADER, dashboardApiServer } from '../../index'
import endpoints from './endpoints'

// Uses api-dashboard for request
const apiDashboardServer = apiCall(dashboardApiServer)

export const fetchRoutes = (uuid: string) =>
  apiDashboardServer(endpoints.GET.AccountRoutes.route(uuid), {
    method: 'get',
    headers: JSON_HEADER
  })

export const fetchSuggestionRoutes = (uuid: string) =>
  apiDashboardServer(endpoints.GET.SuggestionRoutes.route(uuid), {
    method: 'get',
    headers: JSON_HEADER
  })

export const fetchProvider = (id: string) =>
  apiDashboardServer(endpoints.GET.Provider.route(id), {
    method: 'get',
    headers: JSON_HEADER
  })

export const fetchPlansSubsidies = (accountId: string, planIds?: string[]) =>
  apiDashboardServer(
    `${endpoints.GET.PlansSubsidies.route(accountId)}?${qs.stringify({ planIds }, { indices: false })}`,
    {
      method: 'get',
      headers: JSON_HEADER
    }
  )
