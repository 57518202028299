import faker from 'faker/locale/en'
import moment from 'moment-timezone'
import { ModeName } from '@commutifi/modes'
import { TripSource } from 'api/modules/trips/types'
import type { Trip } from 'api/modules/trips/types'
import { getFakeAccount } from '../accounts/data'
import {
  fakeBikeCommuteProfile,
  fakeDriveCommuteProfile,
  fakeDriveTransitCommuteProfile
} from '../commuteProfiles/data'
import { fakeAccountTimezone } from '../../config/constants'
import { mobilityServicesResultMock } from '../mobilityServices/data'

const fakeAccount = getFakeAccount()
moment.updateLocale(fakeAccount.preferredLocale || 'en', { week: { dow: 1 } })
const startOfLastWeek = moment.tz(fakeAccountTimezone).subtract(1, 'week').startOf('week')

const CPGoingTripBase = {
  isReturn: false,
  startTime: '00:00:00+00:00',
  endTime: '00:23:58+00:00'
}

const CPReturnTripBase = {
  isReturn: true,
  startTime: '00:00:00+00:00',
  endTime: '00:23:58+00:00'
}

export const newTripMode = ModeName.Carshare

export const lastWeekPartialTrips: Partial<Trip>[] = [
  /**
   * Monday
   */
  {
    // Going
    ...CPGoingTripBase,
    id: faker.datatype.uuid(),
    startDate: moment.tz(startOfLastWeek, fakeAccountTimezone).format(),
    endDate: moment.tz(startOfLastWeek, fakeAccountTimezone).endOf('day').format(),
    days: fakeDriveCommuteProfile.days,
    source: TripSource.SelfReported,
    orderedModes: fakeDriveCommuteProfile.orderedModes,
    timezone: fakeAccountTimezone,
    createdAt: '2020-12-08T00:01:00.648-05:00'
  },
  {
    // Return
    ...CPReturnTripBase,
    id: faker.datatype.uuid(),
    startDate: moment.tz(startOfLastWeek, fakeAccountTimezone).format(),
    endDate: moment.tz(startOfLastWeek, fakeAccountTimezone).endOf('day').format(),
    days: fakeDriveCommuteProfile.days,
    source: TripSource.SelfReported,
    orderedModes: fakeDriveCommuteProfile.orderedModes,
    timezone: fakeAccountTimezone,
    createdAt: '2020-12-08T00:01:00.648-05:00'
  },
  /**
   * Tuesday
   */
  {
    // Going
    id: faker.datatype.uuid(),
    accountId: fakeAccount.id,
    commuteProfileRef: fakeBikeCommuteProfile.id,
    startDate: moment.tz(startOfLastWeek, fakeAccountTimezone).add(1, 'days').format(),
    endDate: moment.tz(startOfLastWeek, fakeAccountTimezone).add(1, 'days').endOf('day').format(),
    isReturn: false,
    startTime: '00:00:00+00:00',
    endTime: '00:00:00+00:00',
    days: undefined,
    source: TripSource.Event,
    orderedModes: fakeBikeCommuteProfile.orderedModes,
    timezone: fakeAccountTimezone,
    createdAt: '2020-12-08T00:01:00.648-05:00',
    legs: [
      {
        partnerName: mobilityServicesResultMock.records[0].displayName,
        partnerLogoUrl: mobilityServicesResultMock.records[0].logoUrl
      }
    ]
  },
  {
    // Return
    id: faker.datatype.uuid(),
    accountId: fakeAccount.id,
    commuteProfileRef: fakeBikeCommuteProfile.id,
    startDate: moment.tz(startOfLastWeek, fakeAccountTimezone).add(1, 'days').format(),
    endDate: moment.tz(startOfLastWeek, fakeAccountTimezone).add(1, 'days').endOf('day').format(),
    isReturn: true,
    startTime: '15:30:00+00:00',
    endTime: '15:30:00+00:00',
    days: undefined,
    source: TripSource.Event,
    orderedModes: fakeBikeCommuteProfile.orderedModes,
    timezone: fakeAccountTimezone,
    createdAt: '2020-12-08T00:01:00.648-05:00',
    legs: [
      {
        partnerName: mobilityServicesResultMock.records[0].displayName,
        partnerLogoUrl: mobilityServicesResultMock.records[0].logoUrl
      }
    ]
  },
  /**
   * Wednesday
   */
  {
    // Going
    ...CPGoingTripBase,
    id: faker.datatype.uuid(),
    startDate: moment.tz(startOfLastWeek, fakeAccountTimezone).add(2, 'days').format(),
    endDate: undefined,
    days: fakeBikeCommuteProfile.days,
    source: TripSource.CommuteProfile,
    orderedModes: fakeBikeCommuteProfile.orderedModes,
    timezone: fakeAccountTimezone,
    createdAt: '2020-12-08T00:01:00.648-05:00'
  },
  {
    // Return
    ...CPReturnTripBase,
    id: faker.datatype.uuid(),
    startDate: moment.tz(startOfLastWeek, fakeAccountTimezone).add(2, 'days').format(),
    endDate: undefined,
    days: fakeBikeCommuteProfile.days,
    source: TripSource.CommuteProfile,
    orderedModes: fakeBikeCommuteProfile.orderedModes,
    timezone: fakeAccountTimezone,
    createdAt: '2020-12-08T00:01:00.648-05:00'
  },
  /**
   * Thursday
   */
  {
    // Going
    id: faker.datatype.uuid(),
    accountId: fakeAccount.id,
    commuteProfileRef: fakeDriveCommuteProfile.id,
    startDate: moment.tz(startOfLastWeek, fakeAccountTimezone).add(3, 'days').format(),
    endDate: undefined,
    isReturn: false,
    startTime: '00:10:12+00:00',
    endTime: '00:53:31+00:00',
    days: fakeDriveCommuteProfile.days,
    source: TripSource.CommuteProfile,
    orderedModes: fakeDriveCommuteProfile.orderedModes,
    timezone: fakeAccountTimezone,
    createdAt: '2020-12-08T00:01:00.648-05:00'
  },
  {
    // Return
    id: faker.datatype.uuid(),
    accountId: fakeAccount.id,
    commuteProfileRef: fakeDriveCommuteProfile.id,
    startDate: moment.tz(startOfLastWeek, fakeAccountTimezone).add(3, 'days').format(),
    endDate: undefined,
    isReturn: true,
    startTime: '15:10:12+00:00',
    endTime: '15:53:31+00:00',
    days: fakeDriveCommuteProfile.days,
    source: TripSource.CommuteProfile,
    orderedModes: fakeDriveCommuteProfile.orderedModes,
    timezone: fakeAccountTimezone,
    createdAt: '2020-12-08T00:01:00.648-05:00'
  },
  /**
   * Friday
   */
  {
    // Going
    id: '01dfd014-db97-4f9b-b1bb-d7c4657g24g9',
    accountId: fakeAccount.id,
    commuteProfileRef: fakeDriveTransitCommuteProfile.id,
    startDate: moment.tz(startOfLastWeek, fakeAccountTimezone).add(4, 'days').format(),
    endDate: moment.tz(startOfLastWeek, fakeAccountTimezone).add(4, 'days').endOf('day').format(),
    isReturn: false,
    startTime: '02:00:00+00:00',
    endTime: '02:23:58+00:00',
    days: undefined,
    source: TripSource.SelfReported,
    orderedModes: fakeDriveTransitCommuteProfile.orderedModes,
    timezone: fakeAccountTimezone,
    createdAt: '2020-12-08T00:01:00.648-05:00'
  },
  {
    // Return
    id: '01dfd014-db97-4f9b-b1bb-d7c4657g24h9',
    accountId: fakeAccount.id,
    commuteProfileRef: fakeDriveTransitCommuteProfile.id,
    startDate: moment.tz(startOfLastWeek, fakeAccountTimezone).add(4, 'days').format(),
    endDate: moment.tz(startOfLastWeek, fakeAccountTimezone).add(4, 'days').endOf('day').format(),
    isReturn: true,
    startTime: '17:00:00+00:00',
    endTime: '17:23:58+00:00',
    days: undefined,
    source: TripSource.SelfReported,
    orderedModes: fakeDriveTransitCommuteProfile.orderedModes,
    timezone: fakeAccountTimezone,
    createdAt: '2020-12-08T00:01:00.648-05:00'
  }
]
