import types from './types'

export const fetchPlans = (planIds) => ({
  type: types.FETCH_PLANS,
  planIds
})

export const fetchPlansRequest = (planIds) => ({
  type: types.FETCH_PLANS_REQUEST,
  planIds
})

export const fetchPlansSuccess = (planIds, payload) => ({
  type: types.FETCH_PLANS_SUCCESS,
  planIds,
  payload
})

export const fetchPlansFailure = (planIds, error) => ({
  type: types.FETCH_PLANS_FAILURE,
  planIds,
  error
})

export const fetchPlansCancel = () => ({
  type: types.FETCH_PLANS_CANCEL
})
