import { useCallback } from 'react'
import { RequestParams } from '@commutifi-fe/interfaces'
import { GetCommuterGuideRequest, CommuterGuideRepository } from '../../../Domain/Repositories/CommuterGuide'
import { IGetCommuterGuideUseCase } from '../../../Domain/UseCases/CommuterGuide/IGetCommuterGuideUseCase'
import {
  getGuideCategoryTranslationKey,
  getRandomSectionsMemoized,
  getSectionByIdMemoized
} from '../../../Domain/UseCases/CommuterGuide/utils'

export function useCommuterGuide<TQueryParams extends RequestParams>(
  repository: CommuterGuideRepository<TQueryParams>,
  options?: {
    queryOptions?: GetCommuterGuideRequest<TQueryParams>['queryOptions']
    queryParams?: TQueryParams
  }
): UseCommuterGuideHook<TQueryParams> {
  const execute = useCallback(
    (req: GetCommuterGuideRequest<TQueryParams> = {}) =>
      repository.useCommuterGuide(
        options ? new GetCommuterGuideRequest(options?.queryParams, options?.queryOptions) : req
      ),
    [options, repository]
  )

  return {
    execute,
    getGuideCategoryTranslationKey,
    getRandomSectionsMemoized,
    getSectionByIdMemoized
  }
}

export interface UseCommuterGuideHook<TQueryParams extends RequestParams>
  extends IGetCommuterGuideUseCase<TQueryParams> {
  getGuideCategoryTranslationKey: typeof getGuideCategoryTranslationKey
  getRandomSectionsMemoized: typeof getRandomSectionsMemoized
  getSectionByIdMemoized: typeof getSectionByIdMemoized
}
