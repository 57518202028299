import { HttpMethod } from '../../constants'

export const merchantEndpoints = {
  [HttpMethod.Patch]: {
    Merchant: {
      path: '/merchants/:merchantId',
      route(mId: string): string {
        return this.path.replace(':merchantId', mId)
      }
    }
  }
}

export default merchantEndpoints
