import { HttpMethod } from '../../constants'

function replaceAccountId(accountId: string): string {
  return this.path.replace(':accountId', accountId)
}
function returnPath(): string {
  return this.path
}

export const accountEndpoints = {
  [HttpMethod.Get]: {
    Account: {
      path: '/accounts/:accountId',
      route: replaceAccountId
    },
    SubscriptionsLegacy: {
      path: '/_/accounts/:accountId/subscriptions',
      route: replaceAccountId
    },
    CommuteData: {
      path: '/accounts/:accountId/commuteData',
      route: replaceAccountId
    },
    KCI: {
      path: '/accounts/:accountId/kci',
      route: replaceAccountId
    },
    RegionMetrics: {
      path: '/accounts/:accountId/region-metrics',
      route: replaceAccountId
    },
    ScoreVariation: {
      path: '/accounts/:accountId/scores/variation-graph',
      route: replaceAccountId
    },
    Trips: {
      path: '/accounts/:accountId/trips',
      route: replaceAccountId
    },
    Plans: {
      path: '/accounts/:accountId/plans',
      route: replaceAccountId
    },
    PlansSuggestions: {
      path: '/accounts/:accountId/plans/suggestions',
      route: replaceAccountId
    },
    PaymentMethods: {
      path: '/accounts/:accountId/payment-methods',
      route: replaceAccountId
    },
    UserAccountVehicles: {
      path: '/accounts/:accountId/vehicles',
      route: replaceAccountId
    },
    AccountBookings: {
      path: '/accounts/:accountId/bookings',
      route: replaceAccountId
    },
    AccountWalletEntries: {
      path: '/accounts/:accountId/wallet-entries',
      route: replaceAccountId
    },
    AccountWalletEnrollments: {
      path: '/accounts/:accountId/accounts-wallets',
      route: replaceAccountId
    },
    AccountCards: {
      path: '/accounts/:accountId/cards',
      route: replaceAccountId
    },
    VerifyPasswordNeeded: {
      path: 'accounts/:accountId/passwordNeeded',
      route: replaceAccountId
    }
  },
  [HttpMethod.Patch]: {
    Account: {
      path: '/accounts/:accountId',
      route: replaceAccountId
    },
    BatchAccounts: {
      path: '/accounts',
      route: returnPath
    },
    AccountsGroups: {
      path: '/groups/accounts',
      route: returnPath
    },
    AccountPassword: {
      path: '/accounts/:accountId/password',
      route: replaceAccountId
    }
  },
  [HttpMethod.Post]: {
    AccountEnterpriseGroup: {
      path: '/accounts/:accountId/enterprise-groups/:groupId',
      route(accountId: string, groupId: string | undefined): string {
        return this.path.replace(':accountId', accountId).replace(':groupId', groupId)
      }
    },
    AccountGroup: {
      path: '/accounts/:accountId/groups/:groupId',
      route(accountId: string, groupId: string | undefined): string {
        return this.path.replace(':accountId', accountId).replace(':groupId', groupId)
      }
    },
    AccountPaymentMethods: {
      path: '/accounts/:accountId/payment-methods',
      route: replaceAccountId
    },
    SendEmailToVerifyAccount: {
      path: '/accounts/send-email-verification',
      route: returnPath
    },
    VerifyEmail: {
      path: '/accounts/:accountId/verify-email',
      route: replaceAccountId
    },
    Accounts: {
      path: '/accounts',
      route: returnPath
    }
  },
  [HttpMethod.Put]: {
    AccountPasses: {
      path: '/bookings/:id/account/passes',
      route(bookingId: string): string {
        return this.path.replace(':id', bookingId)
      }
    }
  }
}

export default accountEndpoints
