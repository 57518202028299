import { createSelector } from 'reselect'
import { makeGetSubsidiesSum } from 'store/modules/benefits/selectors'
import { makeGetServiceWithProvider } from 'store/modules/services/selectors'
import { sortingOrdersEnum } from 'constants/global'
import { getById } from './selectors.shared'
import { sortByKey } from 'utils/helpers'

/**
 * Transforms the array in a objects with keys using the key specified. The keys will
 * be ordered. Each collision will add the value to an array
 * @example
 * Input: [{type: 'field', prop: 'prop1'}, {type: 'field', prop: 'prop2'}, {type: 'section', prop: 'prop3'}]
 * Function: mappingByKeyOrdered(array, 'type')
 * Output:
 * {
 *    field: [{type: 'field', prop: 'prop1'}, {type: 'field', prop: 'prop2'}],
 *    section: [{type: 'section', prop: 'prop3'}]
 * }
 * @param {*} array: array to map and order by the key specified
 * @param {*} key: key to use to create the returned object keys
 * @param {optional} order: order to sort (ASC or DESC)
 */
export const mappingByKeyOrdered = (array, key, order) => {
  const sorted = order ? sortByKey(array, key, order) : array
  return sorted.reduce(
    (byRow, field) => ({
      ...byRow,
      [field.row]: byRow[field.row] ? [...byRow[field.row], field] : [field]
    }),
    {}
  )
}

/**
 * Returns the subscription details config to be easily parsable by the UI component.
 * For all the fields (or extraFields in a section) we will group them and organize
 * them by the row number:
 *  1. Sort by row key -> we want the fields to be ordered
 *  2. With the resulting ordered array we will make an object with rows representing the keys
 *     To do that we make a reduce. We take the previous object (ex. { "0": [{...field1}] })
 *     and add it the next field by concat to the array of fields ([...byRow[field.row], field]).
 *     BUT, if there is no previous field we need to init the array ([field])
 * @example Output
 * {
 *    planId: '12345'
 *    sections: [
 *      {
 *        id: 'vehicle',
 *        type: 'section',
 *        extraFields: {
 *                        '0': [{ id: 'evCharger', row: 0, component: 'checkbox', type: 'field' }],
 *                      }
 *      }
 *    ]
 *    fields: {
 *      '0': [{ id: 'startDate', row: 0, component: 'date', type: 'field' }, { id: 'endDate', row: 0, component: 'date', type: 'field' }],
 *      '1': [{ id: 'email', row: 1, component: 'input', type: 'field' }]
 *    }
 * }
 *
 * @param {*} plan
 */
export const formatSubscriptionDetails = (plan) => {
  const empty = { sections: [], fields: [] }
  if (!plan || !plan.subscriptionDetails || !Array.isArray(plan.subscriptionDetails)) {
    return null
  }
  const formattedDetails = plan.subscriptionDetails.reduce((acc, details) => {
    details.type === 'section' ? acc.sections.push(details) : acc.fields.push(details)
    return acc
  }, empty)

  formattedDetails.sections = formattedDetails.sections.map((s) => ({
    ...s,
    extraFields: s.extraFields ? mappingByKeyOrdered(s.extraFields, 'row', sortingOrdersEnum.ASC) : undefined
  }))
  formattedDetails.fields = mappingByKeyOrdered(formattedDetails.fields, 'row', sortingOrdersEnum.ASC)

  return { planId: plan.id, ...formattedDetails }
}

export const getAllIds = ({ plans }) => plans.allIds
export const getFromPackages = ({ plans }) => plans.fromPackages
export const getFetchStatus = ({ plans }) => plans.fetchStatus
export const getIsFetching = ({ plans }) => plans.isFetching
export const makeGetPlanByIdWithSubsidy = (id) =>
  createSelector([getById, makeGetSubsidiesSum(id)], (byId, maxSubsidy) => ({ ...byId[id], maxSubsidy }))

export const makeGetPlanServices = (plan) => (state) => ({
  ...plan,
  services: plan.services
    ? plan.services.map((serviceId) => makeGetServiceWithProvider(serviceId)(state)).map((s) => s)
    : []
})

const _makeGetDetailedPlan = (plan) => (state) =>
  plan && plan.id
    ? {
        ...plan,
        maxSubsidy: makeGetSubsidiesSum(plan.id)(state),
        subscriptionDetails: formatSubscriptionDetails(plan)
      }
    : undefined

export const makeGetPlan = (planId) => createSelector(getById, (byId) => byId[planId])
export const makeGetPlanDetails = (planId) => (state) => {
  if (!planId) return

  const plan = makeGetPlan(planId)(state)
  if (!plan) return
  const planWithoutRelations = _makeGetDetailedPlan(plan)(state)
  const planWithServices = makeGetPlanServices(planWithoutRelations)(state)
  return planWithServices
}
