export enum HttpMethod {
  Get = 'GET',
  Put = 'PUT',
  Post = 'POST',
  Patch = 'PATCH',
  Delete = 'DELETE'
}
export const ACCOUNT_ID_IDENTIFIER = 'account:id'
export const JSON_HEADER = { 'Content-type': 'application/json' }
export const cancelMessage = (url: string) => `Concurrent call to ${url}. Cancelling previous call...`
