import { HttpMethod } from '../../constants'

function replaceCommuteProfileId(cpId: string): string {
  return this.path.replace(':cpId', cpId)
}
function returnPath(): string {
  return this.path
}

export const commuteProfileEndpoints = {
  [HttpMethod.Get]: {
    CommuteProfiles: {
      path: '/commuteProfiles',
      route: returnPath
    },
    CommuteProfileSummary: {
      path: '/commuteProfiles/:cpId/summary',
      route: replaceCommuteProfileId
    }
  },
  [HttpMethod.Patch]: {
    CommuteProfile: {
      path: '/commuteProfiles/:cpId',
      route: replaceCommuteProfileId
    }
  },
  [HttpMethod.Post]: {
    CommuteProfiles: {
      path: '/commuteProfiles',
      route: returnPath
    }
  },
  [HttpMethod.Put]: {
    CommuteProfile: {
      path: '/commuteProfiles/:cpId',
      route: replaceCommuteProfileId
    }
  },
  [HttpMethod.Delete]: {
    CommuteProfile: {
      path: '/commuteProfiles/:cpId',
      route: replaceCommuteProfileId
    }
  }
}

export default commuteProfileEndpoints
