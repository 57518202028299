import React from 'react'
import { colors } from '../style/constants'
import { CommutifiLogo } from '../Logo'

interface PageLoaderProps {
  style?: React.CSSProperties
}

export function PageLoader({ style }: PageLoaderProps) {
  return (
    <section id="loader" data-testid="page-loader" style={style}>
      <div className="column">
        <CommutifiLogo fill={colors.primary400} hideText height={44} />
        <div className="spinner spinner--path" />
      </div>
    </section>
  )
}

export default PageLoader
