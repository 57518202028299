import { getAuthenticatedAccountId } from 'api/index'
import { AuthActionType } from './actions'
import * as types from './types'

const initialState = {
  error: null,
  loggedIn: Boolean(getAuthenticatedAccountId()),
  loading: false,
  loggingOut: false,
  logoutParams: null
}

export function reducer(state = initialState, action: AuthActionType = {} as any) {
  switch (action.type) {
    case types.LOGOUT:
      return {
        ...state,
        loggingOut: true
      }
    case types.LOGIN_REQUEST:
    case types.CREATE_PASSWORD_REQUEST:
      return {
        ...state,
        error: null,
        loading: true
      }
    case types.LOGIN_SUCCESS:
    case types.CREATE_PASSWORD_SUCCESS:
      return {
        ...state,
        error: null,
        loggedIn: true,
        loading: false
      }
    case types.LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        loggedIn: false,
        error: action.error
      }
    case types.LOGOUT_SUCCESS:
    case types.UNAUTHORIZE_USER:
      return {
        ...state,
        loading: false,
        loggedIn: false,
        loggingOut: false,
        logoutParams: action.payload
      }
    default:
      return state
  }
}

export type AuthState = ReturnType<typeof reducer>
export default reducer
