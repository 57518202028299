import cx from 'classnames'
import React from 'react'
import { Overwrite } from 'utility-types'
import { Button, AppButtonProps } from '../../../AppButton'
import './styles.scss'

export interface AppModalFooterProps {
  okText: React.ReactNode
  cancelText?: React.ReactNode
  cancelButtonProps?: Overwrite<AppButtonProps, { onClick?: React.MouseEventHandler<HTMLButtonElement> }> & {
    'data-testid'?: string
  }
  okButtonProps?: Overwrite<AppButtonProps, { onClick?: React.MouseEventHandler<HTMLButtonElement> }> & {
    'data-testid'?: string
  }
  children?: React.ReactNode
}

export function ModalFooter({ okText, cancelText, okButtonProps, cancelButtonProps, children }: AppModalFooterProps) {
  const { className, ...cancelProps } = cancelButtonProps || {}
  return (
    <div className={cx('app-modal-footer', 'u-margin-vertical--small', 'u-flex-space-between u-flex-align-center')}>
      {/* Make sure to apply space between -> fallback to empty div */}
      {children || <div />}
      <div className="app-modal-ok-cancel">
        <Button type="primary" {...(okButtonProps as AppButtonProps)}>
          <span>{okText}</span>
        </Button>
        {cancelText ? (
          <Button ghost className={className} {...(cancelProps as AppButtonProps)}>
            {cancelText}
          </Button>
        ) : null}
      </div>
    </div>
  )
}
