import moment from 'moment-timezone'
import faker from 'faker/locale/en'
import { PaymentMethodMethod } from '@commutifi/constants/PaymentMethod'
import { PaymentMethodRelationStatus } from '@commutifi/models/PaymentMethod'
import { PeriodPriceBreakdownOperation } from '@commutifi/models/PriceBreakdown'
import { PlanBookingType, PlanCurrency } from 'api/modules/commuterPlans/types'
import type { PeriodPriceBreakdownProps, PriceCheckoutQueryParams } from 'api/modules/commuterPlans/types'
import { randomIn } from '../../_utils'
import { fakeAccountTimezone } from '../../config/constants'
import { PaymentMethod } from 'api/modules/paymentMethods'

export type { PriceCheckoutQueryParams }

export const fakePaymentMethod = (base?: Partial<PaymentMethod>): PaymentMethod => {
  const basePaymentMethod = base || {}
  const paymentMethodId = faker.datatype.uuid()
  const ccNb = faker.finance.creditCardNumber()
  const futureDate = moment.utc(faker.date.future(4))
  const expMonth = futureDate.month()
  const expYear = futureDate.year()
  return {
    id: paymentMethodId,
    method: PaymentMethodMethod.CARD,
    accountId: faker.datatype.uuid(),
    isDefault: basePaymentMethod.isDefault ?? false,
    paymentMethodId,
    currency: PlanCurrency.USD,
    expMonth,
    expYear,
    information: { last4: ccNb.substr(ccNb.length - 4, ccNb.length), expMonth, expYear, brand: 'visa' },
    setupIntentId: faker.datatype.uuid(),
    status: PaymentMethodRelationStatus.SUCCEEDED
  }
}

export const getCommutifiBillingDate = (date: moment.Moment) => date.clone().subtract(6, 'days')

export const calculateNextPaymentDate = (dateRef?: moment.Moment | string) =>
  dateRef
    ? typeof dateRef === 'string'
      ? moment.tz(dateRef, fakeAccountTimezone).add(1, 'month')
      : dateRef.clone().add(1, 'month')
    : moment.tz(fakeAccountTimezone).add(1, 'month')

export const calculatePartialTotal = (totalAmount: number) => {
  const today = moment.tz(fakeAccountTimezone)
  const paymentDate = calculateNextPaymentDate()
  return totalAmount * (Math.abs(today.diff(paymentDate, 'days')) / 30)
}

export interface PaymentCheckout extends PeriodPriceBreakdownProps {
  quantity?: number
}
export const fakePaymentCheckout = (
  basePayment: Partial<PaymentCheckout>,
  {
    applySubsidy,
    isNextMonthPayment,
    bookingType
  }: { applySubsidy?: boolean; isNextMonthPayment?: boolean; bookingType: PlanBookingType }
): PaymentCheckout => {
  const operationsMap = new Map<PeriodPriceBreakdownOperation, PeriodPriceBreakdownProps['breakdown'][number]>()
  basePayment.breakdown?.forEach((priceItem) => {
    operationsMap.set(priceItem.operation, priceItem)
  })
  const activationFee =
    operationsMap.get(PeriodPriceBreakdownOperation.ActivationFee)?.amount ?? randomIn({ min: 0, max: 5_00 })
  const quantity = basePayment.quantity || 1
  const basePrice =
    (operationsMap.get(PeriodPriceBreakdownOperation.BaseCost)?.amount ?? randomIn({ min: 0, max: 100_00 })) * quantity
  const partialTotal = calculatePartialTotal(basePrice)
  const proratedDiscount = -Math.abs(basePrice - partialTotal)
  const periodStart = basePayment.periodStartDate ? moment.utc(basePayment.periodStartDate) : moment.utc()
  const periodEnd = basePayment.periodEndDate
    ? moment.utc(basePayment.periodEndDate)
    : bookingType === PlanBookingType.RECURRENT
      ? periodStart.clone().endOf('month')
      : periodStart.clone().endOf('day')
  const invoiceGenerationDate =
    bookingType === PlanBookingType.RECURRENT ? getCommutifiBillingDate(periodEnd) : moment.utc()

  const breakdown: PeriodPriceBreakdownProps['breakdown'] = [
    { amount: basePrice, operation: PeriodPriceBreakdownOperation.BaseCost, priceBefore: 0, priceAfter: basePrice }
  ]
  if (activationFee) {
    const priceBefore = breakdown[breakdown.length - 1].priceAfter
    breakdown.push({
      amount: activationFee,
      operation: PeriodPriceBreakdownOperation.ActivationFee,
      priceBefore,
      priceAfter: priceBefore + activationFee
    })
  }
  if (proratedDiscount && !isNextMonthPayment) {
    const priceBefore = breakdown[breakdown.length - 1].priceAfter
    const amount = Math.floor(proratedDiscount === partialTotal ? -basePrice : proratedDiscount)
    breakdown.push({
      amount,
      operation: PeriodPriceBreakdownOperation.ProratedDiscount,
      priceBefore,
      priceAfter: priceBefore + amount
    })
  }
  const priceBefore = breakdown[breakdown.length - 1].priceAfter
  breakdown.push({
    amount: Math.floor(priceBefore * 0.15),
    operation: PeriodPriceBreakdownOperation.Taxes,
    operationLabel: 'GST',
    priceBefore,
    priceAfter: Math.floor(priceBefore * 1.15)
  })
  if (applySubsidy) {
    // Only support full subsidy for now
    const { priceAfter } = breakdown[breakdown.length - 1]
    breakdown.push({
      amount: -priceAfter,
      operation: PeriodPriceBreakdownOperation.Subsidy,
      priceBefore: priceAfter,
      priceAfter: 0
    })
  }

  return {
    quantity,
    periodStartDate: periodStart.toISOString(),
    periodEndDate: periodEnd.toISOString(),
    generationDateOffset: 5,
    invoiceGenerationDate: invoiceGenerationDate.isBefore(moment.tz(fakeAccountTimezone))
      ? moment.utc().format()
      : invoiceGenerationDate.format(),
    totalAmount: breakdown[breakdown.length - 1].priceAfter,
    breakdown
  }
}

export const fakeCheckout = (
  basePayment: Partial<PaymentCheckout>,
  { applySubsidy, bookingType }: { applySubsidy?: boolean; bookingType: PlanBookingType }
): PaymentCheckout[] => {
  const thisMonthCheckout = fakePaymentCheckout(basePayment, { applySubsidy, bookingType })
  const fakePayments = [
    thisMonthCheckout,
    fakePaymentCheckout(
      { ...basePayment, periodStartDate: calculateNextPaymentDate(thisMonthCheckout.periodStartDate).toISOString() },
      { isNextMonthPayment: true, applySubsidy, bookingType }
    )
  ]
  return fakePayments
}
