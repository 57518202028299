import { call, takeEvery } from 'redux-saga/effects'
import * as api from 'api/modules/commuterPlans'
import types from './types'

export const smsSagas = [takeEvery(types.SEND_SMS_REQUEST, sendSms)]

export function* sendSms({ serviceId, phoneNumber, promise }) {
  try {
    yield call(api.sendServiceSms, serviceId, { phoneNumber })
  } catch (err) {
    if (promise) yield call(promise.reject)
  }

  if (promise) yield call(promise.resolve)
}
