import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import type { AppProps } from 'antd'
import { App, message as antdMessage, notification as antdNotification } from 'antd'
import type {
  ConfigOptions,
  JointContent,
  ArgsProps as MessageArgsProps,
  MessageInstance,
  NoticeType
} from 'antd/es/message/interface'
// import type { ModalStaticFunctions } from 'antd/es/modal/confirm'
import type { ArgsProps as AntdNotificationArgsProps, NotificationConfig } from 'antd/es/notification/interface'
import cx from 'classnames'
import React from 'react'
import './styles.scss'

export interface NotificationArgsProps extends AntdNotificationArgsProps {
  size?: 'lg'
}

type StaticFn = (args: NotificationArgsProps) => void
export interface NotificationInstance {
  success: StaticFn
  error: StaticFn
  info: StaticFn
  warning: StaticFn
  open: StaticFn
  // eslint-disable-next-line @typescript-eslint/method-signature-style -- Define as antd does it
  destroy(key?: React.Key): void
}

export type { ConfigOptions as MessageConfig, NotificationConfig, AppProps as AntdAppProps, MessageArgsProps }

const messagePrefixClass = 'app-message-notice'
const notificationPrefixClass = 'app-notification-notice'
const successMessageConfig: Partial<MessageArgsProps> = {
  // Empty string with a space is to avoid the default antd icon to be displayed
  icon: ' ',
  className: `${messagePrefixClass} ${messagePrefixClass}-success`
}
const errorMessageConfig: Partial<MessageArgsProps> = {
  className: `${messagePrefixClass} ${messagePrefixClass}-error`
}
const infoMessageConfig: Partial<MessageArgsProps> = {
  className: `${messagePrefixClass} ${messagePrefixClass}-info`
}
const loadingMessageConfig: Partial<MessageArgsProps> = {
  className: `${messagePrefixClass} ${messagePrefixClass}-loading`
}
const warningMessageConfig: Partial<MessageArgsProps> = {
  className: `${messagePrefixClass} ${messagePrefixClass}-warning`
}
const getMessageConfig = (type: NoticeType | undefined) => {
  switch (type) {
    case 'success':
      return successMessageConfig
    case 'error':
      return errorMessageConfig
    case 'info':
      return infoMessageConfig
    case 'warning':
      return warningMessageConfig
    case 'loading':
      return loadingMessageConfig
    default:
      return {}
  }
}

const successNotificationConfig: Partial<NotificationArgsProps> = {
  // Empty string with a space is to avoid the default antd icon to be displayed
  placement: 'bottom',
  className: `${notificationPrefixClass} ${notificationPrefixClass}-success`
}
const errorNotificationConfig: Partial<NotificationArgsProps> = {
  icon: <FontAwesomeIcon icon={faExclamationCircle} />,
  // Error notification are designed to be dismissed but to avoid being annoying we
  // add a long duration to eventually close it
  duration: 15,
  placement: 'bottom',
  className: `${notificationPrefixClass} ${notificationPrefixClass}-error`
}
const infoNotificationConfig: Partial<NotificationArgsProps> = {
  placement: 'bottom',
  className: `${notificationPrefixClass} ${notificationPrefixClass}-info`
}
const warningNotificationConfig: Partial<NotificationArgsProps> = {
  placement: 'bottom',
  className: `${notificationPrefixClass} ${notificationPrefixClass}-warning`
}

export const notification: typeof antdNotification = {
  ...antdNotification,
  useNotification: (): ReturnType<typeof antdNotification.useNotification> => {
    const [api, contextHolder] = antdNotification.useNotification()
    const originalError = api.error.bind(this)
    api.error = ({ className, ...contentProps }) => {
      originalError({
        ...errorNotificationConfig,
        ...contentProps,
        className: cx(errorNotificationConfig.className, className)
      })
    }

    const originalSuccess = api.success.bind(this)
    api.success = ({ className, ...contentProps }) => {
      originalSuccess({
        ...successNotificationConfig,
        ...contentProps,
        className: cx(successNotificationConfig.className, className)
      })
    }

    const originalInfo = api.info.bind(this)
    api.info = ({ className, ...contentProps }) => {
      originalInfo({
        ...infoNotificationConfig,
        ...contentProps,
        className: cx(infoNotificationConfig.className, className)
      })
    }

    const originalWarning = api.warning.bind(this)
    api.warning = ({ className, ...contentProps }) => {
      originalWarning({
        ...warningNotificationConfig,
        ...contentProps,
        className: cx(warningNotificationConfig.className, className)
      })
    }
    return [api, contextHolder]
  }
}

const generateMessageConfig = (config: Partial<MessageArgsProps>, content: JointContent): MessageArgsProps =>
  isContentProps(content)
    ? { duration: 5, ...config, ...content, className: cx(config.className, content.className) }
    : { duration: 5, ...config, content }
export const message: typeof antdMessage = {
  ...antdMessage,
  useMessage: (): ReturnType<typeof antdMessage.useMessage> => {
    const [api, contextHolder] = antdMessage.useMessage()
    const originalError = api.error.bind(this)
    api.error = (content) => originalError(generateMessageConfig(errorMessageConfig, content))

    const originalSuccess = api.success.bind(this)
    api.success = (content) => originalSuccess(generateMessageConfig(successMessageConfig, content))

    const originalInfo = api.info.bind(this)
    api.info = (content) => originalInfo(generateMessageConfig(infoMessageConfig, content))

    const originalLoading = api.loading.bind(this)
    api.loading = (content) => originalLoading(generateMessageConfig(loadingMessageConfig, content))

    const originalWarning = api.warning.bind(this)
    api.warning = (content) => originalWarning(generateMessageConfig(warningMessageConfig, content))

    const originalOpen = api.open.bind(this)
    api.open = (content) => originalOpen(generateMessageConfig(getMessageConfig(content.type), content))
    return [api, contextHolder]
  }
}

const PrefixCls = 'app-container'
const isContentProps = (content: JointContent): content is MessageArgsProps =>
  typeof content === 'object' && 'content' in (content || {})

/**
 * This class is a wrap around [antd App](https://ant.design/components/app/) component extending some of
 * the properties to follow our styling guidelines.
 *
 * Please refer to antd documentation for more details.
 *
 */
export function AntdApp({ className, ...props }: AppProps) {
  return <App {...props} className={cx(PrefixCls, className)} />
}

const useApp = () => {
  const { message: appMessage, modal, notification: appNotification } = App.useApp()
  const internalNotification = Object.prototype.hasOwnProperty.call(appNotification, 'error')
    ? appNotification
    : antdNotification
  const internalMessage = Object.prototype.hasOwnProperty.call(appMessage, 'error') ? appMessage : antdMessage
  const message: MessageInstance = React.useMemo(
    () => ({
      error: (content, duration, onClose) =>
        internalMessage.error(generateMessageConfig(errorMessageConfig, content), duration, onClose),
      success: (content, duration, onClose) =>
        internalMessage.success(generateMessageConfig(successMessageConfig, content), duration, onClose),
      // eslint-disable-next-line @typescript-eslint/unbound-method -- Legacy
      destroy: internalMessage.destroy,
      info: (content, duration, onClose) =>
        internalMessage.info(generateMessageConfig(infoMessageConfig, content), duration, onClose),
      loading: (content, duration, onClose) =>
        internalMessage.loading(generateMessageConfig(loadingMessageConfig, content), duration, onClose),
      // eslint-disable-next-line @typescript-eslint/unbound-method -- Legacy
      open: internalMessage.open,
      warning: (content, duration, onClose) =>
        internalMessage.warning(generateMessageConfig(warningMessageConfig, content), duration, onClose)
    }),
    [internalMessage]
  )
  const notification: NotificationInstance = React.useMemo(
    () => ({
      success: ({ className, size, ...contentProps }) => {
        internalNotification.success({
          ...successNotificationConfig,
          className: cx(successNotificationConfig.className, size && `${notificationPrefixClass}--${size}`, className),
          ...contentProps
        })
      },
      error: ({ className, size, ...contentProps }) => {
        internalNotification.error({
          ...errorNotificationConfig,
          className: cx(errorNotificationConfig.className, size && `${notificationPrefixClass}--${size}`, className),
          ...contentProps
        })
      },
      // eslint-disable-next-line @typescript-eslint/unbound-method -- Legacy
      destroy: internalNotification.destroy,
      info: ({ className, size, ...contentProps }) => {
        internalNotification.info({
          ...infoNotificationConfig,
          className: cx(infoNotificationConfig.className, size && `${notificationPrefixClass}--${size}`, className),
          ...contentProps
        })
      },
      open: internalNotification.open,
      warning: ({ className, size, ...contentProps }) => {
        internalNotification.warning({
          ...warningNotificationConfig,
          className: cx(warningNotificationConfig.className, size && `${notificationPrefixClass}--${size}`, className),
          ...contentProps
        })
      }
    }),
    [internalNotification]
  )
  return { message, modal, notification }
}

AntdApp.useApp = useApp
AntdApp.displayName = App.displayName
AntdApp.defaultProps = App.defaultProps
AntdApp.contextTypes = App.contextTypes
