const pre = 'routes/'

export default {
  // Action types with no side-effects (no api calls, only change state)
  SELECT_CURRENT_ROUTE: `${pre}SELECT_CURRENT_ROUTE`,
  SELECT_TAB: `${pre}SELECT_TAB`,
  FETCH_USER_ROUTES: `${pre}FETCH_USER_ROUTES`,

  SELECT_SUGGESTION_ROUTE: `${pre}SELECT_SUGGESTION_ROUTE`,
  FETCH_ROUTE_PROVIDERS: `${pre}FETCH_ROUTE_PROVIDERS`,
  FETCH_ROUTE_PLANS: `${pre}FETCH_ROUTE_PLANS`,
  FETCH_SUGGESTION_ROUTES: `${pre}FETCH_SUGGESTION_ROUTES`,

  // Action types with side-effects
  FETCH_USER_ROUTES_REQUEST: `${pre}FETCH_USER_ROUTES_REQUEST`,
  FETCH_USER_ROUTES_SUCCESS: `${pre}FETCH_USER_ROUTES_SUCCESS`,
  FETCH_USER_ROUTES_FAILURE: `${pre}FETCH_USER_ROUTES_FAILURE`,

  FETCH_SUGGESTION_ROUTES_REQUEST: `${pre}FETCH_SUGGESTION_ROUTES_REQUEST`,
  FETCH_SUGGESTION_ROUTES_SUCCESS: `${pre}FETCH_SUGGESTION_ROUTES_SUCCESS`,
  FETCH_SUGGESTION_ROUTES_FAILURE: `${pre}FETCH_SUGGESTION_ROUTES_FAILURE`,
  FETCH_SUGGESTION_ROUTES_CANCEL: `${pre}FETCH_SUGGESTION_ROUTES_CANCEL`,

  ADD_NEW_USER_ROUTE_REQUEST: `${pre}ADD_NEW_USER_ROUTE_REQUEST`,
  ADD_NEW_USER_ROUTE_SUCCESS: `${pre}ADD_NEW_USER_ROUTE_SUCCESS`,
  ADD_NEW_USER_ROUTE_FAILURE: `${pre}ADD_NEW_USER_ROUTE_FAILURE`
}
