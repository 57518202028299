import { colors, CookieCategory, policyLink } from 'constants/global'
import { useEffect } from 'react'
import './CookieConsent.scss'

import { localeToSupportedTranslationFile } from 'constants/settings'
import { usePrevious } from 'shared/hooks/global'
import { useConfig } from 'shared/providers'
import { getAuthenticatedAccountId } from './api'
import { defaultMessages, useIntl } from './locales/index'
import { initialSetup } from './setupIntegrations'

export default function CookieConsent() {
  const { locale, region, cookieconsent, isLoadingTranslations } = useConfig()
  const intl = useIntl()

  useEffect(() => {
    /**
     * Overwrite some CSS variables from cookie consent to style it as Commutifi
     */
    document.documentElement.style.setProperty('--cc-text', colors.neutral600)
    document.documentElement.style.setProperty('--cc-btn-primary-bg', colors.primary400)
    document.documentElement.style.setProperty('--cc-btn-primary-hover-bg', colors.primary500)
    document.documentElement.style.setProperty('--cc-btn-secondary-bg', 'transparent')
    document.documentElement.style.setProperty('--cc-btn-secondary-hover-bg', 'transparent')
    document.documentElement.style.setProperty('--cc-cookie-category-block-bg', colors.neutral200)
    document.documentElement.style.setProperty('--cc-cookie-category-block-bg-hover', colors.neutral300)
    document.documentElement.style.setProperty('--cc-toggle-bg-off', colors.accentRed500)
  }, [])

  const previousLang = usePrevious(localeToSupportedTranslationFile(locale))
  useEffect(() => {
    const lang = localeToSupportedTranslationFile(locale)
    const autorun = cookieconsent && !getAuthenticatedAccountId()

    // Because we use lazy loading we dynamically add languages to the configuration by mutating the value.
    if (autorun && lang && lang !== cookieconsent?.getConfig('current_lang')) {
      if (isLoadingTranslations) {
        // Short circuit, otherwise since we lazy load messages we will defined english content under the new lang (ex. french)
        // language
        return
      }

      let langConfig
      try {
        langConfig = cookieconsent?.getConfig('languages')
      } catch (e) {
        // Do nothing config is not loaded yet. Not sure why it happens but we don't want to crash the platform
        return
      }

      if (!langConfig || langConfig[lang]) {
        // Config not loaded yet or language config already defined previously
        cookieconsent?.updateLanguage(lang, true)
        return
      }

      // @ts-expect-error -- It's fine, we know it's defined. Since we are mutating we don't want to assign it to a const
      // to make typescript happy
      cookieconsent.getConfig('languages')[lang] = {
        consent_modal: {
          title: intl.formatMessage({ id: 'cookies.consentPopup.title' }),
          description:
            `${intl.formatMessage({ id: 'cookies.consentPopup.description' })} ` +
            `<button type="button" data-cc="c-settings" class="cc-link">${intl.formatMessage({
              id: 'cookies.consentPopup.manage'
            })}</button>`,
          primary_btn: {
            text: intl.formatMessage({ id: 'cookies.consentPopup.buttons.acceptAll' }),
            role: 'accept_all' // 'accept_selected' or 'accept_all'
          },
          secondary_btn: {
            text: intl.formatMessage({ id: 'cookies.consentPopup.buttons.onlyNecessary' }),
            role: 'accept_necessary' // 'settings' or 'accept_necessary'
          }
        },
        settings_modal: {
          title: intl.formatMessage({ id: 'cookies.consentPopup.title' }),
          save_settings_btn: intl.formatMessage({ id: 'cookies.consentPopup.buttons.save' }),
          accept_all_btn: intl.formatMessage({ id: 'cookies.consentPopup.buttons.acceptAll' }),
          reject_all_btn: intl.formatMessage({ id: 'cookies.consentPopup.buttons.onlyNecessary' }),
          close_btn_label: intl.formatMessage({ id: 'cookies.consentPopup.buttons.close' }),
          blocks: [
            {
              title: intl.formatMessage({ id: 'cookies.consentPopup.settings.usage.title' }),
              description:
                `${intl.formatMessage({ id: 'cookies.consentPopup.description' })} ` +
                `<a class="cc-link" href="${policyLink}" target="__blank">${intl.formatMessage({
                  id: 'privacyPolicy'
                })}</a>`
            },
            {
              title: intl.formatMessage({ id: 'cookies.consentPopup.settings.necessary.title' }),
              description: intl.formatMessage({ id: 'cookies.consentPopup.settings.necessary.description' }),
              toggle: {
                value: CookieCategory.Necessary,
                enabled: true,
                readonly: true // cookie categories with readonly=true are all treated as "necessary cookies"
              }
            },
            {
              title: intl.formatMessage({ id: 'cookies.consentPopup.settings.performance.title' }),
              description: intl.formatMessage({ id: 'cookies.consentPopup.settings.performance.description' }),
              toggle: {
                value: CookieCategory.Analytics, // your cookie category
                enabled: false,
                readonly: false
              }
            },
            {
              title: intl.formatMessage({ id: 'cookies.consentPopup.settings.info.title' }),
              description: intl.formatMessage({ id: 'cookies.consentPopup.settings.info.description' })
            }
          ]
        }
      }
      cookieconsent?.updateLanguage(lang, true)
    }

    if (autorun) {
      cookieconsent?.run({
        gui_options: {
          consent_modal: {
            layout: 'bar', // box/cloud/bar
            position: 'bottom center', // bottom/middle/top + left/right/center
            transition: 'slide', // zoom/slide
            swap_buttons: true // enable to invert buttons
          },
          settings_modal: {
            layout: 'box', // box/bar
            // position: 'left',           // left/right
            transition: 'slide' // zoom/slide
          }
        },

        languages: {
          en: {
            consent_modal: {
              title: defaultMessages['cookies.consentPopup.title'],
              description:
                `${defaultMessages['cookies.consentPopup.description']} ` +
                `<button type="button" data-cc="c-settings" class="cc-link">${defaultMessages['cookies.consentPopup.manage']}</button>`,
              primary_btn: {
                text: defaultMessages['cookies.consentPopup.buttons.acceptAll'],
                role: 'accept_all' // 'accept_selected' or 'accept_all'
              },
              secondary_btn: {
                text: defaultMessages['cookies.consentPopup.buttons.onlyNecessary'],
                role: 'accept_necessary' // 'settings' or 'accept_necessary'
              }
            },
            settings_modal: {
              title: defaultMessages['cookies.consentPopup.title'],
              save_settings_btn: defaultMessages['cookies.consentPopup.buttons.save'],
              accept_all_btn: defaultMessages['cookies.consentPopup.buttons.acceptAll'],
              reject_all_btn: defaultMessages['cookies.consentPopup.buttons.onlyNecessary'],
              close_btn_label: defaultMessages['cookies.consentPopup.buttons.close'],
              blocks: [
                {
                  title: defaultMessages['cookies.consentPopup.settings.usage.title'],
                  description:
                    `${defaultMessages['cookies.consentPopup.description']} ` +
                    `<a class="cc-link" href="${policyLink}" target="__blank">${defaultMessages.privacyPolicy}</a>`
                },
                {
                  title: defaultMessages['cookies.consentPopup.settings.necessary.title'],
                  description: defaultMessages['cookies.consentPopup.settings.necessary.description'],
                  toggle: {
                    value: CookieCategory.Necessary,
                    enabled: true,
                    readonly: true // cookie categories with readonly=true are all treated as "necessary cookies"
                  }
                },
                {
                  title: defaultMessages['cookies.consentPopup.settings.performance.title'],
                  description: defaultMessages['cookies.consentPopup.settings.performance.description'],
                  toggle: {
                    value: CookieCategory.Analytics, // your cookie category
                    enabled: false,
                    readonly: false
                  }
                },
                {
                  title: defaultMessages['cookies.consentPopup.settings.info.title'],
                  description: defaultMessages['cookies.consentPopup.settings.info.description']
                }
              ]
            }
          }
        },

        current_lang: localeToSupportedTranslationFile(locale),
        autoclear_cookies: true, // default: false
        page_scripts: true, // default: false

        // mode: 'opt-in'                          // default: 'opt-in'; value: 'opt-in' or 'opt-out'
        // delay: 0,                               // default: 0
        // auto_language: null                     // default: null; could also be 'browser' or 'document'
        autorun, // default: true
        // force_consent: false,                   // default: false
        // hide_from_bots: false,                  // default: false
        remove_cookie_tables: true, // default: false
        // cookie_name: 'cc_cookie',               // default: 'cc_cookie'
        // cookie_expiration: 182,                 // default: 182 (days)
        // cookie_necessary_only_expiration: 182   // default: disabled
        // cookie_domain: location.hostname,       // default: current domain
        // cookie_path: '/',                       // default: root
        // cookie_same_site: 'Lax',                // default: 'Lax'
        // use_rfc_cookie: false,                  // default: false
        // revision: 0,                            // default: 0

        onFirstAction() {
          // callback triggered only once
        },

        onAccept(cookie) {
          if (
            cookie.categories.findIndex((cat) => cat === CookieCategory.Analytics) >= 0 ||
            cookie.categories.findIndex((cat) => cat === CookieCategory.All) >= 0
          ) {
            initialSetup({ hidePersonalData: false })
          } else {
            initialSetup({ hidePersonalData: true })
          }
        },

        onChange() {
          // ..
        }
      })
    }
    if (!autorun) {
      initialSetup({ hidePersonalData: false })
    }
  }, [cookieconsent, intl, isLoadingTranslations, locale, previousLang, region])

  return null
}
