import React from 'react'
import { theme, GlobalToken, MappingAlgorithm } from 'antd'
import { extractThemeFromDocumentStyle } from './context'

const {
  useToken: useAntdToken,
  compactAlgorithm,
  darkAlgorithm,
  defaultAlgorithm,
  defaultConfig,
  getDesignToken
} = theme

function useToken() {
  const { theme: antdTheme, hashId, token } = useAntdToken()
  const commutifiToken = extractThemeFromDocumentStyle()
  const mergedToken = React.useMemo(
    () => ({ ...token, ...commutifiToken }),
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Cause theme change should trigger an update to our commutifi tokens
    [commutifiToken, token, antdTheme]
  )

  const value = React.useMemo(
    () => ({ theme: antdTheme, token: mergedToken, hashId }),
    [antdTheme, hashId, mergedToken]
  )

  return value
}

export type { GlobalToken, MappingAlgorithm }

export default {
  defaultConfig,

  /** Default seedToken */
  defaultSeed: defaultConfig.token,

  useToken,
  defaultAlgorithm,
  darkAlgorithm,
  compactAlgorithm,
  getDesignToken
}
