import types from './types'
import { combineReducers } from 'redux'
import FETCH_STATUS from 'constants/global'
import uniq from 'lodash/uniq'

const byId = (state = {}, action = {}) => {
  switch (action.type) {
    case types.FETCH_PLANS_SUCCESS:
      return {
        ...state,
        ...action.payload.entities.plans
      }
    default:
      return state
  }
}

const allIds = (state = [], action = {}) => {
  switch (action.type) {
    case types.FETCH_PLANS_SUCCESS:
      return uniq([...state, ...action.payload.result])
    default:
      return state
  }
}

/**
 * The user plan catalog gives access to all the plans available through
 * packages:
 *  1. User package
 *  2. Enterprise default package
 *  3. Organization default package
 * This state is to keep track of the plans available in the plan catalog
 */
const fromPackages = (state = [], action = {}) => {
  switch (action.type) {
    default:
      return state
  }
}

const isFetching = (state = false, action = {}) => {
  switch (action.type) {
    case types.FETCH_PLANS_REQUEST:
      return true
    case types.FETCH_PLANS_SUCCESS:
    case types.FETCH_PLANS_FAILURE:
    case types.FETCH_PLANS_CANCEL:
      return false
    default:
      return state
  }
}

/**
 * Associate fetch status to each id in the array (Must be an array of id)
 * @param {*} array : Array of id
 * @param {*} status : Status to associate to each id
 */
const setStatusById = (array, status) => array.reduce((byId, planId) => ({ ...byId, [planId]: status }), {})

const fetchStatus = (state = {}, action = {}) => {
  switch (action.type) {
    case types.FETCH_PLANS_REQUEST:
      return {
        ...state,
        ...setStatusById(action.planIds, FETCH_STATUS.LOADING)
      }
    case types.FETCH_PLANS_SUCCESS:
      return { ...state, ...setStatusById(action.planIds, FETCH_STATUS.LOADED) }
    case types.FETCH_PLANS_FAILURE:
      return { ...state, ...setStatusById(action.planIds, FETCH_STATUS.FAILED) }
    default:
      return state
  }
}

export default combineReducers({
  byId,
  allIds,
  fromPackages,
  isFetching,
  fetchStatus
})
