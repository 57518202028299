import { GetResponse } from '@commutifi-fe/interfaces'
import { UseQueryOptions } from '@tanstack/react-query'
import { Resource } from '../../../Models/CommuterGuide'

export class GetCommuterGuideResourcesRequest<TQueryParams extends Record<string, any>> {
  constructor(
    public readonly queryParams?: TQueryParams,
    public readonly queryOptions?: Partial<
      UseQueryOptions<GetResponse<Resource>, any, Resource[], [string, TQueryParams | undefined]>
    >
  ) {}
}
