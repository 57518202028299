import { UnknownAction } from 'redux'
import { LocalesType } from '@commutifi-fe/constants'
import * as types from './types'

export interface ChangeLocaleAction extends UnknownAction {
  type: typeof types.CHANGE_LOCALE
  locale: LocalesType
}

export type LocalSettingsActionTypes = ChangeLocaleAction

export const changeLocale = (locale: LocalesType): ChangeLocaleAction => ({
  type: types.CHANGE_LOCALE,
  locale
})
