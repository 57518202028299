import { queryClient } from 'api/reactQuery'
import { getAuthenticatedAccountId } from 'api/index'
import { getSubdomainQueryKey, IdentityProviderProps } from 'api/modules/subdomains'
import React from 'react'
import { connect } from 'react-redux'
import { Redirect, RouteComponentProps } from 'react-router-dom'
import { NewPasswordModal, PasswordFormValues } from 'shared/components/modals/NewPasswordModal'
import { AdminRouting, CommuterRouting, PublicRouting } from 'src/routing'
import { authActions } from 'store/modules/auth'
import { RootState } from 'store/index'
import { wrapActionInPromise } from 'utils/helpers'
import * as qs from 'utils/queryParams'

interface ReduxProps {
  urlAccountId: string
  token: string | null
}
interface ReduxDispatch {
  verifyPasswordRequired: typeof authActions.verifyPasswordRequired
  createPassword: typeof authActions.createPasswordRequest
}
type OwnProps = RouteComponentProps<{ accountId: string }> & { queryParams?: { adminId?: string } }
type UnauthenticatedAccessProps = OwnProps & ReduxProps & ReduxDispatch

export function UnauthenticatedAccess({
  urlAccountId,
  queryParams,
  createPassword,
  verifyPasswordRequired,
  token,
  history
}: UnauthenticatedAccessProps) {
  const [error, setError] = React.useState<any>(null)
  const [email, setEmail] = React.useState<string | null>(null)
  const [name, setName] = React.useState<string | null>(null)

  const [showModal, setShowModal] = React.useState(false)

  const handleCreatePassword = (values: PasswordFormValues) => {
    wrapActionInPromise(createPassword, values, urlAccountId)
      .then(() =>
        history.push(
          queryParams?.adminId ? AdminRouting.root.getRoute(queryParams.adminId) : CommuterRouting.dashboard.path
        )
      )
      .catch((error) => {
        setError(error)
      })
  }

  React.useEffect(() => {
    if (token) {
      return history.push(CommuterRouting.dashboard.path)
    }
    wrapActionInPromise(verifyPasswordRequired, urlAccountId)
      .then((accountStatus) => {
        const { passwordNeeded, email, name } = accountStatus || {}
        if (passwordNeeded) {
          setShowModal(true)
          setEmail(email)
          setName(name)
        } else {
          return history.push(PublicRouting.auth.path)
        }
      })
      .catch(() => history.push(PublicRouting.auth.path))
  }, [history, token, urlAccountId, verifyPasswordRequired])

  const identityProvider = queryClient.getQueryData<IdentityProviderProps>(getSubdomainQueryKey())

  if (identityProvider?.id) {
    // A user with access to identity providers should not be able
    // to create a password
    return <Redirect to={PublicRouting.auth.path} />
  }

  return <NewPasswordModal onCreate={handleCreatePassword} open={showModal} name={name} email={email} error={error} />
}

const mapStateToProps = (state: RootState, { match, location }: OwnProps) => ({
  urlAccountId: match.params.accountId,
  queryParams: qs.parse(location.search),
  token: getAuthenticatedAccountId() as string | null
})

const mapDispatchToProps = {
  verifyPasswordRequired: authActions.verifyPasswordRequired,
  createPassword: authActions.createPasswordRequest
}

export default connect<ReduxProps, ReduxDispatch, OwnProps>(mapStateToProps, mapDispatchToProps)(UnauthenticatedAccess)
