import { HttpMethod } from '../../constants'

function replaceCardId(cardId: string | undefined): string {
  return this.path.replace(':cardId', cardId || '')
}

function returnPath(): string {
  return this.path
}

export const commutifiCardEndpoints = {
  [HttpMethod.Get]: {
    Card: {
      path: '/cards/:cardId',
      route: replaceCardId
    },
    CardNumber: {
      path: '/cards/:cardId/number',
      route: replaceCardId
    }
  },
  [HttpMethod.Patch]: {
    CardAddresses: {
      path: '/cards/:cardId/addresses',
      route: replaceCardId
    },
    ActivateCard: {
      path: '/cards/:cardId/activate-card',
      route: replaceCardId
    }
  },
  [HttpMethod.Post]: {
    Card: {
      path: '/cards',
      route: returnPath
    }
  }
}

export default commutifiCardEndpoints
