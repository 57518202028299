import { HttpMethod } from '../../constants'

function returnPath(): string {
  return this.path
}

export const internalStripeEndpoints = {
  [HttpMethod.Get]: {
    SubdomainData: {
      path: '/subdomains/:subdomain',
      route(subdomain: string): string {
        return this.path.replace(':subdomain', subdomain)
      }
    }
  },
  [HttpMethod.Post]: {
    AccountSetupIntent: {
      path: '/accounts/:accountId/setup-intent',
      route(accountId: string): string {
        return this.path.replace(':accountId', accountId)
      }
    },
    EnterpriseSetupIntent: {
      path: '/enterprises/:enterpriseId/setup-intent',
      route(enterpriseId: string): string {
        return this.path.replace(':enterpriseId', enterpriseId)
      }
    },
    StripeOAuthToken: {
      path: '/stripe/oauth/token',
      route: returnPath
    },
    StripeMerchantCustomAccount: {
      path: '/merchants/:merchantId/stripe/accounts/custom',
      route(merchantId: string): string {
        return this.path.replace(':merchantId', merchantId)
      }
    },
    StripeMerchantCustomAccountLink: {
      path: '/merchants/:merchantId/stripe/accounts/:stripeAccountId/links',
      route(merchantId: string, stripeAccountId: string): string {
        return this.path.replace(':merchantId', merchantId).replace(':stripeAccountId', stripeAccountId)
      }
    }
  },
  [HttpMethod.Patch]: {
    Stripe3DSessionInvoiceTransaction: {
      path: '/invoice-transactions/:invoiceTransactionId/confirm',
      route(invoiceTransactionId: string): string {
        return this.path.replace(':invoiceTransactionId', invoiceTransactionId)
      }
    }
  }
}

export default internalStripeEndpoints
