import { CurrencyCode } from '@commutifi/constants/Locale'
import { PlanCostType } from '@commutifi/models/Plan'
import { FormatNumberOptions } from 'react-intl'
import { isFinite } from 'lodash'
import { TypedIntl, useLocaleReceiver } from '../locales/LocaleReceiver'

/**
 * Function used to display formatted cost based on the options passed in param (defaults to react-intl options).
 * This function will make sure we display the proper translated texts if the cost if of type 'free'
 * or of type 'variable'. In all other cases it will display the formatted currency cost
 *
 * @param cost - Cost to display
 * @param costType - Cost type (defined by commutifi plans: 'free', 'variable', 'fixed')
 * @param options - {@link FormatNumberOptions} React Intl number format options to apply in the formatting
 */

type DisplayCost = (
  cost: number | null | undefined,
  costType: PlanCostType | null | undefined,
  { currency, intl, ...options }: FormatNumberOptions & { intl: TypedIntl<'CostDisplay'> }
) => string | null

export const displayCost: DisplayCost = (cost, costType, { currency, intl, ...options }) => {
  if (costType === PlanCostType.FREE) {
    return intl.formatMessage({ id: 'free' })
  }

  if (costType === PlanCostType.VARIABLE) {
    return intl.formatMessage({ id: 'variable' })
  }

  if (typeof cost === 'number' && isFinite(cost)) {
    return intl.formatNumber(cost, {
      style: 'currency',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      currency: currency || CurrencyCode.USD,
      trailingZeroDisplay: 'stripIfInteger',
      ...options
    })
  }

  return null
}

/**
 * Display a localized cost properly formatted
 * @param props - See interface definition {@link CostProps}
 *
 * @returns JSX Element representing the formatted subsidized cost or cost:
 *            1. If you pass a param 'subsidy' and we can apply that to the cost : display the subsidized cost
 *            2. In all other cases, if the cost is defined or as a type we should return a text we
 *               display it and fallback to a dash
 */
export function CostDisplay({ value, costType, currency, children, ...options }: CostProps) {
  const intl = useLocaleReceiver('CostDisplay')

  return (
    <>
      {isFinite(value) || costType === PlanCostType.FREE || costType === PlanCostType.VARIABLE ? (
        displayCost(value, costType, {
          currency,
          intl,
          ...options
        })
      ) : (
        <span>—</span>
      )}
      {children}
    </>
  )
}

interface CostProps extends FormatNumberOptions {
  // Either fixed cost, variable or free.
  // There is some business logic based on this cost type
  costType?: PlanCostType
  children?: React.ReactNode
  value: number | null | undefined
  className?: string
}
