import { useQuery } from '@tanstack/react-query'
import { getUnreservedSubDomains } from 'utils/domains'
import { fetchSubdomainData } from './api'

export const getSubdomainQueryKey = (subdomainOverwrite?: string) => {
  const subdomain = subdomainOverwrite || getUnreservedSubDomains(window.location.href)[0]
  return ['subdomains', subdomain]
}

export const useSubdomain = () => {
  const subdomain = getUnreservedSubDomains(window.location.href)[0]
  const queryKey = getSubdomainQueryKey(subdomain)

  return useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps -- Query key actually contains the subdomain
    queryKey,
    queryFn: async () => {
      const { data } = await fetchSubdomainData(subdomain)
      return {
        ...data.identityProvider,
        queryKey,
        subdomain,
        customBranding: data.customBranding
      }
    },
    enabled: Boolean(subdomain),
    gcTime: 24 * 60 * 60
  })
}
