import React, { ErrorInfo } from 'react'
import { noop } from 'lodash'
import FallbackError from './FallbackError'

interface ErrorBoundaryProps<T> {
  children?: React.ReactNode
  // Optional only because the is a default property
  ErrorComponent?: React.FC<T & { error: Error }> | React.ComponentClass<T, { error: Error }> | null
  onError?: (error: Error) => void
}

type Props<T> = ErrorBoundaryProps<Omit<T, 'error'>> & Omit<T, keyof ErrorBoundaryProps<T>>
export class ErrorBoundary<T extends Record<string, any>> extends React.Component<
  Props<T>,
  { error: Error | null; info: ErrorInfo | null }
> {
  // eslint-disable-next-line react/static-property-placement -- Legacy
  static defaultProps = {
    ErrorComponent: FallbackError,
    children: undefined,
    onError: noop
  }

  constructor(props: Props<T>) {
    super(props)
    // eslint-disable-next-line react/no-unused-state -- It's used
    this.state = { error: null, info: null }
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    const { onError } = this.props
    if (onError) onError(error)
    // Display fallback UI
    // eslint-disable-next-line react/no-unused-state -- It's used
    this.setState({ error, info })
  }

  render() {
    const { children, ErrorComponent, ...rest } = this.props
    const { error } = this.state

    if (error !== null) {
      return ErrorComponent ? <ErrorComponent {...rest} error={error} /> : null
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return -- Works since forever
    return children
  }
}
