export const FETCH_SURVEY_TRIGGER = 'enterpriseSurvey/FETCH_SURVEY_TRIGGER'
export const FETCH_SURVEY_REQUEST = 'enterpriseSurvey/FETCH_SURVEY_REQUEST'
export const FETCH_SURVEY_BEFORE_SUCCESS = 'enterpriseSurvey/FETCH_SURVEY_BEFORE_SUCCESS'
export const FETCH_SURVEY_SUCCESS = 'enterpriseSurvey/FETCH_SURVEY_SUCCESS'
export const FETCH_SURVEY_FAILURE = 'enterpriseSurvey/FETCH_SURVEY_FAILURE'

export const FETCH_DEFAULT_SURVEY_TRIGGER = 'enterpriseSurvey/FETCH_DEFAULT_SURVEY_TRIGGER'
export const FETCH_DEFAULT_SURVEY_REQUEST = 'enterpriseSurvey/FETCH_DEFAULT_SURVEY_REQUEST'
export const FETCH_DEFAULT_SURVEY_SUCCESS = 'enterpriseSurvey/FETCH_DEFAULT_SURVEY_SUCCESS'
export const FETCH_DEFAULT_SURVEY_FAILURE = 'enterpriseSurvey/FETCH_DEFAULT_SURVEY_FAILURE'

export const POST_SURVEY_TRIGGER = 'enterpriseSurvey/POST_SURVEY_TRIGGER'
export const POST_SURVEY_REQUEST = 'enterpriseSurvey/POST_SURVEY_REQUEST'
export const POST_SURVEY_SUCCESS = 'enterpriseSurvey/POST_SURVEY_SUCCESS'
export const POST_SURVEY_FAILURE = 'enterpriseSurvey/POST_SURVEY_FAILURE'
export const UPDATE_SURVEY_ANSWERS = 'enterpriseSurvey/UPDATE_SURVEY_ANSWERS'
export const RESET_SURVEY_ANSWERS = 'enterpriseSurvey/RESET_SURVEY_ANSWERS'
export const RESET_SURVEY = 'enterpriseSurvey/RESET_SURVEY'
export const CREATE_SURVEY_ANSWERS = 'enterpriseSurvey/CREATE_SURVEY_ANSWERS'
export const UPSERT_SURVEY_QUESTION = 'enterpriseSurvey/UPSERT_SURVEY_QUESTION'
export const REMOVE_SURVEY_QUESTION = 'enterpriseSurvey/REMOVE_SURVEY_QUESTION'

export const UPDATE_PROFILE_ADDRESS = 'enterpriseSurvey/UPDATE_PROFILE_ADDRESS'
export const UPDATE_PROFILE_ADDRESS_DONE = 'enterpriseSurvey/UPDATE_PROFILE_ADDRESS_DONE'

export default {
  FETCH_SURVEY_TRIGGER,
  FETCH_SURVEY_REQUEST,
  FETCH_SURVEY_BEFORE_SUCCESS,
  FETCH_SURVEY_SUCCESS,
  FETCH_SURVEY_FAILURE,

  FETCH_DEFAULT_SURVEY_TRIGGER,
  FETCH_DEFAULT_SURVEY_REQUEST,
  FETCH_DEFAULT_SURVEY_SUCCESS,
  FETCH_DEFAULT_SURVEY_FAILURE,

  POST_SURVEY_TRIGGER,
  POST_SURVEY_REQUEST,
  POST_SURVEY_SUCCESS,
  POST_SURVEY_FAILURE,
  UPDATE_SURVEY_ANSWERS,
  RESET_SURVEY_ANSWERS,
  RESET_SURVEY,
  CREATE_SURVEY_ANSWERS,
  UPSERT_SURVEY_QUESTION,
  REMOVE_SURVEY_QUESTION,
  UPDATE_PROFILE_ADDRESS,
  UPDATE_PROFILE_ADDRESS_DONE
}
