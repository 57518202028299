import faker from 'faker/locale/en'
import { times } from 'lodash'
import { AttributeProps } from '@commutifi/models/Attributes'
import { Countries } from '@commutifi/constants/Countries'
import { EnterpriseKind } from '@commutifi/models/Enterprises'
import { OfficeProps } from '@commutifi/models/Offices'
import { IOrganization } from 'shared/interfaces'
import { fakeCommutifiEmail, fakeFullAddress, fakeFullName, fakeTimezone, randomIn } from '../../_utils'
import { fakeOffice } from '../offices/fakers'
import {
  fakeEnterprise as generateFakeEnterprise,
  fakeAttribute,
  fakeShippingRates,
  fakeFundingInstructions,
  fakeBalances
} from './fakers'

export const fakeEnterpriseId = faker.datatype.uuid()
const fakeOfficeId = faker.datatype.uuid()
const fakeBuildingId = faker.datatype.uuid()

export const fakeAccountOffice = {
  id: fakeOfficeId,
  createdAt: '2021-01-28T18:13:24.005Z',
  updatedAt: '2021-01-28T18:13:24.005Z',
  assignedPerson: undefined,
  unit: null,
  building: {
    id: fakeBuildingId,
    address: '1111 Pearl St, Boulder, CO 80302, USA',
    city: 'Boulder',
    country: Countries.UnitedStates,
    createdAt: '2020-01-27T16:57:44.452Z',
    updatedAt: '2020-07-14T00:40:33.536Z',
    lastLeedOccupantSubmission: null,
    lastLeedTransportSubmission: null,
    location: {
      lat: 40.0179654,
      lng: -105.2809452,
      toJSON: () => ({
        lat: 40.0179654,
        lng: -105.2809452
      })
    },
    name: undefined,
    postalCode: '80302',
    state: 'Colorado',
    street: 'Pearl Street',
    streetNumber: '1111',
    timezone: fakeTimezone()
  },
  buildingId: fakeBuildingId,
  enterpriseId: fakeEnterpriseId,
  occupancy: 20,
  squareFootage: 5000
}

export const boulderOffice: OfficeProps = fakeOffice({
  createdAt: faker.date.past().toISOString(),
  updatedAt: faker.date.past().toISOString(),
  assignedPerson: {
    name: 'John Doe',
    email: 'john@commutifi.com',
    phoneNumber: '+1 123 456 7890'
  },
  squareFootage: 10000,
  occupancy: 50,
  enterpriseId: fakeEnterpriseId,
  building: {
    name: undefined,
    address: '1111 Pearl St, Boulder, CO 80302, USA',
    location: {
      lat: 40.0179654,
      lng: -105.2809452
    },
    streetNumber: '1111',
    street: 'Pearl Street',
    postalCode: '80302',
    city: 'Boulder',
    state: 'Colorado',
    country: Countries.UnitedStates
  }
})

const fakedOffice = fakeOffice()
export const fakeEnterpriseDashboardResponse = {
  enterprise: generateFakeEnterprise({
    id: fakeEnterpriseId,
    createdAt: faker.date.past().toISOString(),
    updatedAt: faker.date.past().toISOString(),
    billingEmail: 'testbilling@commutifi.com',
    identityProviderId: undefined,
    canRegisterManagers: false,
    offices: [fakeAccountOffice, fakedOffice],
    buildings: [fakeAccountOffice.building, fakedOffice.building]
  })
}
// Simply unwrap faked enterprise from the /dashboard endpoint fake response to make it easier to use
export const fakeEnterprise = fakeEnterpriseDashboardResponse.enterprise

export const fakeOrganizationDashboardResponse = {
  organization: {
    ...fakeEnterpriseDashboardResponse.enterprise,
    kind: EnterpriseKind.organization
  }
}

/**
 * Faked /enterprises/:entId/sub-enterprises payload used for
 * enterprises list view
 *
 * Min 2 cause we also use those to generate fake plan permissions
 * and for tests purposes we want at least one enterprise with 0
 * plan permissions and one with \>0
 */
export const fakeSubEnterprises: IOrganization[] = times(randomIn({ min: 2, max: 20 }), () => ({
  ...generateFakeEnterprise(),
  accounts: times(randomIn({ min: 0, max: 10 }), () => ({ id: faker.datatype.uuid() })),
  managers: times(randomIn({ min: 0, max: 3 }), () => ({
    id: faker.datatype.uuid(),
    email: fakeCommutifiEmail(),
    name: fakeFullName()
  })),
  offices: times(randomIn({ min: 1, max: 10 }), () => ({
    id: faker.datatype.uuid(),
    createdAt: faker.date.past().toISOString(),
    building: { id: faker.datatype.uuid(), address: fakeFullAddress(), location: { lat: 0, lng: 0 } }
  }))
}))

export const fakeEnterpriseOffices = [
  ...(fakeEnterpriseDashboardResponse.enterprise.offices || []),
  ...times(2, () => fakeOffice())
]

export const fakeAnalyticsOffices = [
  {
    officeId: 'ef34c3a2-f514-4990-b744-2dc883176ba8',
    enterpriseId: '29be2907-6edf-4347-9c12-d83797829e11',
    buildingId: '6475a0c2-34e0-46f0-833a-111a03f0d90c',
    officeName: null,
    officeAddress: '1275 Av. des Canadiens-de-Montréal, Montréal, QC H3B 5E6, Canada',
    officeStreetNumber: '1275',
    officeStreet: 'Avenue des Canadiens-de-Montréal',
    officeCity: 'Montréal',
    officeState: 'Québec',
    officeCountry: 'CA',
    officePostalCode: 'H3B5E6',
    officeLatitude: 45.4964397,
    officeLongitude: -73.5706624
  },
  {
    officeId: 'af1b1388-7dee-4bb3-b78f-a5e36497f7b3',
    enterpriseId: '29be2907-6edf-4347-9c12-d83797829e11',
    buildingId: '10222800-3057-41ac-bdf5-7c254dc5ca8c',
    officeName: null,
    officeAddress: '1600 Pennsylvania Avenue NW, Washington, DC 20500, USA',
    officeStreetNumber: '1600',
    officeStreet: 'Pennsylvania Avenue Northwest',
    officeCity: 'Washington',
    officeState: 'District of Columbia',
    officeCountry: 'US',
    officePostalCode: '20500',
    officeLatitude: 38.8976633,
    officeLongitude: -77.0365739
  },
  {
    officeId: 'b44aefcb-d09e-48fb-8811-939ad91b0938',
    enterpriseId: '29be2907-6edf-4347-9c12-d83797829e11',
    buildingId: 'ae8e8ae6-0965-4bee-8e59-ea782542ccf5',
    officeName: null,
    officeAddress: '1701 Rhode Island Ave NW, Washington, DC 20036, USA',
    officeStreetNumber: '1701',
    officeStreet: 'Rhode Island Avenue Northwest',
    officeCity: 'Washington',
    officeState: 'District of Columbia',
    officeCountry: 'US',
    officePostalCode: '20036',
    officeLatitude: 38.9067117,
    officeLongitude: -77.0387573
  },
  {
    officeId: '56e8d5e5-0a2d-4028-944c-0a0e610ddd89',
    enterpriseId: '29be2907-6edf-4347-9c12-d83797829e11',
    buildingId: '1071d62e-739d-445d-b138-f76a23bb83da',
    officeName: null,
    officeAddress: '400 Concar Dr, San Mateo, CA 94402, USA',
    officeStreetNumber: '400',
    officeStreet: 'Concar Drive',
    officeCity: 'San Mateo',
    officeState: 'California',
    officeCountry: 'US',
    officePostalCode: '94402',
    officeLatitude: 37.5532213,
    officeLongitude: -122.3074963
  }
]

export const fakeEnterpriseCities = ['New York', 'Los Angeles', 'Chicago', 'Houston', 'Phoenix']

export const fakeEnterpriseAttributes = times<AttributeProps>(randomIn({ min: 1, max: 2 }), () => fakeAttribute())

export const fakeEnterpriseManagers = times(3, () => ({
  id: faker.datatype.uuid(),
  name: faker.name.findName(),
  email: faker.internet.email()
}))

export const fakeInvitedEnterpriseManagers = times(2, () => ({
  id: faker.datatype.uuid(),
  name: faker.name.findName(),
  email: faker.internet.email()
}))

export const fakedShippingRates = fakeShippingRates()
export const fakedFundingInstructions = fakeFundingInstructions()
export const fakedEnterpriseBalances = fakeBalances()
