export const heightWidthRatio = (
  h: number | string | undefined,
  w: number | string | undefined,
  {
    defaultHeight,
    defaultWidth
  }: {
    defaultHeight: number
    defaultWidth: number
  }
) => {
  const widthHeightRatio = defaultWidth / defaultHeight
  const heightWidthRatio = defaultHeight / defaultWidth
  const hUnits = typeof h === 'string' ? h.replace(/[0-9.]/g, '') : undefined
  const wUnits = typeof w === 'string' ? w.replace(/[0-9.]/g, '') : undefined
  const units = hUnits || wUnits
  const heightNumber =
    typeof h === 'number' ? h : h ? parseInt(h) : (h as undefined)
  const widthNumber =
    typeof w === 'number' ? w : w ? parseInt(w) : (w as undefined)
  const height =
    h || (widthNumber ? widthNumber * heightWidthRatio : defaultHeight)
  const width =
    w || (heightNumber ? heightNumber * widthHeightRatio : defaultWidth)
  return units
    ? {
        height: `${height}${hUnits || 'px'}`,
        width: `${width}${wUnits || 'px'}`
      }
    : { height, width }
}
