import { camelCase } from 'lodash'
import { CssVariablesCamelizeString } from '@commutifi-fe/interfaces'
import defaultTheme, { Styles as DefaultTheme } from './themes/default.module.scss'

export type ChartsTokens = Record<CssVariablesCamelizeString<keyof DefaultTheme>, string>

export const extractThemeFromDocumentStyle = () => {
  const styles = getComputedStyle(document.body)
  // Here we use the default theme simply because we know it has ALL the style keys
  return Object.entries(defaultTheme).reduce(
    (acc, [key, value]) => ({ ...acc, [camelCase(key)]: `rgb(${styles.getPropertyValue(key) || value})` }),
    // eslint-disable-next-line @typescript-eslint/prefer-reduce-type-parameter -- Faking CommutifiToken object
    {} as ChartsTokens
  )
}

export const dynamicColors = extractThemeFromDocumentStyle()
