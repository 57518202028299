import { createSlice } from '@reduxjs/toolkit'
import { resetAllFilters } from '../actions'

const initialState = {
  includeRemote: false
}

export const remoteStatusSlice = createSlice({
  name: 'remoteStatus',
  initialState,
  reducers: {
    setIncludeRemote: (state, action) => {
      state.includeRemote = action.payload
    },
    resetRemoteStatus: () => initialState
  },
  extraReducers(builder) {
    builder.addCase(resetAllFilters, (state) => {
      state.includeRemote = initialState.includeRemote
    })
  }
})

const remoteStatusReducer = remoteStatusSlice.reducer
const { setIncludeRemote, resetRemoteStatus } = remoteStatusSlice.actions

export { setIncludeRemote, resetRemoteStatus, remoteStatusReducer }
