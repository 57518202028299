import types from './types'
import { combineReducers } from 'redux'
import FETCH_STATUS from 'constants/global'
import values from 'lodash/values'

/**
 * USER ROUTES SECTION. WE MAKE THE DISTINCTION BECAUSE
 * A USER CAN HAVE SUGGESTION ROUTES WHICH ARE NOT THE SAME
 * AND SHOULD BE STORED SEPERATLY AND HAVE THEIR OWN LOGIC
 */
const byId = (state = {}, action = {}) => {
  switch (action.type) {
    case types.FETCH_USER_ROUTES_SUCCESS:
      return {
        ...state,
        ...action.payload.entities.route
      }
    default:
      return state
  }
}

const allIds = (state = [], action = {}) => {
  switch (action.type) {
    case types.FETCH_USER_ROUTES_SUCCESS:
      return action.payload.result
    default:
      return state
  }
}

const isFetching = (state = false, action = {}) => {
  switch (action.type) {
    case types.FETCH_USER_ROUTES_REQUEST:
      return true
    case types.FETCH_USER_ROUTES_SUCCESS:
    case types.FETCH_USER_ROUTES_FAILURE:
      return false
    default:
      return state
  }
}

const currentRouteId = (state = null, action = {}) => {
  switch (action.type) {
    case types.SELECT_CURRENT_ROUTE:
      return action.id
    default:
      return state
  }
}

const currentTab = (state = 0, action = {}) => {
  switch (action.type) {
    case types.SELECT_TAB:
      return action.index
    default:
      return state
  }
}

const suggestionsByRouteId = (state = {}, action = {}) => {
  switch (action.type) {
    case types.FETCH_SUGGESTION_ROUTES_SUCCESS:
      return {
        ...state,
        [action.userRouteId]: action.payload.result
      }
    default:
      return state
  }
}

/**
 * Example:
 * {
 *    [routeId1]: [serviceId1, serviceId2, ...]
 *    [routeId2]: [serviceId1, serviceId2, ...]
 *    ...
 * }
 */
const servicesByRouteId = (state = {}, action = {}) => {
  switch (action.type) {
    case types.FETCH_SUGGESTION_ROUTES_SUCCESS:
    case types.FETCH_USER_ROUTES_SUCCESS: {
      const legs = action.payload.entities.leg
      return {
        ...state,
        ...values(action.payload.entities.route).reduce(
          (byRouteId, route) => ({
            ...byRouteId,
            [route.id]: route.legs.map((legId) =>
              legs[legId].serviceId ? legs[legId].serviceId : legs[legId].activity
            )
          }),
          {}
        )
      }
    }
    default:
      return state
  }
}

/**
 * USER SUGGESTIONS ROUTES
 */
const suggestionsById = (state = {}, action = {}) => {
  switch (action.type) {
    case types.FETCH_SUGGESTION_ROUTES_SUCCESS:
      return {
        ...state,
        ...action.payload.entities.route
      }
    default:
      return state
  }
}

const suggestionsFetchStatus = (state = {}, action = {}) => {
  switch (action.type) {
    case types.FETCH_SUGGESTION_ROUTES_REQUEST:
      return { ...state, [action.userRouteId]: FETCH_STATUS.LOADING }
    case types.FETCH_SUGGESTION_ROUTES_SUCCESS:
      return { ...state, [action.userRouteId]: FETCH_STATUS.LOADED }
    case types.FETCH_SUGGESTION_ROUTES_FAILURE:
      return { ...state, [action.userRouteId]: FETCH_STATUS.FAILED }
    default:
      return state
  }
}

const currentSuggestionId = (state = null, action = {}) => {
  switch (action.type) {
    case types.SELECT_SUGGESTION_ROUTE:
      return action.routeId
    // Unselect suggestion when we select user current route
    case types.SELECT_CURRENT_ROUTE:
    case types.RESET_CURRENT_SUGGESTION_ROUTE:
      return null
    default:
      return state
  }
}

export default combineReducers({
  // User routes
  byId,
  allIds,
  isFetching,
  currentRouteId,
  currentTab,
  suggestionsByRouteId,
  servicesByRouteId,
  // User suggestions routes
  suggestionsById,
  suggestionsFetchStatus,
  currentSuggestionId
})
