import { ModeName, modeNames } from '@commutifi/modes'
// These constants were created to accommodate the use of SCSS on our project
import { colors, spacing } from '@commutifi-fe/ui/styles/constants'
import themeVariables from './variables.scss'

export { colors, spacing, themeVariables }

// !IMPORTANT, it needs to be the sames values as we set in our scss (styles/settings/_global.scss)
export const screenSizes = {
  xsMaxWidth: '575.98px', // Extra small devices: < 576px
  smMinWidth: '576px',
  smMaxWidth: '759.98px', // Small devices (landscape phones, less than 768px): >= 576px && < 760px
  mdMinWidth: '760px',
  mdMaxWidth: '1023.98px', // Medium devices (tablets, less than 992px): >= 760px && < 992px
  lgMinWidth: '1024px',
  lgMaxWidth: '1279.98px', // Large devices (desktops, less than 1200px): >= 992px && < 1200px
  xlMinWidth: '1280px',
  xlMaxWidth: '1599.98px' // Extra large devices (large desktop): >= 1200px && < 1600px
}

export const sortingOrdersEnum = { ASC: 0, DESC: 1 }

/** To keep track of the fetch status state. Inspired by:
 * https://medium.com/statuscode/dissecting-twitters-redux-store-d7280b62c6b1
 */
export default {
  LOADING: 'loading',
  LOADED: 'loaded',
  FAILED: 'failed'
}

export const termsLink = 'https://www.commutifi.com/terms-of-use'
export const policyLink = 'https://www.commutifi.com/privacy-policy'
export const contactUsLink = 'https://www.commutifi.com/company/contact-us'
export const homeLink = 'https://www.commutifi.com'

export const helpEmail = 'help@commutifi.com'
export const dcCashoutGovEmail = 'parkingcashout@dc.gov'
export const dcCashoutEmail = 'dccashout@commutifi.com'

export const WEEKS_PER_MONTH = 4.34524
export const ROUTE_WEEKLY_FREQUENCY = 2 * 5

export const COMMUTIFI_TRANSACTION_FEES = 4.5

export const antCardStyling = {
  className: 'border-top--none',
  styles: {
    header: { borderBottom: 'none', paddingTop: '8px' },
    body: { paddingTop: 0 }
  }
}

export const COMMUTE_MODES = modeNames.filter((mode) => (mode as ModeName) !== ModeName.None)

// Remove taxi (will be merged with rideshare in future sprints) but for now
// we need to keep it in COMMUTE_MODES variable
export const AVAILABLE_MODES = ['all', ...COMMUTE_MODES]
export const ACTIVITIES = ['kids', 'breakfastordinner', 'school', 'cofee', 'gym', 'meeting', 'parking']

export const emailRegex =
  /^(?:(?:[^<>()[\]\\.,;:\s@"]+(?:\.[^<>()[\]\\.,;:\s@"]+)*)|(?:".+"))@(?:(?:\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(?:(?:[a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export enum EntityType {
  Commuter = 'accounts',
  SuperAdmin = 'super-admins',
  Admin = 'admins'
}

export const PAYMENT_FREQUENCY = {
  free: 'free',
  variable: 'variable'
}

export const LOCATION_RADIUS_IN_M = 150000

export enum CookieCategory {
  All = 'all',
  Necessary = 'necessary',
  Analytics = 'analytics'
}

export const STORAGE_THEME_KEY = 'theme'

export const DEFAULT_OFFICE_ARRIVAL_TIME_HHMM = '0900'
export const DEFAULT_OFFICE_LEAVING_TIME_HHMM = '1700'
export const DEFAULT_CARPOOL_PEOPLE_COUNT = 2
