import { UnknownAction } from 'redux'
import { LoginBody } from 'api/modules/authentication'
import { VerifyPasswordResponse } from 'api/modules/accounts'
import { DeconstructedPromiseType } from 'shared/interfaces/utils'
import * as types from './types'

export interface PasswordFormValues {
  email: string
  password: string
}

interface LogoutOptions {
  error?: string
}

export interface LogoutAction<T = void> extends UnknownAction {
  type: typeof types.LOGOUT
  payload?: LogoutOptions
  promise?: DeconstructedPromiseType<T>
}

export interface LogoutSuccessAction extends UnknownAction {
  type: typeof types.LOGOUT_SUCCESS
  payload?: LogoutOptions
}

export interface LoginAction<T = void> extends UnknownAction {
  type: typeof types.LOGIN_REQUEST
  auth: LoginBody
  options: { stayLoggedIn?: boolean }
  promise?: DeconstructedPromiseType<T>
}

export interface LoginSuccessAction<T = void> extends UnknownAction {
  type: typeof types.LOGIN_SUCCESS
  accountId: string
  options: { stayLoggedIn?: boolean }
  promise?: DeconstructedPromiseType<T>
}

export interface LoginFailureAction extends UnknownAction {
  type: typeof types.LOGIN_FAILURE
  error: any
  messageKey?: string
}

export interface CreatePasswordAction<T = void> extends UnknownAction {
  type: typeof types.CREATE_PASSWORD_REQUEST
  values: PasswordFormValues
  accountId: string
  promise?: DeconstructedPromiseType<T>
}

export interface CreatePasswordSuccessAction extends UnknownAction {
  type: typeof types.CREATE_PASSWORD_SUCCESS
  accountId: string
}

export interface CreatePasswordFailureAction extends UnknownAction {
  type: typeof types.CREATE_PASSWORD_FAILURE
  error: any
}

export interface UnauthorizedUserAction extends UnknownAction {
  type: typeof types.UNAUTHORIZE_USER
  payload?: LogoutOptions
}

export interface ClearUserAction extends UnknownAction {
  type: typeof types.CLEAR_USER
}

export interface VerifyPasswordRequiredAction<T = VerifyPasswordResponse> extends UnknownAction {
  type: typeof types.VERIFY_HAS_PASSWORD
  accountId: string
  promise?: DeconstructedPromiseType<T>
}

/*
 * LOGIN
 */
export const loginSuccess = <T = void>(
  accountId: string,
  options: { stayLoggedIn?: boolean },
  promise?: DeconstructedPromiseType<T>
): LoginSuccessAction<T> => ({
  type: types.LOGIN_SUCCESS,
  accountId,
  options,
  promise
})

export const loginFailure = (error: any): LoginFailureAction => ({
  type: types.LOGIN_FAILURE,
  error
})

export const loginRequest = <T = void>(
  auth: LoginBody,
  options: { stayLoggedIn?: boolean },
  promise?: DeconstructedPromiseType<T>
): LoginAction<T> => ({
  type: types.LOGIN_REQUEST,
  auth,
  options,
  promise
})

/*
 * LOGOUT
 */
export const logout = <T = void>(
  options?: LogoutOptions | React.MouseEvent,
  promise?: DeconstructedPromiseType<T>
): LogoutAction<T> => ({
  type: types.LOGOUT,
  // This check is to avoid passing click events to the logout params since logout could be used directly
  // in an onClick event without really knowing the impact. Instead of risking for the issue to come back we simply do the check here
  payload: (Object.prototype.hasOwnProperty.call(options || {}, 'target') ? undefined : options) as
    | LogoutOptions
    | undefined,
  promise
})

export const logoutSuccess = (payload?: LogoutOptions): LogoutSuccessAction => ({
  type: types.LOGOUT_SUCCESS,
  payload
})

/*
 * CREATE PASSWORD
 */
export const createPasswordFailure = (error: any): CreatePasswordFailureAction => ({
  type: types.CREATE_PASSWORD_FAILURE,
  error
})

export const createPasswordRequest = <T = void>(
  values: PasswordFormValues,
  accountId: string,
  promise?: DeconstructedPromiseType<T>
): CreatePasswordAction<T> => ({
  type: types.CREATE_PASSWORD_REQUEST,
  values,
  accountId,
  promise
})

export const createPasswordSuccess = (accountId: string): CreatePasswordSuccessAction => ({
  type: types.CREATE_PASSWORD_SUCCESS,
  accountId
})

export const unauthorizeUser = (options?: LogoutOptions): UnauthorizedUserAction => ({
  type: types.UNAUTHORIZE_USER,
  payload: options
})

export const clearUser = (): ClearUserAction => ({
  type: types.CLEAR_USER
})

export const verifyPasswordRequired = (
  accountId: string,
  promise?: DeconstructedPromiseType<VerifyPasswordResponse>
): VerifyPasswordRequiredAction => ({
  type: types.VERIFY_HAS_PASSWORD,
  accountId,
  promise
})

export type AuthActionType =
  | LoginAction<any>
  | LoginSuccessAction<any>
  | LoginFailureAction
  | LogoutAction<any>
  | LogoutSuccessAction
  | CreatePasswordAction<any>
  | CreatePasswordSuccessAction
  | CreatePasswordFailureAction
  | UnauthorizedUserAction
  | ClearUserAction
  | VerifyPasswordRequiredAction
