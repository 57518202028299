import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { resetAllFilters } from '../actions'

const initialState: { selectedAttributes: Record<string, string[]> } = {
  selectedAttributes: {}
}

export const attributesSlice = createSlice({
  name: 'attributes',
  initialState,
  reducers: {
    setSelectedAttributes: (state, action: PayloadAction<Record<string, string[]>>) => {
      state.selectedAttributes = action.payload
    },
    resetAttributes: (state) => {
      state.selectedAttributes = initialState.selectedAttributes
    }
  },
  extraReducers(builder) {
    builder.addCase(resetAllFilters, (state) => {
      state.selectedAttributes = initialState.selectedAttributes
    })
  }
})

const { setSelectedAttributes, resetAttributes } = attributesSlice.actions
const attributesReducer = attributesSlice.reducer

export { setSelectedAttributes, resetAttributes, attributesReducer }
