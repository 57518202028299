import { DeepPartial, Overwrite } from 'utility-types'
import { Form as AntdForm } from 'antd'
import {
  ErrorListProps,
  FormInstance,
  FormItemProps,
  FormListFieldData,
  FormListOperation,
  FormListProps,
  FormProps as AntdFormProps,
  Rule,
  RuleObject,
  RuleRender
} from 'antd/es/form'
import { FormLayout } from 'antd/es/form/Form'
import { InternalNamePath, NamePath, Store } from 'antd/es/form/interface'
import cx from 'classnames'
import React from 'react'
import './styles.scss'

const PrefixCls = 'app-form'

interface FormProps<Values = any> extends Overwrite<AntdFormProps<Values>, { initialValues?: DeepPartial<Values> }> {
  displayMode?: boolean
}

export type {
  FormProps,
  ErrorListProps,
  FormItemProps,
  FormListFieldData,
  FormListOperation,
  FormListProps,
  Rule as FormRule,
  RuleObject as FormRuleObject,
  RuleRender as FormRuleRender,
  InternalNamePath as FormInternalNamePath,
  NamePath as FormNamePath,
  FormInstance,
  FormLayout
}

/**
 * This class is a wrap around [antd Form](https://ant.design/components/form/) component extending some of
 * the properties to follow our styling guidelines.
 *
 * Please refer to antd documentation for more details.
 *
 */
function InternalForm<Values = Store>(
  { className, displayMode, ...props }: FormProps<Values>,
  ref: React.Ref<FormInstance>
) {
  // @ts-expect-error -- Legacy and it's been working since a while
  return <AntdForm ref={ref} {...props} className={cx(PrefixCls, displayMode && 'app-form--display-mode', className)} />
}
InternalForm.displayName = 'Form'

function InternalFormItem<Values = any>({ label, ...props }: FormItemProps<Values>) {
  return (
    <AntdForm.Item
      {...props}
      label={
        React.isValidElement(label) && label.type === LabelWithHint ? (
          label
        ) : label ? (
          <span className="app-form-item-label" style={{ width: 'inherit' }}>
            {label}
          </span>
        ) : null
      }
    />
  )
}

function LabelWithHint({ label, hint }: { label: React.ReactNode; hint: React.ReactNode }) {
  return (
    <div className="app-form-item-custom-label">
      <span className="app-typography">{label}</span>
      <span className="app-form-item-custom-label-hint app-typography ant-typography-secondary">{hint}</span>
    </div>
  )
}
InternalFormItem.LabelWithHint = LabelWithHint

const FormRF = React.forwardRef<FormInstance, FormProps>(InternalForm) as <Values = any>(
  props: React.PropsWithChildren<FormProps<Values>> & { ref?: React.Ref<FormInstance<Values>> }
) => React.ReactElement

type CompoundedComponent = typeof FormRF & {
  useForm: typeof AntdForm.useForm
  useFormInstance: typeof AntdForm.useFormInstance
  useWatch: typeof AntdForm.useWatch
  Item: typeof InternalFormItem
  List: typeof AntdForm.List
  ErrorList: typeof AntdForm.ErrorList
  Provider: typeof AntdForm.Provider

  /** @deprecated Only for warning usage. Do not use. */
  create: () => void
}

export const Form = FormRF as CompoundedComponent
Form.ErrorList = AntdForm.ErrorList
Form.Item = InternalFormItem
Form.List = AntdForm.List
Form.Provider = AntdForm.Provider
Form.useForm = AntdForm.useForm
Form.useFormInstance = AntdForm.useFormInstance
Form.useWatch = AntdForm.useWatch
