import get from 'lodash/get'
import { primaryRoutePath, REF_KEYS_LIST } from 'constants/onboarding'
import { Location } from 'shared/interfaces'
import { Account, Question, QUESTION_TYPES } from '@commutifi-fe/interfaces'

interface IEnterpriseSurvey {
  id: string
  account: { name: string; email: string } | undefined
  building: { id: string; address: string; location: Location } | undefined
  enterprise: { id: string; name: string } | undefined
  organization: { id: string; name: string } | undefined
  accountContext?: Account
}

const filterActivityMapper = (answersById: Record<string, any> | undefined) => {
  const previousAnswers = answersById || {}
  const routeAnswer = previousAnswers[REF_KEYS_LIST.commuteProfileRoute]
  const primaryRoute = get(routeAnswer, primaryRoutePath)
  // Make sure at least the primary route is defined if we have an activity mapper question
  if (!primaryRoute) {
    return false
  }
  return true
}

const filterMissingStartOrEndAddress = (
  answersById: Record<string, any> | undefined,
  enterpriseSurveyData: IEnterpriseSurvey
) => {
  const previousAnswers = answersById || {}
  // For home and office answers we look for `.value` because this means the user selected an
  // autocompleted value and is not currently typing a value. This is important if the commute router
  // is displayed on the same step than the office selector
  const homeAddressAnswer = previousAnswers[REF_KEYS_LIST.accountAddress]?.value
  const officeAddressAnswer =
    previousAnswers[REF_KEYS_LIST.accountOfficeId]?.value ||
    (enterpriseSurveyData.building?.address && enterpriseSurveyData.building.location)
  // Hide CommuteTimeline when the home address and office address are not defined
  if (!homeAddressAnswer || !officeAddressAnswer) {
    return false
  }
  return true
}

const filterOfficeSelector = (
  answersById: Record<string, any> | undefined,
  enterpriseSurveyData: IEnterpriseSurvey | undefined
) => {
  const previousAnswers = answersById || {}
  const enterpriseIdAnswer =
    previousAnswers[REF_KEYS_LIST.accountEnterpriseId]?.key ||
    enterpriseSurveyData?.enterprise?.id ||
    enterpriseSurveyData?.accountContext?.enterpriseId
  const enterpriseSurveyBuildingId = enterpriseSurveyData?.building?.id
  // Hide OfficeSelector when enterprise is not selected or there is no enterprise id set in the short link
  // Also hide it when the building id is already preset in the enterpriseSurvey
  if (enterpriseSurveyBuildingId || !enterpriseIdAnswer) {
    return false
  }
  return true
}

const filterEnterpriseSelector = (enterpriseSurveyData: IEnterpriseSurvey | undefined) => {
  // Hide CompanySelector when enterprise is already preset in the short link
  if (enterpriseSurveyData?.enterprise?.id || enterpriseSurveyData?.accountContext?.enterpriseId) {
    return false
  }
  return true
}

const filterSelectableCardsQuestions = (question: Question | undefined) => {
  const options = question?.content?.options?.options
  if (!options || !Array.isArray(options) || (Array.isArray(options) && options.length <= 0)) {
    return false
  }
  return true
}

const filterDaySelector = (question: Question, answersById: Record<string, any> | undefined) => {
  // This is to filter the case where the remote frequency and commute frequency questions are in the same section.
  // In that case if there is 0 days selected in the commute frequency (as a temporary state of the day picker) we will
  // hide the remote frequency question.
  const previousAnswers = answersById || {}
  const commuteWeeklyFrequency = previousAnswers[REF_KEYS_LIST.commuteProfileFrequency]?.weeklyFrequency
  return !(question.referenceKey === REF_KEYS_LIST.commuteProfileRemoteFrequency && commuteWeeklyFrequency === 0)
}

const applyFilterRelatedToSpecificQuestionType = (
  question: Question,
  answersById: Record<string, any> | undefined,
  enterpriseSurveyData: IEnterpriseSurvey
) => {
  switch (question.type || '') {
    case QUESTION_TYPES.activityMapper:
      return filterActivityMapper(answersById)
    case QUESTION_TYPES.commuteTimeline:
    case QUESTION_TYPES.commuteTimelineDriveAndTransit:
    case QUESTION_TYPES.commuteRouter:
    case QUESTION_TYPES.secondaryCommuteRouter:
      return filterMissingStartOrEndAddress(answersById, enterpriseSurveyData)
    case QUESTION_TYPES.officeSelector:
      return filterOfficeSelector(answersById, enterpriseSurveyData)
    case QUESTION_TYPES.enterpriseSelector:
      return filterEnterpriseSelector(enterpriseSurveyData)
    case QUESTION_TYPES.radioButton:
    case QUESTION_TYPES.checkboxes:
      return filterSelectableCardsQuestions(question)
    case QUESTION_TYPES.daySelector:
      return filterDaySelector(question, answersById)
    default:
      return true
  }
}

/**
 * Go through every questions passed in parameter to filter those that
 * doesn't fulfil each filter conditions need to avoid crashes on those components
 *
 * @param questions - Survey Question array
 * @param answersById - Survey cumulated answers
 * @param enterpriseSurveyData - Survey enterpriseSurvey data
 */
export const filterInvalidQuestions = (
  questions: Question[],
  answersById: Record<string, any> | undefined,
  enterpriseSurveyData: IEnterpriseSurvey
) =>
  questions.filter((question) => applyFilterRelatedToSpecificQuestionType(question, answersById, enterpriseSurveyData))
