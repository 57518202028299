import { HttpMethod } from '../../constants'

function replaceAccountWalletId(accountWalletId: string): string {
  return this.path.replace(':accountWalletId', accountWalletId)
}
function replaceWalletId(walletId: string): string {
  return this.path.replace(':walletId', walletId)
}
function returnPath(): string {
  return this.path
}

export const walletEndpoints = {
  [HttpMethod.Get]: {
    WalletEnrolment: {
      path: '/accounts-wallets/:accountWalletId',
      route: replaceAccountWalletId
    },
    WalletsInMyGroups: {
      path: '/groups-wallets/wallets',
      route: returnPath
    },
    Wallet: {
      path: '/wallets/:walletId',
      route: replaceWalletId
    },
    EnrollmentWallet: {
      path: '/accounts-wallets/:accountWalletId/wallets/:walletId',
      route(accountWalletId: string, walletId: string): string {
        return this.path.replace(':accountWalletId', accountWalletId).replace(':walletId', walletId)
      }
    },
    SpendingRules: {
      path: '/wallet-spending-rules',
      route: returnPath
    }
  },
  [HttpMethod.Patch]: {
    WalletEnrolment: {
      path: '/accounts-wallets/:accountWalletId',
      route: replaceAccountWalletId
    },
    WalletEnrolments: {
      path: '/accounts-wallets',
      route: returnPath
    },
    Wallet: {
      path: '/wallets/:walletId',
      route: replaceWalletId
    }
  },
  [HttpMethod.Post]: {
    AccountWallet: {
      path: '/accounts-wallets',
      route: returnPath
    },
    Wallet: {
      path: '/wallets',
      route: returnPath
    },
    WalletSpendingRules: {
      path: '/wallets/:walletId/spending-rules',
      route: replaceWalletId
    },
    SpendingRules: {
      path: '/wallet-spending-rules',
      route: returnPath
    },
    WalletGroups: {
      path: '/wallets/:walletId/groups-wallets',
      route: replaceWalletId
    }
  },
  [HttpMethod.Put]: {
    WalletSpendingRules: {
      path: '/wallets/:walletId/spending-rules',
      route: replaceWalletId
    }
  },
  [HttpMethod.Delete]: {
    WalletSpendingRules: {
      path: '/wallets/:walletId/spending-rules',
      route: replaceWalletId
    },
    WalletGroups: {
      path: '/wallets/:walletId/groups-wallets',
      route: replaceWalletId
    }
  }
}

export default walletEndpoints
