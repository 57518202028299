import React from 'react'
import useMemo from 'rc-util/es/hooks/useMemo'
import { ConfigProvider as AntdConfigProvider } from 'antd'
import { isEqual } from 'lodash'
import { SupportedLocales } from '@commutifi/constants/Locale'
import { usePrevious } from '@commutifi-fe/hooks'
import commutifiTheme from '../theme'
import { defaultConfig, extractThemeFromDocumentStyle } from '../theme/context'
import { Themes } from '../theme/constants'
import { colors } from '../style/constants'
import defaultTheme from '../style/themes/default.module.scss'
import darkTheme from '../style/themes/dark.module.scss'
import enUS from '../locales/default'
import { Locale } from '../locales/index'
import { ConfigContext } from './context'
import 'antd/dist/reset.css'
import { ConfigProviderProps } from './props'

export type { Locale }

const { getDesignToken } = commutifiTheme

function hexToRgbComponents(hex: string) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  if (!result) return undefined
  return `${parseInt(result[1], 16)},${parseInt(result[2], 16)},${parseInt(result[3], 16)}`
}

export function ConfigProvider({
  children,
  localeKey = SupportedLocales.enUS,
  locale = enUS,
  theme,
  ...config
}: ConfigProviderProps) {
  const prevTheme = usePrevious(theme?.appearance)
  const commutifiConfig = { locale, localeKey }

  const memoedCommutifiConfig = useMemo(
    () => commutifiConfig,
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Not really a React useMemo
    commutifiConfig,
    (prevConfig, currentConfig) => {
      const prevKeys = Object.keys(prevConfig) as (keyof typeof commutifiConfig)[]
      const currentKeys = Object.keys(currentConfig) as (keyof typeof commutifiConfig)[]
      return prevKeys.length !== currentKeys.length || prevKeys.some((key) => prevConfig[key] !== currentConfig[key])
    }
  )

  const [dynamicColors, setDynamicColors] = React.useState<ReturnType<typeof extractThemeFromDocumentStyle>>(
    extractThemeFromDocumentStyle()
  )
  const getAntdTheme = React.useCallback<(currentTheme: `${Themes}`) => ConfigProviderProps['theme']>(
    (currentTheme: `${Themes}`) => ({
      algorithm: currentTheme === Themes.DARK ? commutifiTheme.darkAlgorithm : commutifiTheme.defaultAlgorithm,
      token: {
        ...defaultConfig.token,
        colorPrimary: dynamicColors.primary400 || '',
        colorLink: dynamicColors.primary400 || '',
        colorLinkHover: dynamicColors.primary500 || '',
        colorLinkActive: dynamicColors.primary500 || '',
        colorSuccess: colors.feedbackSuccess400,
        colorWarning: colors.feedbackWarning400,
        colorText: dynamicColors.neutral600 || '',
        colorTextHeading: dynamicColors.neutral600 || '',
        colorTextSecondary: dynamicColors.neutral500 || '',
        colorTextPlaceholder: dynamicColors.neutral500 || '',
        colorError: colors.feedbackDestructive500,
        colorIcon: dynamicColors.neutral600 || '',
        colorTextDisabled: dynamicColors.neutral500 || '',
        colorPrimaryActive: dynamicColors.primary500 || '',
        colorPrimaryHover: dynamicColors.primary500 || '',
        colorErrorHover: colors.feedbackDestructive500,
        ...(currentTheme !== Themes.DARK && {
          colorBgLayout: dynamicColors.backgroundColor || ''
        })
      },
      components: {
        Alert: {
          ...defaultConfig.components?.Alert,
          colorInfo: dynamicColors.neutral600,
          colorInfoBg: dynamicColors.neutral200,
          colorWarning: dynamicColors.neutral600Static,
          colorWarningBg: colors.feedbackWarning400,
          colorSuccess: dynamicColors.neutral600,
          colorSuccessBg: dynamicColors.feedbackSuccess200,
          colorError: dynamicColors.neutral600,
          colorErrorBg: colors.feedbackDestructive100,
          colorErrorBorder: '#B82608'
        },
        Button: {
          ...defaultConfig.components?.Button,
          textTextHoverColor: dynamicColors.neutral500,
          defaultBorderColor: dynamicColors.neutral300,
          defaultShadow: `0px 2px 4px -2px rgba(${colors.neutral600}, 0.12)`,
          colorPrimaryTextHover: dynamicColors.primary600,
          defaultGhostColor: dynamicColors.neutral600,
          defaultGhostBorderColor: dynamicColors.neutral600,
          defaultHoverColor: dynamicColors.neutral500,
          defaultActiveColor: dynamicColors.primary400,
          colorError: colors.feedbackDestructive400,
          colorLinkActive: dynamicColors.primary600
        },
        Checkbox: {
          ...defaultConfig.components?.Checkbox,
          colorPrimary: dynamicColors.primary350 || '',
          colorPrimaryHover: dynamicColors.primary400 || ''
        },
        Divider: {
          ...defaultConfig.components?.Divider,
          colorSplit: dynamicColors.neutral300 || ''
        },
        InputNumber: {
          ...defaultConfig.components?.InputNumber,
          colorErrorOutline: colors.feedbackDestructive500
        },
        Menu: {
          ...defaultConfig.components?.Menu
        },
        Message: {
          ...defaultConfig.components?.Message,
          colorBgElevated: dynamicColors.colorBgLayout || ''
        },
        Modal: {
          ...defaultConfig.components?.Modal,
          ...(currentTheme === Themes.DARK && {
            colorBgMask: 'rgba(0, 0, 0, 0.10)'
          })
        },
        Notification: {
          ...defaultConfig.components?.Notification
        },
        Radio: {
          ...defaultConfig.components?.Radio,
          colorPrimary: dynamicColors.primary350 || '',
          colorBgContainerDisabled: dynamicColors.neutral300 || '',
          colorTextDisabled: dynamicColors.neutral400 || '',
          buttonCheckedBgDisabled: dynamicColors.neutral400 || '',
          buttonCheckedColorDisabled: dynamicColors.neutral500 || '',
          buttonBg: dynamicColors.neutral200 || '',
          buttonCheckedBg: dynamicColors.colorBgLayout || ''
        },
        Select: {
          ...defaultConfig.components?.Select
        },
        Skeleton: {
          ...defaultConfig.components?.Skeleton,
          gradientFromColor: currentTheme === Themes.DARK ? dynamicColors.neutral400 : dynamicColors.neutral200,
          gradientToColor: dynamicColors.neutral300 || ''
        },
        Statistic: {
          contentFontSize: 38
        },
        Switch: {
          ...defaultConfig.components?.Switch,
          colorPrimary: dynamicColors.primary350 || '',
          colorPrimaryHover: dynamicColors.primary400 || ''
        },
        Tag: {
          ...defaultConfig.components?.Tag,
          defaultBg: dynamicColors.neutral200Border,
          colorSuccess: dynamicColors.neutral600,
          colorSuccessBg: colors.feedbackSuccess200,
          colorSuccessBorder: colors.feedbackSuccess400,
          colorError: dynamicColors.neutral600,
          colorErrorBg: colors.feedbackDestructive200,
          colorErrorBorder: colors.feedbackDestructive400,
          colorWarning: dynamicColors.neutral600Static,
          colorWarningBg: dynamicColors.feedbackWarning300,
          colorWarningBorder: dynamicColors.feedbackWarning400
        },
        Tooltip: {
          // Fix min-height of tooltip that is set to global controlHeight token.
          // Since it can contains text we can have weird displays with a bigger control height
          controlHeight: 32
        },
        Tree: {
          ...defaultConfig.components?.Tree,
          colorPrimary: dynamicColors.primary350 || '',
          colorPrimaryHover: dynamicColors.primary400 || ''
        }
      }
    }),
    [dynamicColors]
  )

  const changeTheme = React.useCallback(
    (newTheme: `${Themes}`) => {
      if (newTheme === prevTheme) {
        return
      }

      const globalToken = getDesignToken(getAntdTheme(newTheme))
      switch (newTheme) {
        case Themes.DARK: {
          document.getElementsByTagName('html')[0].style.setProperty('color-scheme', 'dark')
          document.getElementsByTagName('html')[0].setAttribute('data-theme', 'dark')

          /**
           * Set token from antd generated theme colors and then simply apply values from the theme file
           */
          document.documentElement.style.setProperty(
            '--color-bg-layout',
            hexToRgbComponents(globalToken.colorBgLayout) || ''
          )
          document.documentElement.style.setProperty(
            '--color-bg-container',
            hexToRgbComponents(globalToken.colorBgContainer) || ''
          )
          document.documentElement.style.setProperty(
            '--color-bg-elevated',
            hexToRgbComponents(globalToken.colorBgElevated) || ''
          )

          Object.entries(darkTheme).forEach(([key, value]) => {
            document.documentElement.style.setProperty(key, value)
          })

          break
        }
        default:
          document.getElementsByTagName('html')[0].style.setProperty('color-scheme', 'light')
          document.getElementsByTagName('html')[0].setAttribute('data-theme', 'light')

          Object.entries(defaultTheme).forEach(([key, value]) => {
            document.documentElement.style.setProperty(key, value)
          })
      }
      // Modifying the document styles won't trigger a re-render so we 'force' it by updating the state used
      // to set the Theme Provider value
      setDynamicColors(extractThemeFromDocumentStyle())
    },
    [getAntdTheme, prevTheme]
  )

  React.useEffect(() => {
    changeTheme(theme?.appearance || Themes.LIGHT)
  }, [changeTheme, theme?.appearance])

  const currentTheme = theme?.appearance || Themes.LIGHT
  const antdTheme = getAntdTheme(currentTheme)
  const antdConfig: Omit<ConfigProviderProps, 'children'> = {
    theme: {
      cssVar: true,
      ...antdTheme,
      ...theme,
      token: {
        ...theme?.token,
        ...antdTheme?.token
      },
      components: { ...antdTheme?.components, ...theme?.components }
    },
    locale,
    ...config
  }

  const memoedAntdConfig = useMemo(
    () => antdConfig,
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Not really a React useMemo
    antdConfig,
    (prevConfig, currentConfig) => {
      const prevKeys = Object.keys(prevConfig) as (keyof typeof antdConfig)[]
      const currentKeys = Object.keys(currentConfig) as (keyof typeof antdConfig)[]
      return prevKeys.length !== currentKeys.length || !isEqual(prevConfig, currentConfig)
    }
  )

  return (
    <ConfigContext.Provider value={memoedCommutifiConfig}>
      <AntdConfigProvider {...memoedAntdConfig}>{children}</AntdConfigProvider>
    </ConfigContext.Provider>
  )
}

export default ConfigProvider
