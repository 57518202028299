import { createSlice } from '@reduxjs/toolkit'
import { resetAllFilters } from '../actions'
import { ModeCategory, ModeName } from '@commutifi/modes'

const initialState: { selectedModes: { [key in ModeCategory]?: ModeName[] } } = {
  selectedModes: {}
}

export const modesSlice = createSlice({
  name: 'modes',
  initialState,
  reducers: {
    setSelectedModes: (state, action) => {
      state.selectedModes = action.payload
    },
    resetModes: (state) => {
      state.selectedModes = initialState.selectedModes
    }
  },
  extraReducers(builder) {
    builder.addCase(resetAllFilters, (state) => {
      state.selectedModes = initialState.selectedModes
    })
  }
})

const modesReducer = modesSlice.reducer

const { setSelectedModes, resetModes } = modesSlice.actions
export { setSelectedModes, resetModes, modesReducer }
