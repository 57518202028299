import { GetResponse, OfficeWithBuilding } from '@commutifi-fe/interfaces'
import { EnterpriseDepositProps } from '@commutifi/models/EnterpriseDeposits'
import {
  EnterpriseNotificationTemplateType,
  EnterpriseNotificationTemplatesProps
} from '@commutifi/models/EnterpriseNotificationTemplates'
import { EnterpriseKind } from '@commutifi/models/Enterprises'
import { InventoryProps } from '@commutifi/models/Inventory'
import { PlanProps } from '@commutifi/models/Plan'
import { TransactionProps } from '@commutifi/models/Transaction'
import { QueryKey, keepPreviousData, useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import React from 'react'
import { Paths } from 'shared/interfaces'
import { RequestParams } from 'shared/interfaces/Api'
import { Optional, Overwrite, Primitive, Required } from 'utility-types'
import { ExtractFnReturnType, MutationConfig, QueryConfig, QueryConfigPickRes, QueryResult } from '../../reactQuery'
import { DetailedBooking } from '../bookings'
import { Submission } from '../compliance/api'
import { InvoiceWithRelations } from '../invoices'
import { PostPaymentMethodBody } from '../paymentMethods'
import {
  PostSubEnterpriseBody,
  SubEnterpriseProps,
  deleteManager,
  fetchAccounts,
  fetchAccountsWallets,
  fetchAdminDDOTSubmission,
  fetchAdminDDOTSubmissions,
  fetchAttributes,
  fetchEnterpriseBalances,
  fetchEnterpriseBookings,
  fetchEnterpriseChildren,
  fetchEnterpriseDeposits,
  fetchEnterpriseGroupsWallets,
  fetchEnterpriseInvoices,
  fetchEnterpriseNotificationTemplate,
  fetchEnterpriseOffices,
  fetchEnterprisePaymentMethods,
  fetchEnterprisePlanServiceLatestInventory,
  fetchEnterprisePlans,
  fetchEnterpriseShippingRates,
  fetchEnterpriseWallets,
  getEnterpriseMerchants,
  getEnterpriseServiceBookingsCount,
  getFundingInstructions,
  importEnterpriseOffices,
  patchEnterprise,
  patchSubmissionAdmin,
  postEnterprisePaymentMethods,
  postEnterprisePlanPermissions,
  postManagerAccountEnterprise,
  postManagers,
  postOffice,
  postSubEnterprise,
  putNotificationTemplate,
  setEnterprisePaymentMethodToDefault
} from './api'
import endpoints from './endpoints'
import { logger } from '@commutifi-fe/logger'

export function useEnterpriseAccounts(
  kind: EnterpriseKind,
  enterpriseId: string | undefined,
  queryParams: RequestParams & { 'ne:groups.id'?: string },
  options?: QueryConfig<typeof fetchAccounts>
) {
  return useQuery({
    queryKey: ['enterprise-accounts', enterpriseId, queryParams, kind],
    queryFn: async () => {
      if (!enterpriseId) {
        throw Error('Missing enterprise id')
      }

      return fetchAccounts(kind, enterpriseId, queryParams)
    },
    ...options
  })
}

export function useEnterpriseChildren<T = ExtractFnReturnType<typeof fetchEnterpriseChildren>>(
  enterpriseId: string,
  queryParams: Parameters<typeof fetchEnterpriseChildren>[1] = {},
  options?: QueryConfig<typeof fetchEnterpriseChildren, T>
) {
  return useQuery({
    queryKey: ['enterprises', enterpriseId, 'sub-enterprises', queryParams],
    queryFn: () => fetchEnterpriseChildren(enterpriseId, queryParams),
    ...options
  })
}

/**
 * Same as useEnterpriseChildren but will target a single enterprise and add a limit of 1
 * to make sure we get a single child of the enterprise
 */
export function useEnterpriseChild(
  organizationId: string,
  subEnterpriseId: string,
  queryParams: RequestParams = {},
  options: Overwrite<
    Optional<QueryConfig<typeof fetchEnterpriseChildren>, 'queryKey' | 'queryFn'>,
    { initialData?: SubEnterpriseProps }
  > = {}
): QueryResult<SubEnterpriseProps> {
  return useEnterpriseChildren<SubEnterpriseProps>(
    organizationId,
    { id: subEnterpriseId, limit: 1, ...queryParams },
    {
      initialData: options?.initialData
        ? { records: [options?.initialData], _metadata: { totalCount: 1, total: 1, offset: 1, limit: 1 } }
        : undefined,
      enabled: !options.initialData,
      select: ({ records }) => records[0]
    }
  )
}

// After this date we don't have any more legacy invoices
const endOfLegacyInvoices = '2021-08-30T00:00:00.000Z'
export const useFetchEnterpriseInvoices = (
  enterpriseId: string,
  queryParams: RequestParams & {
    // Apply filters on invoice object
    [K in Paths<Overwrite<InvoiceWithRelations, { transactions: TransactionProps }>>]?: Primitive
  },
  options?: QueryConfig<typeof fetchEnterpriseInvoices>
): QueryResult<GetResponse<InvoiceWithRelations>> & {
  baseQueryKey: QueryKey
  defaultQuery: RequestParams
} => {
  const baseQueryKey = React.useMemo(() => ['enterprises', enterpriseId, 'invoices'], [enterpriseId])
  const defaultQuery = React.useMemo(
    () => ({
      // We what to avoid fetching legacy invoices as they wont have enough info
      // to display them properly
      'gte:createdAt': endOfLegacyInvoices,
      sort: '-createdAt'
    }),
    []
  )
  const paginationQueryRes = useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps -- Enterprise id is set on the baseQueryKey
    queryKey: [...baseQueryKey, queryParams],
    queryFn: async () =>
      fetchEnterpriseInvoices(enterpriseId, {
        ...defaultQuery,
        ...queryParams
      }),

    ...options,
    placeholderData: keepPreviousData
  })

  const res = { ...paginationQueryRes, baseQueryKey, defaultQuery }
  return res
}
export const useFetchEnterpriseDeposits = (
  enterpriseId: string,
  queryParams: RequestParams & {
    [K in Paths<Overwrite<EnterpriseDepositProps, { transactions: TransactionProps }>>]?: Primitive
  }
): QueryResult<GetResponse<EnterpriseDepositProps>> =>
  useQuery({
    queryKey: ['enterprises', enterpriseId, 'topups', queryParams],
    queryFn: async () => {
      if (!enterpriseId) {
        throw new Error('Missing enterprise id')
      }
      const data = await fetchEnterpriseDeposits(enterpriseId, { sort: '-createdAt', ...queryParams })
      return data
    }
  })

export function useEnterpriseAttributes(
  enterpriseId: string | undefined,
  queryParams: Parameters<typeof fetchAttributes>[1] = {},
  options?: QueryConfigPickRes<typeof fetchAttributes, 'records'>
) {
  return useQuery({
    queryKey: ['enterprises/attributes', enterpriseId, queryParams],
    queryFn: async () => {
      if (!enterpriseId) {
        throw Error('Missing enterprise id')
      }

      const { records } = await fetchAttributes(enterpriseId, queryParams)
      return records
    },
    ...options
  })
}

export function useEnterpriseAccountsWallets<T = ExtractFnReturnType<typeof fetchAccountsWallets>>(
  enterpriseId: string | undefined,
  queryParams: Parameters<typeof fetchAccountsWallets>[1],
  options?: QueryConfig<typeof fetchAccountsWallets, T>
) {
  return useQuery({
    queryKey: ['enterprises', enterpriseId, 'accounts-wallets', queryParams],
    queryFn: async () => {
      if (!enterpriseId) {
        throw Error('Missing enterprise id')
      }

      return fetchAccountsWallets(enterpriseId, queryParams)
    },
    ...options
  })
}

export function useEnterprisePlans<T extends PlanProps[] = ExtractFnReturnType<typeof fetchEnterprisePlans>['records']>(
  enterpriseId: string | undefined,
  queryParams: Parameters<typeof fetchEnterprisePlans>[1] = {},
  options?: QueryConfigPickRes<typeof fetchEnterprisePlans, 'records'>
): QueryResult<T> {
  return useQuery({
    queryKey: ['enterprises', enterpriseId, 'plans', queryParams],
    queryFn: async () => {
      if (!enterpriseId) {
        throw Error('Missing enterprise id')
      }

      const { records } = await fetchEnterprisePlans(enterpriseId, queryParams)
      return records
    },
    ...options
  }) as QueryResult<T>
}

export function useEnterprisePlanServiceLatestInventory<
  T extends InventoryProps | undefined = ExtractFnReturnType<typeof fetchEnterprisePlanServiceLatestInventory>
>(
  enterpriseId: string | undefined,
  planId: string | undefined,
  queryParams: Parameters<typeof fetchEnterprisePlanServiceLatestInventory>[2] = {},
  options?: QueryConfig<typeof fetchEnterprisePlanServiceLatestInventory>
): QueryResult<T> {
  return useQuery({
    queryKey: ['enterprises', enterpriseId, 'plans', planId, 'services/inventory', queryParams],
    queryFn: async () => {
      if (!enterpriseId || !planId) {
        throw Error('Missing enterprise id or plan id')
      }

      return fetchEnterprisePlanServiceLatestInventory(enterpriseId, planId, queryParams)
    },
    ...options
  }) as QueryResult<T>
}

export const useEnterpriseBookings = <T = Awaited<ReturnType<typeof fetchEnterpriseBookings>>>(
  enterpriseId: string,
  queryParams: Parameters<typeof fetchEnterpriseBookings>[1],
  options?: QueryConfig<typeof fetchEnterpriseBookings, T>
) =>
  useQuery({
    queryKey: [endpoints.GET.EnterpriseBookings.route(enterpriseId), queryParams],
    queryFn: async () => {
      if (!enterpriseId) {
        throw Error('Cannot fetch enterprise bookings without an enterpriseId parameter')
      }

      return fetchEnterpriseBookings(enterpriseId, queryParams)
    },
    ...options
  })

export const useEnterpriseServiceBookingsCount = (
  enterpriseId: string,
  serviceId: string | undefined,
  queryParams: Partial<DetailedBooking> = {},
  options?: QueryConfig<typeof getEnterpriseServiceBookingsCount>
) =>
  useQuery({
    queryKey: [endpoints.GET.EnterpriseServiceBookingsCount.route(enterpriseId, serviceId || ''), queryParams],
    queryFn: async () => {
      if (!serviceId) {
        throw Error('Missing service id to get service bookings count')
      }

      return getEnterpriseServiceBookingsCount(enterpriseId, serviceId, queryParams)
    },
    ...options
  })

export function useEnterpriseWalletsGroups<Res>(
  enterpriseId: string,
  queryParams: Parameters<typeof fetchEnterpriseGroupsWallets>[1] = {},
  options: QueryConfig<typeof fetchEnterpriseGroupsWallets, Res> = {}
) {
  return useQuery({
    queryKey: [enterpriseId, 'groups-wallets', queryParams],
    queryFn: async () => {
      if (!enterpriseId) {
        throw Error('Cannot fetch enterprise wallets groups without an enterpriseId')
      }

      return fetchEnterpriseGroupsWallets(enterpriseId, queryParams)
    },
    ...options
  })
}

export function useEnterpriseWallets<Res>(
  enterpriseId: string | undefined,
  queryParams: Parameters<typeof fetchEnterpriseWallets>[1] = {},
  options?: QueryConfig<typeof fetchEnterpriseWallets, Res>
) {
  return useQuery({
    queryKey: ['enterprises', enterpriseId, 'wallets', queryParams],
    queryFn: async () => {
      if (!enterpriseId) {
        throw Error('Missing enterprise id to fetch wallets of a specific enterprise')
      }

      return fetchEnterpriseWallets(enterpriseId, queryParams)
    },
    enabled: Boolean(enterpriseId),
    ...options
  })
}

export function useEnterpriseBalances(
  enterpriseId: string | undefined,
  // To make sure order of params don't change in the future but there is no params supported yet
  queryParams: Record<string, never> = {},
  options?: QueryConfig<typeof fetchEnterpriseBalances>
) {
  return useQuery({
    queryKey: ['enterprises', enterpriseId, 'balances', queryParams],
    queryFn: async () => {
      if (!enterpriseId) {
        throw Error('Missing enterprise id')
      }

      return fetchEnterpriseBalances(enterpriseId, queryParams)
    },
    ...options
  })
}

export function useAdminDDOTSubmissions<T>(
  orgId: string,
  queryParams: Parameters<typeof fetchAdminDDOTSubmissions>[1] = {},
  options?: QueryConfig<typeof fetchAdminDDOTSubmissions, T>
) {
  return useQuery({
    queryKey: ['organizations', orgId, 'ddot-submissions', queryParams],
    queryFn: async () => fetchAdminDDOTSubmissions(orgId, queryParams),
    ...options
  })
}

export const useAdminDDOTSubmission = (
  organizationId: string,
  submissionId: string,
  queryParams: Parameters<typeof fetchAdminDDOTSubmission>[2] = {},
  options?: QueryConfig<typeof fetchAdminDDOTSubmission>
) =>
  useQuery({
    queryKey: ['organizations', organizationId, 'ddot-submissions', submissionId, queryParams],
    queryFn: async () => fetchAdminDDOTSubmission(organizationId, submissionId, queryParams),
    ...options
  })

export const usePatchSubmissionAdmin = (
  orgId: string,
  options?: MutationConfig<typeof patchSubmissionAdmin, Required<Partial<Submission>, 'id'>>
) => useMutation({ mutationFn: ({ id, ...body }) => patchSubmissionAdmin(orgId, id, body), ...options })

export const usePatchEnterprise = (
  enterpriseId: string,
  options?: MutationConfig<typeof patchEnterprise, Parameters<typeof patchEnterprise>[1]>
) => useMutation({ mutationFn: (body) => patchEnterprise(enterpriseId, body), ...options })

export const usePostManagerAccountEnterprise = (
  enterpriseRegistrationId: string,
  options?: MutationConfig<typeof postManagerAccountEnterprise>
) => useMutation({ mutationFn: (body) => postManagerAccountEnterprise(body, { enterpriseRegistrationId }), ...options })

export const usePostSubEnterprise = (
  organizationId: string,
  options?: MutationConfig<typeof postSubEnterprise, PostSubEnterpriseBody>
) => useMutation({ mutationFn: (body) => postSubEnterprise(organizationId, body), ...options })

export const usePostManagers = (
  organizationId: string,
  queryKey?: QueryKey,
  options?: MutationConfig<
    typeof postManagers,
    {
      body: Parameters<typeof postManagers>[1]
      queryParams: Parameters<typeof postManagers>[2]
    }
  >
) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ body, queryParams }) => postManagers(organizationId, body, queryParams),
    ...options,
    onSuccess: (...args) => {
      if (queryKey) {
        queryClient.refetchQueries({ queryKey })
      }
      if (options?.onSuccess) {
        options.onSuccess(...args)
      }
    }
  })
}

export const managersKeys = {
  list: (organizationId: string) => ['list', endpoints.GET.OrganizationManagers.route(organizationId)] as const
}

export const useDeleteManager = (
  enterpriseId: string,
  queryKey?: ReturnType<typeof managersKeys.list>,
  options?: MutationConfig<typeof deleteManager>
) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (managerId) => deleteManager(enterpriseId, managerId),
    ...options,
    onSuccess: (...args) => {
      if (queryKey) {
        queryClient.refetchQueries({ queryKey })
      }
      if (options?.onSuccess) {
        options.onSuccess(...args)
      }
    }
  })
}

export const useBatchDeleteManagers = (queryKey: ReturnType<typeof managersKeys.list>, organizationId: string) => {
  const queryClient = useQueryClient()
  const { mutateAsync: unassignManager } = useDeleteManager(organizationId)

  return useMutation({
    mutationFn: async (managerIds: string[]) => {
      const deletePromises = managerIds.map((managerId) => unassignManager(managerId))
      await Promise.all(deletePromises)
    },

    onSuccess: () => {
      queryClient.refetchQueries({ queryKey })
    }
  })
}

export const useCreateEnterprisePlanPermissions = (
  enterpriseId: string,
  options?: MutationConfig<typeof postEnterprisePlanPermissions, Parameters<typeof postEnterprisePlanPermissions>[1]>
) => useMutation({ mutationFn: (body) => postEnterprisePlanPermissions(enterpriseId, body), ...options })

// Enterprise notification templates
// ------------------------------------------------------------------------------------------
export function useEnterpriseNotificationTemplate<
  Res = ExtractFnReturnType<typeof fetchEnterpriseNotificationTemplate>
>(
  enterpriseId: string | undefined,
  queryParams?: Parameters<typeof fetchEnterpriseNotificationTemplate>[1],
  options?: QueryConfig<typeof fetchEnterpriseNotificationTemplate, Res>
) {
  return useQuery({
    queryKey: [endpoints.GET.EnterpriseNotificationTemplates.route(enterpriseId || ''), queryParams],
    queryFn: async () => {
      if (!enterpriseId) {
        throw Error(
          'Missing enterprise id, disabling GET enterprise notifications details details (/enterprises/:enterpriseId/notifications/templates)'
        )
      }

      return fetchEnterpriseNotificationTemplate(enterpriseId, queryParams)
    },
    ...options
  })
}

export function useEnterpriseManagerInviteTemplate(
  enterpriseId: string | undefined,
  queryParams?: Parameters<typeof useEnterpriseNotificationTemplate>[1],
  options: QueryConfig<typeof fetchEnterpriseNotificationTemplate, EnterpriseNotificationTemplatesProps> = {}
) {
  return useEnterpriseNotificationTemplate(
    enterpriseId,
    { type: EnterpriseNotificationTemplateType.MANAGER_INVITE, limit: 1, ...queryParams },
    {
      select: ({ records }) => records[0],
      ...options
    }
  )
}
export const usePutNotificationTemplate = (
  options?: MutationConfig<
    typeof putNotificationTemplate,
    { enterpriseId: string; body: Parameters<typeof putNotificationTemplate>[1] }
  >
) =>
  useMutation({
    mutationFn: ({ enterpriseId, body }) => putNotificationTemplate(enterpriseId, body),
    ...options
  })

export const useEnterpriseMerchants = <Res = Awaited<ReturnType<typeof getEnterpriseMerchants>>>(
  enterpriseId: string,
  queryParams: Parameters<typeof getEnterpriseMerchants>[1] = {},
  options?: QueryConfig<typeof getEnterpriseMerchants, Res>
) =>
  useQuery({
    queryKey: ['enterprises', enterpriseId, 'merchants', queryParams],
    queryFn: async () => getEnterpriseMerchants(enterpriseId, queryParams),
    ...options
  })

export const useSingleEnterpriseMerchant = (
  enterpriseId: string,
  queryParams: Parameters<typeof getEnterpriseMerchants>[1] = {},
  options?: QueryConfig<typeof getEnterpriseMerchants, any>
) =>
  useEnterpriseMerchants(enterpriseId, queryParams, {
    ...options,
    select: (data) => {
      const firstMerchant = data.records[0]

      // Log if no merchant is found
      if (!firstMerchant) {
        logger.error(`No merchant found for enterpriseId: ${enterpriseId}`)
      }

      return firstMerchant
    }
  })

export const useShippingRates = (enterpriseId: string | undefined) =>
  useQuery({
    queryKey: [endpoints.GET.ShippingRates.route(enterpriseId || '')],
    queryFn: async () => {
      if (!enterpriseId) {
        throw Error('Cannot fetch virtual the shipping rates without an enterprise id')
      }
      return fetchEnterpriseShippingRates(enterpriseId)
    }
  })

export const usePostEnterpriseOffice = (enterpriseId: string, options?: MutationConfig<typeof postOffice>) =>
  useMutation({ mutationFn: (body) => postOffice(body, enterpriseId), ...options })

export const useImportEnterpriseOffices = (
  enterpriseId: string,
  options?: MutationConfig<typeof importEnterpriseOffices, FormData>
) => {
  if (!enterpriseId) {
    throw new Error('Enterprise id needs to be defined to import enterprise offices')
  }
  return useMutation({ mutationFn: (file) => importEnterpriseOffices(enterpriseId, file), ...options })
}

type EnterpriseOfficesParams = RequestParams & { countEmployees?: boolean }
export const useFetchEnterpriseOffices = (
  enterpriseId: string,
  queryParams: EnterpriseOfficesParams,
  options?: QueryConfig<typeof fetchEnterpriseOffices, GetResponse<Required<OfficeWithBuilding, 'id'>>> & {
    baseQueryKey: QueryKey
    defaultQuery: EnterpriseOfficesParams
  }
) => {
  const baseQueryKey = React.useMemo(() => ['enterprises', enterpriseId, 'offices'], [enterpriseId])
  const defaultQuery = React.useMemo(
    () => ({
      relations: ['building'],
      fields: [
        '*',
        'building.address',
        'building.city',
        'building.country',
        'building.state',
        'building.street',
        'building.streetNumber'
      ],
      sort: '-createdAt',
      countEmployees: true
    }),
    []
  )
  const paginationQueryRes = useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps -- Enterprise id is set on the baseQueryKey
    queryKey: [...baseQueryKey, queryParams],
    queryFn: async () =>
      fetchEnterpriseOffices(enterpriseId, {
        ...defaultQuery,
        ...queryParams
      }),

    ...options,
    placeholderData: keepPreviousData
  })

  const res = { ...paginationQueryRes, baseQueryKey, defaultQuery }
  return res
}

export const usePostEnterprisePaymentMethod = (
  enterpriseId: string | undefined,
  options?: MutationConfig<typeof postEnterprisePaymentMethods, PostPaymentMethodBody>
) =>
  useMutation({
    mutationFn: (body) => {
      if (!enterpriseId) {
        throw new Error('Missing enterprise id')
      }
      return postEnterprisePaymentMethods(enterpriseId, body)
    },
    ...options
  })

export const useSetEnterprisePaymentMethodToDefault = (
  options?: MutationConfig<
    typeof setEnterprisePaymentMethodToDefault,
    { enterpriseId: string | undefined; paymentMethodId: string | undefined }
  >
) =>
  useMutation({
    mutationFn: ({ enterpriseId, paymentMethodId }) => {
      if (!enterpriseId || !paymentMethodId) {
        throw new Error('Missing enterprise id or paymentMethod id')
      }
      return setEnterprisePaymentMethodToDefault(enterpriseId, paymentMethodId)
    },
    ...options
  })

export const useEnterprisePaymentMethods = (
  enterpriseId: string | undefined,
  queryParams: RequestParams & { isDefault?: boolean },
  options?: QueryConfigPickRes<typeof fetchEnterprisePaymentMethods, 'records'>
) =>
  useQuery({
    queryKey: ['paymentMethods', enterpriseId, queryParams],
    queryFn: async () => {
      if (!enterpriseId) {
        throw new Error('Missing enterprise id')
      }

      const { records } = await fetchEnterprisePaymentMethods(enterpriseId, { sort: '-isDefault', ...queryParams })
      return records
    },
    ...options
  })

export const useFundingInstructions = <Res = Awaited<ReturnType<typeof getFundingInstructions>>>(
  enterpriseId: string,
  queryParams: Parameters<typeof getFundingInstructions>[1] = {},
  options?: QueryConfig<typeof getFundingInstructions, Res>
) =>
  useQuery({
    queryKey: ['enterprises', enterpriseId, 'funding-instructions', queryParams],
    queryFn: async () => getFundingInstructions(enterpriseId, queryParams),
    ...options
  })

type UseEnterprisePaymentMethodsParams = Parameters<typeof useEnterprisePaymentMethods>
export const useEnterpriseDefaultPaymentMethod = (enterpriseId: UseEnterprisePaymentMethodsParams[0]) => {
  const { data, ...rest } = useEnterprisePaymentMethods(enterpriseId, { isDefault: true })
  return {
    data: data?.[0],
    ...rest
  }
}
