import { CustomFontSource } from '@stripe/stripe-js'
import SegmaBold from 'static/fonts/Segma/Segma-Bold.otf'
import SegmaMedium from 'static/fonts/Segma/Segma-Medium.otf'
import SegmaRegular from 'static/fonts/Segma/Segma-Regular.otf'
import { colors } from './global'

const fontStyles: CustomFontSource[] = [
  {
    src: `url(${SegmaRegular}) format('opentype')`,
    family: 'Segma',
    style: 'normal',
    weight: '400'
  },
  {
    src: `url(${SegmaMedium}) format('opentype')`,
    family: 'Segma',
    style: 'normal',
    weight: '500'
  },
  {
    src: `url(${SegmaBold}) format('opentype')`,
    family: 'Segma',
    style: 'normal',
    weight: '700'
  }
]

export const inputFontStyles = {
  fontSize: '14px',
  fontFamily: 'Segma',
  color: colors.neutral600
}

export default fontStyles
