import { Overwrite } from 'utility-types'
import faker from 'faker/locale/en'
import type { EnterpriseGroupPlanProps } from '@commutifi/models/EnterpriseGroupPlan'

export type EnterpriseGroupPlan = Overwrite<EnterpriseGroupPlanProps, { createdAt: string; updatedAt: string }>

export const fakeEnterpriseGroupPlan = (base: Partial<EnterpriseGroupPlanProps>): EnterpriseGroupPlan => ({
  enterpriseGroupId: base.enterpriseGroupId || faker.datatype.uuid(),
  planId: base.planId || faker.datatype.uuid(),
  createdAt: faker.date.past().toISOString(),
  updatedAt: faker.date.past().toISOString()
})

export const fakeSimulateGroupAccount = (
  base: Partial<{ id: string; name: string }> = {}
): { id: string; name: string } => ({
  id: base.id || faker.datatype.uuid(),
  name: base.name || faker.name.findName()
})

export const fakeSimulateGroupAccounts = (count: number): ReturnType<typeof fakeSimulateGroupAccount>[] =>
  Array.from({ length: count }, () => fakeSimulateGroupAccount())
