import { HttpMethod } from '../../constants'

export const routeEndpoints = {
  [HttpMethod.Get]: {
    AccountRoutes: {
      path: '/accounts/:uuid/routes',
      route(uuid: string): string {
        return this.path.replace(':uuid', uuid)
      }
    },
    SuggestionRoutes: {
      path: '/routes/:uuid/suggestions',
      route(uuid: string): string {
        return this.path.replace(':uuid', uuid)
      }
    },
    Provider: {
      path: '/providers/:id',
      route(id: string): string {
        return this.path.replace(':id', id)
      }
    },
    PlansSubsidies: {
      path: '/_/accounts/:accountId/subsidies',
      route(accountId: string): string {
        return this.path.replace(':accountId', accountId)
      }
    }
  }
}

export default routeEndpoints
