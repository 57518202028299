// Give error in Jest but can't figure out why.
import { GENERIC_ACCOUNT_CREATION_ERROR_CODE, INVALID_EMAIL_ERROR_CODE } from '@commutifi/constants/Errors'
import { NormalizerResultForFeatureFlags, NormalizerResultForOrganizations } from 'store/schemas'

export enum EnterpriseErrorCodes {
  Base = INVALID_EMAIL_ERROR_CODE,
  FeatureFlags = GENERIC_ACCOUNT_CREATION_ERROR_CODE
}

export const FETCH_ENTERPRISE_REQUEST = 'enterprise/FETCH_ENTERPRISE_REQUEST'
export const FETCH_ENTERPRISE_SUCCESS = 'enterprise/FETCH_ENTERPRISE_SUCCESS'
export const FETCH_ENTERPRISE_FAILURE = 'enterprise/FETCH_ENTERPRISE_FAILURE'

export const CREATE_ENTERPRISE_REQUEST = 'enterprise/CREATE_ENTERPRISE_REQUEST'
export const CREATE_ENTERPRISE_SUCCESS = 'enterprise/CREATE_ENTERPRISE_SUCCESS'
export const CREATE_ENTERPRISE_FAILURE = 'enterprise/CREATE_ENTERPRISE_FAILURE'

export const UPDATE_ENTERPRISE_REQUEST = 'enterprise/UPDATE_ENTERPRISE_REQUEST'
export const UPDATE_ENTERPRISE_SUCCESS = 'enterprise/UPDATE_ENTERPRISE_SUCCESS'
export const UPDATE_ENTERPRISE_FAILURE = 'enterprise/UPDATE_ENTERPRISE_FAILURE'

export const FETCH_FEATURE_FLAGS_TRIGGER = 'enterprise/FETCH_FEATURE_FLAGS_TRIGGER'
export const FETCH_FEATURE_FLAGS_REQUEST = 'enterprise/FETCH_FEATURE_FLAGS_REQUEST'
export const FETCH_FEATURE_FLAGS_SUCCESS = 'enterprise/FETCH_FEATURE_FLAGS_SUCCESS'
export const FETCH_FEATURE_FLAGS_FAILURE = 'enterprise/FETCH_FEATURE_FLAGS_FAILURE'

export const FETCH_ORGANIZATION_TRIGGER = 'enterprise/FETCH_ORGANIZATION_TRIGGER'
export const FETCH_ORGANIZATION_REQUEST = 'enterprise/FETCH_ORGANIZATION_REQUEST'
export const FETCH_ORGANIZATION_SUCCESS = 'enterprise/FETCH_ORGANIZATION_SUCCESS'
export const FETCH_ORGANIZATION_FAILURE = 'enterprise/FETCH_ORGANIZATION_FAILURE'

interface IFeatureFlagRequest {
  type: typeof FETCH_FEATURE_FLAGS_REQUEST
  payload: Record<string, any>
}

interface IFeatureFlagSuccess {
  type: typeof FETCH_FEATURE_FLAGS_SUCCESS
  response: NormalizerResultForFeatureFlags
}

interface IFeatureFlagsFailure {
  type: typeof FETCH_FEATURE_FLAGS_FAILURE
  error: any
}
export type FeatureFlagsTypes = IFeatureFlagRequest | IFeatureFlagSuccess | IFeatureFlagsFailure

interface IFetchOrganizationRequest {
  type: typeof FETCH_ORGANIZATION_REQUEST
  payload: Record<string, any>
}

interface IFetchOrganizationSuccess {
  type: typeof FETCH_ORGANIZATION_SUCCESS
  response: NormalizerResultForOrganizations
}

interface IFetchOrganizationsFailure {
  type: typeof FETCH_ORGANIZATION_FAILURE
  error: any
}
export type FetchOrganizationTypes = IFetchOrganizationRequest | IFetchOrganizationSuccess | IFetchOrganizationsFailure

export type OrganizationActionTypes = FeatureFlagsTypes | FetchOrganizationTypes
