import types from './types'

export const fetchPlanSubsidiesRequest = (planId) => ({
  type: types.FETCH_PLAN_SUBSIDIES_REQUEST,
  planId
})

export const fetchPlanSubsidiesSuccess = (planIds = [], payload) => ({
  type: types.FETCH_PLAN_SUBSIDIES_SUCCESS,
  planIds,
  payload
})

export const fetchPlanSubsidiesFailure = (planIds = [], error) => ({
  type: types.FETCH_PLAN_SUBSIDIES_FAILURE,
  planIds,
  error
})
