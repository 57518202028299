/* eslint-disable @typescript-eslint/default-param-last -- Legacy */
import { combineReducers } from 'redux'
import { FeatureFlagResponse } from '@commutifi/models/FeatureFlag'
import { IOrganization } from 'shared/interfaces'
import {
  EnterpriseErrorCodes,
  FETCH_FEATURE_FLAGS_FAILURE,
  FETCH_FEATURE_FLAGS_REQUEST,
  FETCH_FEATURE_FLAGS_SUCCESS,
  FETCH_ORGANIZATION_FAILURE,
  FETCH_ORGANIZATION_REQUEST,
  FETCH_ORGANIZATION_SUCCESS,
  OrganizationActionTypes
} from './types'

const byId = (state: Record<string, IOrganization | undefined> = {}, action: OrganizationActionTypes) => {
  switch (action.type) {
    case FETCH_ORGANIZATION_SUCCESS:
      return {
        ...state,
        ...(action.response.entities.enterprises || action.response.entities.organizations)
      }
    default:
      return state
  }
}

const currentId = (state = null, action: OrganizationActionTypes) => {
  switch (action.type) {
    case FETCH_ORGANIZATION_SUCCESS:
      return action.response.result.enterprise || action.response.result.organization || state
    default:
      return state
  }
}

const featureFlagsByEnterpriseId = (
  state: Record<string, FeatureFlagResponse | undefined> = {},
  action: OrganizationActionTypes
) => {
  switch (action.type) {
    case FETCH_FEATURE_FLAGS_SUCCESS:
      return {
        ...state,
        ...action.response.entities.featureFlags
      }
    default:
      return state
  }
}

const isFetching = (state = false, action: OrganizationActionTypes) => {
  switch (action.type) {
    case FETCH_ORGANIZATION_REQUEST:
      return true
    case FETCH_ORGANIZATION_SUCCESS:
    case FETCH_ORGANIZATION_FAILURE:
      return false
    default:
      return state
  }
}

const isFetchingFlags = (state = false, action: OrganizationActionTypes) => {
  switch (action.type) {
    case FETCH_FEATURE_FLAGS_REQUEST:
      return true
    case FETCH_FEATURE_FLAGS_SUCCESS:
    case FETCH_FEATURE_FLAGS_FAILURE:
      return false
    default:
      return state
  }
}

const error = (state: number[] = [], action: OrganizationActionTypes) => {
  switch (action.type) {
    case FETCH_FEATURE_FLAGS_SUCCESS:
      return state.filter((errCode) => errCode !== EnterpriseErrorCodes.FeatureFlags)
    case FETCH_ORGANIZATION_SUCCESS:
      return state.filter((errCode) => errCode !== EnterpriseErrorCodes.Base)
    case FETCH_ORGANIZATION_FAILURE:
      state.push(EnterpriseErrorCodes.Base)
      return state
    case FETCH_FEATURE_FLAGS_FAILURE:
      state.push(EnterpriseErrorCodes.FeatureFlags)
      return state
    default:
      return state
  }
}

const enterpriseReducer = combineReducers({
  byId,
  currentId,
  featureFlagsByEnterpriseId,
  isFetching,
  isFetchingFlags,
  error
})
export type EnterpriseState = ReturnType<typeof enterpriseReducer>
export default enterpriseReducer
