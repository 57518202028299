import types from './types'

export const fetchMetricsRequest = (id, queryParams) => ({
  type: types.FETCH_METRICS_REQUEST,
  id,
  queryParams
})

export const fetchMetricsSuccess = (payload) => ({
  type: types.FETCH_METRICS_SUCCESS,
  payload
})

export const fetchMetricsFailure = (error) => ({
  type: types.FETCH_METRICS_FAILURE,
  error
})
