import faker from 'faker/locale/en'
import { ModeCategory, ModeName, Stop } from '@commutifi/modes'
import { LEG_TYPES } from '@commutifi-fe/constants'
import { DaysValueEnum } from 'shared/interfaces'
import { getFakeAccount } from '../accounts/data'
import { fakeAccountTimezone } from '../../config/constants'
import { fakeAccountVehicles } from '../vehicles/data'
import { GetResponse } from '@commutifi-fe/interfaces'
import { CommuteProfileProcessed } from 'api/modules/commuteProfiles'
import { DEFAULT_OFFICE_ARRIVAL_TIME_HHMM, DEFAULT_OFFICE_LEAVING_TIME_HHMM } from 'constants/global'

/**
 * USED for Commute profile settings and more
 */
const fakeAccount = getFakeAccount()
export const fakeDriveTransitCommuteProfile: CommuteProfileProcessed = {
  id: '49b51d65-595b-464d-a6ea-0d9b4be270da',
  isReturn: false,
  createdAt: '2020-09-11T14:06:34.235Z',
  updatedAt: '2020-10-19T19:59:56.175Z',
  accountId: fakeAccount.id,
  vehicleId: 'cb87fa75-eb39-4fc6-942c-0f85fde6f146',
  timezone: fakeAccountTimezone,
  weeklyFrequency: 4,
  days: [DaysValueEnum.Friday],
  leaveOfficeTime: '1800',
  arriveOfficeTime: '830',
  startAddress: fakeAccount.address ?? undefined,
  endAddress: fakeAccount.office?.building?.address,
  activities: null,
  legs: [],
  goingLegs: [
    {
      id: 'ef1e596a-4ef2-4758-8750-fd3706183cbc',
      createdAt: '2022-01-17T20:47:56.957Z',
      updatedAt: '2022-01-17T20:47:56.957Z',
      externalPlanId: null,
      statisticalPlanId: null,
      order: 3,
      removeFromGroupId: false,
      routeId: '654f3114-ae0c-44b6-9f42-e4a5c4d22760',
      serviceId: null,
      metricsId: 'fa55ac7e-cddc-4d16-98ae-a95c0b93525e',
      planId: null,
      accountVehicleId: null,
      startAddress: '',
      endAddress: fakeAccount.office?.building?.address || '',
      activity: undefined,
      peopleCount: 1,
      name: null,
      displayColor: undefined,
      departureTime: '2022-01-26T15:49:00.000Z',
      arrivalTime: '2022-01-26T15:57:45.000Z',
      timezone: fakeAccountTimezone,
      transferTime: 0,
      type: LEG_TYPES.leg,
      polyline: 'cwfsF`vpaSBVFf@L`ABPNpAT~AHt@@HBPBPLfA^pCK@s@R[F[HODe@LDPBVMBw@Tc@JMBk@Nq@NyA^NhANjA',
      mode: ModeName.Walk,
      startLocation: {
        lat: 40.0166636,
        lng: -105.2760088
      },
      endLocation: fakeAccount.office?.building?.location || {
        lat: parseFloat(faker.address.latitude()),
        lng: parseFloat(faker.address.longitude())
      },
      metrics: {
        id: 'fa55ac7e-cddc-4d16-98ae-a95c0b93525e',
        createdAt: '2022-01-17T20:47:56.487Z',
        updatedAt: '2022-01-17T20:47:56.487Z',
        carbon: 0,
        emissionRate: 0,
        duration: 525,
        cost: 0,
        distance: 651
      }
    },
    {
      id: 'c201ffeb-73c6-4e47-8d0c-f7dc5d72a04e',
      createdAt: '2022-01-17T20:47:56.957Z',
      updatedAt: '2022-01-17T20:47:56.957Z',
      externalPlanId: null,
      statisticalPlanId: null,
      order: 2,
      removeFromGroupId: false,
      routeId: '654f3114-ae0c-44b6-9f42-e4a5c4d22760',
      serviceId: null,
      metricsId: '9b2159dd-d64b-4d64-9957-bff89885539b',
      planId: null,
      accountVehicleId: null,
      startAddress: '',
      endAddress: '',
      activity: undefined,
      peopleCount: 1,
      name: '205',
      displayColor: '#0076ce',
      departureTime: '2022-01-26T15:35:45.000Z',
      arrivalTime: '2022-01-26T15:49:00.000Z',
      timezone: fakeAccountTimezone,
      transferTime: 0,
      type: LEG_TYPES.leg,
      polyline:
        '_nlsFnimaS?Kt@?fG@jCA~@?D?D?V?R?N?^?B?\\?J?b@APAr@?@?`AA\\AhC?@?v@?V?bA?\\@X?`@?R?r@?bAAp@?h@?\\@|@@dACT?vC?n@?X?n@?jB?|A?RAT?hA?jAClA?jBA~A?x@?\\?bB?LM~@?`A?r@AP?N?~@?R?BDBB@@H?B?@@P@Z?B?d@?P?T?F?bA?P?nA?h@AF?T?l@?XOj@?h@?JAX?`B?f@AX?D?T?DF@@@@@?@@B?@?b@?@?R?zA?bBAhB?jB@B?t@?`@?J@v@AH@fA?dAAX@hA?X?@?l@?f@Aj@@B?l@?z@@hA?~@?dA?t@?R?h@A\\?tA?r@?f@AD?\\?JLFFBBBDDFBFHRCzD?`AApA?dG?hABhC@\\?J@RBh@B^@X@T@R@h@@VBxA@jDA|@?rA@zE?jA?\\@|E?J?fD?h@?hA?tC@tAAvB?D@l@?@Bb@BPBNVrBBPTjB\\xCTjBTjBBLE@',
      mode: ModeName.Bus,
      startLocation: {
        lat: 40.045922,
        lng: -105.25864
      },
      endLocation: {
        lat: 40.016653,
        lng: -105.276005
      },
      metrics: {
        id: '9b2159dd-d64b-4d64-9957-bff89885539b',
        createdAt: '2022-01-17T20:47:56.488Z',
        updatedAt: '2022-01-17T20:47:57.858Z',
        carbon: 0.842288,
        emissionRate: 0.1804,
        duration: 795,
        cost: 0,
        distance: 4669
      }
    },
    {
      id: '1aac43e7-d282-4de4-a383-97c90c655149',
      createdAt: '2022-01-17T20:47:56.957Z',
      updatedAt: '2022-01-17T20:47:56.957Z',
      externalPlanId: null,
      statisticalPlanId: null,
      order: 1,
      removeFromGroupId: false,
      routeId: '654f3114-ae0c-44b6-9f42-e4a5c4d22760',
      serviceId: null,
      metricsId: '3163926c-2282-4cb7-86fe-1c09573850f6',
      planId: null,
      accountVehicleId: null,
      startAddress: '',
      endAddress: '',
      activity: undefined,
      peopleCount: 1,
      name: null,
      displayColor: undefined,
      departureTime: '2022-01-26T15:30:56.000Z',
      arrivalTime: '2022-01-26T15:35:45.000Z',
      timezone: fakeAccountTimezone,
      transferTime: 0,
      type: LEG_TYPES.leg,
      polyline: 'u|lsF~dmaSb@?P?V@\\?d@?LEHR?`A?`@`@ATGFALCVCJCHINIRQLMB@@@?@@B@F?J?F?PALAPADAPABl@?HABAT?X?@?N?',
      mode: ModeName.Walk,
      startLocation: {
        lat: 40.0482686,
        lng: -105.2579213
      },
      endLocation: {
        lat: 40.0459225,
        lng: -105.2586798
      },
      metrics: {
        id: '3163926c-2282-4cb7-86fe-1c09573850f6',
        createdAt: '2022-01-17T20:47:56.488Z',
        updatedAt: '2022-01-17T20:47:56.488Z',
        carbon: 0,
        emissionRate: 0,
        duration: 289,
        cost: 0,
        distance: 374
      }
    },
    {
      id: '851eabdb-8986-4377-b9ac-8e2233fc80dc',
      createdAt: '2022-01-17T20:47:56.957Z',
      updatedAt: '2022-01-17T20:47:56.957Z',
      externalPlanId: null,
      statisticalPlanId: null,
      order: 0,
      removeFromGroupId: false,
      routeId: '654f3114-ae0c-44b6-9f42-e4a5c4d22760',
      serviceId: null,
      metricsId: '0013893e-647e-4740-a333-3fd605724415',
      planId: null,
      accountVehicleId: fakeAccountVehicles[0].id,
      startAddress: fakeAccount.address || '',
      endAddress: '4040 28th St, Boulder, CO 80301, USA',
      activity: undefined,
      peopleCount: 1,
      name: null,
      displayColor: undefined,
      departureTime: '2022-01-26T15:23:50.000Z',
      arrivalTime: '2022-01-26T15:30:56.000Z',
      timezone: fakeAccountTimezone,
      transferTime: 0,
      type: LEG_TYPES.leg,
      polyline:
        'supsFlisaSfBm@tBm@nCK?sAn@CX?fFEfDEt@Nf@ZJDZ_A@MVs@|@cClAeDRk@BYDsA?_ACW?e@?a@@y@@uDA{AKuEEcFCiN?eEAgAFIDQNaARq@Vs@vBeElAgCfC}ErDyG~DoH\\q@La@|@gBzDkHvB{DxCiFpG_MdBmDrBiDtAmBtBaCp@u@lAeAl@a@nC_BdAg@lBs@fCq@xB]xAK?w@A}@KYgAAmAA',
      mode: ModeName.Drive,
      startLocation: fakeAccount.location,
      endLocation: {
        lat: 40.0482679,
        lng: -105.2582692
      },
      metrics: {
        id: '0013893e-647e-4740-a333-3fd605724415',
        createdAt: '2022-01-17T20:47:56.487Z',
        updatedAt: '2022-01-17T20:47:57.858Z',
        carbon: 0.882504,
        emissionRate: 0.218225,
        duration: 426,
        cost: 1.37496,
        distance: 4044
      }
    }
  ],
  returnLegs: [
    {
      id: '42de664f-c003-44da-bc42-df5df2cdadf7',
      createdAt: '2022-01-17T20:47:56.957Z',
      updatedAt: '2022-01-17T20:47:56.957Z',
      externalPlanId: null,
      statisticalPlanId: null,
      order: 3,
      removeFromGroupId: false,
      routeId: '842b86b5-f05f-41b0-bcc8-7aab902bf0ca',
      serviceId: null,
      metricsId: '03ec4e32-68f2-4ee8-9f7e-a7f97e680169',
      planId: null,
      accountVehicleId: '839f785c-d358-41db-8e4d-a27886f1b989',
      startAddress: '4040 28th St, Boulder, CO 80301, USA',
      endAddress: '430 Pierre St, Boulder, CO 80304, USA',
      activity: undefined,
      peopleCount: 1,
      name: null,
      displayColor: undefined,
      departureTime: '2022-01-27T00:51:55.000Z',
      arrivalTime: '2022-01-27T00:57:09.000Z',
      timezone: fakeAccountTimezone,
      transferTime: 0,
      type: 'leg',
      polyline:
        'k|lsFvimaSmAPeATmCx@mBx@cDtB{@n@cA`AYXyAdB_BbCcB|CcHlMcDjGkB~DaHnMu@xAUV_@j@e@`AoA|Bw@zA_JvPcHnMeHzMkB`DgArAgAjA_@^k@f@Vj@v@z@PNYf@QHKBa@?SFOHSVI\\CnA?dC@hAGj@g@v@gDxEM^Eh@?~HJvB^jCuBl@gBl@',
      mode: ModeName.Drive,
      startLocation: {
        lat: 40.0482679,
        lng: -105.2582692
      },
      endLocation: {
        lat: 40.0675951,
        lng: -105.2894477
      },
      metrics: {
        id: '03ec4e32-68f2-4ee8-9f7e-a7f97e680169',
        createdAt: '2022-01-17T20:47:56.487Z',
        updatedAt: '2022-01-17T20:47:57.858Z',
        carbon: 0.797832,
        emissionRate: 0.218225,
        duration: 314,
        cost: 1.24304,
        distance: 3656
      }
    },
    {
      id: 'c6926ee1-e828-4871-a73c-eaee94783d41',
      createdAt: '2022-01-17T20:47:56.957Z',
      updatedAt: '2022-01-17T20:47:56.957Z',
      externalPlanId: null,
      statisticalPlanId: null,
      order: 2,
      removeFromGroupId: false,
      routeId: '842b86b5-f05f-41b0-bcc8-7aab902bf0ca',
      serviceId: null,
      metricsId: '34979c4f-50d7-4509-aff2-33067c8653d3',
      planId: null,
      accountVehicleId: null,
      startAddress: '',
      endAddress: '',
      activity: undefined,
      peopleCount: 1,
      name: null,
      displayColor: undefined,
      departureTime: '2022-01-27T00:49:53.000Z',
      arrivalTime: '2022-01-27T00:51:55.000Z',
      timezone: fakeAccountTimezone,
      transferTime: 0,
      type: 'leg',
      polyline: 'qvlsFrhmaS@?DAa@@M@?c@?{@ISe@?]?WAQ?c@?',
      mode: ModeName.Walk,
      startLocation: {
        lat: 40.0472856,
        lng: -105.2585003
      },
      endLocation: {
        lat: 40.0482686,
        lng: -105.2579213
      },
      metrics: {
        id: '34979c4f-50d7-4509-aff2-33067c8653d3',
        createdAt: '2022-01-17T20:47:56.488Z',
        updatedAt: '2022-01-17T20:47:56.488Z',
        carbon: 0,
        emissionRate: 0,
        duration: 122,
        cost: 0,
        distance: 161
      }
    },
    {
      id: 'b047ad0a-562c-4841-a4d0-cabd01d9b8ce',
      createdAt: '2022-01-17T20:47:56.957Z',
      updatedAt: '2022-01-17T20:47:56.957Z',
      externalPlanId: null,
      statisticalPlanId: null,
      order: 1,
      removeFromGroupId: false,
      routeId: '842b86b5-f05f-41b0-bcc8-7aab902bf0ca',
      serviceId: null,
      metricsId: '318bef77-e1fe-4efb-b2b3-8686e3537601',
      planId: null,
      accountVehicleId: null,
      startAddress: '',
      endAddress: '',
      activity: undefined,
      peopleCount: 1,
      name: '205',
      displayColor: '#0076ce',
      departureTime: '2022-01-27T00:37:00.000Z',
      arrivalTime: '2022-01-27T00:49:53.000Z',
      timezone: 'America/Denver',
      transferTime: 0,
      type: 'leg',
      polyline:
        'qyfsF|zpaSBPkAVGc@OqASaBGq@J?l@MZIXGn@OVIRES}AK{@AOS{AIw@Kw@K{@COIw@CMEc@AQ?YAS?I?a@?iA?aD?kA?wAAm@?gK?}E?_BA_@?[?cC?{BAoAEeBAUAe@E{@C_@Ce@?M?SA]A_B?cB?a@?k@?w@?gAB_BBkB@_B?Y@w@?S?e@U?O?k@?a@@uAB_A@E?S?M?M?e@?u@?kB?w@?WAg@?s@?sDA}@?y@?Y?w@?uB?Q?u@?a@?G?A?S@w@?s@?mB@{A@q@?}@?_@?A?A?A?A@C?A@A@GDU?E?Y?g@@aB?Y?K@i@?k@?QKGCY?g@@cAA}@?SA{@?W?O@A?]?C@Y?{@@C@ABAFS?_A?O?Q?s@@aA?_A?MK[?i@?uB@}E?M?I?]@uA?oB@?W?I?H?VgAAG?g@AmA?w@?[?u@Aw@?c@@u@?m@@k@@w@?o@?u@?S?aCBk@?{@@_@@q@@{@BE?c@@U?W@aA@A?c@?O?E?cCA{@?mA?E?E?U@k@As@@_@?cA?eB?uC?aA@a@@o@?W?W?uABi@@C??M',
      mode: ModeName.Bus,
      startLocation: {
        lat: 40.017049,
        lng: -105.276789
      },
      endLocation: {
        lat: 40.04729,
        lng: -105.25848
      },
      metrics: {
        id: '318bef77-e1fe-4efb-b2b3-8686e3537601',
        createdAt: '2022-01-17T20:47:56.488Z',
        updatedAt: '2022-01-17T20:47:57.858Z',
        carbon: 0.924009,
        emissionRate: 0.1804,
        duration: 773,
        cost: 0,
        distance: 5122
      }
    },
    {
      id: 'c38e809d-8b6d-4a59-a427-555c8aedd1bc',
      createdAt: '2022-01-17T20:47:56.957Z',
      updatedAt: '2022-01-17T20:47:56.957Z',
      externalPlanId: null,
      statisticalPlanId: null,
      order: 0,
      removeFromGroupId: false,
      routeId: '842b86b5-f05f-41b0-bcc8-7aab902bf0ca',
      serviceId: null,
      metricsId: 'ce2591ec-646f-425e-8b99-87aaa45dc20d',
      planId: null,
      accountVehicleId: null,
      startAddress: '1111 Pearl St, Boulder, CO 80302, USA',
      endAddress: '',
      activity: undefined,
      peopleCount: 1,
      name: null,
      displayColor: undefined,
      departureTime: '2022-01-27T00:30:14.000Z',
      arrivalTime: '2022-01-27T00:37:00.000Z',
      timezone: 'America/Denver',
      transferTime: 0,
      type: 'leg',
      polyline: 'q`gsFjuqaSOkAOiAxA_@CSEUk@wEAQAQIg@AQM_A?A?EAIEQGa@Iu@CQ\\K`AULCFC\\Ip@QLCdAU',
      mode: ModeName.Walk,
      startLocation: {
        lat: 40.0181701,
        lng: -105.2810169
      },
      endLocation: {
        lat: 40.0170401,
        lng: -105.2768363
      },
      metrics: {
        id: 'ce2591ec-646f-425e-8b99-87aaa45dc20d',
        createdAt: '2022-01-17T20:47:56.487Z',
        updatedAt: '2022-01-17T20:47:56.487Z',
        carbon: 0,
        emissionRate: 0,
        duration: 406,
        cost: 0,
        distance: 527
      }
    }
  ],
  orderedModes: [ModeName.Bus, ModeName.Drive, ModeName.Walk],
  scores: {
    id: faker.datatype.uuid(),
    carbon: 71.0606,
    time: 43.391450000000006,
    cost: 83.219,
    global: 67.04385
  },
  metrics: {
    daily: {
      id: faker.datatype.uuid(),
      carbon: 1.723315,
      duration: 1825,
      cost: 1.309,
      distance: 9602
    },
    monthly: {
      id: faker.datatype.uuid(),
      duration: 1825 * 30,
      distance: 9602 * 30,
      carbon: 0,
      cost: 0
    }
  }
}

export const fakeDriveCommuteProfile: CommuteProfileProcessed = {
  id: 'ab622317-ac53-4e24-ae53-f4c3d3757d71',
  isReturn: false,
  createdAt: '2022-01-17T20:20:39.291Z',
  updatedAt: '2022-01-17T20:35:46.254Z',
  accountId: fakeAccount.id,
  accountVehicleId: fakeAccountVehicles[0].id,
  timezone: fakeAccountTimezone,
  weeklyFrequency: 4,
  days: [DaysValueEnum.Monday, DaysValueEnum.Thursday],
  leaveOfficeTime: DEFAULT_OFFICE_LEAVING_TIME_HHMM,
  arriveOfficeTime: DEFAULT_OFFICE_ARRIVAL_TIME_HHMM,
  startAddress: fakeAccount.address ?? undefined,
  endAddress: fakeAccount.office?.building?.address,
  activities: null,
  legs: [
    {
      mode: ModeName.Drive,
      type: LEG_TYPES.leg,
      endAddress: fakeAccount.office?.building?.address,
      endLocation: fakeAccount.office?.building?.location,
      startAddress: fakeAccount.address ?? undefined,
      startLocation: fakeAccount.location
    }
  ],
  commuteCosts: [
    {
      id: '7f3dc726-30e3-4795-92da-ec19f641d072',
      createdAt: '2022-01-17T20:20:39.079Z',
      updatedAt: '2022-01-17T20:20:39.079Z',
      cost: 250,
      paymentFrequency: 'monthly',
      mode: undefined,
      category: undefined,
      stop: Stop.Parking
    }
  ],
  vehicle: fakeAccountVehicles[0].vehicle,
  orderedModes: [ModeName.Drive],
  scores: {
    id: faker.datatype.uuid(),
    carbon: 72.0305,
    time: 97.1576,
    cost: 39.28506,
    global: 69.7911
  },
  metrics: {
    daily: {
      id: faker.datatype.uuid(),
      carbon: 1.3354300000000001,
      duration: 696.5,
      cost: 9.27,
      distance: 6119.5
    },
    monthly: {
      id: faker.datatype.uuid(),
      duration: 696.5 * 30,
      distance: 6119.5 * 30,
      carbon: 23.2110554128,
      cost: 161.16356112320003
    }
  },
  goingLegs: [
    {
      id: '25dd9d9f-c5b3-4b47-bf4d-d134a1d4e883',
      createdAt: '2022-01-17T20:35:48.285Z',
      updatedAt: '2022-01-17T20:35:48.285Z',
      externalPlanId: null,
      statisticalPlanId: null,
      order: 1,
      removeFromGroupId: false,
      routeId: 'a50b85b8-85be-4844-bdc0-790a0a46100f',
      serviceId: null,
      metricsId: '3b75fa21-966a-4393-aa7a-0ca2eaea0c61',
      planId: null,
      accountVehicleId: null,
      startAddress: fakeAccount.office?.building?.address || '',
      endAddress: fakeAccount.office?.building?.address || '',
      activity: Stop.Parking,
      peopleCount: 1,
      name: null,
      displayColor: undefined,
      departureTime: '2022-01-24T16:00:00.000Z',
      arrivalTime: '2022-01-24T16:00:00.000Z',
      timezone: fakeAccountTimezone,
      transferTime: null,
      type: LEG_TYPES.stop,
      polyline: undefined,
      mode: null,
      startLocation: fakeAccount.office?.building?.location || {
        lat: parseFloat(faker.address.latitude()),
        lng: parseFloat(faker.address.longitude())
      },
      endLocation: fakeAccount.office?.building?.location || {
        lat: parseFloat(faker.address.latitude()),
        lng: parseFloat(faker.address.longitude())
      },
      metrics: {
        id: '3b75fa21-966a-4393-aa7a-0ca2eaea0c61',
        createdAt: '2022-01-17T20:35:47.781Z',
        updatedAt: '2022-01-17T20:35:49.039Z',
        carbon: 0,
        emissionRate: 0,
        duration: 0,
        cost: 14.3836,
        distance: 0
      }
    },
    {
      id: '14a8d0c6-b80e-40ca-9d06-f4f04bd0ddce',
      createdAt: '2022-01-17T20:35:48.285Z',
      updatedAt: '2022-01-17T20:35:48.285Z',
      externalPlanId: null,
      statisticalPlanId: null,
      order: 0,
      removeFromGroupId: false,
      routeId: 'a50b85b8-85be-4844-bdc0-790a0a46100f',
      serviceId: null,
      metricsId: 'e85e5590-10de-4562-81bc-a989f88a10cc',
      planId: null,
      accountVehicleId: fakeAccountVehicles[0].id,
      startAddress: fakeAccount.address ?? '',
      endAddress: fakeAccount.office?.building?.address ?? '',
      activity: undefined,
      peopleCount: 1,
      name: null,
      displayColor: undefined,
      departureTime: '2022-01-24T15:48:26.000Z',
      arrivalTime: '2022-01-24T16:00:00.000Z',
      timezone: fakeAccountTimezone,
      transferTime: 0,
      type: LEG_TYPES.leg,
      polyline:
        'supsFlisaSfBm@tBm@nCK?sAn@CX?fFEfDEt@Nf@ZJDZ_A@MVs@|@cClAeDRk@BYDsA?_ACW?e@?a@@y@@uDA{AKuEvJ?rJAvNAtNAlK?fEJ`BApCIvICbGA|F?RJdA@|A?xDCNGX?~BAnA?hC?~NAzD@pAA`HBbGCzK?tOFtGElG@`FAxGG`AKjKeCzCs@R@^I|A[jAWjEgALrATlBFZJL\\Nz@YHEQ{A',
      mode: ModeName.Drive,
      startLocation: fakeAccount.location,
      endLocation: fakeAccount.office?.building?.location || {
        lat: parseFloat(faker.address.latitude()),
        lng: parseFloat(faker.address.longitude())
      },
      metrics: {
        id: 'e85e5590-10de-4562-81bc-a989f88a10cc',
        createdAt: '2022-01-17T20:35:47.781Z',
        updatedAt: '2022-01-17T20:35:49.039Z',
        carbon: 1.34383,
        emissionRate: 0.218225,
        duration: 694,
        cost: 2.09372,
        distance: 6158
      }
    }
  ],
  returnLegs: [
    {
      id: 'c38a03d7-6e40-4291-89f8-4da3c986518a',
      createdAt: '2022-01-17T20:35:48.285Z',
      updatedAt: '2022-01-17T20:35:48.285Z',
      externalPlanId: null,
      statisticalPlanId: null,
      order: 0,
      removeFromGroupId: false,
      routeId: '480cf3e4-b693-4482-b1a8-d12a410a9100',
      serviceId: null,
      metricsId: 'edee8635-cd3f-4fc3-ad97-9f1afd6ba7b6',
      planId: null,
      accountVehicleId: fakeAccountVehicles[0].id,
      startAddress: fakeAccount.office?.building?.address ?? '',
      endAddress: fakeAccount.address ?? '',
      activity: undefined,
      peopleCount: 1,
      name: null,
      displayColor: undefined,
      departureTime: '2022-01-25T00:00:00.000Z',
      arrivalTime: '2022-01-25T00:11:39.000Z',
      timezone: fakeAccountTimezone,
      transferTime: 0,
      type: LEG_TYPES.leg,
      polyline:
        'w`gsFluqaSc@aDCSgBb@aCj@gAVgEbAQLqAZwCr@_FjAmB`@_AHgIDwD@{I?aEBkQI_NBi@@{GCmF?gH@iG@eBAwC?}D@OE_AAqECi@@_@?UFsB?iC?qC@qB?wIBqCHaB@gEKmK?uN@wN@sJ@wJ?JtE@zAAtDAzA?d@GX?x@CrAMhAkChHWr@GFa@~@_@Ua@O[Ec@AuAD{CDcED?rAkABcAF}@TwBr@g@P',
      mode: ModeName.Drive,
      startLocation: fakeAccount.office?.building?.location || {
        lat: parseFloat(faker.address.latitude()),
        lng: parseFloat(faker.address.longitude())
      },
      endLocation: fakeAccount.location,
      metrics: {
        id: 'edee8635-cd3f-4fc3-ad97-9f1afd6ba7b6',
        createdAt: '2022-01-17T20:35:47.781Z',
        updatedAt: '2022-01-17T20:35:49.039Z',
        carbon: 1.32703,
        emissionRate: 0.218225,
        duration: 699,
        cost: 2.06754,
        distance: 6081
      }
    }
  ]
}

export const fakeBikeCommuteProfile: CommuteProfileProcessed = {
  id: '119e2b9e-98e6-4cb8-b227-488007ac3945',
  isReturn: false,
  createdAt: '2022-01-17T20:37:15.880Z',
  updatedAt: '2022-01-25T14:52:06.860Z',
  accountId: fakeAccount.id,
  accountVehicleId: undefined,
  timezone: fakeAccountTimezone,
  weeklyFrequency: 2,
  days: [DaysValueEnum.Tuesday, DaysValueEnum.Wednesday],
  leaveOfficeTime: DEFAULT_OFFICE_LEAVING_TIME_HHMM,
  arriveOfficeTime: DEFAULT_OFFICE_ARRIVAL_TIME_HHMM,
  startAddress: fakeAccount.address ?? undefined,
  endAddress: fakeAccount.office?.building?.address,
  activities: [],
  legs: [
    {
      mode: ModeName.Bike,
      type: LEG_TYPES.leg,
      endAddress: fakeAccount.office?.building?.address,
      endLocation: fakeAccount.office?.building?.location,
      startAddress: fakeAccount.address ?? undefined,
      startLocation: fakeAccount.location
    }
  ],
  commuteCosts: [],
  orderedModes: [ModeName.Bike],
  scores: {
    id: faker.datatype.uuid(),
    carbon: 100,
    time: 45.48925,
    cost: 100,
    global: 83.32975
  },
  metrics: {
    daily: {
      id: faker.datatype.uuid(),
      carbon: 0,
      duration: 1763.5,
      cost: 0,
      distance: 7242.5
    },
    monthly: {
      id: faker.datatype.uuid(),
      duration: 1763.5 * 30,
      distance: 7242.5 * 30,
      carbon: 0,
      cost: 0
    }
  },
  goingLegs: [
    {
      id: '3331cca2-1b4c-4152-b98e-28792079fbed',
      createdAt: '2022-01-25T14:52:08.074Z',
      updatedAt: '2022-01-25T14:52:08.074Z',
      externalPlanId: null,
      statisticalPlanId: null,
      order: 0,
      removeFromGroupId: false,
      routeId: 'b3063df2-faa6-4ea1-a0db-25765ca23829',
      serviceId: null,
      metricsId: 'e29aee0a-5bf4-46a5-a3b6-b93f166a1f52',
      planId: null,
      accountVehicleId: null,
      startAddress: fakeAccount.address ?? '',
      endAddress: fakeAccount.office?.building?.address ?? '',
      activity: undefined,
      peopleCount: 1,
      name: null,
      displayColor: undefined,
      departureTime: '2022-02-01T15:34:20.000Z',
      arrivalTime: '2022-02-01T16:00:00.000Z',
      timezone: fakeAccountTimezone,
      transferTime: 0,
      type: LEG_TYPES.leg,
      polyline:
        'supsFlisaSfBm@tBm@nCK?sAn@CX?fFEfDEt@Nf@Z\\LFAHANH^XRVHRP@JBFBL\\JZJB\\MLBRZHT\\f@l@r@L\\PRp@`AZd@Th@RZLHn@`@PBzDDpRBfA?vEF\\NNTVt@XfAXr@HFTDr@AhASRGR@tAVZT`@^PHHG?w@NaAFSP]b@[F?TLl@R~@Td@?l@Ot@Qb@?TD^P`@l@`@^d@NZ?p@SjAg@r@Mt@_@RIRDl@VRH`@HVTRHHLZl@XVf@X|@^p@DjAM\\ALCf@[^S\\[~@iAXg@\\aAtB[VKNOZg@Vi@~@kC^{@fBtBf@Xb@H`AIrACHb@El@Dh@TOHELAV?zC@lAAd@GDK^AR??\\jBCj@BNDXPZXRHtB?dE@~GAdDC|EI|NCvECzPAjK@hF@rBC`B?`F?pBCjG?hC??eMAoHGs@eA{Is@sFMq@s@mFYyBOoA`@I`Du@d@Iz@YHEQ{A',
      mode: ModeName.Bike,
      startLocation: fakeAccount.location,
      endLocation: fakeAccount.office?.building?.location || {
        lat: parseFloat(faker.address.latitude()),
        lng: parseFloat(faker.address.longitude())
      },
      metrics: {
        id: 'e29aee0a-5bf4-46a5-a3b6-b93f166a1f52',
        createdAt: '2022-01-25T14:52:07.726Z',
        updatedAt: '2022-01-25T14:52:07.726Z',
        carbon: 0,
        emissionRate: 0,
        duration: 1540,
        cost: 0,
        distance: 7052
      }
    }
  ],
  returnLegs: [
    {
      id: 'be79d7eb-7476-4c0e-b555-f82efe571e06',
      createdAt: '2022-01-25T14:52:08.074Z',
      updatedAt: '2022-01-25T14:52:08.074Z',
      externalPlanId: null,
      statisticalPlanId: null,
      order: 0,
      removeFromGroupId: false,
      routeId: '2886440b-e0a6-4188-bda6-ab43ca97b126',
      serviceId: null,
      metricsId: '7c201998-04ac-4d1a-99f0-d1b4bf27447f',
      planId: null,
      accountVehicleId: null,
      startAddress: fakeAccount.office?.building?.address ?? '',
      endAddress: fakeAccount.address ?? '',
      activity: undefined,
      peopleCount: 1,
      name: null,
      displayColor: undefined,
      departureTime: '2022-02-02T00:00:00.000Z',
      arrivalTime: '2022-02-02T00:33:07.000Z',
      timezone: fakeAccountTimezone,
      transferTime: 0,
      type: LEG_TYPES.leg,
      polyline:
        'w`gsFluqaSRnBn@lFT~AT`CSDq@Ru@VeD|@|AfMb@zD@xM?zGiC?kG?qBB_G?qG@oLAoO@mZFkGJkF@qJ?uB?SI[YYQOEk@CkBB?]S?_@@EJe@FeEA{@@M@IDUNEi@Dm@Ic@sABaAHc@Ig@Y_CsCYe@Om@WuDIuBHyBNeBAkA[_CIY[i@UQWIc@GW@_@FqBfAoDfCu@`@kBb@uAPgAF}A?qAAAKy@ASFC@KC]SIGOCy@Ey@Cw@CqAIw@EaAH_@JiALMZORcA~A[b@WHe@@G?C??}A?iHcDDgDC?TU@OHmAKe@A_@Dg@HUCi@OMGKCAUsGBoAA?GEKc@WYt@QNc@Xa@d@MHOKQKeAWq@MeAK]@WFyCBMJCr@?TU?k@?EFIJIKqAAkA@m@EIEC^mAdD}@bCWr@GFa@~@_@Ua@O[Ec@AuADaHF}@B?rAkABcAF}@TwBr@g@P',
      mode: ModeName.Bike,
      startLocation: fakeAccount.office?.building?.location || {
        lat: parseFloat(faker.address.latitude()),
        lng: parseFloat(faker.address.longitude())
      },
      endLocation: fakeAccount.location,
      metrics: {
        id: '7c201998-04ac-4d1a-99f0-d1b4bf27447f',
        createdAt: '2022-01-25T14:52:07.726Z',
        updatedAt: '2022-01-25T14:52:07.726Z',
        carbon: 0,
        emissionRate: 0,
        duration: 1987,
        cost: 0,
        distance: 7433
      }
    }
  ]
}

export const fakeDriveTransitWithStopInactiveCommuteProfile: CommuteProfileProcessed = {
  id: '1a713689-5828-4530-8be9-05a8a110791a',
  isReturn: false,
  createdAt: '2022-01-17T21:08:04.319Z',
  updatedAt: '2022-01-17T21:08:39.057Z',
  accountId: fakeAccount.id,
  accountVehicleId: fakeAccountVehicles[0].id,
  timezone: fakeAccount.id,
  weeklyFrequency: 0,
  days: [],
  leaveOfficeTime: DEFAULT_OFFICE_LEAVING_TIME_HHMM,
  arriveOfficeTime: DEFAULT_OFFICE_ARRIVAL_TIME_HHMM,
  startAddress: fakeAccount.address ?? '',
  endAddress: fakeAccount.office?.building?.address,
  activities: [Stop.DropOffKids],
  legs: [
    {
      mode: ModeName.Drive,
      type: LEG_TYPES.leg,
      endAddress: '1955 21st St, Boulder, CO 80302, USA',
      endLocation: {
        lat: 40.0199472,
        lng: -105.2682628
      },
      startAddress: fakeAccount.address ?? '',
      startLocation: fakeAccount.location
    },
    {
      type: LEG_TYPES.stop,
      activity: Stop.DropOffKids,
      category: undefined,
      endAddress: '1955 21st St, Boulder, CO 80302, USA',
      endLocation: {
        lat: 40.0199472,
        lng: -105.2682628
      },
      startAddress: '1955 21st St, Boulder, CO 80302, USA',
      startLocation: {
        lat: 40.0199472,
        lng: -105.2682628
      }
    },
    {
      mode: ModeName.Drive,
      type: LEG_TYPES.leg,
      endAddress: '2690 Valmont Rd, Boulder, CO 80304, USA',
      endLocation: {
        lat: 40.0288913,
        lng: -105.2612163
      },
      startAddress: '1955 21st St, Boulder, CO 80302, USA',
      startLocation: {
        lat: 40.0199472,
        lng: -105.2682628
      }
    },
    {
      type: LEG_TYPES.leg,
      transit: {
        legs: [
          {
            id: faker.datatype.uuid(),
            mode: ModeName.Walk,
            type: LEG_TYPES.leg,
            category: ModeCategory.Walk,
            distance: 346,
            endAddress: '',
            endLocation: {
              lat: 40.0284173,
              lng: -105.2585516
            },
            startAddress: '2690 Valmont Rd, Boulder, CO 80304, USA',
            startLocation: {
              lat: 40.0288905,
              lng: -105.2613493
            }
          },
          {
            id: faker.datatype.uuid(),
            icon: 'https://maps.gstatic.com/mapfiles/transit/iw2/6/bus2.png',
            mode: ModeName.Bus,
            name: 'BOLT',
            type: 'leg',
            category: 'public_transit',
            distance: 2986,
            textColor: '#ffffff',
            endAddress: '',
            endLocation: {
              lat: 40.016993,
              lng: -105.276446
            },
            displayColor: '#64a70b',
            startAddress: '',
            startLocation: {
              lat: 40.028417,
              lng: -105.258572
            }
          },
          {
            id: faker.datatype.uuid(),
            mode: ModeName.Walk,
            type: 'leg',
            category: 'walk',
            distance: 565,
            endAddress: fakeAccount.office?.building?.address || '',
            endLocation: fakeAccount.office?.building?.location || {
              lat: parseFloat(faker.address.latitude()),
              lng: parseFloat(faker.address.longitude())
            },
            startAddress: '',
            startLocation: {
              lat: 40.016993,
              lng: -105.276446
            }
          }
        ],
        endAddress: fakeAccount.office?.building?.address || '',
        endLocation: fakeAccount.office?.building?.location || {
          lat: parseFloat(faker.address.latitude()),
          lng: parseFloat(faker.address.longitude())
        },
        startAddress: '2690 Valmont Rd, Boulder, CO 80304, USA',
        durationInSec: 1226,
        startLocation: {
          lat: 40.0288905,
          lng: -105.2613493
        },
        formattedDuration: '20 mins'
      },
      category: ModeCategory.PublicTransit,
      endAddress: fakeAccount.office?.building?.address,
      endLocation: fakeAccount.office?.building?.location,
      startAddress: '2690 Valmont Rd, Boulder, CO 80304, USA',
      startLocation: {
        lat: 40.0288913,
        lng: -105.2612163
      },
      preferredShape: [
        {
          mode: ModeName.Walk
        },
        {
          mode: ModeName.Bus,
          transitLine: 'BOLT'
        },
        {
          mode: ModeName.Walk
        }
      ]
    }
  ],
  commuteCosts: [],
  vehicle: fakeAccountVehicles[0].vehicle,
  orderedModes: [ModeName.Drive, ModeName.Bus, ModeName.Walk],
  scores: {
    id: faker.datatype.uuid(),
    carbon: 62.2989,
    time: 34.99555,
    cost: 67.229,
    global: 53.581
  },
  metrics: {
    daily: {
      id: faker.datatype.uuid(),
      carbon: 2.42871,
      duration: 2266,
      cost: 3.0856700000000004,
      distance: 12637
    },
    monthly: {
      id: faker.datatype.uuid(),

      duration: 2266 * 30,
      distance: 12637 * 30,
      carbon: 0,
      cost: 0
    }
  },
  goingLegs: [
    {
      id: '7e013a3f-618f-4eb5-aa46-daa27d56b2a0',
      createdAt: '2022-01-17T21:08:41.795Z',
      updatedAt: '2022-01-17T21:08:41.795Z',
      externalPlanId: null,
      statisticalPlanId: null,
      order: 5,
      removeFromGroupId: false,
      routeId: 'c799c451-f212-4c8e-b686-3086d7e51a49',
      serviceId: null,
      metricsId: 'eaca5556-3b61-4b83-a8d3-4bb691245039',
      planId: null,
      accountVehicleId: null,
      startAddress: '',
      endAddress: fakeAccount.office?.building?.address || '',
      activity: undefined,
      peopleCount: 1,
      name: null,
      displayColor: undefined,
      departureTime: '2022-01-26T15:32:00.000Z',
      arrivalTime: '2022-01-26T15:39:53.000Z',
      timezone: fakeAccountTimezone,
      transferTime: 0,
      type: LEG_TYPES.leg,
      polyline: 'eyfsFxxpaSoAPMBHp@BZBP?DFb@b@hD@PBNBR@J@PLbANhADZF^DPBVMBw@Tc@JMBk@Nq@NyA^NhANjA',
      mode: ModeName.Walk,
      startLocation: {
        lat: 40.016993,
        lng: -105.276446
      },
      endLocation: fakeAccount.office?.building?.location || {
        lat: parseFloat(faker.address.latitude()),
        lng: parseFloat(faker.address.longitude())
      },
      metrics: {
        id: 'eaca5556-3b61-4b83-a8d3-4bb691245039',
        createdAt: '2022-01-17T21:08:41.030Z',
        updatedAt: '2022-01-17T21:08:41.030Z',
        carbon: 0,
        emissionRate: 0,
        duration: 473,
        cost: 0,
        distance: 565
      }
    },
    {
      id: '7dd28aa0-2911-4631-ad30-66f5577b7750',
      createdAt: '2022-01-17T21:08:41.795Z',
      updatedAt: '2022-01-17T21:08:41.795Z',
      externalPlanId: null,
      statisticalPlanId: null,
      order: 4,
      removeFromGroupId: false,
      routeId: 'c799c451-f212-4c8e-b686-3086d7e51a49',
      serviceId: null,
      metricsId: '398cb547-845e-4aef-8cf3-1882a33cc68a',
      planId: null,
      accountVehicleId: null,
      startAddress: '',
      endAddress: '',
      activity: undefined,
      peopleCount: 1,
      name: 'BOLT',
      displayColor: '#64a70b',
      departureTime: '2022-01-26T15:23:33.000Z',
      arrivalTime: '2022-01-26T15:32:00.000Z',
      timezone: fakeAccountTimezone,
      transferTime: 0,
      type: LEG_TYPES.leg,
      polyline:
        's`isF`imaS?OT?l@?XOj@?h@?JAX?`B?f@AX?D?T?DF@@@@@?@@B?@?b@?T?zA?bBAhB?jB@B?t@?`@?J@v@AH@fA?dAAX@hA?X?@?l@?f@Aj@@B?l@?z@@hA?~@?dA?t@?R?h@A\\?tA?r@?f@AD?\\?JLFFBBBDDFBFHRCzD?`AApA?dG?hABhC@\\?J@RBh@B^@X@T@R@h@@VBxA@jDA|@?rA@zE?jA?\\@|E?J?fD?h@?hA?tC@tAAvB?D@l@?@D?@`@BPr@`Gt@dGVtBL~@?@BNJ~@cAXUD?@gB^KaAAMVIlAY?@',
      mode: ModeName.Bus,
      startLocation: {
        lat: 40.028417,
        lng: -105.258572
      },
      endLocation: {
        lat: 40.016993,
        lng: -105.276446
      },
      metrics: {
        id: '398cb547-845e-4aef-8cf3-1882a33cc68a',
        createdAt: '2022-01-17T21:08:41.031Z',
        updatedAt: '2022-01-17T21:08:43.208Z',
        carbon: 0.538674,
        emissionRate: 0.1804,
        duration: 507,
        cost: 0,
        distance: 2986
      }
    },
    {
      id: '473496dd-e4bd-40c7-aa4d-c2f910075fdb',
      createdAt: '2022-01-17T21:08:41.795Z',
      updatedAt: '2022-01-17T21:08:41.795Z',
      externalPlanId: null,
      statisticalPlanId: null,
      order: 3,
      removeFromGroupId: false,
      routeId: 'c799c451-f212-4c8e-b686-3086d7e51a49',
      serviceId: null,
      metricsId: '4cb3d0e8-5aed-4fc8-9469-8a3afaa0b8a9',
      planId: null,
      accountVehicleId: null,
      startAddress: '',
      endAddress: '',
      activity: undefined,
      peopleCount: 1,
      name: null,
      displayColor: undefined,
      departureTime: '2022-01-26T15:19:28.000Z',
      arrivalTime: '2022-01-26T15:23:33.000Z',
      timezone: fakeAccountTimezone,
      transferTime: 0,
      type: LEG_TYPES.leg,
      polyline: 'qcisFlzmaSs@??gA?i@?A?Q@kADI?Q@]?K?S@E?OAIDuABqAN?DEDC@GBG?Eb@?FK@G@Gb@?F?',
      mode: ModeName.Walk,
      startLocation: {
        lat: 40.0288905,
        lng: -105.2613493
      },
      endLocation: {
        lat: 40.0284173,
        lng: -105.2585516
      },
      metrics: {
        id: '4cb3d0e8-5aed-4fc8-9469-8a3afaa0b8a9',
        createdAt: '2022-01-17T21:08:41.031Z',
        updatedAt: '2022-01-17T21:08:41.031Z',
        carbon: 0,
        emissionRate: 0,
        duration: 245,
        cost: 0,
        distance: 346
      }
    },
    {
      id: '030b2278-a055-4f8f-8f0d-d58b6a059797',
      createdAt: '2022-01-17T21:08:41.795Z',
      updatedAt: '2022-01-17T21:08:41.795Z',
      externalPlanId: null,
      statisticalPlanId: null,
      order: 2,
      removeFromGroupId: false,
      routeId: 'c799c451-f212-4c8e-b686-3086d7e51a49',
      serviceId: null,
      metricsId: 'bea644ed-c003-4c06-a276-9a230bae1b58',
      planId: null,
      accountVehicleId: '839f785c-d358-41db-8e4d-a27886f1b989',
      startAddress: '1955 21st St, Boulder, CO 80302, USA',
      endAddress: '2690 Valmont Rd, Boulder, CO 80304, USA',
      activity: undefined,
      peopleCount: 1,
      name: null,
      displayColor: undefined,
      departureTime: '2022-01-26T15:16:30.000Z',
      arrivalTime: '2022-01-26T15:19:28.000Z',
      timezone: fakeAccountTimezone,
      transferTime: 0,
      type: LEG_TYPES.leg,
      polyline:
        '{kgsFpdoaScAVMiAYaCaAcI{AyL@]CWk@BoD\\m@LSLeCl@aAVe@Dc@@sAOkAa@iAy@o@]_AUUAoC@m@@iANi@Du@@wA?oA?qB@AsC?kA?y@?_Ax@?',
      mode: ModeName.Drive,
      startLocation: {
        lat: 40.0199472,
        lng: -105.2682628
      },
      endLocation: {
        lat: 40.0288913,
        lng: -105.2612163
      },
      metrics: {
        id: 'bea644ed-c003-4c06-a276-9a230bae1b58',
        createdAt: '2022-01-17T21:08:41.031Z',
        updatedAt: '2022-01-17T21:08:43.208Z',
        carbon: 0.341523,
        emissionRate: 0.218225,
        duration: 178,
        cost: 0.5321,
        distance: 1565
      }
    },
    {
      id: '5be077d9-1674-49f9-89d0-cc79e675a58a',
      createdAt: '2022-01-17T21:08:41.795Z',
      updatedAt: '2022-01-17T21:08:41.795Z',
      externalPlanId: null,
      statisticalPlanId: null,
      order: 1,
      removeFromGroupId: false,
      routeId: 'c799c451-f212-4c8e-b686-3086d7e51a49',
      serviceId: null,
      metricsId: '46b3bcb2-a440-4f24-8248-17170f0a19bb',
      planId: null,
      accountVehicleId: null,
      startAddress: '1955 21st St, Boulder, CO 80302, USA',
      endAddress: '1955 21st St, Boulder, CO 80302, USA',
      activity: Stop.DropOffKids,
      peopleCount: 1,
      name: null,
      displayColor: undefined,
      departureTime: '2022-01-26T15:16:30.000Z',
      arrivalTime: '2022-01-26T15:16:30.000Z',
      timezone: fakeAccountTimezone,
      transferTime: null,
      type: LEG_TYPES.stop,
      polyline: undefined,
      mode: null,
      startLocation: {
        lat: 40.0199472,
        lng: -105.2682628
      },
      endLocation: {
        lat: 40.0199472,
        lng: -105.2682628
      },
      metrics: {
        id: '46b3bcb2-a440-4f24-8248-17170f0a19bb',
        createdAt: '2022-01-17T21:08:41.031Z',
        updatedAt: '2022-01-17T21:08:41.031Z',
        carbon: 0,
        emissionRate: 0,
        duration: 0,
        cost: 0,
        distance: 0
      }
    },
    {
      id: '107d203b-6e84-438f-9fc6-aacd4632049e',
      createdAt: '2022-01-17T21:08:41.795Z',
      updatedAt: '2022-01-17T21:08:41.795Z',
      externalPlanId: null,
      statisticalPlanId: null,
      order: 0,
      removeFromGroupId: false,
      routeId: 'c799c451-f212-4c8e-b686-3086d7e51a49',
      serviceId: null,
      metricsId: '9909c934-be81-49f8-a475-f29f276e27d1',
      planId: null,
      accountVehicleId: fakeAccountVehicles[0].id,
      startAddress: fakeAccount.address || '',
      endAddress: '1955 21st St, Boulder, CO 80302, USA',
      activity: undefined,
      peopleCount: 1,
      name: null,
      displayColor: undefined,
      departureTime: '2022-01-26T15:04:15.000Z',
      arrivalTime: '2022-01-26T15:16:30.000Z',
      timezone: fakeAccountTimezone,
      transferTime: 0,
      type: LEG_TYPES.leg,
      polyline:
        'supsFlisaSfBm@tBm@nCK?sAn@CX?fFEfDEt@Nf@ZJDZ_A@MVs@|@cClAeDRk@BYDsA?_ACW?e@?a@@y@@uDA{AKuEEcFCiN?eEAgAFIDQNaARq@Vs@vBeElAgCfC}ErDyG~DoH\\q@La@|@gBzDkHvB{DxCiFpG_MdBmDrBiDtAmBtBaCp@u@lAeAl@a@nC_BdAg@lBs@fCq@xB]xAK|@ExBAnD@rK?vB?fEC`KC~I@vOA|GEpJAbB?LM`C?tCAR?BDDDN@vA@rB?hDAl@?XOtA?nDC^?T?DFBBB@h@?~HArEB`A?fA?RJXPNLRNf@~D^xBVpB\\pCHv@l@jFDVNZ\\lCl@bFj@nE~@|HLhAbAW',
      mode: ModeName.Drive,
      startLocation: fakeAccount.location,
      endLocation: {
        lat: 40.0199472,
        lng: -105.2682628
      },
      metrics: {
        id: '9909c934-be81-49f8-a475-f29f276e27d1',
        createdAt: '2022-01-17T21:08:41.030Z',
        updatedAt: '2022-01-17T21:08:43.208Z',
        carbon: 1.65393,
        emissionRate: 0.218225,
        duration: 735,
        cost: 2.57686,
        distance: 7579
      }
    }
  ],
  returnLegs: [
    {
      id: '4bcdf8df-c689-4002-b9ef-c1c67f070d7d',
      createdAt: '2022-01-17T21:08:41.795Z',
      updatedAt: '2022-01-17T21:08:41.795Z',
      externalPlanId: null,
      statisticalPlanId: null,
      order: 5,
      removeFromGroupId: false,
      routeId: 'da1d7107-2931-4ec0-964c-6e12cb5f6d62',
      serviceId: null,
      metricsId: '5ffaab4a-8b77-4e60-ad9c-217a4d29cba4',
      planId: null,
      accountVehicleId: fakeAccountVehicles[0].id,
      startAddress: '1955 21st St, Boulder, CO 80302, USA',
      endAddress: fakeAccount.address || '',
      activity: undefined,
      peopleCount: 1,
      name: null,
      displayColor: undefined,
      departureTime: '2022-01-27T00:34:12.000Z',
      arrivalTime: '2022-01-27T00:46:51.000Z',
      timezone: fakeAccountTimezone,
      transferTime: 0,
      type: LEG_TYPES.leg,
      polyline:
        '{kgsFpdoaScAVMiAYaCaAcI{AyL@]CW[mC[qCcC{RGa@qA?gF?{LDe@?IDGDU?_@?oDBuA?YOcE?yB?wBBEDAFS?oA?eA@aC?MKeA?sI@W?kH@uDAqAAqC@aEBsHDsCFoEDoK?_J?kDBiDDgAF}ANsAReATmCx@mBx@cDtB{@n@cA`AYXyAdB_BbCcB|CcHlMcDjGkB~DaHnMu@xAUV_@j@e@`AoA|Bw@zA_JvPcHnMeHzMkB`DgArAgAjA_@^k@f@Vj@v@z@PNYf@QHKBa@?SFOHSVI\\CnA?dC@hAGj@g@v@gDxEM^Eh@?~HJvB^jCuBl@gBl@',
      mode: ModeName.Drive,
      startLocation: {
        lat: 40.0199472,
        lng: -105.2682628
      },
      endLocation: fakeAccount.location,
      metrics: {
        id: '5ffaab4a-8b77-4e60-ad9c-217a4d29cba4',
        createdAt: '2022-01-17T21:08:41.030Z',
        updatedAt: '2022-01-17T21:08:43.208Z',
        carbon: 1.62796,
        emissionRate: 0.218225,
        duration: 759,
        cost: 2.5364,
        distance: 7460
      }
    },
    {
      id: 'a907767d-9752-4814-835c-7a61996ee0a7',
      createdAt: '2022-01-17T21:08:41.795Z',
      updatedAt: '2022-01-17T21:08:41.795Z',
      externalPlanId: null,
      statisticalPlanId: null,
      order: 4,
      removeFromGroupId: false,
      routeId: 'da1d7107-2931-4ec0-964c-6e12cb5f6d62',
      serviceId: null,
      metricsId: '969fd8e8-85b7-4810-ab87-81652e0874c8',
      planId: null,
      accountVehicleId: null,
      startAddress: '1955 21st St, Boulder, CO 80302, USA',
      endAddress: '1955 21st St, Boulder, CO 80302, USA',
      activity: Stop.DropOffKids,
      peopleCount: 1,
      name: null,
      displayColor: undefined,
      departureTime: '2022-01-27T00:34:12.000Z',
      arrivalTime: '2022-01-27T00:34:12.000Z',
      timezone: fakeAccountTimezone,
      transferTime: null,
      type: LEG_TYPES.stop,
      polyline: undefined,
      mode: null,
      startLocation: {
        lat: 40.0199472,
        lng: -105.2682628
      },
      endLocation: {
        lat: 40.0199472,
        lng: -105.2682628
      },
      metrics: {
        id: '969fd8e8-85b7-4810-ab87-81652e0874c8',
        createdAt: '2022-01-17T21:08:41.031Z',
        updatedAt: '2022-01-17T21:08:41.031Z',
        carbon: 0,
        emissionRate: 0,
        duration: 0,
        cost: 0,
        distance: 0
      }
    },
    {
      id: 'b6f297b0-cea5-4999-a2a5-1435f0c79618',
      createdAt: '2022-01-17T21:08:41.795Z',
      updatedAt: '2022-01-17T21:08:41.795Z',
      externalPlanId: null,
      statisticalPlanId: null,
      order: 3,
      removeFromGroupId: false,
      routeId: 'da1d7107-2931-4ec0-964c-6e12cb5f6d62',
      serviceId: null,
      metricsId: 'cf44693a-3a76-46c5-98b2-e3e001e195ae',
      planId: null,
      accountVehicleId: fakeAccountVehicles[0].id,
      startAddress: '2690 Valmont Rd, Boulder, CO 80304, USA',
      endAddress: '1955 21st St, Boulder, CO 80302, USA',
      activity: undefined,
      peopleCount: 1,
      name: null,
      displayColor: undefined,
      departureTime: '2022-01-27T00:30:23.000Z',
      arrivalTime: '2022-01-27T00:34:12.000Z',
      timezone: fakeAccountTimezone,
      transferTime: 0,
      type: LEG_TYPES.leg,
      polyline: 'qcisFvzmaSy@??L?vA@rF~HAbAKfAK~DC`@Fx@VhBnAd@Rz@Rl@Hr@Ad@EfEeATBj@EtBSvAMNZPtAPtAPvAn@hFlA|JP|AbAW',
      mode: ModeName.Drive,
      startLocation: {
        lat: 40.0288913,
        lng: -105.2612163
      },
      endLocation: {
        lat: 40.0199472,
        lng: -105.2682628
      },
      metrics: {
        id: 'cf44693a-3a76-46c5-98b2-e3e001e195ae',
        createdAt: '2022-01-17T21:08:41.031Z',
        updatedAt: '2022-01-17T21:08:43.208Z',
        carbon: 0.337595,
        emissionRate: 0.218225,
        duration: 229,
        cost: 0.52598,
        distance: 1547
      }
    },
    {
      id: '03fb20c0-b0cd-416c-aca4-302541780ef7',
      createdAt: '2022-01-17T21:08:41.795Z',
      updatedAt: '2022-01-17T21:08:41.795Z',
      externalPlanId: null,
      statisticalPlanId: null,
      order: 2,
      removeFromGroupId: false,
      routeId: 'da1d7107-2931-4ec0-964c-6e12cb5f6d62',
      serviceId: null,
      metricsId: 'eabd9d39-4b24-4d7a-b9b5-f4e9c10d5cc7',
      planId: null,
      accountVehicleId: null,
      startAddress: '',
      endAddress: '',
      activity: undefined,
      peopleCount: 1,
      name: null,
      displayColor: undefined,
      departureTime: '2022-01-27T00:17:00.000Z',
      arrivalTime: '2022-01-27T00:30:23.000Z',
      timezone: fakeAccountTimezone,
      transferTime: 0,
      type: LEG_TYPES.leg,
      polyline:
        'stgsF|anaSP~AQ@S@K?WBw@HyALMBC?ODUDSN}Bh@MDy@R]DM@[@MAe@GGAUCUIQEKEUK]Wi@a@c@UOIi@OKAOCDO@G?C?CAGAGEIAEOUq@sAcAC[AcBCQA_@C@QGAS?C?CAYAA{BEIAGME_@@gA@o@@I@G`@a@?',
      mode: ModeName.Walk,
      startLocation: {
        lat: 40.0213844,
        lng: -105.2625467
      },
      endLocation: {
        lat: 40.0288905,
        lng: -105.2613493
      },
      metrics: {
        id: 'eabd9d39-4b24-4d7a-b9b5-f4e9c10d5cc7',
        createdAt: '2022-01-17T21:08:41.031Z',
        updatedAt: '2022-01-17T21:08:41.031Z',
        carbon: 0,
        emissionRate: 0,
        duration: 803,
        cost: 0,
        distance: 1037
      }
    },
    {
      id: '64be92ef-1b6b-4934-bf29-47b95c435697',
      createdAt: '2022-01-17T21:08:41.795Z',
      updatedAt: '2022-01-17T21:08:41.795Z',
      externalPlanId: null,
      statisticalPlanId: null,
      order: 1,
      removeFromGroupId: false,
      routeId: 'da1d7107-2931-4ec0-964c-6e12cb5f6d62',
      serviceId: null,
      metricsId: '10c0a483-afdd-4e20-9e51-a4d08c534598',
      planId: null,
      accountVehicleId: null,
      startAddress: '',
      endAddress: '',
      activity: undefined,
      peopleCount: 1,
      name: 'HOP Clockwise',
      displayColor: '#ce5504',
      departureTime: '2022-01-27T00:09:36.000Z',
      arrivalTime: '2022-01-27T00:17:00.000Z',
      timezone: fakeAccountTimezone,
      transferTime: 0,
      type: LEG_TYPES.leg,
      polyline:
        'c{fsFv}qaSQDG_@]mCGk@HA`@Kv@Qr@ONEFAHMDOBIBIM}@Ks@AGK{@Ga@CQIi@G]MiAMcACQCSC[CQa@iDAEE[AQGc@OqASaBCAAAGKGGECGEGCIBm@PMDy@Rk@LWsBQwAKy@MeAUaBEg@Io@Ii@K{@E[S}AGe@Ik@E]?EE]OkAOqAM_AQoAOqAMgAAC]uCKaAMiACSUmBK}@Y}BQsAAKGg@ACMcAUmBCYM}@E]Kw@QuA@]CWUiBJC',
      mode: ModeName.Bus,
      startLocation: {
        lat: 40.0173035,
        lng: -105.2823563
      },
      endLocation: {
        lat: 40.021374,
        lng: -105.262543
      },
      metrics: {
        id: '10c0a483-afdd-4e20-9e51-a4d08c534598',
        createdAt: '2022-01-17T21:08:41.031Z',
        updatedAt: '2022-01-17T21:08:43.208Z',
        carbon: 0.357733,
        emissionRate: 0.1804,
        duration: 444,
        cost: 0,
        distance: 1983
      }
    },
    {
      id: '63e5117b-0f46-439b-a0d7-314c302b810c',
      createdAt: '2022-01-17T21:08:41.795Z',
      updatedAt: '2022-01-17T21:08:41.795Z',
      externalPlanId: null,
      statisticalPlanId: null,
      order: 0,
      removeFromGroupId: false,
      routeId: 'da1d7107-2931-4ec0-964c-6e12cb5f6d62',
      serviceId: null,
      metricsId: '772317bc-3c09-4a17-a5c8-7dd311d1150d',
      planId: null,
      accountVehicleId: null,
      startAddress: fakeAccount.office?.building?.address || '',
      endAddress: '',
      activity: undefined,
      peopleCount: 1,
      name: null,
      displayColor: undefined,
      departureTime: '2022-01-27T00:06:57.000Z',
      arrivalTime: '2022-01-27T00:09:36.000Z',
      timezone: fakeAccountTimezone,
      transferTime: 0,
      type: LEG_TYPES.leg,
      polyline: 'q`gsFjuqaSNnA\\IDAFCn@QHCBTA@D\\\\lCD`@',
      mode: ModeName.Walk,
      startLocation: {
        lat: 40.0181701,
        lng: -105.2810169
      },
      endLocation: {
        lat: 40.01736,
        lng: -105.2823763
      },
      metrics: {
        id: '772317bc-3c09-4a17-a5c8-7dd311d1150d',
        createdAt: '2022-01-17T21:08:41.030Z',
        updatedAt: '2022-01-17T21:08:41.030Z',
        carbon: 0,
        emissionRate: 0,
        duration: 159,
        cost: 0,
        distance: 206
      }
    }
  ]
}

export const fakeCommuteProfilesResult: GetResponse<CommuteProfileProcessed> = {
  records: [
    fakeDriveTransitCommuteProfile,
    fakeDriveCommuteProfile,
    fakeBikeCommuteProfile,
    fakeDriveTransitWithStopInactiveCommuteProfile
  ],
  _metadata: {
    totalCount: 4,
    limit: 10,
    offset: 4
  }
}
/**
 * Make sure that the CPs above are well defined as
 * their data might be reused A LOT
 * ===========================================
 */

/**
 * Single use (data us less important) that are for edit commute
 * tests or any other isolated use case test that need only one CP
 */
export const fakeRemoteCommuteProfile: CommuteProfileProcessed = {
  id: '16cc36bf-37f0-425f-856f-3fd7c1b55680',
  isReturn: false,
  createdAt: '2022-01-17T21:03:17.877Z',
  updatedAt: '2022-01-17T21:03:17.877Z',
  accountId: fakeAccount.id,
  accountVehicleId: undefined,
  timezone: fakeAccountTimezone,
  weeklyFrequency: 1,
  days: [DaysValueEnum.Friday],
  leaveOfficeTime: DEFAULT_OFFICE_LEAVING_TIME_HHMM,
  arriveOfficeTime: DEFAULT_OFFICE_ARRIVAL_TIME_HHMM,
  startAddress: fakeAccount.address || '',
  endAddress: fakeAccount.office?.building?.address,
  activities: [],
  legs: [
    {
      mode: ModeName.Remote,
      type: LEG_TYPES.leg,
      category: ModeName.Remote,
      endAddress: fakeAccount.office?.building?.address,
      endLocation: fakeAccount.office?.building?.location,
      startAddress: fakeAccount.address || '',
      startLocation: fakeAccount.location
    }
  ],
  commuteCosts: [],
  orderedModes: [ModeName.Remote],
  scores: {
    id: faker.datatype.uuid(),
    carbon: 100,
    time: 100,
    cost: 100,
    global: 100
  },
  metrics: {
    daily: {
      id: faker.datatype.uuid(),
      carbon: 0,
      duration: 0,
      cost: 0,
      distance: 0
    },
    monthly: {
      id: faker.datatype.uuid(),
      distance: 0,
      duration: 0,
      carbon: 0,
      cost: 0
    }
  },
  goingLegs: [
    {
      id: '7e9b372a-9ec1-47b9-9546-1839ace076ce',
      createdAt: '2022-01-17T21:03:23.994Z',
      updatedAt: '2022-01-17T21:03:23.994Z',
      externalPlanId: null,
      statisticalPlanId: null,
      order: 0,
      removeFromGroupId: false,
      routeId: '52cc3d32-4970-4ee7-9048-f2e70792fca2',
      serviceId: null,
      metricsId: '4fa8c924-a28e-4525-8cfb-c9151bb8ebe3',
      planId: null,
      accountVehicleId: null,
      startAddress: fakeAccount.address || '',
      endAddress: fakeAccount.office?.building?.address || '',
      activity: undefined,
      peopleCount: 1,
      name: null,
      displayColor: undefined,
      departureTime: '2022-01-28T16:00:00.000Z',
      arrivalTime: '2022-01-28T16:00:00.000Z',
      timezone: fakeAccountTimezone,
      transferTime: 0,
      type: LEG_TYPES.leg,
      polyline: undefined,
      mode: ModeName.Remote,
      startLocation: fakeAccount.location,
      endLocation: fakeAccount.office?.building?.location || {
        lat: parseFloat(faker.address.latitude()),
        lng: parseFloat(faker.address.longitude())
      },
      metrics: {
        id: '4fa8c924-a28e-4525-8cfb-c9151bb8ebe3',
        createdAt: '2022-01-17T21:03:23.936Z',
        updatedAt: '2022-01-17T21:03:23.936Z',
        carbon: 0,
        emissionRate: 0,
        duration: 0,
        cost: 0,
        distance: 0
      }
    }
  ],
  returnLegs: [
    {
      id: 'c2f0558b-0df4-45bf-b372-7d8071271dc1',
      createdAt: '2022-01-17T21:03:23.994Z',
      updatedAt: '2022-01-17T21:03:23.994Z',
      externalPlanId: null,
      statisticalPlanId: null,
      order: 0,
      removeFromGroupId: false,
      routeId: '645a4ce7-611a-4c08-8b09-2aab64cafa2d',
      serviceId: null,
      metricsId: '093d836b-1cd3-4237-9631-5a5302717be9',
      planId: null,
      accountVehicleId: null,
      startAddress: fakeAccount.office?.building?.address || '',
      endAddress: fakeAccount.address || '',
      activity: undefined,
      peopleCount: 1,
      name: null,
      displayColor: undefined,
      departureTime: '2022-01-29T00:00:00.000Z',
      arrivalTime: '2022-01-29T00:00:00.000Z',
      timezone: fakeAccountTimezone,
      transferTime: 0,
      type: LEG_TYPES.leg,
      polyline: undefined,
      mode: ModeName.Remote,
      startLocation: fakeAccount.office?.building?.location || {
        lat: parseFloat(faker.address.latitude()),
        lng: parseFloat(faker.address.longitude())
      },
      endLocation: fakeAccount.location,
      metrics: {
        id: '093d836b-1cd3-4237-9631-5a5302717be9',
        createdAt: '2022-01-17T21:03:23.936Z',
        updatedAt: '2022-01-17T21:03:23.936Z',
        carbon: 0,
        emissionRate: 0,
        duration: 0,
        cost: 0,
        distance: 0
      }
    }
  ]
}

export const fakeBikeshareShuttleCommuteProfile: CommuteProfileProcessed = {
  id: 'c7527849-c883-4201-b6a4-2c3561a79398',
  isReturn: false,
  createdAt: '2020-12-18T17:03:34.257Z',
  updatedAt: '2020-12-18T17:03:34.257Z',
  accountId: 'c5bc8747-2b47-4ca3-898b-2f0393ba4b97',
  vehicleId: null,
  timezone: 'America/Los_Angeles',
  weeklyFrequency: 3,
  days: [DaysValueEnum.Monday, DaysValueEnum.Tuesday, DaysValueEnum.Wednesday],
  leaveOfficeTime: DEFAULT_OFFICE_LEAVING_TIME_HHMM,
  arriveOfficeTime: DEFAULT_OFFICE_ARRIVAL_TIME_HHMM,
  startAddress: '2516 Mission St, San Francisco, CA 94110, USA',
  endAddress: '400 Concar Dr, San Mateo, CA 94402, USA',
  activities: null,
  legs: [
    {
      mode: ModeName.Bikeshare,
      type: LEG_TYPES.leg,
      endAddress: '105 Arroyo Dr, South San Francisco, CA 94080, USA',
      endLocation: {
        lat: 37.6544316,
        lng: -122.4382749
      },
      startAddress: '2516 Mission St, San Francisco, CA 94110, USA',
      startLocation: {
        lat: 37.7566647,
        lng: -122.4190863
      }
    },
    {
      mode: ModeName.Shuttle,
      type: 'leg',
      endAddress: '400 Concar Dr, San Mateo, CA 94402, USA',
      endLocation: {
        lat: 37.5532213,
        lng: -122.3074963
      },
      startAddress: '105 Arroyo Dr, South San Francisco, CA 94080, USA',
      startLocation: {
        lat: 37.6544316,
        lng: -122.4382749
      }
    }
  ],
  commuteCosts: [
    {
      id: '982f8f69-aa65-4f1f-82ab-eed23d0b24c0',
      createdAt: '2020-12-18T17:03:33.483Z',
      updatedAt: '2020-12-18T17:03:33.483Z',
      cost: 0,
      paymentFrequency: 'monthly',
      mode: ModeName.Shuttle,
      category: undefined,
      stop: undefined
    },
    {
      id: 'd54b44e0-1f96-4682-9749-04013b9f0290',
      createdAt: '2020-12-18T17:03:33.483Z',
      updatedAt: '2020-12-18T17:03:33.483Z',
      cost: 22,
      paymentFrequency: 'monthly',
      mode: ModeName.Bikeshare,
      category: undefined,
      stop: undefined
    },
    // To make sure we only handle the commute costs related to the actual CP
    {
      id: 'd54b44e0-1f96-4682-9749-04013b9f0280',
      createdAt: '2020-12-18T17:03:33.483Z',
      updatedAt: '2020-12-18T17:03:33.483Z',
      cost: 5,
      paymentFrequency: 'monthly',
      mode: ModeName.CarpoolPassenger,
      category: undefined,
      stop: undefined
    }
  ],
  orderedModes: [ModeName.Shuttle, ModeName.Bikeshare],
  scores: {
    id: faker.datatype.uuid(),
    carbon: 72.67555,
    time: -11.133165,
    cost: 87.3846,
    global: 49.466300000000004
  },
  metrics: {
    daily: {
      id: faker.datatype.uuid(),
      carbon: 2.49,
      duration: 4716.5,
      cost: 0.85,
      distance: 40528
    },
    monthly: {
      id: faker.datatype.uuid(),
      distance: 40528 * 30,
      duration: 4716.5 * 30,
      carbon: 32.36,
      cost: 11
    }
  },
  goingLegs: [
    {
      id: '560761f2-e998-4318-9216-2a34c6593e04',
      createdAt: '2020-12-18T17:03:44.897Z',
      updatedAt: '2020-12-18T17:03:44.897Z',
      order: 1,
      removeFromGroupId: false,
      routeId: '84807ada-121e-4da7-9977-8bd1ca15552e',
      serviceId: null,
      metricsId: '89e0bd94-3c1c-40f5-ada2-d961ed391b45',
      planId: null,
      accountVehicleId: null,
      startAddress: '105 Arroyo Dr, South San Francisco, CA 94080, USA',
      endAddress: '400 Concar Dr, San Mateo, CA 94402, USA',
      activity: undefined,
      peopleCount: 1,
      name: null,
      displayColor: undefined,
      departureTime: '2020-12-21T16:44:22.000Z',
      arrivalTime: '2020-12-21T17:00:00.000Z',
      timezone: 'America/Los_Angeles',
      transferTime: 0,
      type: 'leg',
      polyline:
        '_mydF`whjVQi@Ua@[c@p@{Bf@{ARUJCL?HDFHHNH~@VvCN|APjA\\lA\\~@f@~@l@z@~@~@pAv@nH|CdDxA^T^Xt@v@n@~@f@`ANb@z@vCbBhG`@nATh@`AhBvC|EzBnDLT`@]vBgBrAkAnAsAv@gAfAiBrAoCtB{DzBgDj@u@hByBbBwAvC_CRC\\B\\LNNTTRPf@`@`@G`@]FQX_@x@eAvA_BXYzAiANQDQ@GnCqAbBo@vJoDnAi@jCyA`D_ChCgC|@iA`EsF|@qAHKt@c@p@{@rBaCv@}@dAeAbBeB`@e@h@cAb@yALu@BiAE}@OiAMk@IYQa@o@iA}@oAeA}Ao@_AUe@G[iBmE]w@?AsBaGyBmGQe@u@uB{DsJ[w@iA{CeAwDc@qAuBsGSq@]{AUmBYgBOaCAuA?WAy@?a@B_BHiB\\sFFyAJKDKTsCTkD@_B?s@EwBQoCUuDCgA@w@@MBs@RuAb@aBj@qARYr@{@FGXWx@e@dA]~@Qh@CpAKx@GzGg@vFc@zCa@rAW~A_@xDmAvBm@LILS`Ck@~VeGfMyCtGyAzEcBzAs@bB_AlCgBjC{BfBgBjB}B|@iAz@sAhG_LhG{KzK{RlUqa@pKgRzMyU~LsTbW}c@pPwYzDiHv@gBlAeDl@oBf@qBp@cDb@oCf@iEX{DFkBFkDXa_@Z}`@d@sm@H{DPoCXoBn@yC`AqCfA}BtAuBfE{EzIyJvGoHxGmHlVmXdXoZtSmUdS{ThZu\\hEkEr@q@`A{@ZAZM`BkApA}@pAgAVEPQbEuC~HqFj@UVGb@CV?^Df@Nh@X`@`@l@bAHXR`Aj@jEHlCHnADrDFzKE\\?t@A\\ItCE~@Kf@QZc@\\WLULGNeAn@`AhB',
      mode: ModeName.Shuttle,
      startLocation: {
        lat: 37.6544316,
        lng: -122.4382749
      },
      endLocation: {
        lat: 37.5532213,
        lng: -122.3074963
      },
      metrics: {
        id: '89e0bd94-3c1c-40f5-ada2-d961ed391b45',
        createdAt: '2020-12-18T17:03:44.083Z',
        updatedAt: '2020-12-18T17:03:47.511Z',
        carbon: 2.19382,
        emissionRate: 0.101,
        duration: 938,
        cost: 0,
        distance: 21721
      }
    },
    {
      id: '9f128989-a9f8-42de-ad10-bf708c7ffb7d',
      createdAt: '2020-12-18T17:03:44.897Z',
      updatedAt: '2020-12-18T17:03:44.897Z',
      order: 0,
      removeFromGroupId: false,
      routeId: '84807ada-121e-4da7-9977-8bd1ca15552e',
      serviceId: null,
      metricsId: 'b836cfa8-0fb4-471f-ac25-2fbf4c3ead96',
      planId: null,
      accountVehicleId: null,
      startAddress: '2516 Mission St, San Francisco, CA 94110, USA',
      endAddress: '105 Arroyo Dr, South San Francisco, CA 94080, USA',
      activity: undefined,
      peopleCount: 1,
      name: null,
      displayColor: undefined,
      departureTime: '2020-12-21T15:43:54.000Z',
      arrivalTime: '2020-12-21T16:44:22.000Z',
      timezone: 'America/Los_Angeles',
      transferTime: 0,
      type: 'leg',
      polyline:
        'gjmeF`}djVvFUHrDNbG~@CzBKlFW~H_@jGWzKi@?rBJzFBbA@TXCp@E`Ja@|BKr@JxCv@lKrCrBl@t@Vn@RtBp@fCf@`BVxB^v@Vt@\\h@XhAv@`@^jAnAjB|BxB~CbA~A`A`BhBpDRb@h@xA?L?PFPdB`FT|@Hx@Vz@Z|ANj@Pd@RF@MBYDQFUFURMZYHGj@a@RIVGvB[ZK~CeAj@SDVPz@P~@VnAZrAf@rAp@nAV^n@n@pBbBnFrEhAj@tAn@nJfEj@Zp@Zz@TTB~ATfA`@~CbBdDdBzDtBlAn@`Ar@t@z@vC~DbGlIxFxH~AxB|@bBvDjKzEfN\\~@j@jAXh@t@jAxEvGpA|Bj@pAj@|Ab@vAVnADTJSp@_ANSDKDQhA{AbCkDj@u@FOHKfDyAr@YV~@v@dDHZz@~BNf@~BrJxDlPfA|Eb@f@b@j@Rb@^`AFd@HrA?r@@~@vBVtCZ~BEzKUvOY|O[dEMxAMnKeA|Gs@bCa@vCs@rFkAd@SzA}@rEmCh@_@rE}EtA}AnBoB`BeBV]l@oAlDgIjEqJdC{FtG_OzFgMzGiOhKuUvDsIzAiDRQl@wAj@uA\\XTRX\\p@bAhCvDfHlKxDxFtFdItAtBxAfCl@v@jAtAlBvBZZ^ZXNj@Rx@PnAPxALJCh@Dd@@`BI|@Ij@BZFt@^d@`@^v@ZfAf@KPKNSVa@v@|@DBH?HCf@y@HS?CLBPTf@b@l@{Ap@d@b@b@V\\HZ`A_CfD{Il@cB`GiRfBwFb@kA`@a@XMCeCI{@',
      mode: ModeName.Bikeshare,
      startLocation: {
        lat: 37.7566647,
        lng: -122.4190863
      },
      endLocation: {
        lat: 37.6544316,
        lng: -122.4382749
      },
      metrics: {
        id: 'b836cfa8-0fb4-471f-ac25-2fbf4c3ead96',
        createdAt: '2020-12-18T17:03:44.083Z',
        updatedAt: '2020-12-18T17:03:47.511Z',
        carbon: 0,
        emissionRate: 0,
        duration: 3628,
        cost: 0.846154,
        distance: 15826
      }
    }
  ]
}

export const fakeSharedScooterCarpoolPassengerCommuteProfile: CommuteProfileProcessed = {
  id: 'ded524ee-f930-4e8c-999c-aa0ebfacd2b3',
  isReturn: false,
  createdAt: '2020-12-18T17:03:33.832Z',
  updatedAt: '2020-12-18T17:03:33.832Z',
  accountId: 'c5bc8747-2b47-4ca3-898b-2f0393ba4b97',
  vehicleId: null,
  timezone: 'America/Los_Angeles',
  weeklyFrequency: 2,
  days: [DaysValueEnum.Friday, DaysValueEnum.Thursday],
  leaveOfficeTime: DEFAULT_OFFICE_LEAVING_TIME_HHMM,
  arriveOfficeTime: DEFAULT_OFFICE_ARRIVAL_TIME_HHMM,
  startAddress: '2516 Mission St, San Francisco, CA 94110, USA',
  endAddress: '400 Concar Dr, San Mateo, CA 94402, USA',
  activities: null,
  legs: [
    {
      mode: ModeName.SharedScooter,
      type: 'leg',
      endAddress: '400 Concar Dr, San Mateo, CA 94402, USA',
      endLocation: {
        lat: 37.5532213,
        lng: -122.3074963
      },
      startAddress: '2516 Mission St, San Francisco, CA 94110, USA',
      startLocation: {
        lat: 37.7566647,
        lng: -122.4190863
      }
    },
    {
      mode: ModeName.CarpoolPassenger,
      type: 'leg',
      endAddress: '400 Concar Dr, San Mateo, CA 94402, USA',
      endLocation: {
        lat: 37.5532213,
        lng: -122.3074963
      },
      startAddress: '11 Junipero Serra Blvd, San Francisco, CA 94127, USA',
      startLocation: {
        lat: 37.7345574,
        lng: -122.4709583
      }
    }
  ],
  commuteCosts: [
    {
      id: '1f251241-61bf-471c-a42d-730908c37739',
      createdAt: '2020-12-18T17:03:33.483Z',
      updatedAt: '2020-12-18T17:03:33.483Z',
      cost: 35,
      paymentFrequency: 'monthly',
      mode: ModeName.SharedScooter,
      category: undefined,
      stop: undefined
    },
    {
      id: '63e33721-3c74-4090-8522-ca7bb723735a',
      createdAt: '2020-12-18T17:03:33.482Z',
      updatedAt: '2020-12-18T17:03:33.482Z',
      cost: 40,
      paymentFrequency: 'monthly',
      mode: ModeName.CarpoolPassenger,
      category: undefined,
      stop: undefined
    }
  ],
  orderedModes: [ModeName.SharedScooter, ModeName.CarpoolPassenger],
  scores: {
    id: faker.datatype.uuid(),
    carbon: 58.2442,
    time: -95.13595,
    cost: 56.0577,
    global: 6.2516300000000005
  },
  metrics: {
    daily: {
      id: faker.datatype.uuid(),
      carbon: 3.9,
      duration: 8123,
      cost: 4.33,
      distance: 63315.5
    },
    monthly: {
      id: faker.datatype.uuid(),
      distance: 63315.5 * 30,
      duration: 8123 * 30,
      carbon: 33.76,
      cost: 37.5
    }
  },
  goingLegs: [
    {
      id: '4430711f-9e83-4273-923f-65b9fa744d4a',
      createdAt: '2020-12-18T17:03:44.495Z',
      updatedAt: '2020-12-18T17:03:44.495Z',
      order: 0,
      removeFromGroupId: false,
      routeId: 'f4129792-29a7-475e-a3c9-622d57bce1d1',
      serviceId: null,
      metricsId: 'e77e22a6-7f04-4c65-822b-d6644a4543d5',
      planId: null,
      accountVehicleId: null,
      startAddress: '2516 Mission St, San Francisco, CA 94110, USA',
      endAddress: '400 Concar Dr, San Mateo, CA 94402, USA',
      activity: undefined,
      peopleCount: 1,
      name: null,
      displayColor: undefined,
      departureTime: '2020-12-24T14:45:30.000Z',
      arrivalTime: '2020-12-24T16:38:48.000Z',
      timezone: 'America/Los_Angeles',
      transferTime: 0,
      type: 'leg',
      polyline:
        'gjmeF`}djVvFUHrDNbG~@CzBKlFW~H_@fTaANrL@TXCp@E`Ja@|BKr@JxCv@lKrCnIjChF~@xB^lBt@rBpAlBnBdF|GbA~AjDrGRb@h@fBFb@zB~GHx@r@xCNj@Pd@RF@MBYDQFUZc@d@a@j@a@j@QrCg@~CeAj@SDVPz@P~@VnAZrAf@rAhAnB`DrCnFrE~CzAnJfE|Av@z@TtBXfA`@~CbBdDdBzDtBlAn@`Ar@lEzF`CfDr@`APVNOx@cAv@}@`@a@^UbB{@PSh@}ARm@xEeNxCuIjDwJd@}ANwAb@iDTiBEUb@mDz@kHn@sCf@{Av@sBzA_Fx@oDbAcC~AsB`AsA|@aBz@mDnCgO|CmP\\oBNINw@VsA`@wB^qB^kB|B{LEOb@wCvBmLvAqHV{A`AZfCdA`Ab@tEnBfB`@`CLhBEpB[tEcBxIkDb\\kMpCiArIsD|BqAvBaBlAmA~CcEfMoQlFaHfGkF`BiA|CyAnBc@tCe@FIp@KrAUt@QHHpAc@|Au@|AaAlC{BxDuDlE_EbBi@xBCx@Pl@VdD`B`KnF`C~AzCtCfEbF~@fBp@|@vBbBrA~@xCjCnCbDbAhAhCfD`DpFfC|DjIpNxA~A~ArAvBrAxFpCbRtHnOjG|Bj@nADfAClCs@zBuAdAs@_@oBe@sCCu@LcAj@_BRe@PDVBjD?`@APQ`AARRd@?jDCv@ErAWrAm@pDgBbAk@d@i@fHmDb@ItEaCbP_IrOyH|FuC`IgDnA]P@d@Xt@`D~@`Fd@`Ax@p@xGvDzAf@v@Ff@A~B[jE_@nFi@tCi@bM}Cf@MXDdAUlJyBxC{@pJiChCq@hCw@|Aq@t@U^Wd@_AHsABwDBkHJw@\\w@XWlCw@~Bs@d@@z@Ej@Xb@b@dAdAr@Rf@Bv@Q\\[Zc@nDsGdFsId@uAtHsNfCiE~EyGbE_H~DiHPkAIeCEwBMyBOeCe@_BwAaCIy@`@_BfBeFxCyIzFuP`AyBbDiFzDkFPi@j@u@`AmApAwAbCiBrE_CdIeDrHyCxB}@^G~Bu@j@]d@o@z@mBpCmHh@yAHm@LCLKTA|@T\\@vA]h@Jp@ZX\\n@`CHb@QFKKAqAXi@L[@AF@NDCPp@^d@^bDnCpH{UzJm[xS_p@pBmGXy@Zg@tByGHW}@wAcGwJiG{JgCcE|F}GlAuA|DoEfCuCnC}ClLmMfScUng@kk@tOeQd^_a@rJuKv@}@t@lAvCzEnAUHCl@_@f@QZYxAqAnBe@`CsBlCkC_B}C',
      mode: ModeName.SharedScooter,
      startLocation: {
        lat: 37.7566647,
        lng: -122.4190863
      },
      endLocation: {
        lat: 37.5532213,
        lng: -122.3074963
      },
      metrics: {
        id: 'e77e22a6-7f04-4c65-822b-d6644a4543d5',
        createdAt: '2020-12-18T17:03:43.643Z',
        updatedAt: '2020-12-18T17:03:47.804Z',
        carbon: 0,
        emissionRate: 0,
        duration: 6798,
        cost: 2.01923,
        distance: 32932
      }
    },
    {
      id: '2928bf92-b724-4ec6-baee-f18d696d22be',
      createdAt: '2020-12-18T17:03:44.495Z',
      updatedAt: '2020-12-18T17:03:44.495Z',
      order: 1,
      removeFromGroupId: false,
      routeId: 'f4129792-29a7-475e-a3c9-622d57bce1d1',
      serviceId: null,
      metricsId: '7f0b5e10-cc6a-4b94-97ae-7725215d8d7f',
      planId: null,
      accountVehicleId: null,
      startAddress: '11 Junipero Serra Blvd, San Francisco, CA 94127, USA',
      endAddress: '400 Concar Dr, San Mateo, CA 94402, USA',
      activity: undefined,
      peopleCount: 2,
      name: null,
      displayColor: undefined,
      departureTime: '2020-12-24T16:38:48.000Z',
      arrivalTime: '2020-12-24T17:00:00.000Z',
      timezone: 'America/Los_Angeles',
      transferTime: 0,
      type: 'leg',
      polyline:
        'y`ieFlbojVU??V?dAEZBBVTTDvCLdO\\nA?jELrHN~DJ`B@nKRjBDbTd@dNZpDDf@@t@Bv@Gz@OdBc@|GkBvCo@dC_@|AOf@ClDMhACtHA|DDjBNbAF|Jl@hFZvDLvC?tKObDCLDTR~AIbEWzBQjAK~@QbDy@tEgAhAQbAIrDEzFCtKCpB@x@DtBTpDz@lFfAfBTbBLnA@~HL~DJbCBvFFvCDhCDlA?jBKdAMpAYzAe@|@a@`B{@xFgEzBeBjEeDzCqBlEiCbEkB|DmAbDs@vC]vAMp@?x@A`@?rHCpLC`CGfAGhASdBe@|Am@tCgBfAw@d@i@jAyAtOgTfNqR~McRTYtAqAz@s@pA}@hEyBhNgHlGeDr@e@|@u@z@y@~AoBnAsBbByDrCeGhA}BhAiBp@aAnA}AlCiC`GsFjE{D~BmBpBoAzCuAtHqC~D{AjBaAzBuAzAiArBiBtA_B^g@~DsFz@mAt@c@X]x@cArA}At@{@dBeB~AeB`@q@Xq@H[XsA@W?_BQ_BOo@Me@Uc@q@eAgD_FUe@G[yBmFSg@gFaOgA{CeEeKe@mAu@sBu@mCMg@Oc@Yy@uBqG]uAa@eC]_COaC?c@AiA?AAiA?gAFyALiB\\{GNQBMXuDNgC@yAAsBIoBOeCSkD@}A?UJuAToAb@uAt@qARYh@i@r@i@DCz@_@VKhASzBOpBO~Hk@pAKvC[zB_@fB_@vA_@pDkAlA_@JKFKp@O`EcAlV}FjKeCpEeA^GZMfDkAbAa@nAo@`B_AdAs@hAy@jC_CjBqBlBeCt@aAj@_A~IkPrUua@vRo]hRo\\pQw[dHaMlc@sv@bCuEnA}Cx@aCrAmFd@aCl@eEZ}CXsEHgCDqE`@{h@FeHT{YR_XDuFLqDNoB^gCr@sCdAqCjA_C~AuBjRcTjG_HnHgIrp@}t@ty@w~@lE{EpDqD|AwAd@EbAo@pBuAb@_@|@u@PCNKTQrGqEjFqDj@SXEv@A^FVHb@RZV`@b@PZVl@H\\T`B`@bDH|CFl@@vADtCDvIEf@CtBIfCEv@K`@SZe@ZYLQRk@^]THNv@xA',
      mode: ModeName.CarpoolPassenger,
      startLocation: {
        lat: 37.7345574,
        lng: -122.4709583
      },
      endLocation: {
        lat: 37.5532213,
        lng: -122.3074963
      },
      metrics: {
        id: '7f0b5e10-cc6a-4b94-97ae-7725215d8d7f',
        createdAt: '2020-12-18T17:03:43.643Z',
        updatedAt: '2020-12-18T17:03:47.804Z',
        carbon: 3.84959,
        emissionRate: 0.1255,
        duration: 1272,
        cost: 2.30769,
        distance: 30674
      }
    }
  ]
}

export const fakeVanpoolCommuteProfile: CommuteProfileProcessed = {
  id: 'd782b480-021b-40af-a23b-a5c2b2f93f45',
  isReturn: false,
  createdAt: '2020-12-18T17:13:34.106Z',
  updatedAt: '2020-12-18T17:13:34.106Z',
  accountId: '42e925f2-c7b9-425c-bcda-c0e8b0ba1a6e',
  vehicleId: null,
  timezone: 'America/Toronto',
  weeklyFrequency: 2,
  days: [DaysValueEnum.Monday, DaysValueEnum.Tuesday],
  leaveOfficeTime: DEFAULT_OFFICE_LEAVING_TIME_HHMM,
  arriveOfficeTime: DEFAULT_OFFICE_ARRIVAL_TIME_HHMM,
  startAddress: '11 Rue Wellington, Montréal, QC H3K, Canada',
  endAddress: '1275 Avenue des Canadiens-de-Montréal, Montréal, QC H3B 5E6, Canada',
  activities: null,
  legs: [
    {
      mode: ModeName.Vanpool,
      type: 'leg',
      endAddress: '1275 Avenue des Canadiens-de-Montréal, Montréal, QC H3B 5E6, Canada',
      endLocation: {
        lat: 45.4964397,
        lng: -73.5706624
      },
      peopleCount: 8,
      startAddress: '11 Rue Wellington, Montréal, QC H3K, Canada',
      startLocation: {
        lat: 45.48843110000001,
        lng: -73.55711000000001
      }
    }
  ],
  commuteCosts: [],
  vehicle: undefined,
  orderedModes: [ModeName.Vanpool],
  scores: {
    id: faker.datatype.uuid(),
    carbon: 99.1747,
    time: 100,
    cost: 100,
    global: 100
  },
  metrics: {
    daily: {
      id: faker.datatype.uuid(),
      carbon: 0.1,
      duration: 453.5,
      cost: 0,
      distance: 1744.5
    },
    monthly: {
      id: faker.datatype.uuid(),
      distance: 1744.5 * 30,
      duration: 453.5 * 30,
      carbon: 0.85,
      cost: 0
    }
  },
  goingLegs: [
    {
      id: 'ed3b2653-3849-4298-bf80-3af9c094f866',
      createdAt: '2020-12-18T17:13:42.797Z',
      updatedAt: '2020-12-18T17:13:42.797Z',
      order: 0,
      removeFromGroupId: false,
      routeId: 'bbf924c7-17a9-4048-b5c5-6bfb8dea5cc5',
      serviceId: null,
      metricsId: '51341c52-f98d-446c-bdb5-96beb046dee5',
      planId: null,
      accountVehicleId: null,
      startAddress: '11 Rue Wellington, Montréal, QC H3K, Canada',
      endAddress: '1275 Avenue des Canadiens-de-Montréal, Montréal, QC H3B 5E6, Canada',
      activity: undefined,
      peopleCount: 8,
      name: null,
      displayColor: undefined,
      departureTime: '2020-12-21T13:51:56.000Z',
      arrivalTime: '2020-12-21T14:00:00.000Z',
      timezone: 'America/Toronto',
      transferTime: 0,
      type: 'leg',
      polyline:
        'umstG|r}_MiBQ[@c@DuB`@sB`@oAZQ?WJYTm@l@_@TYH_@F_@@OCKCcAk@o@a@IXq@`CcC`JkB~GzA|@PNfC`BtC`BEj@Gl@Kj@g@zA_B|CiE~KYd@_@bA_A|BAViAxCc@pAoDjJiAkA',
      mode: ModeName.Vanpool,
      startLocation: {
        lat: 45.48843110000001,
        lng: -73.55711000000001
      },
      endLocation: {
        lat: 45.4964397,
        lng: -73.5706624
      },
      metrics: {
        id: '51341c52-f98d-446c-bdb5-96beb046dee5',
        createdAt: '2020-12-18T17:13:41.971Z',
        updatedAt: '2020-12-18T17:13:44.350Z',
        carbon: 0.108916,
        emissionRate: 0.056375,
        duration: 484,
        cost: 0,
        distance: 1932
      }
    }
  ]
}

export const fakeCarshareCommuteProfile: CommuteProfileProcessed = {
  id: 'cfa1bb04-d929-4787-9c24-274833f5acdd',
  isReturn: false,
  createdAt: '2020-12-18T17:13:34.008Z',
  updatedAt: '2020-12-18T17:13:34.008Z',
  accountId: '42e925f2-c7b9-425c-bcda-c0e8b0ba1a6e',
  vehicleId: '07956d11-e131-49d0-83a3-952f9adcc2b9',
  timezone: 'America/Toronto',
  weeklyFrequency: 3,
  days: [DaysValueEnum.Wednesday, DaysValueEnum.Thursday, DaysValueEnum.Friday],
  leaveOfficeTime: DEFAULT_OFFICE_LEAVING_TIME_HHMM,
  arriveOfficeTime: DEFAULT_OFFICE_ARRIVAL_TIME_HHMM,
  startAddress: '11 Rue Wellington, Montréal, QC H3K, Canada',
  endAddress: '1275 Avenue des Canadiens-de-Montréal, Montréal, QC H3B 5E6, Canada',
  activities: null,
  legs: [
    {
      mode: ModeName.Carshare,
      type: 'leg',
      endAddress: '1275 Avenue des Canadiens-de-Montréal, Montréal, QC H3B 5E6, Canada',
      endLocation: {
        lat: 45.4964397,
        lng: -73.5706624
      },
      startAddress: '11 Rue Wellington, Montréal, QC H3K, Canada',
      startLocation: {
        lat: 45.48843110000001,
        lng: -73.55711000000001
      }
    }
  ],
  commuteCosts: [
    {
      id: '7c3eafff-4b25-4112-8bd6-2c1f811ee137',
      createdAt: '2020-12-18T17:13:33.574Z',
      updatedAt: '2020-12-18T17:13:33.574Z',
      cost: 100,
      paymentFrequency: 'monthly',
      mode: ModeName.Carshare,
      category: undefined,
      stop: undefined
    }
  ],
  vehicle: {
    id: '07956d11-e131-49d0-83a3-952f9adcc2b9',
    make: 'Aston Martin',
    model: 'Vantage',
    classification: 'Grand tourer',
    year: 2019,
    uuid: '07956d11-e131-49d0-83a3-952f9adcc2b9',
    engineType: 'Gas',
    combinedMpg: 21,
    maxMpg: 25,
    minMpg: 18,
    price: 149995,
    name: 'Vantage'
  },
  orderedModes: [ModeName.Carshare],
  scores: {
    id: faker.datatype.uuid(),
    carbon: 78.1554,
    time: 100,
    cost: 60.3846,
    global: 79.81335
  },
  metrics: {
    daily: {
      id: faker.datatype.uuid(),

      carbon: 0.05,
      duration: 509,
      cost: 3.85,
      distance: 1744.5
    },
    monthly: {
      id: faker.datatype.uuid(),
      distance: 1744.5 * 30,
      duration: 509 * 30,
      carbon: 5.89,
      cost: 50
    }
  },
  goingLegs: [
    {
      id: 'e3cb8bed-021a-46c9-9937-781b31c3ded8',
      createdAt: '2020-12-18T17:13:42.597Z',
      updatedAt: '2020-12-18T17:13:42.597Z',
      order: 0,
      removeFromGroupId: false,
      routeId: 'e351264b-fe96-4def-83bd-d30288d30696',
      serviceId: null,
      metricsId: '741d8edb-32e1-40ad-9a6a-9e263cd66505',
      planId: null,
      accountVehicleId: '07956d11-e131-49d0-83a3-952f9adcc2b9',
      startAddress: '11 Rue Wellington, Montréal, QC H3K, Canada',
      endAddress: '1275 Avenue des Canadiens-de-Montréal, Montréal, QC H3B 5E6, Canada',
      activity: undefined,
      peopleCount: 1,
      name: null,
      displayColor: undefined,
      departureTime: '2020-12-23T13:51:05.000Z',
      arrivalTime: '2020-12-23T14:00:00.000Z',
      timezone: 'America/Toronto',
      transferTime: 0,
      type: 'leg',
      polyline:
        'umstG|r}_MiBQ[@c@DuB`@sB`@oAZQ?WJYTm@l@_@TYH_@F_@@OCKCcAk@o@a@IXq@`CcC`JkB~GzA|@PNfC`BtC`BEj@Gl@Kj@g@zA_B|CiE~KYd@_@bA_A|BAViAxCc@pAoDjJiAkA',
      mode: ModeName.Carshare,
      startLocation: {
        lat: 45.48843110000001,
        lng: -73.55711000000001
      },
      endLocation: {
        lat: 45.4964397,
        lng: -73.5706624
      },
      metrics: {
        id: '741d8edb-32e1-40ad-9a6a-9e263cd66505',
        createdAt: '2020-12-18T17:13:41.856Z',
        updatedAt: '2020-12-18T17:13:44.781Z',
        carbon: 0.501919,
        emissionRate: 0.259792,
        duration: 535,
        cost: 3.84615,
        distance: 1932
      }
    }
  ]
}
