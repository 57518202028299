export function put(key: string, value: any) {
  window.sessionStorage.setItem(key, JSON.stringify(value))
}

export function get(key: string) {
  const rawItem = window.sessionStorage.getItem(key)
  try {
    if (!rawItem) {
      return null
    }
    return JSON.parse(rawItem)
  } catch (error) {
    return rawItem
  }
}

export function remove(key: string) {
  return window.sessionStorage.removeItem(key)
}

export function clear() {
  window.sessionStorage.clear()
}

export default {
  put,
  get,
  remove,
  clear
}
