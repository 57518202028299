import { citiesReducer } from './cities/slice'
import { modesReducer } from './modes/slice'
import { officesReducer } from './offices/slice'
import { attributesReducer } from './attributes/slice'
import { unitsReducer } from './units/slice'
import { remoteStatusReducer } from './remoteStatus/slice'
import { combineReducers } from '@reduxjs/toolkit'

export const analyticsFilters = combineReducers({
  modes: modesReducer,
  offices: officesReducer,
  cities: citiesReducer,
  attributes: attributesReducer,
  units: unitsReducer,
  remoteStatus: remoteStatusReducer
})
