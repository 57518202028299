import { DEFAULT_UNITS_BY_MEASURE_TYPE } from '@commutifi-fe/constants'
import { convertToUnit } from '@commutifi-fe/utils'
import { createSelector } from 'reselect'
import { formatMetrics } from 'store/helpers/routes'
import { getLocaleCurrency, getLocaleUnits } from 'store/modules/localSettings/selectors'

export const getMetrics = ({ metrics }) => metrics.route
export const getById = createSelector(getMetrics, (metrics) => metrics.byId)
export const getFetchStatus = createSelector(getMetrics, (metrics) => metrics.fetchStatus)
export const makeGetMetricsFromId = (id) => (id || id === 0 ? createSelector(getById, (byId) => byId[id]) : null)

export const makeGetConvertedMetricsWithFrequency = (metricsIds) => (state) => {
  const metrics = metricsIds.map((mId) => makeGetMetricsFromId(mId)(state))
  const currency = getLocaleCurrency(state)
  const localeUnits = getLocaleUnits(state)
  const convertedMetrics = formatMetrics(...metrics)
  return {
    cost: {
      value: convertedMetrics.cost,
      currency,
      frequency: 'month'
    },
    time: { value: convertedMetrics.time, unit: 's', frequency: 'trip' },
    carbon: {
      value: convertToUnit(`${convertedMetrics.carbon} ${DEFAULT_UNITS_BY_MEASURE_TYPE.mass}`, localeUnits.mass),
      unit: localeUnits.mass,
      frequency: 'month'
    }
  }
}
