import { HttpMethod } from '../../constants'

export const enterpriseCreationEndpoints = {
  [HttpMethod.Get]: {
    EnterprisesRegistration: {
      path: '/enterprises-registrations/:enterpriseRegistrationId',
      route(enterpriseRegistrationId: string): string {
        return this.path.replace(':enterpriseRegistrationId', enterpriseRegistrationId)
      }
    }
  }
}

export default enterpriseCreationEndpoints
