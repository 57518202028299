import { Location } from 'history'
import qs, { IParseOptions, IStringifyOptions } from 'qs'

export enum CommonQueryParams {
  Integration = 'integration',
  Success = 'success',
  Error = 'error'
}

export function parse<T extends Record<string, any>>(q: string | undefined, options?: IParseOptions): T | undefined {
  return q ? (qs.parse(q, { ignoreQueryPrefix: true, ...options }) as T) : undefined
}
export const stringify = (q: string | Record<string, any>, options?: IStringifyOptions) => qs.stringify(q, options)

export const getURLCommonParam = <T = string | number>(
  location: Location | undefined,
  param: CommonQueryParams
): T | undefined => {
  const parsed = parse(location?.search || window.location.search)
  return parsed?.[param]
}

export const getSuccessParam = (location: Location): string | undefined =>
  getURLCommonParam(location, CommonQueryParams.Success)
export const getErrorParam = (location: Location): string | undefined =>
  getURLCommonParam(location, CommonQueryParams.Error)
