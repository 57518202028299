import { Location } from 'history'
import difference from 'lodash/difference'
import isNil from 'lodash/isNil'
import React from 'react'
import { FormattedMessage } from 'locales/index'
import { EntityType } from 'constants/global'
import { DEFAULT_WEEKLY_FREQUENCY } from 'constants/onboarding'
import { Frequency } from 'shared/interfaces'
import { getInitials, isUuid, stringToHslColor } from './helpers/index'
import { parse } from './queryParams'
import { Account } from '@commutifi-fe/interfaces'

export function getArrayValue<T = any>(value: T | T[] | undefined): T[] {
  return !value ? [] : Array.isArray(value) ? value : [value]
}

export const getArrayLastValue = (array: any) => (array.length ? array[array.length - 1] : undefined)

const calculateBrightnessFromRGB = (r: number, g: number, b: number) => (r * 299 + g * 587 + b * 114) / 1000

/**
 * Thanks to https://gist.github.com/w3core/e3d9b5b6d69a3ba8671cc84714cca8a4
 * Calculate brightness value by RGB or HEX color.
 * @param color - (String) The color value in RGB or HEX (for example: #000000 || #000 || rgb(0,0,0) || rgba(0,0,0,0))
 * @returns (Number) The brightness value (dark) 0 ... 255 (light)
 */
export const brightnessByColor = (color: string) => {
  const isHEX = color.startsWith('#')
  const isRGB = color.startsWith('rgb')
  if (isHEX) {
    // eslint-disable-next-line @typescript-eslint/prefer-regexp-exec -- Legacy
    const m = color.substr(1).match(color.length === 7 ? /(\S{2})/g : /(\S{1})/g)
    if (m) {
      const r = parseInt(m[0], 16)
      const g = parseInt(m[1], 16)
      const b = parseInt(m[2], 16)
      return calculateBrightnessFromRGB(r, g, b)
    }
  }
  if (isRGB) {
    const m = color.match(/(\d+){3}/g)
    if (m) {
      const r = parseInt(m[0])
      const g = parseInt(m[1])
      const b = parseInt(m[2])
      return calculateBrightnessFromRGB(r, g, b)
    }
  }
  return null
}

/**
 * Return which theme of color you should apply to an element so it contrast
 * well with the color passed in parameter.
 *
 * default: use the color that you want (default one if there is one)
 * dark: use any color that is in darker shades
 * light: use any color that is in lighter shades
 *
 * @param color - color to analyze and get what type of color you should use
 *                for a text displayed on a background color for example
 * @returns 'dark' | 'light' | 'default'
 */
export const getContrastedColorTheme = (color?: string): 'dark' | 'light' | 'default' => {
  if (!color) {
    return 'default'
  }
  // Based on https://www.w3.org/TR/AERT/#color-contrast a good contrast difference is 125
  const brightness = brightnessByColor(color)
  return brightness && brightness > 125 ? 'dark' : 'light'
}

export function extractUrlSearchParams<T extends Record<string, any>>(
  location?: Location
): T | Record<string, unknown> {
  const urlParams = (location || window.location).search
  const q = parse<T>(urlParams)
  return q || {}
}

type OptionalFrequency = Frequency | undefined
const differenceBetweenDaysFrequency = (
  workFrequency: OptionalFrequency,
  comparedFrequency: OptionalFrequency
): Frequency => {
  const weeklyFrequency =
    (workFrequency?.weeklyFrequency || DEFAULT_WEEKLY_FREQUENCY) - (comparedFrequency?.weeklyFrequency || 0)
  const daysDifference = workFrequency?.days ? difference(workFrequency.days, comparedFrequency?.days || []) : undefined

  return {
    days: daysDifference,
    weeklyFrequency: daysDifference?.length || weeklyFrequency
  }
}
/**
 * This function will be very useful to calculate the remaining available days and weekly frequency
 * of a question that depends on other frequency questions. Usually the first param will be the work frequency
 * as it is our basis to calculate other frequencies, but it is not a must.
 *
 * The work frequency will default to 5 days a week if it is not set when we need that value to calculate the available
 * frequency.
 *
 * @param workFrequency -           [[Frequency]] object representing the user commute profile frequency
 * @param comparedFrequencies -     Single (object) or multiple (array) [[Frequency]] to remove from the
 *                                 source frequency a.k.a. workFrequency. They can be undefined as they will be filtered and not considered
 * @param weeklyFrequencyReserved - number of days to reserve for inferred values like the primary route frequency
 *                                 (Primary = Work - Remote - Secondary = we need to keep an extra day for the primary route to exist)
 */
export const calculateAvailableFrequency = (
  workFrequency: OptionalFrequency,
  comparedFrequencies: OptionalFrequency | OptionalFrequency[],
  weeklyFrequencyReserved = 0
) => {
  const validFrequenciesToCompare = getArrayValue<OptionalFrequency>(comparedFrequencies).filter(
    (freq) => !isNil(freq)
  ) as Frequency[]
  const fallbackFrequency = {
    days: workFrequency?.days,
    weeklyFrequency: workFrequency?.weeklyFrequency || DEFAULT_WEEKLY_FREQUENCY
  }
  const frequencyDiff: Frequency =
    validFrequenciesToCompare.length > 0
      ? validFrequenciesToCompare.reduce(
          (frequency: Frequency, comparedFrequency: Frequency | undefined) =>
            differenceBetweenDaysFrequency(frequency, comparedFrequency),
          fallbackFrequency
        )
      : fallbackFrequency

  // The user cannot take his secondary commute more often that:
  // work frequency - remote frequency - 1 (keep at least one day for the primary route)
  // If the work frequency is not defined yet, we assume 5 days a week
  return {
    ...frequencyDiff,
    weeklyFrequency: Math.max(frequencyDiff.weeklyFrequency - weeklyFrequencyReserved, 0)
  }
}

export const isConsecutiveNumbers = (numbers: (number | string)[] | undefined) => {
  if (!numbers) {
    return false
  }
  if (numbers.length <= 1) {
    return true
  }
  const differenceArray = numbers.slice(1).map((number, index) => {
    const currentNb = typeof number === 'string' ? parseInt(number) : number
    const nextNb = numbers[index]
    const comparedNb = typeof nextNb === 'string' ? parseInt(nextNb) : nextNb
    return currentNb - comparedNb
  })
  return differenceArray.every((value) => value === 1)
}

/**
 * Merge react components together with custom nodes and custom separator
 * using render and renderSeparator callbacks
 * @param children - Array of react element to join
 * @param render - Callback function to display each element
 * @param renderSeparator - Callback function to display the separator between each element
 */
export function joinChildren<T>(
  children: T[],
  render: (child: T, index: number) => JSX.Element | string,
  renderSeparator: (index: number) => JSX.Element | string
) {
  return children.reduce((result: JSX.Element[], child: T, index: number) => {
    if (index < children.length - 1) {
      return [...result, ...[render(child, index), renderSeparator(index)]]
    }

    return [...result, render(child, index)]
  }, [])
}

export const getCommutifiErrorCode = (error: any): number | undefined => {
  if (Object.prototype.hasOwnProperty.call(error, 'error')) {
    return error.error?.data?.code
      ? typeof error.error?.data?.code === 'string'
        ? parseInt(error.error?.data.code)
        : error.error?.data.code
      : undefined
  }
  if (Object.prototype.hasOwnProperty.call(error, 'data')) {
    return error?.data?.code
      ? typeof error.data?.code === 'string'
        ? parseInt(error.data.code)
        : error.data?.code
      : undefined
  }
  if (Object.prototype.hasOwnProperty.call(error, 'response')) {
    return error?.response?.data?.code
      ? typeof error?.response?.data?.code === 'string'
        ? parseInt(error?.response?.data?.code)
        : error?.response?.data?.code
      : undefined
  }
  return error.code ? parseInt(error.code) : error.code
}

export const getHttpStatusFromError = (error: any = {}) =>
  error && (error.response?.status || error.status || error.statusCode || error.httpCode)

/**
 * Just a tiny helper that will check if a number is defined and return
 * the value in dollars
 * @param amount - amount in cents
 * @returns amount in dollars
 */
export const centsToDollars = (amount?: number | null): number | undefined => {
  if (!amount) {
    return amount ?? undefined
  }

  return parseFloat((amount / 100).toFixed(2))
}

interface DashboardOptions {
  avatar: { text: string; color: string }
  title: React.ReactNode
  profile: { id: string; type: `${EntityType}`; name: string }
}
/**
 * @param account - Current account object with managers relation
 * @returns Home page dashboard URL base on the type of the entity
 */
export const extractDashboardOptions = (
  account: Pick<Account, 'managedEnterprises' | 'id' | 'name' | 'isOnboarded'>,
  alwaysIncludeNotOnboarded = false
): DashboardOptions[] => {
  const options: DashboardOptions[] = []
  if (account.managedEnterprises && account.managedEnterprises.length > 0) {
    account.managedEnterprises.map((enterprise) =>
      options.push({
        ...(enterprise.kind === 'enterprise'
          ? {
              avatar: {
                text: 'Ent.',
                color: stringToHslColor('Enterprise')
              },
              title: <FormattedMessage id="dashboard.enterprises" />
            }
          : {
              avatar: {
                text: 'Org.',
                color: stringToHslColor('Organization')
              },
              title: <FormattedMessage id="dashboard.organizations" />
            }),
        profile: {
          id: enterprise.id,
          type: EntityType.Admin,
          name: enterprise.name || ''
        }
      })
    )
  }

  const accountOption = {
    avatar: {
      text: getInitials(account.name ?? ''),
      color: stringToHslColor(account.name ?? '')
    },
    title: <FormattedMessage id="dashboard.users" />,
    profile: {
      id: account.id,
      type: EntityType.Commuter,
      name: account.name || ''
    }
  }
  if (account.isOnboarded || (options.length === 0 && account.isOnboarded === false)) {
    options.push(accountOption)
  } else if (alwaysIncludeNotOnboarded && account.isOnboarded === false) {
    options.push(accountOption)
  }

  return options
}

export const pathnameMatchesPath = (path: string, pathname: string, options?: { isExact?: boolean }): boolean => {
  if (!path || !pathname) {
    return false
  }

  const { isExact } = { isExact: true, ...options }

  const pathPatternParts = path.split('/')
  const pathnameParts = pathname.split('/')

  if ((isExact && pathPatternParts.length !== pathnameParts.length) || pathnameParts.length < pathPatternParts.length) {
    return false
  }

  for (let i = 0; i < pathPatternParts.length; i++) {
    const pathPart = pathPatternParts[i]
    if (pathPart.startsWith(':') && pathPart.toLowerCase().includes('id')) {
      if (!isUuid(pathnameParts[i])) {
        return false
      }
    } else if (pathPart !== pathnameParts[i]) {
      return false
    }
  }

  return true
}
