import { HttpMethod } from '../../constants'

function replacePlanId(planId: string): string {
  return this.path.replace(':planId', planId)
}
function returnPath() {
  return this.path
}

export const commuterPlanEndpoints = {
  [HttpMethod.Get]: {
    PlanBookings: {
      path: '/plans/:planId/bookings',
      route: replacePlanId
    },
    Plan: {
      path: '/plans/:planId',
      route: replacePlanId
    },
    Plans: {
      path: '/plans',
      route: returnPath
    },
    PlanCheckout: {
      path: '/plans/:planId/checkout',
      route: replacePlanId
    }
  },
  [HttpMethod.Post]: {
    PlanGroups: {
      path: '/plans/:planId/groups',
      route: replacePlanId
    }
  }
}

export default commuterPlanEndpoints
