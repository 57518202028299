import { createSelector } from 'reselect'
import { providersSelectors } from 'store/modules/providers'

export const getById = ({ services }) => services.byId

/**
 * Returns a list of all services providers excluding the undefined cases
 * @param {*} ids : services ids from which to get the providerIds
 */
export const makeGetServicesProvidersIds = (ids) =>
  createSelector(getById, (byId) => ids.map((id) => byId[id].provider).filter((providerId) => providerId))

export const makeGetServiceWithProvider = (id) =>
  createSelector([getById, providersSelectors.getById], (servicesById, providersById) => {
    const service = servicesById[id]
    if (!service) return null
    return { ...service, provider: service.provider ? providersById[service.provider] : undefined }
  })
