import { HttpMethod } from '../../constants'

function replaceIDPId(idpId: string): string {
  return this.path.replace(':identityProviderId', idpId)
}
function returnPath(): string {
  return this.path
}

export const authEndpoints = {
  [HttpMethod.Get]: {
    GetMe: {
      path: '/authentication/me',
      route: returnPath
    },
    IDPUrl: {
      path: '/authentication/identityproviders/:identityProviderId/url',
      route: replaceIDPId
    }
  },
  [HttpMethod.Post]: {
    Authentication: {
      path: '/authentication',
      route: returnPath
    },
    Logout: {
      path: '/authentication/logout',
      route: returnPath
    },
    ForgotPassword: {
      path: '/authentication/forgot-password',
      route: returnPath
    },
    ResetPassword: {
      path: '/authentication/reset-password',
      route: returnPath
    },
    CreatePassword: {
      path: '/authentication/create-password',
      route: returnPath
    },
    IDPAuth: {
      path: '/authentication/identityproviders/:identityProviderId',
      route: replaceIDPId
    }
  },
  [HttpMethod.Put]: {
    RefreshToken: {
      path: '/authentication/token/refresh',
      route: returnPath
    }
  }
}

export default authEndpoints
