import { useQuery } from '@tanstack/react-query'
import { QueryConfig, QueryConfigPickRes } from '../../reactQuery'
import { CommuteProfileProcessed, fetchCommuteProfile, fetchCommuteProfiles } from './api'

export const useAccountCommuteProfiles = (
  accountId: string | null,
  options?: QueryConfigPickRes<typeof fetchCommuteProfiles, 'records', CommuteProfileProcessed[]>
) =>
  useQuery({
    queryKey: ['commute-profiles', accountId],
    queryFn: async () => {
      if (!accountId) {
        throw new Error('cannot call fetchCommuteProfiles with accountId undefined')
      }
      const { records } = await fetchCommuteProfiles({ accountId, relations: ['commuteCosts', 'vehicles'] })
      return records
    },
    ...options,
    gcTime: 0
  })

export const useAccountCommuteProfile = (
  commuteProfileId: string | undefined,
  options?: QueryConfig<typeof fetchCommuteProfile>
) =>
  useQuery({
    queryKey: ['commute-profiles', commuteProfileId],
    queryFn: async () => {
      if (!commuteProfileId) {
        throw new Error('cannot call fetchCommuteProfiles with commuteProfileId undefined')
      }
      return fetchCommuteProfile(commuteProfileId, { relations: ['commuteCosts', 'vehicles'] })
    },
    ...options
  })
