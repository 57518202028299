import type { Overwrite, Required } from 'utility-types'
import faker from 'faker/locale/en'
import { times } from 'lodash'
import { BookingStatus, ConfirmationStatus } from '@commutifi/models/Bookings'
import { PlanBookingType } from '@commutifi/models/Plan'
import type { DetailedBooking } from 'api/modules/bookings'
import type { DetailedPlan } from 'api/modules/commuterPlans'
import { formatDateBoundaries } from 'utils/moment'
import { fakeAccountTimezone } from '../../config/constants'
import { generateEndDateFromStartDate } from '../../_utils'
import { fakePlan } from '../plans/fakers'
import { fakeAccount } from '../../shared/fakers'

export const generatePastStartDate = () =>
  formatDateBoundaries(faker.date.past(), {
    startOf: 'day',
    timezone: fakeAccountTimezone
  })
    .utc()
    .toISOString()
const generateSoonStartDate = () =>
  formatDateBoundaries(faker.date.soon(30), {
    startOf: 'day',
    timezone: fakeAccountTimezone
  })
    .utc()
    .toISOString()

export const generateDatesSoon = (nb = 5) =>
  times(nb, () => {
    const startDate = generateSoonStartDate()
    const endDate = generateEndDateFromStartDate(startDate, fakeAccountTimezone)
    return { startDate, endDate }
  })

export const recurrentBookingBase = {
  bookingType: PlanBookingType.RECURRENT
}
export const fakeRecurrentBooking = (baseBooking: Partial<DetailedBooking> = {}) =>
  fakeBooking({ ...recurrentBookingBase, ...baseBooking })

const nonRecurrentTypes = [PlanBookingType.SINGULAR, PlanBookingType.STANDARD, PlanBookingType.UNIQUE]
export const fakeNonRecurrentBooking = (baseBooking: Partial<DetailedBooking> = {}) => {
  const status = faker.random.arrayElement([BookingStatus.cancelled, BookingStatus.completed, BookingStatus.processing])
  return fakeBooking({
    bookingType: faker.random.arrayElement(nonRecurrentTypes),
    status,
    confirmationStatus: status === BookingStatus.processing ? ConfirmationStatus.PROCESSING : undefined,
    ...baseBooking
  })
}

export const fakeBooking = (
  baseBooking: Partial<Overwrite<DetailedBooking, { plan?: Partial<DetailedPlan> }>> = {}
): Required<DetailedBooking, 'startDate'> => {
  const bookingType = baseBooking.bookingType || faker.random.arrayElement(Object.values(PlanBookingType))
  const startDate: string | null = (
    Object.prototype.hasOwnProperty.call(baseBooking, 'startDate')
      ? baseBooking.startDate
      : formatDateBoundaries(faker.datatype.boolean() ? faker.date.past() : faker.date.soon(30), {
          startOf: 'day',
          timezone: fakeAccountTimezone
        })
          .utc()
          .toISOString()
  ) as string | null

  const planId = baseBooking.planId || faker.datatype.uuid()

  const paymentMethodId = Object.prototype.hasOwnProperty.call(baseBooking, 'paymentMethodId')
    ? baseBooking.paymentMethodId
    : faker.datatype.uuid()

  const id = faker.datatype.uuid()
  return {
    id,
    externalBookingId: baseBooking.externalBookingId || faker.datatype.uuid(),
    attachmentUrl: faker.internet.url(),
    bookingType,
    isShared: faker.datatype.boolean(),
    paymentMethodId,
    paymentMethod: baseBooking.paymentMethod,
    planId,
    plan: fakePlan(planId ? { ...baseBooking.plan, id: planId } : baseBooking.plan),
    quantity: baseBooking.quantity || 1,
    responses: [],
    startDate,
    timezone: fakeAccountTimezone,
    accountId: baseBooking.accountId || faker.datatype.uuid(),
    account: baseBooking.account || fakeAccount({ id: baseBooking.accountId }),
    endDate: Object.prototype.hasOwnProperty.call(baseBooking, 'endDate')
      ? baseBooking.endDate
      : bookingType === PlanBookingType.SINGULAR
        ? formatDateBoundaries(startDate, { endOf: 'day', timezone: fakeAccountTimezone }).utc().toISOString()
        : undefined,
    hasEvCharging: faker.datatype.boolean(),
    phoneNumber: baseBooking.phoneNumber || faker.phone.phoneNumber('+1 ### ####'),
    status:
      baseBooking.status ||
      faker.random.arrayElement([
        BookingStatus.active,
        BookingStatus.cancelled,
        BookingStatus.completed,
        BookingStatus.processing
      ]),
    confirmationStatus: baseBooking.confirmationStatus || faker.random.arrayElement(Object.values(ConfirmationStatus)),
    createdAt: faker.date.past().toUTCString(),
    updatedAt: faker.date.past().toUTCString(),
    accountVehiclesBookings: baseBooking.accountVehiclesBookings
      ? baseBooking.accountVehiclesBookings.map((vehicle) => ({ ...vehicle, bookingId: id }))
      : undefined,
    passes: baseBooking.passes,
    billingStreetNumber: faker.address.streetAddress(),
    billingStreet: faker.address.streetName(),
    billingCity: faker.address.city(),
    billingCountry: faker.address.country(),
    billingState: faker.address.state(),
    billingPostalCode: faker.address.zipCode(),
    billingFirstName: faker.name.firstName(),
    billingLastName: faker.name.lastName()
  }
}
