import { HttpMethod } from '../../constants'

function replaceSubmissionId(submissionId: string): string {
  return this.path.replace(':submissionId', submissionId)
}
function returnPath(): string {
  return this.path
}

export const complianceEndpoints = {
  [HttpMethod.Get]: {
    Submissions: {
      path: '/ddot-submissions',
      route: returnPath
    },
    TDMStrategies: {
      path: '/tdm-strategies',
      route: returnPath
    },
    EnterprisesEin: {
      path: '/ddot-submissions/ein/:submissionTaxId',
      route(submissionTaxId: string): string {
        return this.path.replace(':submissionTaxId', submissionTaxId)
      }
    }
  },
  [HttpMethod.Patch]: {
    Submission: {
      path: '/ddot-submissions/:submissionId',
      route: replaceSubmissionId
    }
  },
  [HttpMethod.Post]: {
    Submission: {
      path: '/ddot-submissions',
      route: returnPath
    },

    UploadSubmissionFile: {
      path: '/ddot-submissions/:submissionId/files',
      route: replaceSubmissionId
    }
  },
  [HttpMethod.Delete]: {
    SubmissionFile: {
      path: '/ddot-submissions/:submissionId/files',
      route: replaceSubmissionId
    }
  }
}

export default complianceEndpoints
