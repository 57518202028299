import { createSelector } from 'reselect'
import { EnterpriseKind } from '@commutifi/models/Enterprises'
import { FeatureFlagProps } from '@commutifi/models/FeatureFlag'
import { IOrganization } from 'shared/interfaces/Organization'
import { EnterpriseErrorCodes } from './types'
import { RootState } from '../../reducer'

export const surveyLinkEntityMapping = {
  [EnterpriseKind.organization]: 'organization',
  [EnterpriseKind.enterprise]: 'enterprise',
  office: 'building'
}

export const getById = ({ enterprise }: RootState) => enterprise.byId
export const getCurrentId = ({ enterprise }: RootState) => enterprise.currentId

export const getIsFetching = ({ enterprise }: RootState) => enterprise.isFetching
export const getIsFetchingFeatureFlags = ({ enterprise }: RootState) => enterprise.isFetchingFlags
export const getError = ({ enterprise }: RootState) =>
  enterprise.error?.find((errCode: number) => errCode === EnterpriseErrorCodes.Base)
export const getFeatureFlagsError = ({ enterprise }: RootState) =>
  enterprise.error?.find((errCode: number) => errCode === EnterpriseErrorCodes.FeatureFlags)
export const getCurrentOrganization = createSelector(
  [getCurrentId, getById],
  (currentOrgId: string, orgsById: Record<string, IOrganization | undefined>) => orgsById[currentOrgId]
)
// Shouldn't use kind of organization but to support legacy right now we need it
export const getCurrentKind = createSelector([getCurrentOrganization], (currentOrg) => currentOrg?.kind)

export const getSurveyLinkParams = createSelector(
  [getCurrentId, getCurrentKind],
  (orgId: string | null, entity: EnterpriseKind | undefined) => {
    if (!entity) {
      return
    }

    const queryParams = {
      [`${surveyLinkEntityMapping[entity]}Id`]: orgId
    }
    return queryParams
  }
)

export const getFeatureFlagsByEnterpriseId = ({ enterprise }: RootState) => enterprise.featureFlagsByEnterpriseId
export const getManagerAvailablePages = createSelector(
  [getFeatureFlagsByEnterpriseId, getCurrentId],
  (featureFlagsByEnterpriseId: Record<string, FeatureFlagProps | undefined>, currentOrganizationId: string | null) => {
    if (!currentOrganizationId || !featureFlagsByEnterpriseId[currentOrganizationId]) {
      return null
    }
    return featureFlagsByEnterpriseId[currentOrganizationId] ?? null
  }
)
