import type { ModeName } from '@commutifi/modes'
import type { Metric, Score, RequestParams } from 'shared/interfaces'

export enum TripSource {
  CommuteProfile = 'commuteProfile',
  Event = 'event',
  SelfReported = 'selfReported'
}
export type TripSourceType = `${TripSource}`

export interface TripLeg {
  partnerName: string
  partnerLogoUrl?: string
}

export interface Trip {
  id: string
  accountId: string
  commuteProfileRef: string
  scores: Score
  metrics: Metric
  startDate: string
  endDate: string | null
  startAddress: string
  endAddress: string
  startTime: string
  endTime: string
  days: string[]
  source: TripSourceType
  isReturn: boolean
  orderedModes: ModeName[]
  legs: TripLeg[]
  timezone: string
  createdAt: string
}

export interface TripQueryParams extends RequestParams {
  // Dates format should be YYYY-MM-DD
  startDate: string
  endDate: string
}

export interface SelfReportedTrips {
  commuteProfileRef: string | undefined
  startDate: string
  isReturn: boolean
}
