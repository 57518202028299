import type { Omit, Required } from 'utility-types'
import type { Countries } from '@commutifi/constants/Countries'
import { CurrencyCode } from '@commutifi/constants/Locale'
import {
  PlanBillingCancellationPolicy,
  PlanBillingFirstMonthPolicy,
  PlanBillingMethod,
  PlanBookingType,
  PlanCostType,
  PlanFrequency
} from '@commutifi/models/Plan'
import type { PlanProps } from '@commutifi/models/Plan'
import { PlanBookingFieldCategory, PlanBookingFieldField } from '@commutifi/models/PlanBookingField'
import type { PlanBookingFieldProps } from '@commutifi/models/PlanBookingField'
import type { PlanFaqProps } from '@commutifi/models/PlanFaq'
import type { PeriodPriceBreakdownProps, PriceCheckoutQueryParams } from '@commutifi/models/PriceBreakdown'
import type { RegionProps } from '@commutifi/models/Region'
import { ServiceCategory, ServiceMode, ServiceRegionDefinition, ServiceType } from '@commutifi/models/Service'
import type { ServiceProps } from '@commutifi/models/Service'
import type { ListPlan as LP, Plan } from '@commutifi-fe/commutifi-specific/Domain/Models/CommuterPlans'
import { PlansPermissionsProps } from '@commutifi/models/PlanPermissions'

type ListPlan = LP
type AnyPlan = ListPlan | DetailedPlan | Plan | PlanProps

type PlanFaqs = Required<PlanFaqProps, 'id'>
export type PlanBookingField = Required<PlanBookingFieldProps, 'id'>
interface DetailedPlan extends Omit<Plan, 'plansEnterpriseGroups'> {
  planFaqs: PlanFaqs[]
  service: ServiceProps
  regions: RegionProps[]
  planBookingFields: PlanBookingField[]
}

enum PlanCurrency {
  USD = CurrencyCode.USD,
  CAD = CurrencyCode.CAD
}

export interface PlanPermissionProps extends Required<PlansPermissionsProps, 'enterpriseId'> {
  planId: string
}

export type {
  Plan,
  ListPlan,
  DetailedPlan,
  PlanFaqs,
  PeriodPriceBreakdownProps,
  PriceCheckoutQueryParams,
  AnyPlan,
  PlanFaqs as PlanFaq
}
export {
  PlanBillingCancellationPolicy,
  PlanBillingFirstMonthPolicy,
  PlanBookingType,
  PlanBillingMethod,
  PlanCostType,
  PlanFrequency,
  PlanCurrency,
  ServiceRegionDefinition,
  ServiceMode,
  ServiceCategory,
  ServiceType,
  PlanBookingFieldCategory,
  PlanBookingFieldField,
  Countries
}
