import { HttpMethod } from '../../constants'

function replaceIncentiveProgramId(incentiveProgramId: string): string {
  return this.path.replace(':incentiveProgramId', incentiveProgramId)
}
function returnPath(): string {
  return this.path
}

export const incentiveProgramEndpoints = {
  [HttpMethod.Get]: {
    AccountIncentiveProgramsBalances: {
      path: '/incentive-programs/balances',
      route: returnPath
    },
    IncentiveProgram: {
      path: '/incentive-programs/:incentiveProgramId',
      route: replaceIncentiveProgramId
    },
    PointTransactions: {
      path: '/point-transactions',
      route: returnPath
    },
    PointTransactionsBalance: {
      path: '/point-transactions/balance',
      route: returnPath
    }
  },
  [HttpMethod.Post]: {
    RedeemIncentiveProgramPoints: {
      path: '/incentive-programs/:incentiveProgramId/redeem',
      route: replaceIncentiveProgramId
    }
  }
}

export default incentiveProgramEndpoints
