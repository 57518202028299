export const IDENTITY_PROVIDERS_IDENTIFIER = 'subdomain:identityProvider'

export function put(key: string, value: any) {
  window.localStorage.setItem(key, JSON.stringify(value))
}

export function get(key: string) {
  const rawItem = window.localStorage.getItem(key)
  try {
    if (!rawItem) {
      return null
    }
    return JSON.parse(rawItem)
  } catch (error) {
    return rawItem
  }
}

export function remove(key: string) {
  return window.localStorage.removeItem(key)
}

export function clear() {
  window.localStorage.clear()
}

// Based on https://mitchgavan.com/code-splitting-react-safely/
export function putWithExpiry(key: string, value: any, ttl: number) {
  const item = {
    value,
    expiry: new Date().getTime() + ttl
  }
  put(key, JSON.stringify(item))
}

// Based on https://mitchgavan.com/code-splitting-react-safely/
export function getWithExpiry(key: string) {
  const itemString = get(key)
  if (!itemString) return null

  const item = JSON.parse(itemString)
  const isExpired = new Date().getTime() > item.expiry

  if (isExpired) {
    localStorage.removeItem(key)
    return null
  }

  return item.value
}

export default {
  put,
  putWithExpiry,
  get,
  getWithExpiry,
  remove,
  clear,
  IDENTITY_PROVIDERS_IDENTIFIER
}
