import { Stop } from '@commutifi/modes'
import pick from 'lodash/pick'
import omit from 'lodash/omit'
import { LEG_TYPES } from '@commutifi-fe/constants'
import { AddressPoint, AddressPointWithTimezone, Route, RouteLeg, CommuteTransport } from 'shared/interfaces'
import logger from 'utils/logRocket'

/**
 * Initially we have some stops:    [\{S\}, \{T\}]
 * End result should be:            [\{S\}, \{S -\> T\}, \{T\}]
 *
 * 1. Keep first stop:              [\{S\}]
 * 2. Start at second stop and use
 *    previous stop to add new leg: Previous \{S\} + Current \{T\} -\> [\{S\}, \{S -\> T\}, \{T\}]
 * 3. Repeat step 2 for remaining stops
 *
 * @param stops - Activities the user stops to do
 */
const _insertLegsBetweenStops = (stops: RouteLeg[] | undefined, transport: CommuteTransport | undefined) => {
  if (!stops || stops.length < 1) {
    logger.warn('You are trying to link array of stops of length 0')
    return stops
  }

  // --- STEP 1 ---
  const legs = [stops[0]]
  for (let i = 1; i < stops.length; ++i) {
    // --- STEP 2 ---
    const previousStop = stops[i - 1]
    const currentStop = stops[i]
    legs.push({
      type: LEG_TYPES.leg,
      startLocation: previousStop.endLocation,
      startAddress: previousStop.endAddress,
      endLocation: currentStop.startLocation,
      endAddress: currentStop.startAddress,
      category: transport?.category,
      ...(transport?.mode && { mode: transport.mode })
    })
    legs.push(currentStop)
  }
  // --- STEP 3 ---
  // (for loop)
  return legs
}

/**
 * Input values:    srcLegs -\> [\{A -\> B\}, \{B -\> C\}], stops -\> [\{S\}, \{T\}], atIndex - 1
 * Expected output: [\{A -\> B\}, \{B -\> S\}, \{S\}, \{S -\> T\}, \{T\}, \{T -\> C\}]
 *
 * Steps to achieve that:
 * 1. Extract targeted leg (\{B -\> C\})
 *
 * 2. Prepare this leg by splitting it into 2 to be able
 *    to insert the stops in between. One leg need to finish by the first activity
 *    and the second one by the last activity
 *    Result: \{B -\> S\} and \{T -\> C\}
 *
 * 3. Generate legs to link the different stops
 *    Result: [\{S\}, \{S -\> T\}, \{T\}]
 *
 * 4. Insert the linked stops between the 2 legs generated on step 2
 *
 * @param srcLegs -legs on which to insert one or multiple stops
 * @param stops - stop(s) to insert into the source legs at index specified in params
 * @param atIndex - index at which you want to insert the stops
 */
export const insertStopsAt = (srcLegs: RouteLeg[], stops: RouteLeg[], atIndex: number) => {
  if (stops.length < 1) {
    logger.warn('You are trying to insert stops of length 0 into a commute route')
    return srcLegs
  }

  // --- STEP 1 ---
  // If the user chose a route autocompleted with google we want to invalidate it since
  // it doesn't make sense anymore. Thus the use of omit transit
  const targetedLeg = omit(srcLegs[atIndex], 'transit')
  const legsWithStops = [...srcLegs]

  // --- STEP 2 ---
  // Alter copied source legs to replace {B -\> C} by 2 legs
  legsWithStops.splice(
    atIndex,
    1,
    { ...targetedLeg, endLocation: stops[0].startLocation, endAddress: stops[0].startAddress },
    {
      ...targetedLeg,
      startLocation: stops[stops.length - 1].endLocation,
      startAddress: stops[stops.length - 1].endAddress
    }
  )

  // --- STEP 3 ---
  const linkedStops = _insertLegsBetweenStops(stops, pick(targetedLeg, ['category', 'mode']))

  // --- STEP 4 ---
  // We use atIndex + 1 because we want to insert the stops
  // after the generated {B -\> S} leg
  legsWithStops.splice(atIndex + 1, 0, ...(linkedStops || []))
  return legsWithStops
}

/**
 * Remove legs that are between stops to remove the bridge we made between those stops.
 * That allows to remove the stops without having extra legs.
 *
 * To proceed we will check if the next leg is a stop and if there is another leg after
 * that stop. If it is the case we will remove those 2 legs
 *
 * @example Processing \{A -\> Stop -\> B -\> Stop2 -\> C\} (A will be the original legs vs. A')
 * Start: First leg shouldn't be a Stop -\> \{A -\> Stop\}
 * 1. A is followed by a stop and another leg -\> \{A' -\> B\}
 * 2. First conditions not filled, and we ignore it cause it is a stop
 * 3. B is meets the conditions so we modify the end address of the new legs -\> \{A' -\> C\}
 * 4. Idem than step 2, ignore stop...
 * 5. C is the last leg so it won't meet the first condition. after than we need to check
 *    if it connects with our last leg in our new set of legs. If yes, we keep it. Otherwise,
 *    it means the last new leg is already pointing to the end address of the original leg C
 * Final: \{A' -\> C\}
 *
 * @param legs - Legs of a route
 */
export const removeStops = (legs: RouteLeg[] | undefined) => {
  if (!legs || legs.length === 0) return []

  return legs.reduce(
    (filteredLegs, leg, index) => {
      // When the previous leg is not a stop we can already insert it without making any
      // modifications
      if (legs[index - 1] && legs[index - 1].type === LEG_TYPES.leg && leg.type === LEG_TYPES.leg) {
        filteredLegs.push(leg)
      }

      // When the next leg is a stop and it exists another leg after that one, we need
      // to modify the previous leg retained to point to the leg after the stop
      if (legs[index + 2] && legs[index + 1].type === LEG_TYPES.stop) {
        filteredLegs[filteredLegs.length - 1] = {
          ...filteredLegs[filteredLegs.length - 1],
          endLocation: legs[index + 2].endLocation,
          endAddress: legs[index + 2].endAddress
        }
        return filteredLegs
      }

      return filteredLegs
    },
    [legs[0]]
  )
}

export const generateStopFromLocation = (address: AddressPoint, activity = Stop.DropOffKids): RouteLeg => ({
  type: LEG_TYPES.stop,
  activity,
  category: null,
  startAddress: address.formattedAddress,
  endAddress: address.formattedAddress,
  startLocation: address.location,
  endLocation: address.location
})

/**
 * Return the part before the ',' in a google formatted address
 * @param address - Google formatted address which display the route number
 *                  and street name before the comma
 */
export const extractStreetFromGoogleAddress = (address = '') => address.split(',')[0]

export const generateRouteFromMode = (
  startPoint: AddressPoint,
  endPoint: AddressPoint | AddressPointWithTimezone,
  transport: CommuteTransport
): Route => {
  const routeGeometry = {
    startAddress: startPoint.formattedAddress,
    endAddress: endPoint.formattedAddress,
    startLocation: startPoint.location,
    endLocation: endPoint.location
  }
  return {
    ...routeGeometry,
    legs: [
      {
        category: transport.category,
        mode: transport.mode,
        type: LEG_TYPES.leg,
        ...routeGeometry
      }
    ]
  }
}
