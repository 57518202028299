import { createBrowserHistory } from 'history'
import type { Action, Location, LocationState, Path } from 'history'

const history = createBrowserHistory()

// Implementation is here: https://github.com/ReactTraining/history/issues/573#issuecomment-406871315
let pastLocations: Location[] = []
function updatePastLocations(location: Location, action: Action) {
  switch (action) {
    case 'PUSH':
      // first location when app loads and when pushing onto history
      pastLocations.push(location)
      break
    case 'REPLACE':
      // only when using history.replace
      pastLocations[pastLocations.length - 1] = location
      break
    case 'POP': {
      // happens when using the back button, or forward button
      pastLocations.pop()
      // location according to pastLocations
      const appLocation: Location | undefined = pastLocations[pastLocations.length - 1]
      if (!(appLocation?.key === location?.key)) {
        // If the current location doesn't match what the app thinks is the current location,
        // blow up the app history.
        pastLocations = [location]
      }
      break
    }
    default:
  }
}
history.listen(updatePastLocations)

function isPreviousLocationWithinApp(): boolean {
  return pastLocations.length > 1
}

/**
 * If the last location is in commutifi app we will simply call history.goBack
 * Otherwise, we will replace the url with the requested path (locationPath)
 *
 * @param locationPath- Path to use if we can't call goBack and stay inside our app
 * @param opts- Options object, see below for individual props
 * @param state- State object to pass to the new URL if we don't call goBack
 * @param previousPathBlacklist- When the previous path is in commutifi app we might now want to blindly call goBack.
 *                                     Then you can use this param to apply some blacklist pattern that will avoid calling
 *                                     goBack if the previous path includes those patterns (strings only for now)
 */
export function goBackOrReplace(
  locationPath: Path,
  opts?: { previousPathBlacklist?: string[]; state?: LocationState }
): void {
  const options = opts || {}
  if (
    isPreviousLocationWithinApp() &&
    !(options.previousPathBlacklist || []).some((blacklistPath) => {
      const previousPathParts = pastLocations[pastLocations.length - 2]?.pathname.split('/')
      const blacklistPathParts = blacklistPath.split('/')
      let isMatch = true
      for (let i = 0; i < blacklistPathParts.length; ++i) {
        const blacklistPart = blacklistPathParts[i]
        const previousPart = previousPathParts[i]
        if (blacklistPart && !previousPart) {
          // Blacklist part is longer -> no blacklist match
          isMatch = false
          break
        }

        if (blacklistPart.startsWith(':')) {
          /**
           * In this case where the path part starts with a colon, it means it is a parameter that either accepts
           * specific values (or values matching a regex) when there is something specified in parentheses, or it accepts
           * any string as the parameter if no restrictions are specified.
           */

          const textBetweenParentheses = /\(([^)]+)\)/
          const matches = textBetweenParentheses.exec(blacklistPart)
          const restrictions = matches?.[1]?.split('|')

          let matchesPathRestrictions = false
          if ((!restrictions || restrictions.length === 0) && previousPart) {
            // No specific expression expected (nothing between parentheses on the react-router path)
            // -> anything is a match
            matchesPathRestrictions = true
          }
          if (restrictions?.some((expectedValue) => new RegExp(expectedValue).test(previousPart))) {
            // One of expected expressions is a match -> we continue
            matchesPathRestrictions = true
          }

          // No match found -> we stop
          isMatch = matchesPathRestrictions
          if (!isMatch) {
            break
          }
        } else if (blacklistPart === '*') {
          break
        } else if (blacklistPart !== previousPart) {
          isMatch = false
          break
        } else {
          isMatch = true
        }
      }
      return isMatch
    })
  ) {
    history.goBack()
  } else {
    history.replace(locationPath, options.state)
  }
}

export default history
