import { useMutation, useQuery } from '@tanstack/react-query'
import { ExtractFnReturnType, MutationConfig, QueryConfig } from '../../reactQuery'
import { fetchAccountVehicles, postAccountVehicles, putAccountVehicles } from './api'

export const useAccountVehicles = <T = ExtractFnReturnType<typeof fetchAccountVehicles>>(
  queryParams: Parameters<typeof fetchAccountVehicles>[0],
  options?: QueryConfig<typeof fetchAccountVehicles, T>
) =>
  useQuery({
    queryKey: ['account-vehicles', queryParams],
    queryFn: async () => fetchAccountVehicles(queryParams),
    ...options
  })

export const usePostAccountVehicles = (options?: MutationConfig<typeof postAccountVehicles>) =>
  useMutation({ mutationFn: (body) => postAccountVehicles(body), ...options })

export const usePutAccountVehicles = (options?: MutationConfig<typeof putAccountVehicles>) =>
  useMutation({ mutationFn: (body) => putAccountVehicles(body), ...options })
