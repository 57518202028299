import routestypes from 'store/modules/routes/types'
import plansTypes from 'store/modules/plans/types'
import { combineReducers } from 'redux'
import { COMMUTE_MODES } from 'constants/global'

export const initialState = {
  ...COMMUTE_MODES.reduce(
    (modeById, mode) => ({
      ...modeById,
      [`SELF_PROVIDED_${mode.toUpperCase()}`]: {
        id: `SELF_PROVIDED_${mode.toUpperCase()}`,
        name: `SELF_PROVIDED_${mode.toUpperCase()}`,
        mode,
        provider: 'SELF_PROVIDED'
      }
    }),
    {}
  ),
  SELF_PROVIDED_SUBWAY: {
    id: `SELF_PROVIDED_SUBWAY`,
    name: `SELF_PROVIDED_SUBWAY`,
    mode: 'subway',
    provider: 'SELF_PROVIDED'
  },
  SELF_PROVIDED_BUS: {
    id: `SELF_PROVIDED_BUS`,
    name: `SELF_PROVIDED_BUS`,
    mode: 'bus',
    provider: 'SELF_PROVIDED'
  }
}

const byId = (state = initialState, action = {}) => {
  switch (action.type) {
    case routestypes.FETCH_SUGGESTION_ROUTES_SUCCESS:
    case routestypes.FETCH_USER_ROUTES_SUCCESS:
    case plansTypes.FETCH_PLANS_SUCCESS:
      return {
        ...state,
        ...action.payload.entities.services
      }
    default:
      return state
  }
}
export default combineReducers({
  byId
})
