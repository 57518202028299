import {
  QueryClient,
  QueryCache,
  UseQueryOptions,
  UseMutationOptions,
  DefaultOptions,
  UseQueryResult,
  QueryMeta,
  useQuery as useQueryInternal,
  useInfiniteQuery as useInfiniteQueryInternal,
  useMutation as useMutationInternal,
  QueryKey,
  InfiniteData,
  Query
} from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { CommutifiErrorData } from '@commutifi-fe/interfaces'
import { Optional, Overwrite } from 'utility-types'
import { NotificationArgsProps, NotificationConfig } from '@commutifi-fe/ui'
import { useNotifications } from '@commutifi-specific/hooks'

export type { UseQueryOptions, UseMutationOptions, UseQueryResult, QueryKey }

export const queryCache = new QueryCache()
const queryConfig: DefaultOptions = {
  queries: {
    refetchOnWindowFocus: false,
    retry: false
  }
}

export const queryClient = new QueryClient({ defaultOptions: queryConfig, queryCache })

interface QMeta extends QueryMeta {
  errorMessage?: string
  /**
   * Use this if you want to set a message but also a configuration to apply to the toast like message
   * using our notification system
   */
  errorConfig?: NotificationConfig & { message: string }
  /**
   * If true, the error will be handled by our useNotifications hook; handleError function
   * [OPTIONAL]: If there is a handleErrorConfig object, it will assume this boolean is true
   */
  shouldHandleError?: boolean
  handleErrorConfig?: {
    fallbackMessage?: string
  } & Omit<Optional<NotificationArgsProps, 'message'>, 'description'>
}

export type ExtractFnReturnType<FnType extends (...args: any) => any, PickRes = unknown> = PickRes extends string
  ? Awaited<ReturnType<FnType>>[PickRes]
  : Awaited<ReturnType<FnType>>

/**
 * QueryFnType: Function called to fetch the data and from which we extract the Awaited Return type of that function.
 * Res: Generic type to overwrite the Return result of the query response
 */
export type QueryConfig<QueryFnType extends (...args: any) => any, Res = ExtractFnReturnType<QueryFnType>> = Optional<
  Overwrite<
    UseQueryOptions<ExtractFnReturnType<QueryFnType>, AxiosError<CommutifiErrorData>, Res>,
    {
      meta?: QMeta
    }
  >,
  'queryKey' | 'queryFn'
>

/**
 * Idem to QueryConfig but instead of allowing overwrite of Res it accepts a string that will extract a property from
 * the response object. Ex. 'records' will take api response records property
 */
export type QueryConfigPickRes<
  QueryFnType extends (...args: any) => any,
  Pick extends string = '',
  Res = ExtractFnReturnType<QueryFnType, Pick>
> = Optional<
  Overwrite<
    UseQueryOptions<ExtractFnReturnType<QueryFnType, Pick>, AxiosError<CommutifiErrorData>, Res>,
    {
      meta?: QMeta
    }
  >,
  'queryKey' | 'queryFn'
>

export type MutationConfig<
  MutationFnType extends (...args: any) => any,
  TVariables = Parameters<MutationFnType>[0]
> = Optional<
  UseMutationOptions<ExtractFnReturnType<MutationFnType>, AxiosError<CommutifiErrorData>, TVariables>,
  'mutationKey' | 'mutationFn'
>

/**
 * Idem to QueryConfig but instead of allowing overwrite of Res it accepts a string that will extract a property from
 * the response object. Ex. 'records' will take api response records property
 */
export type MutationConfigPickRes<
  MutationFnType extends (...args: any) => any,
  Pick extends string = '',
  TVariables = Parameters<MutationFnType>[0]
> = Optional<
  Overwrite<
    UseMutationOptions<ExtractFnReturnType<MutationFnType, Pick>, AxiosError<CommutifiErrorData>, TVariables>,
    {
      meta?: QMeta
    }
  >,
  'mutationKey' | 'mutationFn'
>

export type QueryResult<TData = unknown> = UseQueryResult<TData, AxiosError<CommutifiErrorData>>

export function useQuery<
  TQueryFnData = unknown,
  TError = Error,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>(
  options: Overwrite<
    Parameters<typeof useQueryInternal<TQueryFnData, TError, TData, TQueryKey>>[0],
    {
      meta?: QMeta
    }
  >,
  queryClient?: Parameters<typeof useQueryInternal<TQueryFnData, TError, TData, TQueryKey>>[1]
) {
  return useQueryInternal<TQueryFnData, TError, TData, TQueryKey>(options, queryClient)
}

export function useInfiniteQuery<
  TQueryFnData,
  TError = Error,
  TData = InfiniteData<TQueryFnData, unknown>,
  TQueryKey extends QueryKey = QueryKey,
  TPageParam = unknown
>(
  options: Overwrite<
    Parameters<typeof useInfiniteQueryInternal<TQueryFnData, TError, TData, TQueryKey, TPageParam>>[0],
    {
      meta?: QMeta
    }
  >,
  queryClient: Parameters<typeof useInfiniteQueryInternal<TQueryFnData, TError, TData, TQueryKey, TPageParam>>[1]
) {
  return useInfiniteQueryInternal<TQueryFnData, TError, TData, TQueryKey, TPageParam>(options, queryClient)
}

export function useMutation<TData = unknown, TError = Error, TVariables = void, TContext = unknown>(
  options: Overwrite<
    Parameters<typeof useMutationInternal<TData, TError, TVariables, TContext>>[0],
    {
      meta?: QMeta
    }
  >,
  queryClient?: Parameters<typeof useMutationInternal<TData, TError, TVariables, TContext>>[1]
) {
  return useMutationInternal<TData, TError, TVariables, TContext>(options, queryClient)
}

export const useReactQueryOnError = () => {
  const { handleError, error: errorNotification } = useNotifications()
  return (error: Error, query: Overwrite<Query<unknown, unknown, unknown, QueryKey>, { meta?: QMeta }>) => {
    if (query.meta?.shouldHandleError || query.meta?.handleErrorConfig) {
      handleError(error, query.meta.handleErrorConfig)
    } else if (query.meta?.errorMessage) {
      errorNotification({ description: query.meta.errorMessage as string })
    } else if (query.meta?.errorConfig) {
      errorNotification(query.meta.errorConfig)
    }
  }
}
