import types from './types'
import routesTypes from 'store/modules/routes/types'
import { combineReducers } from 'redux'

const requiredPlans = (state = [], action = {}) => {
  switch (action.type) {
    case types.ADD_REQUIRED_PLAN:
      return [...state, action.planId]
    case routesTypes.SELECT_CURRENT_ROUTE:
    case routesTypes.SELECT_SUGGESTION_ROUTE:
    case types.INIT_REQUIRED_PLANS:
    case types.RESET:
      return []
    default:
      return state
  }
}

export default combineReducers({
  requiredPlans
})
