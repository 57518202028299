import faker from 'faker/locale/en'
import { Required } from 'utility-types'
import { times } from 'lodash'
import { PlanThirdPartyIntegration } from '@commutifi/models/Plan'
import {
  PlanBillingMethod,
  PlanBookingFieldCategory,
  PlanBookingFieldField,
  PlanBookingType,
  PlanCostType,
  PlanPermissionProps,
  ServiceMode
} from 'api/modules/commuterPlans'
import { DetailedBooking } from 'api/modules/bookings'
import { generateEndDateFromStartDate, generateNextDaysDatesFromNow, randomIn } from '../../_utils'
import { fakeBooking, generateDatesSoon, generatePastStartDate, recurrentBookingBase } from '../bookings/fakers'
import { fakeAccountTimezone } from '../../config/constants'
import { fakePlan, fakePlanPermissions } from './fakers'
import { enterprisePlans, featuredPlans, notFeaturedPlans } from '../../shared/data'

export const fakePlanWithRegion = fakePlan({
  name: 'Monthly unreserved parking at 404 Denver St',
  regions: [{ id: faker.datatype.uuid(), point: { lng: -103.22115, lat: 44.08809 } }],
  imageUrl: undefined,
  billingMethod: PlanBillingMethod.STRIPE
})
export const fakeStripePlanWithRegion = fakePlan({
  name: 'Monthly unreserved parking at 202 Denver St',
  regions: [{ id: faker.datatype.uuid(), point: { lng: -101.70758, lat: 34.1808 } }],
  imageUrl: undefined,
  billingMethod: PlanBillingMethod.STRIPE
})
export const fakeExternalPlanWithImg = fakePlan({
  name: 'Finance a Vanmoof eBike',
  billingMethod: PlanBillingMethod.EXTERNAL,
  regions: [],
  imageUrl:
    'https://images.unsplash.com/photo-1532298229144-0ec0c57515c7?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1008&q=80',
  planBookingFields: [
    {
      id: faker.datatype.uuid(),
      field: PlanBookingFieldField.PHONE_NUMBER,
      category: PlanBookingFieldCategory.ACCOUNT,
      createdAt: faker.date.past().toUTCString(),
      updatedAt: faker.date.past().toUTCString()
    }
  ]
})
export const fakeStripeBusServicePlan = fakePlan({
  name: 'Just another plan to bite',
  regions: [],
  imageUrl: undefined,
  service: {
    mode: ServiceMode.BUS
  },
  terminationInstructions:
    'Make sure to return your key card to the front desk once your plan is over to avoid a $25 lost key penalty.',
  planBookingFields: [],
  billingMethod: PlanBillingMethod.STRIPE
})
export const fakeCommutifiCardPlan = fakePlan({
  name: 'Mobility',
  regions: [],
  imageUrl: undefined,
  thirdPartyIntegration: PlanThirdPartyIntegration.commutifiCard
})
export const fakeFoodCardPlan = fakePlan({
  name: 'Food',
  regions: [],
  imageUrl: undefined,
  thirdPartyIntegration: PlanThirdPartyIntegration.commutifiCard
})
export const fakeDailyParkingPassPlan = fakePlan({
  name: '1000 daily parking pass parking at 404 Denver St',
  regions: [{ id: faker.datatype.uuid(), point: { lng: -103.22115, lat: 44.08809 } }],
  imageUrl: undefined
})
export const fakeOffsetPlan = fakePlan({
  name: 'Offset your commute emissions',
  regions: [],
  imageUrl:
    'https://images.unsplash.com/photo-1603612692333-7bac35e43500?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1489&q=80',
  costType: PlanCostType.FIXED,
  bookingType: PlanBookingType.STANDARD,
  frequency: undefined,
  subsidy: undefined,
  billingMethod: PlanBillingMethod.EXTERNAL,
  billingDetails: 'Just pay, Some Random Company'
})
export const fakeEmptyBookingFieldsPlan = fakePlan({ planBookingFields: [] })
export const bookingPlans = [
  fakePlanWithRegion,
  fakeStripePlanWithRegion,
  fakeExternalPlanWithImg,
  fakeStripeBusServicePlan,
  fakeCommutifiCardPlan,
  fakeFoodCardPlan,
  fakeDailyParkingPassPlan,
  fakeOffsetPlan,
  fakeEmptyBookingFieldsPlan
]

export const enterpriseExternalPlanIndex = enterprisePlans.length - 2

const singularPlanId = featuredPlans[0].id
const singularBookingBase = {
  bookingType: PlanBookingType.SINGULAR,
  planId: singularPlanId
}
const pastStartDate = generatePastStartDate()

// Generate 10 random dates in the next 30 days to make a case where the user booked singular plans
// for few of the next 30 days but not all
const soonDates = generateDatesSoon(10)

const monthlyRecurrentPlanId = featuredPlans[2].id
const dailyRecurrentPlanId = featuredPlans[3].id
const uniqPlanId = notFeaturedPlans[6].id
export const alreadyBookedCasesByPlan: Record<string, Partial<DetailedBooking>[]> = {
  [singularPlanId]: generateNextDaysDatesFromNow(fakeAccountTimezone).map(({ startDate, endDate }) => ({
    ...singularBookingBase,
    planId: singularPlanId,
    startDate,
    endDate
  })),
  [uniqPlanId]: [{ bookingType: PlanBookingType.UNIQUE, planId: uniqPlanId }],
  [monthlyRecurrentPlanId]: [
    {
      ...recurrentBookingBase,
      planId: monthlyRecurrentPlanId,
      startDate: soonDates[0].startDate,
      endDate: undefined
    }
  ],
  [dailyRecurrentPlanId]: [
    {
      ...recurrentBookingBase,
      planId: monthlyRecurrentPlanId,
      startDate: pastStartDate,
      endDate: generateEndDateFromStartDate(pastStartDate, fakeAccountTimezone)
    },
    {
      ...recurrentBookingBase,
      planId: dailyRecurrentPlanId,
      startDate: soonDates[0].startDate,
      endDate: soonDates[0].endDate
    }
  ]
}

export const bookingsByPlanId = Object.keys(alreadyBookedCasesByPlan).reduce<
  Record<string, Required<DetailedBooking, 'startDate'>[]>
>(
  (fullBookingsByPlan, planId) => ({
    ...fullBookingsByPlan,
    [planId]: alreadyBookedCasesByPlan[planId].map(fakeBooking)
  }),
  {}
)

const generatePlans = (limit: number | undefined) => times(limit || randomIn({ max: 20 }), () => fakePlan())
export const planSuggestions = generatePlans(3)

export const generatePlanPermissionsByEnterpriseId = (
  enterpriseIds: string[]
): Record<string, PlanPermissionProps[] | undefined> => {
  const fakeEnterprisesPlanPermissionsAmount: Record<string, number> = enterpriseIds.reduce(
    (byId, enterpriseId, index) => ({ ...byId, [enterpriseId]: Math.min(index, 3) }),
    {}
  )

  return enterpriseIds.reduce<Record<string, PlanPermissionProps[] | undefined>>(
    (byId, enterpriseId) => ({
      ...byId,
      [enterpriseId]: times(fakeEnterprisesPlanPermissionsAmount[enterpriseId], (index) =>
        fakePlanPermissions({
          enterpriseId,
          // Try to get different plans unless we go over the amount of enterprise plans. In that case go random
          planId:
            enterprisePlans[
              index >= enterprisePlans.length - 1 ? randomIn({ min: 0, max: enterprisePlans.length - 1 }) : index
            ].id,
          // Make sure at least one plan permission has an inventory
          ...(index === 0 && { inventoryAllocation: randomIn({ min: 10, max: 200 }) })
        })
      )
    }),
    {}
  )
}
