import type { PlanWithPricingProps } from '@commutifi/models/Plan'
import faker from 'faker/locale/en'
import times from 'lodash/times'
import { GroupProps } from 'api/modules/groups'
import { randomIn } from '../../_utils'
import { fakeEnterpriseOffices, fakeEnterpriseAttributes } from '../enterprises/data'
import { generatePlanPricing } from '../plans/fakers'
import { EnterpriseGroupPlan, fakeEnterpriseGroupPlan } from './fakers'
import { enterprisePlans } from '../../shared/data'

export type { EnterpriseGroupPlan }

const allMembersCount = 14
const allMembers = times(allMembersCount, () => ({
  accountId: faker.datatype.uuid()
}))

const staticId1 = '73fa124b-f421-4600-aa73-89423c8b9914'
const staticId2 = '73fa124b-f421-4600-aa73-89493c8b1331'
export const fakeEnterpriseGroupPlanIds = [
  enterprisePlans[0],
  enterprisePlans[1],
  enterprisePlans[2],
  enterprisePlans[3],
  enterprisePlans[4]
]
export const fakeStaticGroups = [
  {
    id: staticId1,
    name: 'All Employees',
    enterpriseId: 'string',
    members: allMembers,
    dynamicMemberships: [],
    enterpriseGroupPlans: [
      fakeEnterpriseGroupPlan({ enterpriseGroupId: staticId1, planId: fakeEnterpriseGroupPlanIds[0].id }),
      fakeEnterpriseGroupPlan({ enterpriseGroupId: staticId1, planId: fakeEnterpriseGroupPlanIds[1].id }),
      fakeEnterpriseGroupPlan({ enterpriseGroupId: staticId1, planId: fakeEnterpriseGroupPlanIds[2].id })
    ],
    isAllEmployees: false,
    isDynamic: false
  },
  {
    id: staticId2,
    name: 'Manual Group',
    enterpriseId: 'string',
    members: allMembers.slice(0, randomIn({ min: 0, max: allMembersCount })),
    dynamicMemberships: [],
    enterpriseGroupPlans: [
      fakeEnterpriseGroupPlan({ enterpriseGroupId: staticId2, planId: fakeEnterpriseGroupPlanIds[3].id }),
      fakeEnterpriseGroupPlan({ enterpriseGroupId: staticId2, planId: fakeEnterpriseGroupPlanIds[4].id })
    ],
    isAllEmployees: false,
    isDynamic: false
  }
]

export const fakeGroups: GroupProps[] = [
  fakeStaticGroups[0],
  {
    id: faker.datatype.uuid(),
    name: 'Exec Group',
    enterpriseId: 'string',
    members: allMembers.slice(0, randomIn({ min: 0, max: allMembersCount })),
    dynamicMemberships: [
      {
        id: faker.datatype.uuid(),
        groupId: faker.datatype.uuid(),
        personaPath: 'office.buildingId',
        personaValues: [fakeEnterpriseOffices[0].building.id]
      }
    ],
    enterpriseGroupPlans: []
  },
  {
    id: faker.datatype.uuid(),
    name: 'Top Building Employees',
    enterpriseId: 'string',
    members: allMembers.slice(0, randomIn({ min: 0, max: allMembersCount })),
    dynamicMemberships: [
      {
        id: faker.datatype.uuid(),
        groupId: faker.datatype.uuid(),
        personaPath: 'office.buildingId',
        personaValues: [fakeEnterpriseOffices[1].building.id]
      },
      {
        id: faker.datatype.uuid(),
        groupId: faker.datatype.uuid(),
        personaPath: 'office.buildingId',
        personaValues: [fakeEnterpriseOffices[2].building.id]
      }
    ],
    enterpriseGroupPlans: []
  },
  fakeStaticGroups[1],
  {
    id: faker.datatype.uuid(),
    name: 'Matrix Pill',
    enterpriseId: 'string',
    members: allMembers.slice(0, randomIn({ min: 0, max: allMembersCount })),
    dynamicMemberships: [
      {
        id: faker.datatype.uuid(),
        groupId: faker.datatype.uuid(),
        personaPath: 'office.buildingId',
        personaValues: [fakeEnterpriseOffices[0].building.id]
      },
      {
        id: faker.datatype.uuid(),
        groupId: faker.datatype.uuid(),
        personaPath: 'attributes.Favorite Primary Color',
        personaValues: fakeEnterpriseAttributes[0].answerOptions || []
      }
    ],
    enterpriseGroupPlans: []
  },
  {
    id: faker.datatype.uuid(),
    name: 'Student And Staff',
    enterpriseId: 'string',
    members: allMembers.slice(0, randomIn({ min: 0, max: allMembersCount })),
    dynamicMemberships: [
      {
        id: faker.datatype.uuid(),
        groupId: faker.datatype.uuid(),
        personaPath: 'attributes.Faculty',
        personaValues: fakeEnterpriseAttributes[0].answerOptions?.[0]
          ? [fakeEnterpriseAttributes[0].answerOptions[0]]
          : []
      }
    ],
    enterpriseGroupPlans: []
  },
  {
    id: faker.datatype.uuid(),
    name: 'Student Location',
    enterpriseId: 'string',
    members: allMembers.slice(0, randomIn({ min: 0, max: allMembersCount })),
    dynamicMemberships: [
      {
        id: faker.datatype.uuid(),
        groupId: faker.datatype.uuid(),
        personaPath: 'office.buildingId',
        personaValues: [fakeEnterpriseOffices[1].building.id]
      },
      {
        id: faker.datatype.uuid(),
        groupId: faker.datatype.uuid(),
        personaPath: 'attributes.Faculty',
        personaValues: fakeEnterpriseAttributes[0].answerOptions?.[0]
          ? [fakeEnterpriseAttributes[0].answerOptions[0]]
          : []
      }
    ],
    enterpriseGroupPlans: []
  }
]

export const fakeEnterpriseGroupsPlans: (PlanWithPricingProps & EnterpriseGroupPlan)[] = [
  {
    ...fakeStaticGroups[0].enterpriseGroupPlans[0],
    name: faker.lorem.word(),
    shortDescription: faker.lorem.sentence(),
    pricing: generatePlanPricing(randomIn({ min: 50, max: 100 }))
  },
  {
    ...fakeStaticGroups[0].enterpriseGroupPlans[1],
    name: faker.lorem.word(),
    shortDescription: faker.lorem.sentence(),
    pricing: generatePlanPricing(randomIn({ min: 50, max: 100 }))
  },
  {
    ...fakeStaticGroups[0].enterpriseGroupPlans[2],
    name: faker.lorem.word(),
    shortDescription: faker.lorem.sentence(),
    pricing: generatePlanPricing(randomIn({ min: 50, max: 100 }))
  },
  {
    ...fakeStaticGroups[1].enterpriseGroupPlans[0],
    name: faker.lorem.word(),
    shortDescription: faker.lorem.sentence(),
    pricing: generatePlanPricing(randomIn({ min: 50, max: 100 }))
  },
  {
    ...fakeStaticGroups[1].enterpriseGroupPlans[1],
    name: faker.lorem.word(),
    shortDescription: faker.lorem.sentence(),
    pricing: generatePlanPricing(randomIn({ min: 50, max: 100 }))
  }
]

export const fakeLegacyGroups = [
  { id: 1, name: 'General', isDefault: true },
  { id: 2, name: 'Executive', isDefault: true }
]

export const fakedSimulateGroupAccounts = times(randomIn({ min: 1, max: 9 }), () => ({
  id: faker.datatype.uuid(),
  name: faker.name.firstName()
}))
