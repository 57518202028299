// Defines the available question component types INCLUDING SOME FE CUSTOM TYPES.
// We need some sub components in the more complex ones like the commute router.
// Those sub components are identified by using this pattern: 'MAIN COMPONENT - sub component'.
// NOTE that the sub-components should not be defined on the questions types
// that we receive from our API

import { ReferenceKey } from '@commutifi/constants/ReferenceKeys'

export const REF_KEYS_LIST = ReferenceKey
export const subQuestionIdPrefix = 'sub-section__'
export const primaryRouteObjectId = 'primary'
export const routeAttribute = 'route'
export const primaryRoutePath = `${primaryRouteObjectId}.${routeAttribute}`
export const frequencyAttribute = 'frequency'
export const secondaryRouteObjectId = 'secondary'
export const secondaryRoutePath = `${secondaryRouteObjectId}.${routeAttribute}`
export const secondaryRouteFrequencyPath = `${secondaryRouteObjectId}.${frequencyAttribute}`
export const dropOffKidsQId = `${subQuestionIdPrefix}drop-off-kids-id`

export const DEFAULT_WEEKLY_FREQUENCY = 5

export enum FollowUpStorageKeys {
  SurveyType = 'surveyType',
  ShortlinkId = 'followUpShortlink',
  ProfileUpdate = 'isProfileUpdateRequired'
}
