import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { Modal as AntModal, ModalFuncProps, ModalProps as AntdModalProps } from 'antd'
import cx from 'classnames'
import React from 'react'
import { AppButtonProps } from '../AppButton'
import { Typography, TitleContainerProps } from '../AppTypography'
import { useLocaleReceiver } from '../locales/LocaleReceiver'
import { ModalFooter } from './components/ModalFooter'
import './styles.scss'

export type { ModalFuncProps }

const clsPrefix = 'app-modal'
const { Title } = Typography

export enum ModalPosition {
  Top = 'top',
  Bottom = 'bottom'
}
type PositionType = ModalPosition.Top | ModalPosition.Bottom

export enum ModalType {
  Default = 'default',
  FullScreen = 'full_screen'
}

export interface ModalProps extends AntdModalProps {
  children?: React.ReactNode
  // Position priority on small screens. This prop will be ignored for
  // bigger screens.
  position?: PositionType
  footerExtra?: React.ReactNode
  type?: ModalType
  footer?: React.ReactNode
}

interface ModalTitleProps extends TitleContainerProps {
  label: React.ReactNode
}

interface ModalHeaderProps {
  title: React.ReactNode
  // Extra content to show on the top left or top tight corner
  // Full page modal -> top right corner (since the designs changes the close button to be on the left in this case)
  // Otherwise -> top left corner
  extra?: React.ReactNode
}

export function Modal({
  width = 600,
  className,
  children,
  cancelButtonProps,
  okButtonProps,
  okText,
  cancelText,
  onOk,
  onCancel,
  position = ModalPosition.Top,
  footer,
  footerExtra,
  type = ModalType.Default,
  confirmLoading,
  ...props
}: ModalProps) {
  const intl = useLocaleReceiver('Modal')

  return (
    <AntModal
      width={type === ModalType.FullScreen ? '100%' : width}
      className={cx(
        clsPrefix,
        {
          [`${clsPrefix}--bottom`]: position === ModalPosition.Bottom,
          [`${clsPrefix}--full`]: type === ModalType.FullScreen
        },
        className
      )}
      closeIcon={<FontAwesomeIcon icon={faXmark} />}
      wrapClassName={cx(type === ModalType.FullScreen && `${clsPrefix}-wrap--full`)}
      footer={
        onOk && onCancel && ((typeof footer !== 'boolean' && !footer) || footer === true) ? (
          <ModalFooter
            cancelButtonProps={{
              ghost: true,
              className: 'u-font-weight-semi',
              onClick: onCancel as AppButtonProps['onClick'],
              ...cancelButtonProps,
              'data-testid': 'modal-cancel-btn'
            }}
            okButtonProps={{
              onClick: onOk as AppButtonProps['onClick'],
              ...okButtonProps,
              loading: okButtonProps?.loading || confirmLoading,
              'data-testid': 'modal-ok-btn'
            }}
            okText={okText || intl.formatMessage({ id: 'okText' })}
            cancelText={cancelText ?? intl.formatMessage({ id: 'cancelText' })}
          >
            {footerExtra}
          </ModalFooter>
        ) : !footer ? null : (
          <div className="u-flex u-flex-justify-end">{footer || null}</div>
        )
      }
      onCancel={onCancel}
      {...props}
    >
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex -- To reevaluate if needed */}
      <div tabIndex={0}>{children}</div>
    </AntModal>
  )
}

function ModalTitle({ className, label, style, ...props }: ModalTitleProps) {
  return (
    <Title
      level={1}
      variant="h3"
      className={cx(className)}
      style={{ marginBottom: 0, marginTop: 0, ...style }}
      {...props}
    >
      {label}
    </Title>
  )
}

function ModalHeader({ title, extra }: ModalHeaderProps) {
  return (
    <div className="u-flex-align-center u-flex-justify-center u-flex-1">
      {extra ? <div className={`${clsPrefix}-header--extra`} /> : null}
      <ModalTitle label={title} className={cx({ 'u-flex-1': extra })} style={{ textAlign: 'center' }} />
      {extra ? <span className={`${clsPrefix}-header--extra`}>{extra}</span> : null}
    </div>
  )
}

Modal.useModal = AntModal.useModal
Modal.success = AntModal.success
Modal.error = AntModal.error
Modal.confirm = AntModal.confirm
Modal.config = AntModal.config
Modal.warn = AntModal.warn
Modal.warning = AntModal.warning
Modal.info = AntModal.info
Modal.Title = ModalTitle
Modal.Header = ModalHeader
Modal.Footer = ModalFooter

export default Modal
