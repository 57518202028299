import { HttpMethod } from '../../constants'

function replaceOfficeId(officeId: string): string {
  return this.path.replace(':officeId', officeId)
}
function returnPath(): string {
  return this.path
}

export const officeEndpoints = {
  [HttpMethod.Get]: {
    OfficeSuggestions: {
      path: '/suggest/offices',
      route: returnPath
    }
  },
  [HttpMethod.Put]: {
    Office: {
      path: '/offices/:officeId',
      route: replaceOfficeId
    }
  },
  [HttpMethod.Delete]: {
    Office: {
      path: '/offices/:officeId',
      route: replaceOfficeId
    }
  }
}

export default officeEndpoints
