export const getCommutifiErrorCode = (error: any): number | undefined => {
  if (Object.prototype.hasOwnProperty.call(error, "error")) {
    return error.error?.data?.code
      ? typeof error.error?.data?.code === "string"
        ? parseInt(error.error?.data.code)
        : error.error?.data.code
      : undefined;
  } else if (Object.prototype.hasOwnProperty.call(error, "data")) {
    return error?.data?.code
      ? typeof error.data?.code === "string"
        ? parseInt(error.data.code)
        : error.data?.code
      : undefined;
  } else {
    return error.code ? parseInt(error.code) : error.code;
  }
};

export const getHttpStatusFromError = (error: any | undefined = {}) =>
  error &&
  (error.response?.status ||
    error.status ||
    error.statusCode ||
    error.httpCode);
