import types from './types'

export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    case types.SET_USER_LOCATION:
      return {
        ...state,
        ...action.coord
      }
    default:
      return state
  }
}
