import faker from 'faker/locale/en'
import { FetchAccountVehicleResponse } from 'api/modules/vehicles'

faker.seed(666)

export const fakeAccountVehicle = (
  baseVehicle: Partial<FetchAccountVehicleResponse> = {}
): FetchAccountVehicleResponse => {
  const vehicleId = baseVehicle.vehicleId || faker.datatype.uuid()
  const vehicleMake = faker.vehicle.manufacturer()
  const vehicleModel = faker.vehicle.model()
  const vehicleEngineType = faker.vehicle.fuel()
  return {
    id: baseVehicle.id || faker.datatype.uuid(),
    accountId: baseVehicle.accountId || faker.datatype.uuid(),
    vehicleId,
    // Allow to set vehicles with partial data to test use case where we prefill vehicle with the info we have
    color: Object.prototype.hasOwnProperty.call(baseVehicle, 'color') ? baseVehicle.color : faker.vehicle.color(),
    licensePlate: Object.prototype.hasOwnProperty.call(baseVehicle, 'licensePlate')
      ? baseVehicle.licensePlate
      : faker.random.alphaNumeric(6).toUpperCase(),
    licensePlateState: Object.prototype.hasOwnProperty.call(baseVehicle, 'licensePlateState')
      ? baseVehicle.licensePlateState
      : faker.address.state(),
    vehicle: baseVehicle.vehicle || {
      uuid: vehicleId,
      createdAt: faker.date.past().toUTCString(),
      make: vehicleMake,
      model: vehicleModel,
      year: faker.date.past().getFullYear(),
      engineType: vehicleEngineType
    }
  }
}
