import { HttpMethod } from '../../constants'

function replaceMobilityServiceId(mobilityServiceId: string): string {
  return this.path.replace(':mobilityServiceId', mobilityServiceId)
}
function returnPath(): string {
  return this.path
}

export const mobilityServiceEndpoints = {
  [HttpMethod.Get]: {
    MobilityServices: {
      path: '/mobility-services',
      route: returnPath
    },
    MobilityServiceOAuthUrl: {
      path: '/mobility-services/:mobilityServiceId/oauth-url',
      route: replaceMobilityServiceId
    }
  },
  [HttpMethod.Post]: {
    MobilityServiceAuth: {
      path: '/mobility-services/:mobilityServiceId/authorization',
      route: replaceMobilityServiceId
    },

    MobilityServiceAuthImplicit: {
      path: '/mobility-services/:mobilityServiceId/authorization/implicit',
      route: replaceMobilityServiceId
    }
  },
  [HttpMethod.Delete]: {
    MobilityServiceAuth: {
      path: '/mobility-services/:mobilityServiceId/authorization',
      route: replaceMobilityServiceId
    }
  }
}

export default mobilityServiceEndpoints
