import { get, startsWith } from 'lodash'
import { RcFile, TableSortOrder, TableSorterResult } from '@commutifi-fe/ui'
import { sortingOrdersEnum } from 'constants/global'
import { Location } from 'shared/interfaces'
import { ChatIdentify } from 'shared/interfaces/Chat'
import { DeconstructedPromiseType } from 'shared/interfaces/utils'
import logger from 'utils/logRocket'

const DAYS_PER_YEAR = 365
const WEEKS_PER_YEAR = 52
const MONTHS_PER_YEAR = 12
const SEC_PER_MIN = 60
const MIN_PER_HOUR = 60
const HOURS_PER_DAY = 24
const DAYS_PER_WEEK = 7
export const uuidPattern = '[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}'
const uuidRegex = new RegExp(uuidPattern)

type Last<T extends any[]> = T extends [...unknown[], infer L] ? L : never
export function wrapActionInPromise<F extends (...params: any[]) => any = (...params: any[]) => any>(
  action: F,
  ...args: Parameters<F>
): Promise<
  Last<Required<Parameters<F>>> extends DeconstructedPromiseType
    ? Awaited<ReturnType<Last<Required<Parameters<F>>>['resolve']>>
    : undefined
> {
  return new Promise((resolve, reject) => {
    action(...args, { resolve, reject })
  })
}

export const getDaysPerYear = () => DAYS_PER_YEAR
export const getWeeksPerYear = () => WEEKS_PER_YEAR
export const getMonthsPerYear = () => MONTHS_PER_YEAR
export const getSecondsPerWeek = () => SEC_PER_MIN * MIN_PER_HOUR * HOURS_PER_DAY * DAYS_PER_WEEK
export const minToSec = (min: number) => SEC_PER_MIN * min
export const secToMin = (sec: number) => sec / SEC_PER_MIN

export const isLastItem = (index: number, length: number) => index === length - 1
export const hasOneOrLess = (length: number) => length <= 1
export const hasMoreThanOne = (length: number) => length > 1
export const isFirst = (index: number) => index === 0
export const hasNext = (currentIndex: number, array: any[]) => Boolean(array[currentIndex + 1])
export const getArray = (obj: any) => (obj ? (!Array.isArray(obj) ? [obj] : obj) : [])

export const descendingCompare = (a: any, b: any) => (b < a ? -1 : b > a ? 1 : 0)

export const ascendingCompare = (a: any, b: any) => (a < b ? -1 : a > b ? 1 : 0)

/**
 * Return array of object object sorted by specified objects key
 * @param array - array to sort
 * @param key - object key to sort with
 */
export const sortByKey = (array: any[] | undefined, key: string, order = sortingOrdersEnum.ASC) =>
  (array || []).sort((a, b) => {
    const x = get(a, key)
    const y = get(b, key)
    return order === sortingOrdersEnum.ASC ? ascendingCompare(x, y) : descendingCompare(x, y)
  })

export const sortByKeys = (array: any[] | undefined, keys: { key: string; order: number }[]) =>
  (array || []).sort((a, b) =>
    keys.reduce((acc, k) => {
      const x = get(a, k.key)
      const y = get(b, k.key)
      return acc === 0 ? (k.order === sortingOrdersEnum.ASC ? ascendingCompare(x, y) : descendingCompare(x, y)) : acc
    }, 0)
  )
/**
 * Return the average of the sum of each object passed in param,
 * following the path determined in param
 * @param path - path to the key to sum
 * @param objs - objects to sum
 */
export const avgByKey = (path: string, ...objs: any[]) => {
  const avgFactors = objs.reduce(
    (avg, obj) => {
      const value = get(obj, path)
      return value >= 0 ? { sum: value + avg.sum, nb: ++avg.nb } : avg
    },
    { sum: 0, nb: 0 }
  )
  return avgFactors.nb ? avgFactors.sum / avgFactors.nb : 0
}

/**
 * Retrieve viewType from url of entity dashboard - /(entity)/:webId/:page?
 * @param url - url including view type (entity)
 */
export const getViewTypeFromUrl = (url: string) => {
  const urlTokens = url.split('/')
  return urlTokens.length > 0 ? urlTokens[1] : undefined
}

declare global {
  interface Window {
    StonlyWidget: (
      funcName: 'identify' | 'sendData' | 'setYOffset' | 'closeWidget',
      id?:
        | string
        | {
            contactForm?: {
              email?: string
            }
          }
        | number,
      opts?: Omit<ChatIdentify, 'id'>
    ) => any
  }
}

declare global {
  interface Window {
    STONLY_WID?: string
  }
}

/**
 * This function will init the chat app and identify the user
 * so the chatting experience will be more personalized
 * @param identity - identification of user entity
 */
export const initChatWidget = (identity: ChatIdentify) => {
  try {
    if (!window.STONLY_WID) return
    window.StonlyWidget('identify', identity.id, {
      enterpriseId: identity.enterpriseId,
      organizationId: identity.organizationId,
      segment: identity.segment,
      kind: identity.kind
    })
  } catch (error) {
    logger.error('Error while init a chat widget: ', error)
  }
}

export const sendDataChatWidget = (email: string) => {
  try {
    window.StonlyWidget('sendData', {
      contactForm: {
        email
      }
    })
  } catch (error) {
    logger.error('Error while sending data with email in chat widget: ', error)
  }
}

export const setYOffsetChatWidget = (bottom: number) => {
  try {
    if (!window.STONLY_WID) return
    window.StonlyWidget('setYOffset', bottom)
  } catch (error) {
    logger.error('Error with setYOffset in chat widget: ', error)
  }
}

export const closeChatWidget = () => {
  try {
    if (!window.STONLY_WID) return
    window.StonlyWidget('closeWidget')
  } catch (error) {
    logger.error('Error while closing chat widget: ', error)
  }
}

export const isSelfProvided = (str: string) => str && str.toUpperCase().includes('SELF_PROVIDED')
export const isUuid = (str: string | number | undefined): boolean =>
  Boolean(str && typeof str === 'string' && uuidRegex.test(str))

export const isNumber = (num: any) => num !== null && !Number.isNaN(num) && num !== undefined
export const normalizeString = (str: string) => (str ? str.toUpperCase().trim() : '')
export const noLetters = (str: string) => /[a-zA-Z]/g.test(str)

// Capitalize the first letter of a string
export const capitalizeString = (str: string | number, preserve = true) => {
  if (typeof str !== 'string') return str
  let capitalized = str
  if (!preserve) {
    capitalized = str.toLowerCase()
  }
  return capitalized.charAt(0).toUpperCase() + capitalized.substring(1)
}

/**
 * Returns the first letter of first and last name
 * @param name - Person name
 */
export const getInitials = (name: string) => {
  if (!name) return ''
  const initials = name.match(/\b\w/g) || []
  return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase()
}

export const getFirstTwoCapitalLetters = (str: string | null | undefined) => {
  if (!str) return ''
  const letters = str.match(/[A-Z]+/g)
  if (!letters) return ''
  return letters.join('').substring(0, 2)
}

// Thanks to
// https://medium.com/@pppped/compute-an-arbitrary-color-for-user-avatar-starting-from-his-username-with-javascript-cd0675943b66
export const stringToHslColor = (str: string, s = 30, l = 80) => {
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }

  const h = hash % 360
  return `hsl(${h}, ${s}%, ${l}%)`
}

/**
 * Source: https://stackoverflow.com/questions/36721830/convert-hsl-to-rgb-and-hex
 */
export const hslToHex = (hsl: { h: number; s: number; l: number } | string) => {
  const parseHsl = (innerHsl: string): { h: number; s: number; l: number } => {
    const parts = innerHsl.replace('hsl(', '').replace(')', '').trim().split(',')
    return { h: parseInt(parts[0]), l: parseInt(parts[1]), s: parseInt(parts[2]) }
  }

  const { h, l: originL, s } = typeof hsl === 'string' ? parseHsl(hsl) : hsl
  const l = originL / 100
  const a = (s * Math.min(l, 1 - l)) / 100
  const f = (n: number) => {
    const k = (n + h / 30) % 12
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1)
    return Math.round(255 * color)
      .toString(16)
      .padStart(2, '0') // convert to Hex and prefix "0" if needed
  }
  return `#${f(0)}${f(8)}${f(4)}`
}

/**
 * Gets the best color between white and black to contrast with another color
 * passed in param
 * Source: https://codepen.io/davidhalford/pen/AbKBNr
 */
export const getContrastedTextColor = (hex: string) => {
  /*
			From this W3C document: http://www.webmasterworld.com/r.cgi?f=88&d=9769&url=http://www.w3.org/TR/AERT#color-contrast
			
			Color brightness is determined by the following formula: 
			((Red value X 299) + (Green value X 587) + (Blue value X 114)) / 1000
      
      I know this could be more compact, but I think this is easier to read/explain.
			
			*/
  function cutHex(h: string) {
    return h.startsWith('#') ? h.substring(1, 7) : h
  }
  function hexToR(h: string) {
    return parseInt(cutHex(h).substring(0, 2), 16)
  }
  function hexToG(h: string) {
    return parseInt(cutHex(h).substring(2, 4), 16)
  }
  function hexToB(h: string) {
    return parseInt(cutHex(h).substring(4, 6), 16)
  }

  const threshold = 130 /* about half of 256. Lower threshold equals more dark text on dark background  */

  const hRed = hexToR(hex)
  const hGreen = hexToG(hex)
  const hBlue = hexToB(hex)

  const cBrightness = (hRed * 299 + hGreen * 587 + hBlue * 114) / 1000
  return cBrightness > threshold ? '#000000' : '#ffffff'
}

export const singularize = (word: string) => (word ? word.slice(0, -1) : '')

/**
 * Takes as input a query param sort - (+|-)SORT_NAME
 * and split the value to get the sort order and sort name
 * in an output object
 *
 * @param querySort - query parameter for a sort
 *
 * @example +startDate output is \{order: 'asc', sort: startDate\}
 */
export function toAntdSortFormat<RecordType>(
  querySort: string
): { sort: keyof RecordType; order: TableSortOrder } | undefined {
  if (!querySort || (!startsWith(querySort, '-') && !startsWith(querySort, '+'))) return
  const order = startsWith(querySort, '-') ? 'descend' : 'ascend'
  const sort = querySort.substring(1, querySort.length) as keyof RecordType
  return { order, sort }
}

export const toAntdPaginationFormat = (pagination = { limit: 15, total: 0, page: 1 }) => {
  const { limit, total, page } = pagination
  return { current: Number(page), pageSize: Number(limit), total: Number(total) }
}

export function toCustomSortFormat<T = any>(sorter: TableSorterResult<T> | null) {
  if (!sorter?.order || !sorter.columnKey) return ''
  return `${sorter.order === 'ascend' ? '+' : '-'}${sorter.columnKey}`
}

export const calculatePercentage = (value: number, total: number) => {
  if (total === 0 || value === 0) {
    return 0
  }
  return (value / total) * 100
}

/**
 * Determine if we should display the singular word or plural word.
 * This implies that a value between 0.1 and 0.4 should be singular (ceiled to 1).
 * Otherwise we round the value (1.1 to 1.4 will be singular but 1.5+ would be plural)
 * @param value - input value
 */
export const getCountForPlural = (value: number) => (value < 1 ? Math.ceil(value) : Math.round(value))

export const isEven = (num: number) => num % 2 === 0

// Source: https://blog.shovonhasan.com/using-promises-with-filereader/
// Take a input (type='upload') file as input and wrap the reading process in a Promise
export const readUploadedFileAsText = (inputFile: string | Blob | RcFile): Promise<string | ArrayBuffer | null> => {
  const temporaryFileReader = new FileReader()

  return new Promise((resolve, reject) => {
    if (typeof inputFile === 'string') {
      resolve(inputFile)
      return
    }

    temporaryFileReader.onerror = () => {
      temporaryFileReader.abort()
      reject(new DOMException('Problem parsing input file.'))
    }

    temporaryFileReader.onload = () => {
      resolve(temporaryFileReader.result)
    }
    temporaryFileReader.readAsText(inputFile)
  })
}

// For more details please see the link above
export const getDistanceFromLatLngInKm = (originLocation: Location | undefined, destLocation: Location | undefined) => {
  if (!originLocation || !destLocation) {
    return 0
  }

  const R = 6371 // Radius of the earth in km
  const dLat = deg2rad(destLocation.lat - originLocation.lat)
  const dLng = deg2rad(destLocation.lng - originLocation.lng)
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(originLocation.lat)) *
      Math.cos(deg2rad(destLocation.lat)) *
      Math.sin(dLng / 2) *
      Math.sin(dLng / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  const d = R * c // Distance in km
  return d
}

export const deg2rad = (deg: number) => deg * (Math.PI / 180)

export const limitNumberWithinRange = (num: number | string, min?: number, max?: number) => {
  const MIN = min || Number.MIN_VALUE
  const MAX = max || Number.MAX_VALUE
  const parsed = typeof num === 'string' ? parseInt(num) : num
  return Math.min(Math.max(parsed, MIN), MAX)
}
