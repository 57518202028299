import { useCallback, useEffect, useMemo, useState } from 'react'
import { merge } from 'lodash'
import { Theme, ThemeProvider as NivoThemeProvider, useTheme } from '@nivo/core'
import { ModeCategory } from '@commutifi/modes'
import { Themes } from '@commutifi-fe/ui/components/theme/constants'
import { ChartsThemeProviderProps } from './props'
import { extractThemeFromDocumentStyle } from '../theme'
import { ChartsThemeContext, ChartsThemeContextValue, defaultConfig } from './context'
import { colors } from '../theme/colors'
import defaultTheme from '../theme/themes/default.module.scss'
import darkTheme from '../theme/themes/dark.module.scss'

const fontFamily = 'Segma, sans-serif'
export const defaultNivoTheme: Partial<Theme> = {
  text: {
    fill: colors.neutralGray600,
    fontFamily
  },
  axis: {
    legend: { text: { fill: colors.neutralGray500 } },
    ticks: {
      text: { fill: colors.neutralGray600, fontSize: '10px', fontFamily },
      line: { stroke: colors.neutralGray200 }
    },
    domain: { line: { padding: 10 } }
  },
  crosshair: {
    line: { stroke: colors.neutralGray400 }
  },
  grid: {
    line: { stroke: colors.neutralGray200 }
  },
  legends: {
    text: { fill: colors.neutralGray600, fontFamily, fontWeight: 600 },
    title: { text: { fill: colors.neutralGray600, fontFamily } },
    ticks: {
      text: { fill: colors.neutralGray600, fontFamily },
      line: { stroke: colors.neutralGray200 }
    }
  },
  annotations: {
    text: { fill: colors.neutralGray600, fontFamily },
    link: { stroke: colors.neutralBlue500 }
  },
  tooltip: {
    container: {
      color: colors.neutralGray600
    }
  }
}

export function ChartsThemeProvider({ children, theme, colorScheme }: ChartsThemeProviderProps) {
  const [dynamicColors, setDynamicColors] = useState<ReturnType<typeof extractThemeFromDocumentStyle>>(
    extractThemeFromDocumentStyle()
  )

  const changeTheme = useCallback((newTheme: `${Themes}`) => {
    switch (newTheme) {
      case Themes.DARK: {
        /**
         * Apply values from the theme file
         */
        Object.entries(darkTheme).forEach(([key, value]) => {
          document.documentElement.style.setProperty(key, value)
        })

        break
      }
      default:
        Object.entries(defaultTheme).forEach(([key, value]) => {
          document.documentElement.style.setProperty(key, value)
        })
    }
    // Modifying the document styles won't trigger a re-render so we 'force' it by updating the state used
    // to set the Theme Provider value
    setDynamicColors(extractThemeFromDocumentStyle())
  }, [])

  useEffect(() => {
    changeTheme(colorScheme || Themes.LIGHT)
  }, [changeTheme, colorScheme])

  const memoedTheme = useMemo(() => merge({}, defaultNivoTheme, theme), [theme])
  const nivoTheme = useTheme()

  const memoedConfig = useMemo<ChartsThemeContextValue>(
    () =>
      merge({}, defaultConfig, {
        tokens: dynamicColors,
        theme: merge({}, memoedTheme, nivoTheme),
        customPalettes: {
          blue: [
            dynamicColors.chartNeutralBlue500,
            dynamicColors.chartNeutralBlue200,
            dynamicColors.chartNeutralBlue600,
            dynamicColors.chartNeutralBlue100,
            dynamicColors.chartNeutralBlue400,
            dynamicColors.chartNeutralBlue300
          ],
          blueDescending: [
            dynamicColors.chartNeutralBlue600,
            dynamicColors.chartNeutralBlue500,
            dynamicColors.chartNeutralBlue400,
            dynamicColors.chartNeutralBlue300,
            dynamicColors.chartNeutralBlue200,
            dynamicColors.chartNeutralBlue100
          ],
          perModeCategory: {
            [ModeCategory.Carpool]: [
              dynamicColors.chartAccentYellow200,
              dynamicColors.chartAccentYellow600,
              dynamicColors.chartAccentYellow500,
              dynamicColors.chartAccentYellow400,
              dynamicColors.chartAccentYellow300,
              dynamicColors.chartAccentYellow100
            ],
            [ModeCategory.SharedRide]: [
              dynamicColors.chartAccentBeige400,
              dynamicColors.chartAccentBeige600,
              dynamicColors.chartAccentBeige500,
              dynamicColors.chartAccentBeige300,
              dynamicColors.chartAccentBeige200,
              dynamicColors.chartAccentBeige100
            ],
            [ModeCategory.Bike]: [
              dynamicColors.chartAccentMint500,
              dynamicColors.chartAccentMint600,
              dynamicColors.chartAccentMint400,
              dynamicColors.chartAccentMint300,
              dynamicColors.chartAccentMint200,
              dynamicColors.chartAccentMint100
            ],
            [ModeCategory.PublicTransit]: [
              dynamicColors.chartAccentGreen400,
              dynamicColors.chartAccentGreen600,
              dynamicColors.chartAccentGreen500,
              dynamicColors.chartAccentGreen300,
              dynamicColors.chartAccentGreen200,
              dynamicColors.chartAccentGreen100
            ],
            [ModeCategory.Walk]: [
              dynamicColors.chartAccentPistachio300,
              dynamicColors.chartAccentPistachio600,
              dynamicColors.chartAccentPistachio500,
              dynamicColors.chartAccentPistachio400,
              dynamicColors.chartAccentPistachio200,
              dynamicColors.chartAccentPistachio100
            ],
            [ModeCategory.Drive]: [
              dynamicColors.chartAccentPurple500,
              dynamicColors.chartAccentPurple600,
              dynamicColors.chartAccentPurple400,
              dynamicColors.chartAccentPurple300,
              dynamicColors.chartAccentPurple200,
              dynamicColors.chartAccentPurple100
            ],
            [ModeCategory.Remote]: [
              dynamicColors.chartAccentPink400,
              dynamicColors.chartAccentPink600,
              dynamicColors.chartAccentPink500,
              dynamicColors.chartAccentPink300,
              dynamicColors.chartAccentPink200,
              dynamicColors.chartAccentPink100
            ],
            [ModeCategory.None]: [
              dynamicColors.chartNeutralBlue500,
              dynamicColors.chartNeutralBlue200,
              dynamicColors.chartNeutralBlue600,
              dynamicColors.chartNeutralBlue100,
              dynamicColors.chartNeutralBlue400,
              dynamicColors.chartNeutralBlue300
            ]
          }
        }
      }),
    [dynamicColors, memoedTheme, nivoTheme]
  )

  return (
    <NivoThemeProvider theme={memoedTheme}>
      <ChartsThemeContext.Provider value={memoedConfig}>{children}</ChartsThemeContext.Provider>
    </NivoThemeProvider>
  )
}

export default ChartsThemeProvider
