import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { resetAllFilters } from '../actions'

const initialState: { selectedCities: string[] | undefined } = {
  selectedCities: undefined
}

export const citiesSlice = createSlice({
  name: 'cities',
  initialState,
  reducers: {
    setSelectedCities: (state, action: PayloadAction<string[]>) => {
      state.selectedCities = action.payload
    },
    resetCities: (state) => {
      state.selectedCities = initialState.selectedCities
    }
  },
  extraReducers(builder) {
    builder.addCase(resetAllFilters, (state) => {
      state.selectedCities = initialState.selectedCities
    })
  }
})

const { setSelectedCities, resetCities } = citiesSlice.actions

const citiesReducer = citiesSlice.reducer

export { setSelectedCities, resetCities, citiesReducer }
