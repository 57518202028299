import { all } from 'redux-saga/effects'
// ------------------------------------
// Import each module saga here
// ------------------------------------
import { accountSagas } from './modules/accounts/sagas'
import { authSagas } from './modules/auth/sagas'
import { benefitsSagas } from './modules/benefits/sagas'
import { enterprisesSagas } from './modules/enterprise/sagas'
import { enterpriseSurveySagas } from './modules/enterpriseSurvey/sagas'
import { localSettingsSagas } from './modules/localSettings/sagas'
import { metricsSagas } from './modules/metrics/sagas'
import { plansSagas } from './modules/plans/sagas'
import { providersSagas } from './modules/providers/sagas'
import { routesSagas } from './modules/routes/sagas'
import { smsSagas } from './modules/sms/sagas'

export default function* rootSaga() {
  yield all([
    ...accountSagas,
    ...enterprisesSagas,
    ...authSagas,
    ...metricsSagas,
    ...plansSagas,
    ...providersSagas,
    ...routesSagas,
    ...benefitsSagas,
    ...smsSagas,
    ...enterpriseSurveySagas,
    ...localSettingsSagas
  ])
}
