import { Alert as AntdAlert } from 'antd'
import { AlertProps } from 'antd/es/alert'
import cx from 'classnames'
import './styles.scss'

const AlertClass = 'app-alert'

/**
 * This function is a wrap around [antd Alert](https://ant.design/components/alert/) component extending some of
 * the properties to follow our styling guidelines.
 *
 * Please refer to antd documentation for more details.
 *
 */
export function Alert({ className, ...props }: AlertProps) {
  return <AntdAlert className={cx(className, AlertClass)} {...props} />
}

export default Alert
