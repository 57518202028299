import { MeasureTypes } from "./units";

export enum Factors {
  Time = "time",
  Cost = "cost",
  Carbon = "carbon",
  Score = "score",
}

export const MeasureTypeByFactor = {
  [Factors.Cost]: MeasureTypes.Currency,
  [Factors.Time]: MeasureTypes.Time,
  [Factors.Carbon]: MeasureTypes.Mass,
};
