import type { Overwrite } from 'utility-types'
import faker from 'faker/locale/en'
import type { BuildingProps } from '@commutifi/models/Buildings'
import type { OfficeProps } from '@commutifi/models/Offices'
import { OfficeWithBuilding } from '@commutifi-fe/interfaces'
import { fakeAddress, fakeCommutifiEmail, randomIn } from '../../_utils'

export const fakeOffice = (
  baseOffice: Partial<Overwrite<OfficeProps, { building: Partial<BuildingProps> }>> = {}
): OfficeWithBuilding => {
  const buildingId = baseOffice.buildingId || faker.datatype.uuid()
  const lat = baseOffice.building?.location?.lat || parseFloat(faker.address.latitude())
  const lng = baseOffice.building?.location?.lng || parseFloat(faker.address.longitude())

  return {
    id: baseOffice.id || faker.datatype.uuid(),
    createdAt: faker.date.past().toISOString(),
    updatedAt: faker.date.past().toISOString(),
    assignedPerson: {
      name: `${faker.name.firstName()} ${faker.name.lastName()}`,
      email: fakeCommutifiEmail(),
      phoneNumber: faker.phone.phoneNumber(),
      ...baseOffice.assignedPerson
    },
    squareFootage: baseOffice.squareFootage || randomIn({ min: 2000, max: 10000 }),
    occupancy: baseOffice.occupancy || randomIn({ min: 50, max: 1000 }),
    enterpriseId: baseOffice.enterpriseId || faker.datatype.uuid(),
    buildingId,
    building: {
      id: buildingId,
      createdAt: faker.date.past().toISOString(),
      updatedAt: faker.date.past().toISOString(),
      name: faker.commerce.department(),
      ...fakeAddress(baseOffice.building),
      location: {
        lat,
        lng
      }
    }
  }
}
