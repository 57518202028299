const pre = 'providers/'

export default {
  // Action types with no side-effects (no api calls, only change state)
  FETCH_PROVIDER: `${pre}FETCH_PROVIDER`,
  FETCH_PROVIDERS: `${pre}FETCH_PROVIDERS`,

  // Action types with side-effects
  FETCH_PROVIDERS_REQUEST: `${pre}FETCH_PROVIDERS_REQUEST`,
  FETCH_PROVIDERS_SUCCESS: `${pre}FETCH_PROVIDERS_SUCCESS`,
  FETCH_PROVIDERS_FAILURE: `${pre}FETCH_PROVIDERS_FAILURE`,
  FETCH_PROVIDERS_CANCEL: `${pre}FETCH_PROVIDERS_CANCEL`
}
