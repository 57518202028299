import { apiCall, dashboardApiServer } from '../../index'
import endpoints from './endpoints'

const apiDashboardServer = apiCall(dashboardApiServer)

/**
 * Legacy users might have a link with a userId in it
 * This endpoints fetches the accountId from the userId so we
 * can redirect the user to the right page
 * @param id - Legacy user id (NOT an account id)
 * @returns Account id to link to dashboard URL
 */
export const legacyFetchAccountId = (id: string) =>
  apiDashboardServer(endpoints.GET.LegacyFetchAccountId.route(id), {
    method: 'GET'
  })

export const fetchEnterprise = (id: string) =>
  apiDashboardServer(endpoints.GET.EnterpriseDashboard.route(id), {
    method: 'GET'
  })
