import omit from 'lodash/omit'
import { combineReducers } from 'redux'
import types from './types'

const generateStateWithIndex = (prevState, newIndex) => {
  const { maxIndex, visited } = prevState
  const visitedUpdated = { ...visited, [newIndex]: true }
  return {
    ...prevState,
    index: newIndex,
    maxIndex: maxIndex > newIndex ? maxIndex : newIndex,
    visited: visitedUpdated
  }
}

export const progression = (state = {}, action) => {
  const id = action.id
  let prevStepInfos
  let newIndex
  switch (action.type) {
    case types.INIT: {
      const nbOfSteps = action.nbSteps
      const initVisitedSteps = {}
      for (let i = 0; i < nbOfSteps; i++) {
        i === 0 ? (initVisitedSteps[i] = true) : (initVisitedSteps[i] = false)
      }
      return {
        ...state,
        [id]: {
          ...state[id],
          index: 0,
          maxIndex: 0,
          nbOfSteps,
          visited: initVisitedSteps
        }
      }
    }
    case types.PREVIOUS_STEP: {
      const previousIndex = state[id].index - 1
      return {
        ...state,
        [id]: {
          ...state[id],
          index: previousIndex >= 0 ? previousIndex : 0
        }
      }
    }
    case types.NEXT_STEP: {
      prevStepInfos = state[id]
      const nextStep = prevStepInfos.index + 1
      newIndex = nextStep < prevStepInfos.nbOfSteps ? nextStep : prevStepInfos.index
      return {
        ...state,
        [id]: generateStateWithIndex(prevStepInfos, newIndex)
      }
    }
    case types.SET_STEP: {
      const setIndex = action.index
      prevStepInfos = state[id]
      if (setIndex >= prevStepInfos.nbOfSteps) {
        return { ...state, [id]: prevStepInfos }
      }
      return {
        ...state,
        [id]: generateStateWithIndex(prevStepInfos, setIndex)
      }
    }
    case types.RESET:
      return omit(state, action.id)
    default:
      return state
  }
}

export default combineReducers({
  progression
})
