import { HttpMethod } from '../../constants'

export const tripEndpoints = {
  [HttpMethod.Get]: {
    AccountTrips: {
      path: '/accounts/:accountId/trips',
      route(accountId: string): string {
        return this.path.replace(':accountId', accountId)
      }
    }
  },
  [HttpMethod.Post]: {
    SelfReportedTrips: {
      path: '/trips/self-reported',
      route(): string {
        return this.path
      }
    }
  }
}

export default tripEndpoints
