import { createSelector } from 'reselect'

export const getFetchStatus = ({ benefits }) => benefits.fetchStatus
export const getSubsidiesByPlanId = ({ benefits }) => benefits.subsidiesByPlanId
export const makeGetSubsidiesSum = (planId) =>
  createSelector([getSubsidiesByPlanId], (subsidiesByPlanId) => {
    const subsidies = subsidiesByPlanId[planId]
    if (!subsidies) return 0
    return subsidies.reduce((sum, subsidy) => sum + subsidy, 0)
  })

/**
 * Return the sommation of all plans subsidies. Ex. if a route has 2 plans:
 * plan 1 -> subsidy 20 + subsidy 15
 * plan 2 -> subsidy 10
 * The total will be 45
 * How ? Map all plan to sum a single plan subsidies. Then, sum all the plan subsidies
 * to make the plans total subsidies
 * @param {*} planIds
 **/
export const makeGetPlansSubsidiesSum = (planIds) => (state) =>
  planIds.map((planId) => makeGetSubsidiesSum(planId)(state)).reduce((sum, planSubsidies) => sum + planSubsidies, 0)
