import { PlanBillingMethod } from '@commutifi/models/Plan'
import { clone, isNil, omit, pickBy } from 'lodash'
import slugify from 'slugify'
import { RequestParams } from 'shared/interfaces/Api'
import { formatSearchQuery } from 'utils/formatters'
import { captureError } from 'utils/helpers/errors'
import { calculatePageOffset } from 'utils/helpers/pagination'

/**
 * Format query params to request a paginated resource with
 * offset, limit, filters, search and sort parameters
 * @param queryParams - Non formatted params
 * @param options - Extra options like the page limit
 */
export const formatPaginationQueryParams = (
  qs: ({ search?: string; page?: number; sort?: string } & Record<string, any>) | undefined,
  options: { limit: number | undefined } = { limit: 15 }
) => {
  const queryParams = clone(qs) || {}
  queryParams.sort = Array.isArray(queryParams.sort) ? queryParams.sort.join(',') : queryParams.sort
  const params: RequestParams = {
    // Filter undefined values in the object (ex. {a: 'value', b: undefined} becomes { a: 'value' } )
    ...pickBy(queryParams, (q) => !isNil(q)),
    ...formatSearchQuery(queryParams.search),
    ...(options.limit && { limit: options.limit, offset: calculatePageOffset(queryParams.page || 1, options.limit) })
  }

  // this param is called searchTerm in the apis, search is replaced by that param earlier
  // page param is splitted in offset and limit
  const omitOldPropsRenamedToMatchApi = ['search', 'page']
  if (params.sort === '') {
    omitOldPropsRenamedToMatchApi.push('sort')
  }
  return omit(params, omitOldPropsRenamedToMatchApi)
}

/**
 * Update query param dictated in function parameters (key and value)
 * and remove it if the value is empty:
 * - Array - length of 0 means we don't want to send any filter in the query
 * - string - Empty string means we don't want to send any search term in the query
 * @param previousParams - Previous state of query params
 * @param key - identifier of the updated param
 * @param value - value of the updated param
 */
export const addOrDeleteQueryParam = (previousParams: Record<string, any>, key: string, value: any) => {
  const q = {
    ...previousParams,
    [key]: value
  }
  if ((Array.isArray(value) && value.length === 0) || !value) {
    // eslint-disable-next-line @typescript-eslint/no-dynamic-delete -- Legacy
    delete q[key]
  }
  return q
}

export function delay<T>(ms: number, res: T) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(res)
    }, ms)
  })
}

export function slugifyString(string: string): string {
  return slugify(string, {
    replacement: '_',
    remove: undefined,
    lower: true,
    trim: true
  })
}

/**
 * This function is used to validate we don't display invalid plan pricing with taxes. It will
 * silently fail on the UI but we will record a Sentry error. This is to avoid displaying
 * invalid plans costs that users will pay on OUR PLATFORM (thus the check for PlanBillingMethod = STRIPE)
 */
export const captureIsPlanValid = (plan: Record<string, any> | undefined, extra?: Record<string, any>) => {
  if (plan?.billingMethod === PlanBillingMethod.STRIPE && !('pricing' in plan)) {
    captureError(new Error('Pricing object is required on enterprise plans'), {
      extra: { plan, endpoint: extra }
    })
    return false
  }
  return true
}
