import { useQuery, useMutation } from '@tanstack/react-query'
import { MutationConfig, QueryConfigPickRes } from '../../reactQuery'
import { fetchTrips, postSelfReportedTrips } from './api'

export const useTrips = (
  accountId: string,
  queryParams: Parameters<typeof fetchTrips>[1],
  options?: QueryConfigPickRes<typeof fetchTrips, 'records'>
) =>
  useQuery({
    queryKey: ['trips', accountId, queryParams],
    queryFn: async () => {
      const { records } = await fetchTrips(accountId, queryParams)
      return records
    },
    ...options
  })

export const usePostSelfReportedTrips = (options?: MutationConfig<typeof postSelfReportedTrips>) =>
  useMutation({ mutationFn: (body) => postSelfReportedTrips(body), ...options })
