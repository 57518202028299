import types from './types'

export const fetchProvider = (id) => ({
  type: types.FETCH_PROVIDER,
  id
})

export const fetchProviderRequest = (id) => ({
  type: types.FETCH_PROVIDERS_REQUEST,
  id
})

export const fetchProviderSuccess = (id, payload) => ({
  type: types.FETCH_PROVIDERS_SUCCESS,
  id,
  payload
})

export const fetchProviderFailure = (id, error) => ({
  type: types.FETCH_PROVIDERS_FAILURE,
  id,
  error
})

export const fetchProviderCancel = () => ({
  type: types.FETCH_PROVIDERS_CANCEL
})

export const fetchProviders = (ids) => ({
  type: types.FETCH_PROVIDERS,
  ids
})
