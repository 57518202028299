import { StripePostalAddress } from '@commutifi/models/shared/StripePostalAddress'
import { MERCHANT_CATEGORY_CODES } from '@commutifi/constants/Stripe'
import { BookingProps } from '@commutifi/models/Bookings'
import keyBy from 'lodash/keyBy'
import { CardProps } from '@commutifi/models/Cards'
import { apiCall, dashboardApiServer, JSON_HEADER } from 'api/index'
import { PostAccountWalletBody } from '../wallets'
import endpoints from './endpoints'

export type { BookingProps, CardProps }

export const categoriesById = keyBy(MERCHANT_CATEGORY_CODES, 'category')

const apiDashboardServer = apiCall(dashboardApiServer)

export const fetchVirtualCard = (cardId: string): Promise<{ cardNumber: string; cvc: number }> =>
  apiDashboardServer(endpoints.GET.CardNumber.route(cardId), {
    method: 'get'
  })

export type PostCardBody = PostAccountWalletBody['holder'] & {
  accountId: string
  timezone: string
  walletId: string
}
export const postCard = (body: PostCardBody): Promise<CardProps> =>
  apiDashboardServer(endpoints.POST.Card.route(), {
    method: 'POST',
    headers: JSON_HEADER,
    data: JSON.stringify(body)
  })

export type PatchCardAddressesBody = {
  billingAddress?: StripePostalAddress
  shippingAddress?: StripePostalAddress
  ownerFirstName?: string
  ownerLastName?: string
  phoneNumber?: string
}
export const patchCardAddresses = (cardId: string, body: PatchCardAddressesBody): Promise<CardProps> =>
  apiDashboardServer(endpoints.PATCH.CardAddresses.route(cardId), {
    method: 'PATCH',
    headers: JSON_HEADER,
    data: JSON.stringify(body)
  })

export const activateCard = (cardId: string): Promise<CardProps> =>
  apiDashboardServer(endpoints.PATCH.ActivateCard.route(cardId), {
    method: 'PATCH'
  })
