import { Account } from '@commutifi-fe/interfaces'
import { validateAndExtractClientState } from 'shared/hooks/oauth/utils'
import { apiCall, dashboardApiServer, JSON_HEADER } from '../../index'
import endpoints from './endpoints'

// Uses api-platform for request
const apiDashboardServer = apiCall(dashboardApiServer)

export interface LoginBody {
  email: string
  password: string
}

export interface LoginResponse {
  redirectPath?: string
  account?: Account
}

export interface CreatePasswordResponse {
  account: Account
}

export type PasswordResetBody = Pick<LoginBody, 'password'> & { passwordResetToken: string }

export const loginUser = (auth: LoginBody): Promise<LoginResponse> =>
  apiDashboardServer(endpoints.POST.Authentication.route(), {
    method: 'post',
    headers: JSON_HEADER,
    data: JSON.stringify(auth)
  })

export const logoutUser = () =>
  apiDashboardServer(endpoints.POST.Logout.route(), {
    method: 'post',
    headers: JSON_HEADER
  })

export const refreshAccessToken = () =>
  apiDashboardServer(endpoints.PUT.RefreshToken.route(), {
    method: 'put',
    headers: JSON_HEADER
  })

export const requestPasswordReset = (user: Pick<LoginBody, 'email'>) =>
  apiDashboardServer(endpoints.POST.ForgotPassword.route(), {
    method: 'post',
    headers: JSON_HEADER,
    data: JSON.stringify(user)
  })

export const resetPassword = (auth: PasswordResetBody) =>
  apiDashboardServer(endpoints.POST.ResetPassword.route(), {
    method: 'post',
    headers: JSON_HEADER,
    data: JSON.stringify(auth)
  })

export const createPassword = (data: any): Promise<CreatePasswordResponse> =>
  apiDashboardServer(endpoints.POST.CreatePassword.route(), {
    method: 'post',
    headers: JSON_HEADER,
    data: JSON.stringify(data)
  })

export const fetchIdentityProviderUrl = (identityProviderId: string, queryParams: Record<string, any>) =>
  apiDashboardServer(endpoints.GET.IDPUrl.route(identityProviderId), {
    method: 'get',
    queryParams
  })

export interface SSOLoginState {
  targetUrl: string
  identityProviderId: string
  shortlinkId?: string
  subdomain: string
}

export function signWithIdentityProvider<Res = any>(oauthParams: {
  authorizationCode: string
  state: string
  nonce: string
  identityProviderId: string
}): Promise<Res> {
  const state = validateAndExtractClientState<SSOLoginState>()
  return apiDashboardServer(endpoints.POST.IDPAuth.route(state?.identityProviderId || ''), {
    method: 'post',
    data: oauthParams,
    headers: { ...(state?.shortlinkId && { 'x-commutifi-shortlinkid': state.shortlinkId }) }
  })
}
