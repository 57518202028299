import get from 'lodash/get'
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import { Account } from '@commutifi-fe/interfaces'
import { VerifyPasswordResponse, verifyPasswordNeeded } from 'api/modules/accounts'
import { clearAuthenticated, saveAuthenticated } from 'api/index'
import * as authApi from 'api/modules/authentication'
import { setFakeAccountId } from 'api/__mocks__/modules/accounts'
import { AUTH_ERROR_TYPES } from '@commutifi-fe/constants'
import { captureError } from 'utils/helpers/errors'
import * as actions from './actions'
import * as types from './types'

export function* login({
  auth,
  options,
  promise
}: actions.LoginAction<Account | { redirectPath?: string } | undefined>) {
  try {
    // Server call
    const res: authApi.LoginResponse | undefined = yield call(authApi.loginUser, auth)

    if (res?.redirectPath) {
      if (promise) yield call(promise.resolve, { redirectPath: res.redirectPath })
    }

    const accountId = get(res, 'account.id')
    if (!accountId) {
      throw new Error('We could not authenticate the user')
    }

    // For test purpose ONLY
    if (process.env.ENABLE_API_MOCK === 'true') {
      setFakeAccountId(accountId)
    }

    yield put(actions.loginSuccess(accountId, options))

    if (promise) yield call(promise.resolve, res?.account)
  } catch (error: any) {
    const errorKey = get(error, 'data.error')
    const formattedError = {
      messageKey:
        AUTH_ERROR_TYPES[errorKey as keyof typeof AUTH_ERROR_TYPES] ||
        AUTH_ERROR_TYPES[error?.status as keyof typeof AUTH_ERROR_TYPES] ||
        AUTH_ERROR_TYPES[500],
      error
    }
    yield put(actions.loginFailure(formattedError))
    if (promise) yield call(promise.reject, formattedError)
  }
}

export function* loginSuccess({ accountId, promise, options }: actions.LoginSuccessAction<string>) {
  yield call(saveAuthenticated, accountId, options)
  if (promise) yield call(promise.resolve, accountId)
}

const timeoutCodes = new Set([4, 0])
export function* logout({ promise, payload }: actions.LogoutAction) {
  try {
    yield call(authApi.logoutUser)
    yield call(clearAuthenticated)
    yield put(actions.logoutSuccess(payload))
    if (promise) promise.resolve()
  } catch (e) {
    if (e && timeoutCodes.has(e?.readyState) && timeoutCodes.has(e?.status)) {
      yield call(clearAuthenticated)
      yield put(actions.logoutSuccess(payload))
    }
    if (promise) promise.reject(e)
  }
}

export function* createPassword({ values, accountId, promise }: actions.CreatePasswordAction<Account>) {
  try {
    const data: authApi.CreatePasswordResponse = yield call(authApi.createPassword, values)
    yield call(saveAuthenticated, data.account.id)
    yield put(actions.createPasswordSuccess(accountId))

    if (promise) yield call(promise.resolve, data.account)
  } catch (error) {
    yield put(actions.createPasswordFailure(error))
    if (promise) yield call(promise.reject, error)
  }
}

export function* verifyUserHasPassword({ accountId, promise }: actions.VerifyPasswordRequiredAction) {
  try {
    const passwordNeeded: VerifyPasswordResponse = yield call(verifyPasswordNeeded, accountId)
    if (promise) promise.resolve(passwordNeeded)
  } catch (error) {
    captureError(error)
    if (promise) promise.reject(error)
  }
}

export const authSagas = [
  takeEvery(types.LOGIN_REQUEST, login),
  takeEvery(types.LOGOUT, logout),
  takeEvery(types.CREATE_PASSWORD_REQUEST, createPassword),
  takeLatest(types.VERIFY_HAS_PASSWORD, verifyUserHasPassword),
  takeLatest(types.LOGIN_SUCCESS, loginSuccess)
]
