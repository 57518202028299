import { CurrencyCode } from '@commutifi/constants/Locale'
import { LocalesType } from '@commutifi-fe/constants'
import { getLocaleMeasurementUnits } from '@commutifi-fe/utils'
import React from 'react'

export const MetricsContext = React.createContext<MetricsCardContextValue | null>(null)

export interface MetricsCardContextValue {
  isLoading: boolean
  locale: LocalesType
  currency: `${CurrencyCode}`
  localeMeasurementUnits: ReturnType<typeof getLocaleMeasurementUnits>
}
