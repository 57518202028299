// Make sure you define a translation key for
// each entry you add in this constant
export const AUTH_ERROR_TYPES = {
  deactivated: "error.accountDeactivated",
  401: "error.loginFailure",
  500: "error.tryAgain",
};

export const CANCELLED_API_REQ = "req.cancelled";

export enum UNAUTHORIZED_ERROR_CODES {
  ExpiredToken = "expiredToken",
  Unauthorized = "unauthorized",
}

export enum ErrorCodes {
  InvalidGrant = "invalid_grant",
  AccessDenied = "access_denied",
  ArcOAuthError = "arc_oauth",
  StripeOAuthError = "stripe_oauth",
}

export enum SuccessCodes {
  ArcOauth = "arc_oauth",
}
