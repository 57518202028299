import React from 'react'
import { Divider as AntdDivider } from 'antd'
import { DividerProps as AntdDividerProps } from 'antd/es/divider'
import cx from 'classnames'
import './styles.scss'

const PrefixCls = 'app-divider'

export enum MarginSize {
  // !Warning: Please sync those values with the scss
  None = 'none',
  Small = 'small',
  Small2 = 'small-2',
  Medium = 'medium',
  Medium2 = 'medium-2',
  Default = 'default',
  Large2 = 'large-2'
}

interface DividerProps extends AntdDividerProps {
  verticalMargin?: `${MarginSize}`
}

/**
 * This class is a wrap around [antd Divider](https://ant.design/components/divider/) component extending some of
 * the properties to follow our styling guidelines.
 *
 * Please refer to antd documentation for more details.
 *
 */
export function Divider({ className, verticalMargin, ...props }: DividerProps) {
  return (
    <AntdDivider
      {...props}
      className={cx(PrefixCls, verticalMargin && `${PrefixCls}-margin--${verticalMargin}`, className)}
    />
  )
}

Divider.displayName = AntdDivider.displayName
Divider.defaultProps = AntdDivider.defaultProps
Divider.contextTypes = AntdDivider.contextTypes
