import { Col as AntCol } from 'antd'
import type { ColProps, ColSize } from 'antd/es/grid'
import cx from 'classnames'
import React from 'react'
import './styles.scss'

export type { ColProps, ColSize }
const AppColClassName = 'app-col'

/**
 * This class is a wrap around [antd Col](https://ant.design/components/grid/) component extending some of
 * the properties to follow our styling guidelines.
 *
 * Please refer to antd documentation for more details.
 *
 */
export const Col = React.forwardRef<HTMLDivElement, ColProps>(({ className, ...props }: ColProps, ref) => (
  <AntCol ref={ref} {...props} className={cx(AppColClassName, className)} />
))
Col.displayName = 'Col'
