import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/es/storage'
import steps from './steps/reducer'
import userLocation from './userLocation/reducer'
import planSubscriptions from './planSubscriptions/reducer'
import incentivePrograms from './incentivePrograms/slice'
import mapAnalytics from './mapAnalytics'

const mapAnalyticsPersistConfig = {
  key: 'mapAnalytics',
  storage
}

export default combineReducers({
  steps,
  userLocation,
  planSubscriptions,
  incentivePrograms,
  mapAnalytics: persistReducer(mapAnalyticsPersistConfig, mapAnalytics)
})
