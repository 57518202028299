import React from 'react'
import { SupportedLocales } from '@commutifi/constants/Locale'
import { LocalesType } from '@commutifi-fe/constants'
import type { Locale } from '../locales/index'
import enUS from '../locales/default'

export type { Locale }

export interface ConfigContextValue {
  locale: Locale
  localeKey: LocalesType
}

const defaultConfig: ConfigContextValue = {
  locale: enUS,
  localeKey: SupportedLocales.enUS
}
export const ConfigContext = React.createContext<ConfigContextValue>(defaultConfig)
export const useAppConfig = () => {
  const context = React.useContext(ConfigContext)
  return context
}
