import { takeEvery, call, put } from 'redux-saga/effects'
import * as actions from './actions'
import * as api from 'api/modules/enterprises'
import types from './types'

export const userMetricsSagas = [takeEvery(types.FETCH_METRICS_REQUEST, getMetrics)]

export function* getMetrics({ id, queryParams }) {
  try {
    const res = yield call(api.fetchOrganizationMetrics, id, queryParams)
    yield put(actions.fetchMetricsSuccess(res))
  } catch (error) {
    yield put(actions.fetchMetricsFailure(error))
  }
}
