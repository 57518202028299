import { colors } from '@commutifi-fe/ui/styles/constants'
import { COMMON_DEFAULT_HEIGHT } from './constants'
import { heightWidthRatio } from './utils'

const DEFAULT_HEIGHT = 234.08
const DEFAULT_WIDTH = 322

export const SvgCompassIllustration = ({
  fill = colors.feedbackDestructive200,
  stroke = colors.neutral600,
  height,
  width,
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  const { height: h, width: w } = heightWidthRatio(
    !height && !width ? COMMON_DEFAULT_HEIGHT : height,
    width,
    {
      defaultHeight: DEFAULT_HEIGHT,
      defaultWidth: DEFAULT_WIDTH
    }
  )

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      height={h}
      width={w}
      viewBox={`28 33.22 ${DEFAULT_WIDTH} ${DEFAULT_HEIGHT}`}
      {...props}
    >
      <path
        stroke={stroke}
        strokeWidth={3}
        d="m326.576 249.981-2.333-1.679a.905.905 0 0 1 .353-1.701l2.807-.614a14.723 14.723 0 0 0 8.594-5.644l1.675-2.314a.9.9 0 0 1 1.701.353l.618 2.789a14.65 14.65 0 0 0 5.644 8.594l2.332 1.679a.905.905 0 0 1-.352 1.701l-2.808.614a14.803 14.803 0 0 0-8.594 5.643l-1.679 2.333a.905.905 0 0 1-1.701-.352l-.614-2.808c-.48-3.359-2.518-6.462-5.643-8.594Z"
      />
      <path
        fill={fill}
        d="M343.457 251.28c-3.446-2.648-4.947-4.586-5.524-9.709 1.048 9.304-3.415 16.479-3.415 16.479 2.976-3.996 4.016-5.66 8.939-6.77"
      />
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeWidth={3}
        d="M36.849 90.383a6.68 6.68 0 0 1 2.515.756c3.263 1.734 4.513 5.777 2.793 9.031-1.72 3.254-5.76 4.487-9.022 2.753-3.263-1.734-4.513-5.777-2.793-9.031a6.632 6.632 0 0 1 1.244-1.662"
      />
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeWidth={3.226}
        d="M123.845 239.499c13.429 12.592 30.584 21.506 50.094 24.811 52.819 8.945 102.89-26.622 111.835-79.441a97.265 97.265 0 0 0 1.189-22.346m-179.996 56.071c-11.51-19.155-16.493-42.359-12.469-66.12 8.946-52.82 59.016-88.386 111.836-79.44 15.547 2.632 29.17 8.5 41.033 17.28"
      />
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeWidth={3.226}
        d="M138.769 104.064c-12.268 9.736-21.906 23.067-27.114 39.076-14.1 43.344 9.606 89.912 52.95 104.012 43.344 14.101 89.912-9.605 104.012-52.949a82.439 82.439 0 0 0 4.068-26.189m-114.22-75.555c17.554-7.304 37.704-8.612 57.202-2.269 7.027 2.286 13.017 5.286 18.935 9.127"
      />
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeWidth={3}
        d="m213.743 90.431-3.918 14.625M171.24 249.055l3.015-11.25m-40.643-9.317 8.979-8.978m-31.014-27.378 13.387-3.587m144.234 3.954-11.812-3.165m-146.811-39.338 14.062 3.768m122.625 74.724-8.316-8.316M131.141 112.368l10.751 10.75m68.109 125.839-2.985-11.14M167.498 90.333l3.948 14.735"
      />
      <path
        stroke={fill}
        strokeLinecap="round"
        strokeWidth={7}
        d="M279.067 183.692c-8.321 49.129-54.893 82.212-104.022 73.891-18.147-3.073-34.104-11.365-46.594-23.077"
      />
      <path
        stroke={stroke}
        strokeWidth={3}
        d="m232.535 153.392-31.228 31.684c-8.102 8.221-21.807 6.467-27.578-3.529-5.78-10.011-.421-22.775 10.774-25.659l43.09-11.1"
      />
      <path
        fill={fill}
        stroke={fill}
        d="M186.836 167.2c5.271-2.9 11.368-1.61 13.701 2.631 2.334 4.242.159 10.082-5.112 12.982-5.272 2.9-11.368 1.61-13.702-2.631-2.333-4.242-.159-10.082 5.113-12.982Z"
      />
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeWidth={3}
        d="M286.744 93.554a40.65 40.65 0 0 0-22.237-6.579c-22.556 0-40.842 18.286-40.842 40.843 0 22.556 18.286 40.842 40.842 40.842 22.557 0 40.842-18.286 40.842-40.842 0-11.096-4.424-21.158-11.605-28.519"
      />
      <path
        fill={fill}
        stroke={fill}
        d="M277.844 140.674c3.459-3.32 7.321-5.528 10.748-6.432 3.447-.91 6.334-.478 8.02 1.28 1.686 1.757 1.998 4.659.948 8.065-1.045 3.388-3.411 7.155-6.87 10.474-3.46 3.319-7.321 5.528-10.749 6.432-3.446.909-6.333.477-8.019-1.28-1.687-1.757-1.999-4.66-.948-8.066 1.045-3.387 3.411-7.154 6.87-10.473Z"
      />
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeWidth={3}
        d="M252.227 118.896V117.5c0-6.904 5.597-12.5 12.5-12.5h.989c6.358 0 11.511 5.154 11.511 11.511v1.69a9.479 9.479 0 0 1-7.75 9.32v0a9.479 9.479 0 0 0-7.75 9.32v5.055"
      />
      <path
        fill={stroke}
        stroke={stroke}
        d="M263.727 149.083c0 .763-.651 1.417-1.5 1.417-.848 0-1.5-.654-1.5-1.417 0-.762.652-1.416 1.5-1.416.849 0 1.5.654 1.5 1.416Z"
      />
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeWidth={3}
        d="M221.156 165c1.212 5.008 1.415 8.882 0 14.212-4.536 17.081-22.06 27.252-39.141 22.716-17.081-4.535-27.251-22.059-22.716-39.14 4.536-17.081 22.059-27.252 39.141-22.716a31.852 31.852 0 0 1 13.287 7.218m4.678-78.587a17.069 17.069 0 0 1-3.474-.557c-9.069-2.43-14.451-11.752-12.021-20.82 2.43-9.07 11.752-14.452 20.821-12.022 9.069 2.43 14.451 11.752 12.021 20.821a16.93 16.93 0 0 1-6.525 9.423"
      />
      <path
        stroke={stroke}
        strokeWidth={3}
        d="m218.139 75.753 4.141-15.455a2 2 0 0 0-1.414-2.45l-9.659-2.587a2 2 0 0 0-2.449 1.414l-4.142 15.455"
      />
    </svg>
  )
}
export default SvgCompassIllustration
