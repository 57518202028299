import { Countries } from '@commutifi/constants/Countries'
import {
  AsYouType,
  formatNumber,
  getExampleNumber,
  parsePhoneNumber as parsePhoneNumberLib,
  parsePhoneNumberWithError as parsePhoneNumberWithErrorLib
} from 'libphonenumber-js'
import type { CountryCode, PhoneNumber } from 'libphonenumber-js'
import examples from 'libphonenumber-js/mobile/examples'

export { PhoneNumber, AsYouType, CountryCode, formatNumber, getExampleNumber, examples }

type ParsePhoneNumberParams = Parameters<typeof parsePhoneNumberLib>
type ParsePhoneNumberWithErrorParams = Parameters<typeof parsePhoneNumberWithErrorLib>
type ToInternationalOptions = Parameters<ReturnType<typeof parsePhoneNumberLib>['formatInternational']>
type ToNationalOptions = Parameters<ReturnType<typeof parsePhoneNumberLib>['formatNational']>

/**
 * Parses a phone number. Also supports extensions written like '\<phone number\>x1234' or '\<phone number\>ext1234'
 * or '\<phone number\> ext. 1234' or any similar formats.
 *
 * This function supports legacy phone numbers stored on our accounts object which is why we don't fail if
 * we can't parse the phone number. Instead we simply ignore the number so the user will have to re-enter it
 * and that time we will store it in the international standard format.
 *
 * @param text - The phone number to parse.
 * @param defaultCountry - The default country to use when parsing the phone number.
 *
 * @returns The parsed phone number.
 */
export const parsePhoneNumber = (
  text: ParsePhoneNumberParams[0],
  defaultCountry?: ParsePhoneNumberParams[1]
): ReturnType<typeof parsePhoneNumberLib> | undefined => {
  try {
    return parsePhoneNumberLib(text, defaultCountry as CountryCode | undefined)
  } catch (e) {
    return undefined
  }
}

export const parsePhoneNumberWithError = (
  text: ParsePhoneNumberWithErrorParams[0],
  defaultCountry?: ParsePhoneNumberWithErrorParams[1]
): ReturnType<typeof parsePhoneNumberLib> | undefined =>
  parsePhoneNumberWithErrorLib(text, defaultCountry as CountryCode | undefined)

/**
 * Parses a phone number to international format. Also supports extensions written like '\<phone number\>x1234' or '\<phone number\>ext1234'
 * or '\<phone number\> ext. 1234' or any similar formats.
 *
 * @param text - The phone number to parse.
 * @param defaultCountry - The default country to use when parsing the phone number.
 * @param options - The options to pass to `formatInternational()`.
 *
 * @returns The parsed phone number in international format.
 */
export const parsePhoneNumberToInternational = (
  text: ParsePhoneNumberParams[0],
  defaultCountry?: ParsePhoneNumberParams[1],
  options?: ToInternationalOptions[0]
) => parsePhoneNumberLib(text, defaultCountry as CountryCode | undefined).formatInternational(options)

/**
 * Parses a phone number to national format. Also supports extensions written like '\<phone number\>x1234' or '\<phone number\>ext1234'
 * or '\<phone number\> ext. 1234' or any similar formats.
 *
 * @param text - The phone number to parse.
 * @param defaultCountry - The default country to use when parsing the phone number.
 * @param options - The options to pass to `formatNational()`.
 *
 * @returns The parsed phone number in national format.
 */
export const parsePhoneNumberToNational = (
  text: ParsePhoneNumberParams[0],
  defaultCountry?: Countries | ParsePhoneNumberParams[1],
  options?: ToNationalOptions[0]
) => parsePhoneNumberLib(text, defaultCountry as ParsePhoneNumberParams[1]).formatNational(options)

/**
 * Converts a international phone number to our application phone input value.
 * NOTE that our phone number is stored as international (E.164) formats
 */
export const internationalToPhoneInputValue = (phoneNumber: string | undefined) => {
  const parsedPhone = phoneNumber ? parsePhoneNumber(phoneNumber) : null
  return parsedPhone
    ? {
        phone: parsedPhone.formatNational({ formatExtension: (formattedNumber) => formattedNumber }),
        code: parsedPhone.countryCallingCode ? parseInt(parsedPhone.countryCallingCode) : undefined,
        short: parsedPhone.country,
        ext: parsedPhone.ext
      }
    : undefined
}

/**
 * Converts a parse phone number (Type [[ParsedNumber]]) into our application phone input value.
 * NOTE that our phone number is stored as international (E.164) formats
 */
export const parsedPhoneToPhoneInputValue = (parsed: PhoneNumber | undefined) =>
  parsed
    ? {
        phone: parsed.formatNational({ formatExtension: (formattedNumber) => formattedNumber }),
        code: parsed.countryCallingCode ? parseInt(parsed.countryCallingCode) : undefined,
        short: parsed.country,
        ext: parsed.ext
      }
    : undefined
