import polyline from '@mapbox/polyline'
import flatten from 'lodash/flatten'
import get from 'lodash/get'
import uniq from 'lodash/uniq'
import values from 'lodash/values'
import without from 'lodash/without'
import { createSelector } from 'reselect'
import FETCH_STATUS, { sortingOrdersEnum } from 'constants/global'
import { convertLegMetrics, formatMetrics, mergeByGroupId } from 'store/helpers/routes'
import { getSubsidiesByPlanId, makeGetPlansSubsidiesSum } from 'store/modules/benefits/selectors'
import { legsSelectors } from 'store/modules/legs'
import { getLocaleUnits } from 'store/modules/localSettings/selectors'
import { routeMetricsSelectors } from 'store/modules/metrics/route'
import plansSharedSelectors from 'store/modules/plans/selectors.shared'
import { servicesSelectors } from 'store/modules/services'
import { avgByKey, getArray, isNumber, isUuid, sortByKey } from 'utils/helpers'
import { applySubsidy } from 'utils/helpers/metrics'
import { getModeObject } from 'utils/modes'

export const getAllIds = ({ routes }) => routes.allIds
export const getById = ({ routes }) => routes.byId
export const getCurrentRouteId = ({ routes }) => routes.currentRouteId
export const getIsFetching = ({ routes }) => routes.isFetching
export const getSuggestionsByRouteId = ({ routes }) => routes.suggestionsByRouteId
export const getServicesByRouteId = ({ routes }) => routes.servicesByRouteId
export const getCurrentSuggestionsId = ({ routes }) => routes.currentSuggestionId
export const getCurrentTab = ({ routes }) => routes.currentTab

export const getSuggestionsById = ({ routes }) => routes.suggestionsById
export const getCurrentSuggestionId = ({ routes }) => routes.currentSuggestionId
export const getSuggestionsFetchStatus = ({ routes }) => routes.suggestionsFetchStatus

export const getUserRoutes = createSelector([getAllIds, getById], (allIds, byId) =>
  allIds.map((routeId) => byId[routeId])
)

export const getUserRoutesPlanIds = createSelector([getUserRoutes, legsSelectors.getById], (userRoutes, legsById) => {
  const legIds = flatten(userRoutes.map((route) => route.legs))
  const legs = uniq(legIds).map((legId) => legsById[legId])
  // Return all distinct defined plans in the legs
  return uniq(legs.map((l) => l.planId).filter((planId) => planId))
})

export const getCurrentRouteSuggestionIds = createSelector(
  [getSuggestionsByRouteId, getCurrentRouteId],
  (suggestionsByRouteId, currentId) => suggestionsByRouteId[currentId] || []
)

export const getCurrentRouteSuggestions = createSelector(
  [getCurrentRouteSuggestionIds, getSuggestionsById],
  (suggestionsIds, suggestionsById) => suggestionsIds.map((sId) => suggestionsById[sId])
)

export const getCurrentRouteWithLegs = createSelector(
  [getCurrentRouteId, getById, legsSelectors.getById],
  (currentId, routesById, legsById) => ({
    ...routesById[currentId],
    legs: routesById[currentId].legs.map((legId) => legsById[legId])
  })
)

export const getCurrentSuggestionScore = createSelector(
  [getCurrentSuggestionId, getSuggestionsById],
  (suggestionId, suggestionsById) => {
    const suggestion = suggestionsById[suggestionId]
    const returnRoute = suggestionsById[suggestion.returnRouteId]
    return avgByKey('score.global', suggestion, returnRoute)
  }
)

export const getCurrentSuggestionPlanIds = createSelector(
  [getCurrentSuggestionId, getSuggestionsById, legsSelectors.getById],
  (suggestionId, suggestionsById, legsById) => {
    const suggestion = suggestionsById[suggestionId]
    const planIds = suggestion.legs.map((legId) => legsById[legId].planId)
    return uniq(without(planIds, null))
  }
)

export const makeGetSuggestionIds = (routeId) =>
  createSelector(getSuggestionsByRouteId, (suggestionsByRouteId) => suggestionsByRouteId[routeId] || [])

export const makeGetRoute = (routeId) =>
  createSelector(
    [getById, getSuggestionsById],
    (routesById, suggestionsById) => routesById[routeId] || suggestionsById[routeId]
  )

export const makeGetRouteStartAddress = (routeId) =>
  createSelector(makeGetRoute(routeId), (route) => route.startAddress)

export const makeGetRouteEndAddress = (routeId) => createSelector(makeGetRoute(routeId), (route) => route.endAddress)

export const makeGetIsLoaded = (routeId) =>
  createSelector(getSuggestionsFetchStatus, (fetchStatus) => {
    return Boolean(fetchStatus[routeId] && fetchStatus[routeId] === FETCH_STATUS.LOADED)
  })

/**
 * Returns the currently selected route id. If a suggestion is selected we return that
 * route, except if it is merged we return that result. In the other case, if no
 * suggestion is selected we return the currently USER route selected.
 */
export const getCurrentlySelectedRouteId = createSelector(
  [getCurrentSuggestionsId, getCurrentRouteId],
  (currentSuggestionId, currentUserRouteId) => currentSuggestionId || currentUserRouteId
)

export const getRouteIds = createSelector([getUserRoutes], (userRoutes) => {
  // For now we only handle going route. Change when we handle return routes
  const goingRouteIds = userRoutes.filter((r) => !r.isReturn).map((r) => r.id)
  return goingRouteIds || []
})

export const makeGetRouteLegsIds = (routeId) => createSelector(makeGetRoute(routeId), (route) => route.legs || [])
export const makeGetRouteWithLegs = (routeId) =>
  createSelector([makeGetRoute(routeId), legsSelectors.getById], (route, legsById) => ({
    ...route,
    legs: route.legs.map((legId) => legsById[legId])
  }))
export const makeGetRouteLegs = (routeId) =>
  createSelector([makeGetRoute(routeId), legsSelectors.getById], (route, legsById) =>
    route.legs.map((legId) => legsById[legId])
  )

export const getPlanWithSubsidy = (plan, subsidiesByPlanId) => {
  if (!plan) return undefined
  // Sum all subsidy for the leg plan (if it exists) and apply to cost
  const subsidies = subsidiesByPlanId[plan.id]
    ? subsidiesByPlanId[plan.id].reduce((sum, subsidy) => sum + subsidy, 0)
    : null
  const subsidizedPrice = subsidies && plan.cost ? applySubsidy(plan.cost, subsidies) : null
  return plan ? { ...plan, cost: isNumber(subsidizedPrice) ? subsidizedPrice : plan.cost } : undefined
}

/**
 * Returns the legs associated with the ids passed in param. Those legs
 * are considering the merge routes and include multiple services with the plans to reflect
 * multiple routes merge when it is the case. Those services and plans need to refer
 * to the original. Then by changing the service, in fact we change the route
 * for a new one associated with the service and that has its own metrics.
 *
 * Quick note: we get the mode up in the leg because it is easier to access than in the services
 *
 * **metrics** looks like:
 * [{value: 12, unit: 'km'}, {value: 44, unit: 'min'}]
 * Which is the distance and time that are the metrics we support right now
 *
 * @param {*} routeId : id of the route from which we want the multiple services options (merging)
 */
export const makeGetDetailedLegs = (routeId) =>
  createSelector(
    [
      legsSelectors.getById,
      makeGetRouteLegsIds(routeId),
      servicesSelectors.getById,
      makeGetMergedServices(routeId),
      plansSharedSelectors.getById,
      getSubsidiesByPlanId,
      getLocaleUnits
    ],
    (legsById, legsIds, servicesById, mergedServices, plansById, subsidiesByPlanId, localeUnits) =>
      legsIds.map((legId, legIndex) => {
        const leg = legsById[legId]
        const legMergedServices = mergedServices.length > 0 && mergedServices[legIndex] ? mergedServices[legIndex] : []
        const legService = leg.serviceId ? servicesById[leg.serviceId] : undefined

        // Setup the leg services; Take the merged services if there is any, otherwise make an array with the single leg service
        const services =
          legMergedServices.length > 1
            ? legMergedServices
            : legService
              ? getArray({ ...legService, planId: leg.planId, estimatedCost: get(leg, 'metrics.cost') })
              : []

        // Add plan information to services
        const servicesWithPlans = services.map((s) => ({
          ...s,
          plan: getPlanWithSubsidy(plansById[s.planId], subsidiesByPlanId)
        }))
        // We need to have the mode in a leg to avoid accessing the service to get that information
        const mode = leg.mode || leg.activity
        return {
          ...leg,
          metrics: convertLegMetrics(leg.metrics, localeUnits),
          service: servicesWithPlans,
          mode
        }
      })
  )

/**
 * This function will take the leg metrics to convert them in the locale units
 * based on the language selected in the locale settings
 * Return the route with legs like: [{leg1, ..., metrics: [{value: 123, unit:'km'}, {value: 66, unit:'min'}]} , ...]
 * (We only handle distance and duration in the legs)
 * @param {*} route
 */
export const makeGetLegsMetrics = (route) => (state) => {
  const localeUnits = getLocaleUnits(state)
  return {
    ...route,
    legs:
      route && route.legs
        ? route.legs.map((leg) => ({
            ...leg,
            metrics: convertLegMetrics(leg.metrics, localeUnits)
          }))
        : []
  }
}

/**
 * Returns the route services ids. If there is merged services we make sure to return all the services
 * with the merged ones. In the other case we only parse the services and filter the activities that
 * does not have any real services associated
 */
export const makeGetRouteServicesIds = (routeId) =>
  createSelector([getServicesByRouteId, makeGetMergedServices(routeId)], (servicesByRouteId, mergedServices) => {
    const services = servicesByRouteId[routeId].map((serviceId, index) => {
      const merged = mergedServices[index]
      if (merged && merged.length > 1) return merged.map((s) => s.id)
      return serviceId
    })
    return flatten(services).filter((s) => isUuid(s))
  })

/**
 * Returns all the plan ids for the route only (not the merged route)
 */
export const makeGetRoutePlansIds = (routeId) =>
  createSelector([makeGetRoute(routeId), legsSelectors.getById], (route, legsById) => {
    const planIds = route.legs.map((legId) => legsById[legId].planId)
    // Return defined and non duplicated plan ids
    return uniq(without(planIds, null))
  })

/**
 * Returns all the plan ids for the route AND THE MERGED ROUTES associated with the
 * routeId passed in param
 */
export const makeGetMergedRoutePlansIds = (routeId) => (state) => {
  const originalRoute = makeGetRoute(routeId)(state)
  const mergedIds = makeGetAllMergedIdsFromRoute(routeId)(state)
  const legsById = legsSelectors.getById(state)
  const planIds = mergedIds.length
    ? flatten(
        mergedIds.map((routeId) => {
          const route = makeGetRoute(routeId)(state)
          return route.legs.map((legId) => legsById[legId].planId)
        })
      )
    : originalRoute.legs.map((legId) => legsById[legId].planId)
  // Return defined and non duplicated plan ids
  return uniq(without(planIds, null))
}

// In the services we could have an activity -> it won't be a uuid but a string (workout, kids, etc)
// count is useful for when we will merge multiple routes, it indicates how many services are available
// Output example: [{mode: 'carpooling', count: 2, name: 'Netlift'}, {mode: 'parking', count: 1}]
export const makeGetRouteServices = (routeId) =>
  createSelector(
    [
      makeGetRoute(routeId),
      legsSelectors.getById,
      getServicesByRouteId,
      servicesSelectors.getById,
      makeGetMergedServices(routeId)
    ],
    (route, legsById, servicesByRouteId, servicesById, mergedServices) =>
      servicesByRouteId[routeId].map((serviceId, index) => {
        const legs = route.legs.map((id) => legsById[id])
        const leg = legs.find((l) => l.service === serviceId)
        const service = servicesById[serviceId]
        const mergedService = mergedServices[index] ? mergedServices[index] : undefined
        return service
          ? {
              id: serviceId,
              mode: leg.mode || leg.activity,
              name: service.name,
              displayName: service.displayName,
              count: mergedService && mergedService.length > 1 ? mergedService.length : 1
            }
          : { id: serviceId, mode: serviceId, count: 1 }
      }) || []
  )

export const getCurrentSuggestionServices = (state) => {
  const suggestionId = getCurrentSuggestionId(state)
  return makeGetRouteServices(suggestionId)(state)
}

export const getCurrentSuggestionLegs = (state) => {
  const suggestionId = getCurrentSuggestionId(state)
  return makeGetRouteLegs(suggestionId)(state)
}

export const makeGetRouteMetrics = (routeId) => (state) => {
  const route = makeGetRoute(routeId)(state)
  const metricsById = routeMetricsSelectors.getById(state)
  const returnRoute = makeGetRoute(route.returnRouteId)(state)

  return route && metricsById[route.metrics]
    ? formatMetrics(metricsById[route.metrics], metricsById[returnRoute.metrics])
    : undefined
}

export const makeGetRouteScore = (routeId) => (state) => {
  const route = makeGetRoute(routeId)(state)
  const returnRoute = route.returnRouteId ? makeGetRoute(route.returnRouteId)(state) : undefined
  // Return mean score of route AND return route
  return avgByKey('score.global', route, returnRoute)
}

export const makeGetRouteFrequency = (routeId) =>
  createSelector(makeGetRoute(routeId), (route) => route.weeklyFrequency || undefined)

/**
 * Returns an array of suggestion routes ids. Those routes ids can be single
 * of merged. (Ex. [[id1], [id2, id4], [id3]])
 */
export const getMergedSuggestionsIds = createSelector([getCurrentRouteSuggestions], (suggestions) => {
  const goingSuggestions = suggestions.filter((s) => !s.isReturn)
  const suggestionsToMerge = []
  const noMergeSuggestions = []
  goingSuggestions.map((s) => (s.groupId ? suggestionsToMerge.push(s) : noMergeSuggestions.push(s.id)))
  const byGroupId = mergeByGroupId(suggestionsToMerge)
  const mergedSuggestions = values(byGroupId)
  return [...noMergeSuggestions, ...mergedSuggestions]
})

/**
 * Get the possible merged route. If it is an array it means that
 * there is more than one route and it is a merge -> return that result
 */
export const getAllMergedOptions = createSelector(getMergedSuggestionsIds, (potentialMerged) =>
  potentialMerged.filter((merged) => Array.isArray(merged) && merged.length > 1)
)

/**
 * Returns the available suggestions, which means that when a suggestions is merged
 * it will return only the best suggestion of the ones merged
 * mergeIds is defined like: [id1, [id2, id4], id3]
 * So the result could be [id1, id4, id3] or [id1, id2, id3] depending on the
 * best route between id2 and id4
 */
export const getAvailableSuggestions = (state) => {
  const mergedIds = getMergedSuggestionsIds(state)
  const suggestionsById = getSuggestionsById(state)
  const availableSuggestions = []
  mergedIds.forEach((merged) => {
    if (Array.isArray(merged)) {
      const suggestions = merged.map((id) => ({
        ...suggestionsById[id],
        score: { global: makeGetRouteScore(id)(state) }
      }))
      const sortedByScore = sortByKey(suggestions, 'score.global', sortingOrdersEnum.DESC)
      // Expose the best route on the suggestions list
      sortedByScore.length > 0 && availableSuggestions.push({ ...sortedByScore[0], mergedIds: merged })
    } else {
      availableSuggestions.push({ ...suggestionsById[merged], score: { global: makeGetRouteScore(merged)(state) } })
    }
  })
  // We only handle the going routes for now. Change when we handle return routes
  const sortedSuggestions = sortByKey(availableSuggestions, 'score.global', sortingOrdersEnum.DESC)
  return sortedSuggestions.filter((s) => !s.isReturn).map((s) => s.id)
}

/**
 * Finds and returns the mergedIds from a routeId.
 * We receive an array of array from getAllMergedOptions.
 * All the arrays are the possible merge. Then in a specific merge we
 * need to search for the routeId passed in parameter
 * @param {*} routeId : route to search associated merged routed
 */
export const makeGetAllMergedIdsFromRoute = (routeId) =>
  createSelector([getAllMergedOptions], (mergedRoutes) => {
    for (let i = 0; i < mergedRoutes.length; i++) {
      const ids = mergedRoutes[i]
      if (ids.indexOf(routeId) > -1) return ids
    }
    return []
  })

/**
 * Returns the merged services by legs index:
 * [[s1, s2], [], [s1, s2]] or [] if no services to merge (ex. for an activity)
 * First array at index 0 is the merged services for leg index 0
 *
 * @param {*} routeId : origin route with which we need to get the other
 * similar routes services
 *
 * mergedIds represent the routes ids to merge together. For now we merge each leg service
 * (id1 ; leg0) with (id2 ; leg0) , (id1 ; leg1) with (id2 ; leg1) , etc
 * Something to merge ?
 * No  -> we return an empty array
 * Yes -> We merge each route legs services by their indexes to produce
 *        the expected output. We cannot merge a leg of type 'stop' so
 *        for that case we keep an empty array
 */
export const makeGetMergedServices = (routeId) => (state) => {
  const mergedIds = makeGetAllMergedIdsFromRoute(routeId)(state)
  const servicesByRouteId = getServicesByRouteId(state)
  const servicesById = servicesSelectors.getById(state)
  return mergedIds
    ? mergedIds.reduce((legsMergedServices, routeId) => {
        // Get the next route services so we can merge them to the existing services
        const routeServicesIds = servicesByRouteId[routeId]
        const routeLegs = makeGetRouteLegs(routeId)(state)
        // It is the first route so the merged services should be of size 0. In that
        // case we need to initialize it with the route services objects wrapped in an array
        // so we can just push the other route services that we need to merge.
        // When the service does not exist we create an empty array. This case is for activities
        if (legsMergedServices.length === 0) {
          return routeServicesIds.map(
            // Keep a reference to the route so we know from were the service came
            (serviceId, index) => {
              const leg = routeLegs[index]
              return servicesById[serviceId]
                ? [
                    {
                      ...servicesById[serviceId],
                      routeId,
                      planId: leg && leg.planId ? leg.planId : undefined,
                      estimatedCost: get(leg, 'metrics.cost')
                    }
                  ]
                : []
            }
          )
        }
        // If the merged services are already initialized we map each leg service array
        // and add to it the service of the next route we are merging
        return legsMergedServices.map((existingLegServices, i) => {
          const serviceIdToMerge = routeServicesIds[i] ? routeServicesIds[i] : undefined
          const leg = routeLegs[i]
          // Keep a reference to the route so we know from were the service came
          const legServiceToMerge =
            serviceIdToMerge && servicesById[serviceIdToMerge]
              ? {
                  ...servicesById[serviceIdToMerge],
                  routeId,
                  planId: leg && leg.planId ? leg.planId : undefined,
                  estimatedCost: get(leg, 'metrics.cost')
                }
              : undefined
          // If the leg service to merge exists and it is not already in the existing services array
          // we merge it.
          return legServiceToMerge && !existingLegServices.find((legService) => legServiceToMerge.id === legService.id)
            ? [...existingLegServices, legServiceToMerge]
            : existingLegServices
        })
      }, [])
    : []
}

/**
 * HELPER function for makeGetRouteMapDrawing merge providers ids process
 * @param {*} mergedServices: array of services previously merged
 */
export const getMergedProvidersIds = (mergedServices) => {
  const definedProviders = without(
    mergedServices.map((service) => (service && service.providerId ? service.providerId : undefined)),
    undefined
  )
  return uniq(definedProviders)
}

/**
 * HELPER function for makeGetRouteMapDrawing
 * Returns the leg service in an array or an empty array when there is no service
 * @param {*} leg: leg from which to access potential service object and wrap it in an array
 */
export const getLegProviderIdsArray = (leg, servicesById) =>
  leg.serviceId && servicesById[leg.serviceId] && servicesById[leg.serviceId].provider
    ? [servicesById[leg.serviceId].provider]
    : []

/**
 * HELPER function for makeGetRouteMapDrawing
 * Returns boolean to indicate if the current leg has merged services
 * @param {*} leg: leg from which to access potential service object and wrap it in an array
 */
export const hasMergedServices = (mergedServicesByLeg, legIndex) =>
  mergedServicesByLeg &&
  mergedServicesByLeg.length > 1 &&
  mergedServicesByLeg[legIndex] &&
  mergedServicesByLeg[legIndex].length > 1

/**
 * HELPER function for makeGetRouteMapDrawing
 * Returns the formatted object with the attributes needed to draw the
 * sections of a route on the map:
 * - coordinatesOverview : to find the map bounds
 * - sections : to display each route leg with it's popup
 *    - type: So we know when to display popup information
 *    - rawMode: non translated mode for mapping purposes (PARK -> icon-park)
 *    - travelMode: to display mode icon (!!it is a function that is returned so we can pass intl to translate)
 *    - location [lng, lat]: for the marker position (COORD ORDER used by mapbox)
 *    - coordinates: to draw the line on the map
 *    - providerIds: so we can display potential providers on each section
 */
export const formatMapDrawing = (route, legsById, servicesById, mergedServicesByLeg) => {
  if (!route) return
  const legs = route.legs ? route.legs.map((legId) => legsById[legId]) : []
  const routeStartCoordinates = [route.startLocation.lng, route.startLocation.lat]
  return {
    // toGeoJSON will format the coordinates to display on a map. (lat lng order standards are different on maps)
    coordinatesOverview: route.overviewPolyline
      ? polyline.toGeoJSON(route.overviewPolyline).coordinates
      : [routeStartCoordinates, routeStartCoordinates],
    sections: legs.map((leg, legIndex) => {
      const mode = leg.mode || leg.activity
      const legStartCoordinates = [leg.startLocation.lng, leg.startLocation.lat]
      return {
        type: leg.type,
        mode,
        displayColor: leg.displayColor,
        travelMode: getModeObject(mode),
        displayName: leg.name,
        location: legStartCoordinates,
        // If we don't have a leg polyline it's either because it's a remote route so the start/end location is the same
        // or it failed to get google data. So we fake coordinates by using the leg start location
        coordinates: leg.polyline
          ? polyline.toGeoJSON(leg.polyline).coordinates
          : [legStartCoordinates, legStartCoordinates],
        providerIds: hasMergedServices(mergedServicesByLeg, legIndex)
          ? getMergedProvidersIds(mergedServicesByLeg[legIndex])
          : getLegProviderIdsArray(leg, servicesById)
      }
    })
  }
}

/**
 * Return the necessary information to draw a suggestions route on a map.
 * This route shall be surrounded by services information popup and custom markers.
 * @param {string} routeId: route from which we want to retrieve mapDrawing object
 * @param {boolean} merged: should we get the merged routes information
 */
export const makeGetRouteMapDrawing = (routeId, merged) =>
  createSelector(
    [getSuggestionsById, legsSelectors.getById, getById, servicesSelectors.getById, makeGetMergedServices(routeId)],
    (suggestionsById, legsById, userRoutesById, servicesById, mergedServicesByLeg) => {
      const route = suggestionsById[routeId] || userRoutesById[routeId]
      return formatMapDrawing(route, legsById, servicesById, merged ? mergedServicesByLeg : null)
    }
  )

/**
 * Returns the selected suggestion metrics formatted by key and converted in locale units:
 * {
 *  time: {
 *    value: 22,
 *    unit: 'min',
 *    frequency: 'month'
 *  },
 *  cost: {
 *    value: 120,
 *    currency: 'usd',
 *    frequency: 'month'
 *  },
 *  carbon: {
 *    value: 60,
 *    unit: 'lb',
 *    frequency: 'month'
 *  }
 * }
 */
export const getSelectedSuggestionConvertedMetricsByKey = (state) => {
  const currentSuggestionId = getCurrentSuggestionId(state)

  if (!currentSuggestionId) {
    return
  }

  const suggestionsById = getSuggestionsById(state)
  const suggestion = suggestionsById[currentSuggestionId]
  const returnRoute = makeGetRoute(suggestion.returnRouteId)(state)
  const metricsIds = [suggestion.metrics, returnRoute.metrics]
  const planIds = getCurrentSuggestionPlanIds(state)
  const totalSubsidy = makeGetPlansSubsidiesSum(planIds)(state)
  const metricsByKey = metricsIds ? routeMetricsSelectors.makeGetConvertedMetricsWithFrequency(metricsIds)(state) : null
  return metricsByKey ? { ...metricsByKey, cost: { ...metricsByKey.cost, totalSubsidy } } : undefined
}

export const hasSubsidies = (planIds, subsidiesByPlanId) => {
  for (let i = 0; i < planIds.length; ++i) {
    const planId = planIds[i]
    if (subsidiesByPlanId[planId]) return true
  }
  return false
}

/**
 * ** !Warning! **
 * This function returns if the route (routeId) OR the merged routes has subsidies
 * @param {*} routeId
 */
export const makeGetHasSubsidies = (routeId) =>
  createSelector([makeGetMergedRoutePlansIds(routeId), getSubsidiesByPlanId], (planIds, subsidiesByPlanId) =>
    hasSubsidies(planIds, subsidiesByPlanId)
  )

export const currentSuggestionHasSubsidies = createSelector(
  [getCurrentSuggestionPlanIds, getSubsidiesByPlanId],
  (planIds, subsidiesByPlanId) => hasSubsidies(planIds, subsidiesByPlanId)
)

export const makeGetRouteSubsidies = (routeId) => (state) => {
  const planIds = makeGetRoutePlansIds(routeId)(state)
  return makeGetPlansSubsidiesSum(planIds)(state)
}
