import React from 'react'

interface CommutifiLogoProps {
  // Logo svg fill color
  fill?: string
  // Set the height OR the width and we will adjust to the svg ratio automatically
  height?: number
  // Set the height OR the width and we will adjust to the svg ratio automatically
  width?: number
  className?: string
  style?: React.CSSProperties
  containerStyle?: React.CSSProperties
  // Option to hide company name text and show only the logo mark
  hideText?: boolean
  onClick?: React.MouseEventHandler
}

const DEFAULT_VIEW_BOX_HEIGHT = 88

/**
 * Commutifi SVG logo with customizable color and size
 *
 * @example Display only logo mark: \<CommutifiLogo fill='#000000' hideText /\>
 * @example Modify width (and automatically the height to respect the ratio): \<CommutifiLogo width=\{200\} /\>
 */
export function CommutifiLogo({
  fill = '#FFF',
  style = {},
  containerStyle = {},
  height,
  width,
  className,
  hideText = false,
  onClick
}: CommutifiLogoProps) {
  const defaultViwBoxWidth = hideText ? DEFAULT_VIEW_BOX_HEIGHT : 396
  const defaultWidth = !height && !width ? (hideText ? 40 : 135) : width
  const widthRatio = defaultViwBoxWidth / DEFAULT_VIEW_BOX_HEIGHT
  const heightRatio = 1 / widthRatio
  const computedHeight = height || (defaultWidth ? defaultWidth * heightRatio : DEFAULT_VIEW_BOX_HEIGHT)
  const computedWidth = defaultWidth || (height ? height * widthRatio : defaultViwBoxWidth)
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions -- Consider refactoring
    <div
      className={className}
      onClick={onClick}
      style={{ display: 'flex', ...containerStyle }}
      data-testid="commutifi-logo-svg"
    >
      <svg
        style={{ ...style, height: computedHeight, width: computedWidth }}
        xmlns="http://www.w3.org/2000/svg"
        viewBox={`0 0 ${defaultViwBoxWidth} ${DEFAULT_VIEW_BOX_HEIGHT}`}
      >
        {/* When we hide the text we hotfix the uncentered logo mark by using translate */}
        <g fill={fill} fillRule="evenodd" style={{ ...(hideText && { transform: 'translate(13.3368%, 1px)' }) }}>
          {!hideText && (
            <>
              {/* 'C' of 'Commutifi' */}
              <path d="M112.581 63.2133C116.42 63.3985 120.226 62.4302 123.509 60.4331C126.792 58.436 129.402 55.5016 131.003 52.008L123.611 48.136C122.708 50.2481 121.19 52.0389 119.253 53.274C117.317 54.5091 115.053 55.1311 112.757 55.0587C111.058 55.0807 109.372 54.7473 107.808 54.08C106.245 53.4126 104.838 52.4259 103.678 51.1831C102.518 49.9404 101.631 48.469 101.073 46.8632C100.515 45.2575 100.298 43.5529 100.437 41.8587C100.298 40.1644 100.515 38.4598 101.073 36.8541C101.631 35.2484 102.518 33.777 103.678 32.5342C104.838 31.2914 106.245 30.3047 107.808 29.6374C109.372 28.97 111.058 28.6366 112.757 28.6587C115.046 28.586 117.302 29.2086 119.229 30.4445C121.156 31.6804 122.664 33.4714 123.552 35.5813L131.003 31.7093C129.398 28.2212 126.786 25.2929 123.503 23.3013C120.22 21.3096 116.416 20.3457 112.581 20.5333C109.755 20.4418 106.941 20.9349 104.314 21.9817C101.688 23.0286 99.3059 24.6066 97.3176 26.6169C95.3294 28.6272 93.7777 31.0264 92.7599 33.6643C91.7421 36.3022 91.28 39.1219 91.4027 41.9467C91.3007 44.7594 91.7781 47.563 92.8052 50.1835C93.8323 52.804 95.3871 55.1854 97.3732 57.1797C99.3593 59.174 101.734 60.7387 104.35 61.7767C106.967 62.8146 109.768 63.3036 112.581 63.2133V63.2133Z" />
              {/* 'o' of 'Commutifi' */}
              <path d="M150.157 31.6507C148.051 31.552 145.946 31.8913 143.977 32.6471C142.008 33.4029 140.217 34.5589 138.718 36.0419C137.218 37.525 136.043 39.3029 135.265 41.2634C134.487 43.2238 134.125 45.3243 134.2 47.432C134.129 49.5386 134.495 51.637 135.274 53.5955C136.053 55.554 137.229 57.3301 138.728 58.8122C140.226 60.2943 142.015 61.4504 143.982 62.2078C145.949 62.9652 148.052 63.3076 150.157 63.2133C152.267 63.3115 154.374 62.9723 156.346 62.217C158.317 61.4618 160.112 60.3068 161.616 58.8247C163.12 57.3426 164.302 55.5654 165.086 53.6048C165.87 51.6442 166.24 49.5426 166.173 47.432C166.245 45.3203 165.878 43.2166 165.095 41.254C164.312 39.2914 163.131 37.5124 161.626 36.0294C160.121 34.5464 158.325 33.3916 156.351 32.6379C154.377 31.8842 152.268 31.5481 150.157 31.6507V31.6507ZM150.157 55.704C149.099 55.7235 148.05 55.5167 147.078 55.0972C146.107 54.6778 145.237 54.0556 144.525 53.2722C143.814 52.4888 143.279 51.5624 142.955 50.5552C142.631 49.5479 142.527 48.483 142.648 47.432C142.556 46.3901 142.681 45.3404 143.017 44.3496C143.352 43.3589 143.89 42.4488 144.597 41.6773C145.303 40.9058 146.162 40.2896 147.119 39.8681C148.077 39.4466 149.111 39.229 150.157 39.229C151.203 39.229 152.238 39.4466 153.195 39.8681C154.153 40.2896 155.012 40.9058 155.718 41.6773C156.424 42.4488 156.962 43.3589 157.298 44.3496C157.633 45.3404 157.759 46.3901 157.667 47.432C157.793 48.4853 157.691 49.5534 157.37 50.5642C157.048 51.5751 156.513 52.5052 155.802 53.2919C155.09 54.0786 154.218 54.7036 153.244 55.1247C152.271 55.5459 151.218 55.7534 150.157 55.7333V55.704Z" />
              {/* First 'm' of 'Commutifi' */}
              <path d="M208.707 31.6506C206.686 31.5324 204.674 31.9943 202.907 32.9817C201.14 33.9691 199.692 35.441 198.733 37.224C198.028 35.4952 196.802 34.0292 195.224 33.0302C193.647 32.0313 191.798 31.549 189.933 31.6506C188.063 31.5757 186.208 32.0179 184.573 32.9287C182.937 33.8395 181.585 35.1837 180.664 36.8133V32.472H172.392V62.392H180.723V45.4373C180.627 44.6278 180.71 43.807 180.966 43.033C181.221 42.259 181.644 41.5505 182.203 40.9575C182.763 40.3646 183.445 39.9015 184.203 39.6011C184.961 39.3006 185.776 39.1701 186.589 39.2186C189.933 39.2186 191.341 41.0373 191.341 44.8506V62.4506H199.672V45.4373C199.581 44.6288 199.668 43.8102 199.926 43.0385C200.183 42.2668 200.606 41.5605 201.164 40.9687C201.723 40.3769 202.403 39.9137 203.159 39.6115C203.914 39.3093 204.726 39.1752 205.539 39.2186C208.824 39.2186 210.232 41.0373 210.232 44.8506V62.4506H218.563V42.7973C218.739 35.6986 214.984 31.6506 208.707 31.6506Z" />
              {/* Second 'm' of 'Commutifi' */}
              <path d="M262.797 31.6506C260.777 31.5324 258.764 31.9943 256.997 32.9817C255.23 33.9691 253.782 35.441 252.824 37.224C252.119 35.4952 250.892 34.0292 249.315 33.0302C247.738 32.0313 245.888 31.549 244.024 31.6506C242.139 31.5591 240.266 31.9935 238.613 32.9051C236.961 33.8168 235.594 35.1699 234.667 36.8133V32.472H226.483V62.392H234.813V45.4373C234.717 44.6278 234.8 43.807 235.056 43.033C235.312 42.259 235.735 41.5505 236.294 40.9575C236.853 40.3646 237.536 39.9015 238.294 39.6011C239.052 39.3006 239.866 39.1701 240.68 39.2186C244.024 39.2186 245.432 41.0373 245.432 44.8506V62.4506H253.763V45.4373C253.672 44.6288 253.759 43.8102 254.016 43.0385C254.274 42.2668 254.697 41.5605 255.255 40.9687C255.813 40.3769 256.494 39.9137 257.249 39.6115C258.005 39.3093 258.817 39.1752 259.629 39.2186C262.915 39.2186 264.323 41.0373 264.323 44.8506V62.4506H272.8V42.7973C272.8 35.6986 269.075 31.6506 262.797 31.6506Z" />
              {/* 'u' of 'Commutifi' */}
              <path d="M299.552 32.472V49.368C299.626 50.2098 299.516 51.0576 299.232 51.8533C298.948 52.649 298.495 53.3739 297.904 53.9784C297.314 54.5828 296.599 55.0525 295.811 55.3554C295.022 55.6583 294.177 55.7872 293.333 55.7333C289.872 55.7333 288.64 53.856 288.64 49.8667V32.472H280.309V52.0667C280.309 59.1653 283.829 63.2133 290.107 63.2133C292.036 63.3152 293.956 62.8814 295.654 61.9599C297.352 61.0384 298.762 59.6651 299.728 57.992V62.392H308V32.472H299.552Z" />
              {/* 't' of 'Commutifi' */}
              <path d="M328.915 62.8027C330.896 62.8137 332.869 62.5572 334.781 62.04V54.9413C333.455 55.254 332.096 55.4115 330.733 55.4107C327.8 55.4107 326.685 54.472 326.685 52.008V39.7467H334.664V32.472H326.803V23.672H318.472V32.472H313.016V39.7467H318.472V53.24C318.472 59.928 321.64 62.8027 328.915 62.8027Z" />
              {/* First 'i' of 'Commutifi' */}
              <path d="M345.928 28.7467C348.779 28.7467 351.091 26.514 351.091 23.76C351.091 21.0059 348.779 18.7733 345.928 18.7733C343.077 18.7733 340.765 21.0059 340.765 23.76C340.765 26.514 343.077 28.7467 345.928 28.7467Z" />
              <path d="M349.331 32.472H341V62.392H349.331V32.472Z" />
              {/* 'f' of 'Commutifi' */}
              <path d="M360.184 62.392H368.515V39.7467H376.259V32.472H368.515V29.7733C368.515 27.3093 369.747 26.136 372.915 26.136C374.035 26.1173 375.155 26.2057 376.259 26.4V19.448C374.598 19.1073 372.908 18.9305 371.213 18.92C363.411 18.92 360.184 22.616 360.184 28.8933V32.472H354.669V39.7467H360.184V62.392Z" />
              {/* Second 'i' of 'Commutifi' */}
              <path d="M386.261 28.7467C386.937 28.7968 387.616 28.7035 388.253 28.4731C388.89 28.2426 389.472 27.8801 389.959 27.4093C390.447 26.9385 390.829 26.37 391.081 25.7411C391.334 25.1122 391.451 24.4371 391.424 23.76C391.424 22.3908 390.88 21.0776 389.912 20.1094C388.944 19.1412 387.631 18.5973 386.261 18.5973C384.892 18.5973 383.579 19.1412 382.611 20.1094C381.643 21.0776 381.099 22.3908 381.099 23.76C381.072 24.4371 381.189 25.1122 381.441 25.7411C381.694 26.37 382.076 26.9385 382.563 27.4093C383.051 27.8801 383.632 28.2426 384.27 28.4731C384.907 28.7035 385.586 28.7968 386.261 28.7467V28.7467Z" />
              <path d="M389.664 32.472H381.333V62.392H389.664V32.472Z" />
            </>
          )}
          <path
            opacity="0.547098"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M32.2666 60.4267C35.8315 60.4267 38.72 63.3152 38.72 66.88C38.72 70.4448 35.8315 73.3333 32.2666 73.3333C28.7018 73.3333 25.8133 70.4448 25.8133 66.88C25.8133 63.3152 28.7018 60.4267 32.2666 60.4267"
          />
          <path
            opacity="0.547098"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M34.5021 9.2021C37.8444 10.4376 39.5537 14.1489 38.3181 17.4888C37.0826 20.8311 33.3714 22.5403 30.0291 21.3048C26.6868 20.0693 24.9799 16.358 26.2154 13.0181C27.4509 9.67345 31.1598 7.96659 34.5021 9.2021"
          />
          <path
            opacity="0.547098"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.39435 34.6133C9.92657 34.6133 12.9067 37.5019 12.9067 41.0667C12.9067 44.6315 9.92657 47.52 6.39435 47.52C2.86212 47.52 0 44.6315 0 41.0667C0 37.5019 2.86212 34.6133 6.39435 34.6133"
          />
          <path
            opacity="0.547098"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.36 47.52C22.9248 47.52 25.8133 50.4085 25.8133 53.9733C25.8133 57.5381 22.9248 60.4267 19.36 60.4267C15.7952 60.4267 12.9067 57.5381 12.9067 53.9733C12.9067 50.4085 15.7952 47.52 19.36 47.52"
          />
          <path
            opacity="0.547098"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.36 21.7067C22.9248 21.7067 25.8133 24.5952 25.8133 28.16C25.8133 31.7248 22.9248 34.6133 19.36 34.6133C15.7952 34.6133 12.9067 31.7248 12.9067 28.16C12.9067 24.5952 15.7952 21.7067 19.36 21.7067"
          />
          <path
            opacity="0.547098"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M58.08 34.6133C61.6448 34.6133 64.5333 37.5019 64.5333 41.0667C64.5333 44.6315 61.6448 47.52 58.08 47.52C54.5152 47.52 51.6267 44.6315 51.6267 41.0667C51.6267 37.5019 54.5152 34.6133 58.08 34.6133"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M45.1126 21.7067C41.5143 21.7067 38.5986 24.6223 38.5986 28.2207C38.5986 28.2255 38.5649 30.6268 36.6492 32.5425C34.7382 34.4559 32.3321 34.4895 32.3297 34.4895C28.7314 34.4895 25.8133 37.4076 25.8133 41.006C25.8133 44.6019 28.7314 47.52 32.3297 47.52C35.9281 47.52 38.8438 44.6019 38.8438 41.006C38.8438 41.006 38.8726 38.5999 40.7884 36.6841C42.7041 34.7708 45.1126 34.7347 45.1126 34.7347C48.711 34.7347 51.6266 31.819 51.6266 28.2207C51.6266 24.6223 48.711 21.7067 45.1126 21.7067"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M51.6266 53.9126C51.6266 50.3143 48.711 47.3986 45.1126 47.3986C45.1078 47.3986 42.7065 47.365 40.7908 45.4516C38.8774 43.5383 38.8438 41.1322 38.8438 41.1298C38.8438 37.5314 35.9281 34.6133 32.3297 34.6133C28.7314 34.6133 25.8133 37.5314 25.8133 41.1298C25.8133 44.7257 28.7314 47.6438 32.3297 47.6438C32.3297 47.6438 34.7334 47.675 36.6492 49.5908C38.5625 51.5065 38.5986 53.9126 38.5986 53.9126C38.5986 57.511 41.5143 60.4267 45.1126 60.4267C48.711 60.4267 51.6266 57.511 51.6266 53.9126"
          />
        </g>
      </svg>
    </div>
  )
}
