import { HttpMethod } from '../../constants'

function returnPath(): string {
  return this.path
}

export const vehicleEndpoints = {
  [HttpMethod.Get]: {
    AccountVehicles: {
      path: '/account-vehicles',
      route: returnPath
    },
    VehiclesYears: {
      path: '/vehicles/years',
      route: returnPath
    },
    VehiclesMakes: {
      path: '/vehicles/makes',
      route: returnPath
    },
    VehiclesModels: {
      path: '/vehicles/models',
      route: returnPath
    },
    VehiclesEngineTypes: {
      path: '/vehicles/engine-types',
      route: returnPath
    }
  },

  [HttpMethod.Post]: {
    AccountVehicles: {
      path: '/account-vehicles',
      route: returnPath
    }
  },
  [HttpMethod.Put]: {
    AccountVehicles: {
      path: '/account-vehicles',
      route: returnPath
    }
  }
}

export default vehicleEndpoints
