import { GetResponse } from '@commutifi-fe/interfaces'
import { UseQueryOptions } from '@tanstack/react-query'
import { CommuterGuide } from '../../../Models/CommuterGuide'

export class GetCommuterGuideRequest<TQueryParams extends Record<string, any>> {
  constructor(
    public readonly queryParams?: TQueryParams,
    public readonly queryOptions?: Partial<
      UseQueryOptions<GetResponse<CommuterGuide>, any, CommuterGuide, [string, TQueryParams | undefined]>
    >
  ) {}
}
