export const LEG_TYPES = {
  stop: "stop",
  leg: "leg",
};

export enum CombinedModes {
  MixedModes = "mixed-modes",
  DriveAndTransit = "drive-and-public_transit",
}

export const MIN_HOME_WORK_DIST_KM = 0.015; // We consider 15m as close enough to say we have a geo point match (same point)
export const MAX_HOME_WORK_DIST_KM = 150; // We consider 150km a distance too big for an office -> home route
