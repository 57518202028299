import { HttpMethod } from '../../constants'

function replaceAnnouncementId(id: string): string {
  return this.path.replace(':announcementId', id)
}

export const announcementEndpoints = {
  [HttpMethod.Get]: {
    Announcements: {
      path: '/announcements',
      route: () => '/announcements'
    },
    Announcement: {
      path: '/announcements/:announcementId',
      route: replaceAnnouncementId
    },
    AnnouncementGroups: {
      path: '/enterprises/:enterpriseId/announcements-relations',
      route(id: string): string {
        return this.path.replace(':enterpriseId', id)
      }
    }
  },
  [HttpMethod.Patch]: {
    AnnouncementDraft: {
      path: '/announcements/:announcementId',
      route: replaceAnnouncementId
    }
  },
  [HttpMethod.Post]: {
    AnnouncementEmail: {
      path: '/announcements/:announcementId/send',
      route: replaceAnnouncementId
    },
    AnnouncementTest: {
      path: '/announcements/:announcementId/send/test',
      route: replaceAnnouncementId
    },
    AnnouncementDraft: {
      path: '/announcements',
      route(): string {
        return this.path
      }
    }
  }
}

export default announcementEndpoints
