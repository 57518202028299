import type { Required } from 'utility-types'
import faker from 'faker/locale/en'
import moment from 'moment-timezone'
import { times } from 'lodash'
import { Countries } from '@commutifi/constants/Countries'
import { AccountActivationStatus } from '@commutifi/models/Accounts'
import { EnterpriseKind } from '@commutifi/models/Enterprises'
import { Account } from '@commutifi-fe/interfaces'
import { fetchAccountKCI, fetchAccountRegionMetrics } from 'api/modules/accounts'
import type { ExtendedAccount } from 'shared/interfaces/Account'
import { parsePhoneNumber } from 'utils/phoneNumber'
import storage from 'utils/storage'
import { fakeManagedEnterpriseId, fakeManagedOrgId } from '../admins/data'
import { fakeAccountTimezone } from '../../config/constants'
import { fakeAccountOffice, fakeEnterpriseDashboardResponse, fakeEnterpriseId } from '../enterprises/data'
import { fakeGroups, fakeLegacyGroups } from '../groups/data'
import { fakeAccount } from '../../shared/fakers'
import { fakeOffice } from '../offices/fakers'
import { randomIn } from '../../_utils'

// eslint-disable-next-line no-var -- Need to be var for global scope
var fakeAccountId: string | null = null
export const getFakeAccountId = () => {
  const storedId = storage.get('test_account_id')
  if (!fakeAccountId && !storedId) {
    const accountId = faker.datatype.uuid()
    setFakeAccountId(accountId)
    return accountId
  }

  return fakeAccountId ?? storage.get('test_account_id')
}
export const setFakeAccountId = (accountId: string) => {
  storage.put('test_account_id', accountId)
  fakeAccountId = accountId
}

const fakeOrgId = faker.datatype.uuid()

export const getFakeAccount = (): Account => ({
  id: getFakeAccountId(),
  createdAt: '2021-11-10T20:20:38.942Z',
  updatedAt: '2022-01-17T20:20:44.802Z',
  activationStatus: AccountActivationStatus.Active,
  // Not important to fake real id here, it's just for select screen to work properly
  commuteProfileIds: ['1234'],
  country: Countries.UnitedStates,
  email: 'tm@commutifi.com',
  employeeId: '666',
  enterpriseId: fakeEnterpriseId,
  organizationId: fakeOrgId,
  hasDashboardAccess: true,
  identityProviderProfile: null,
  isDeactivated: false,
  isEmailVerified: true,
  isOnboarded: true,
  lastInvitationDate: null,
  lastLogin: '2022-01-17T20:20:44.571Z',
  location: { lat: 40.0675951, lng: -105.2894477 },
  managedEnterprises: [
    { id: fakeManagedEnterpriseId, kind: EnterpriseKind.enterprise, name: 'The Enterprise I Manage' },
    { id: fakeManagedOrgId, kind: EnterpriseKind.organization, name: 'The Organization I Manage' }
  ],
  name: 'Test Mock',
  office: fakeAccountOffice,
  officeId: fakeAccountOffice.id,
  phoneNumber: '450 555 6666',
  address: '430 Pierre St, Boulder, CO 80304, USA',
  city: 'Boulder',
  postalCode: '80304',
  state: 'Colorado',
  street: 'Pierre Street',
  streetNumber: '430',
  groups: [fakeGroups[0]]
})

export const getFakeExtendedAccount = (): Required<ExtendedAccount, 'timezone'> => ({
  ...getFakeAccount(),
  firstName: 'Test',
  lastName: 'Mock',
  phoneNumber: parsePhoneNumber(`+1 ${getFakeAccount().phoneNumber}`),
  timezone: fakeAccountTimezone
})

export const fakeAccountKCIResponse: Awaited<ReturnType<typeof fetchAccountKCI>> = {
  scoreVariation: {
    value: 5,
    since: moment().subtract(4, 'weeks').toISOString()
  },
  scores: {
    global: 72.0805,
    carbon: 70,
    cost: 73.1,
    time: 71.4
  },
  metrics: {
    time: { perDay: 696.5 },
    carbon: { perDay: 58.0277 / 30 },
    cost: { perDay: 340.408 / 30 }
  }
}

export const fakeAccountRegionMetricsResponse: Awaited<ReturnType<typeof fetchAccountRegionMetrics>> = {
  regionAverages: {
    time: 82,
    carbon: 75,
    cost: 59
  }
}

export const fakeAccountScores = {
  points: [
    {
      user: 73.47866973876953,
      startDate: '2022-02-01',
      endDate: '2022-02-28'
    },
    {
      user: 73.98183689946714,
      startDate: '2022-03-01',
      endDate: '2022-03-31'
    },
    {
      user: 73.74180058070591,
      startDate: '2022-04-01',
      endDate: '2022-04-30'
    },
    {
      user: 73.35308456420898,
      startDate: '2022-05-01',
      endDate: '2022-05-31'
    },
    {
      user: 73.35308456420898,
      startDate: '2022-06-01',
      endDate: '2022-06-30'
    },
    {
      user: 73.74180058070591,
      startDate: '2022-07-01',
      endDate: '2022-07-31'
    }
  ]
}

export const mockEmployee = {
  id: '0c97f246-b2cb-11e9-a2a3-2a2ae2dbcce4',
  name: 'John Smith',
  email: 'johnsmith@commutifi.com',
  phoneNumber: '450 444 4444',
  employeeId: '123456',
  activationStatus: AccountActivationStatus.Active,
  hasDashboardAccess: true,
  isEmailVerified: true,
  office: { buildingId: 'a33b4d3a-b954-11e9-a2a3-2a2ae2dbcce4' },
  // groupId: 1,
  groups: [fakeLegacyGroups[1]]
}
export const mockUpdatedEmployee = {
  ...mockEmployee,
  name: 'Updated name',
  email: 'updated@commutifi.com',
  activationStatus: AccountActivationStatus.Deactivated
}

export const fakeEnterpriseAccounts = times(18, () =>
  fakeAccount({
    office: fakeEnterpriseDashboardResponse.enterprise.offices
      ? fakeOffice(
          fakeEnterpriseDashboardResponse.enterprise.offices[
            randomIn({ min: 0, max: fakeEnterpriseDashboardResponse.enterprise.offices.length - 1 })
          ]
        )
      : fakeOffice(),
    enterpriseId: fakeEnterpriseDashboardResponse.enterprise.id
  })
)
