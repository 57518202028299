import { useMutation, useQuery } from '@tanstack/react-query'
import { createOrFetchEnterpriseSurvey, fetchEnterpriseSurvey, patchAnonymousSurveyAccount } from './api'
import { MutationConfig, QueryConfig, QueryConfigPickRes } from 'api/reactQuery'

export const useCreateOrFetchEnterpriseSurvey = (
  body: Parameters<typeof createOrFetchEnterpriseSurvey>[0],
  queryParams: Parameters<typeof createOrFetchEnterpriseSurvey>[1] = {},
  options?: QueryConfigPickRes<typeof createOrFetchEnterpriseSurvey, 'data'>
) =>
  useQuery({
    queryKey: ['enterprises-surveys', body, queryParams],
    queryFn: async () => {
      const { data } = await createOrFetchEnterpriseSurvey(body, queryParams)
      return data
    },
    ...options
  })

export const useFetchEnterpriseSurvey = (
  shortlinkId: string,
  queryParams = {},
  options: QueryConfig<typeof fetchEnterpriseSurvey> = {}
) =>
  useQuery({
    queryKey: ['enterprises-surveys', shortlinkId, queryParams],
    queryFn: () => fetchEnterpriseSurvey({ ...queryParams, shortlinkId }),
    ...options
  })

export const usePatchSurveyAccount = (
  surveyId: string | undefined,
  options?: MutationConfig<typeof patchAnonymousSurveyAccount, Parameters<typeof patchAnonymousSurveyAccount>[1]>
) =>
  useMutation({
    mutationFn: (data) => {
      if (!surveyId) {
        throw new Error('Survey id undefined')
      }
      return patchAnonymousSurveyAccount(surveyId, data)
    },
    ...options
  })
