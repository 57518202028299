const pre = 'plans/'

export default {
  // Action types with side-effects

  FETCH_PLANS: `${pre}FETCH_PLANS`,
  FETCH_PLANS_REQUEST: `${pre}FETCH_PLANS_REQUEST`,
  FETCH_PLANS_SUCCESS: `${pre}FETCH_PLANS_SUCCESS`,
  FETCH_PLANS_FAILURE: `${pre}FETCH_PLANS_FAILURE`,
  FETCH_PLANS_CANCEL: `${pre}FETCH_PLANS_CANCEL`
}
