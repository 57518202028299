import cx from 'classnames'
import React, { createElement, useReducer } from 'react'
import { useDeepCompareEffect } from '@commutifi-fe/hooks'
import { helpEmail } from '@commutifi-fe/constants'
import ErrorCompass from '@commutifi-fe/custom-icons/svgs/Compass'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons'
import { Button } from '../../AppButton'
import { useLocaleReceiver } from '../../locales/LocaleReceiver'
import { colors } from '../../style/constants'
import Error403 from './error-403.png'
import './styles.scss'

export interface GenericErrorProps {
  // Error object with status so we can infer the type of error and show the right
  // title icon, description, etc.
  error?: unknown
  // Type of the error. Page means we will display a background image and a button to
  // return home by default. Component won't have those by default but you can define actions
  // prop with a button if you want
  type?: 'page' | 'component'
  // When you need to hide the error image/icon set this boolean to true
  hideErrorIcon?: boolean
  // Overwrite the error title. You can set it to an empty string to hide it
  title?: React.ReactNode
  // Overwrite the text on the button
  buttonText?: React.ReactNode
  // Overwrite the error description. You can set it to an empty string to hide it
  description?: React.ReactNode
  // Define your own component(s) to have custom actions
  // (ex. instead of the default button that redirect the user you can define a button to reload the page)
  actions?: React.ReactNode
  // Element to use to build the lik on top of the button to redirect the user
  linkElement?: string
  // Redirect url
  redirect?: string
  style?: React.CSSProperties
  className?: string
  compact?: boolean
}

interface ErrorStateProps {
  img?: React.ReactNode
  title?: React.ReactNode
  description?: React.ReactNode
}

enum TypeKeys {
  SetError = 'set_error'
}

interface ErrorAction {
  type: TypeKeys
  payload: ErrorStateProps
}

type ErrorConfigKeyType = 404 | 403 | 500 | 'unknown'

function ContactUsLink({ children }: { children: React.ReactNode }) {
  return (
    <a href={`mailto:${helpEmail}`} target="__blank">
      {children}
    </a>
  )
}

function ErrorDescription({ description, children }: { description?: React.ReactNode; children?: React.ReactNode }) {
  const intl = useLocaleReceiver('GenericError')
  return (
    <div className="app-generic-error-description">
      <span>
        {description ||
          intl.formatMessage(
            { id: 'description' },
            {
              contactUsLink: (
                <ContactUsLink key="error-contact-us">{intl.formatMessage({ id: 'contactUs' })}</ContactUsLink>
              )
            }
          )}
        {children}
      </span>
    </div>
  )
}

function errorReducer(state: ErrorStateProps, action: ErrorAction): ErrorStateProps {
  const { type, payload } = action
  let changes
  switch (type) {
    case TypeKeys.SetError: {
      const { img, title, description } = payload
      changes = { img, title, description }
      break
    }
    default: {
      throw new Error(`Unhandled errorReducer action type: ${type as any}`)
    }
  }
  return { ...state, ...changes }
}

export const useError = (
  error: any,
  { title, description }: { title?: React.ReactNode; description?: React.ReactNode }
): [ErrorStateProps, React.Dispatch<ErrorAction>] => {
  const intl = useLocaleReceiver('GenericError')
  const [state, dispatch] = useReducer(errorReducer, {})

  const errorTypeConfig: Record<ErrorConfigKeyType, ErrorStateProps> = React.useMemo(
    () => ({
      404: {
        img: <ErrorCompass height={80} />,
        title: intl.formatMessage({ id: 'error404Title' }),
        description: (
          <ErrorDescription
            description={intl.formatMessage(
              { id: 'error404Description' },
              {
                link: <ContactUsLink>{intl.formatMessage({ id: 'contactUs' })}</ContactUsLink>
              }
            )}
          >
            &nbsp;
            {intl.formatMessage({ id: 'refresh' })}
          </ErrorDescription>
        )
      },
      403: {
        img: (
          <img
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access -- All good
            src={typeof Error403 === 'string' ? Error403 : Error403.src}
            className={cx('app-generic-error-icon', 'u-margin-bottom--medium')}
            alt="generic error"
          />
        ),
        title: intl.formatMessage({ id: 'error403Title' }),
        description: ''
      },
      500: {
        img: <ErrorCompass height={80} />,
        title: intl.formatMessage({ id: 'error500Title' }),
        description: <ErrorDescription />
      },
      unknown: {
        img: <ErrorCompass height={80} />,
        title: intl.formatMessage({ id: 'errorSorry' }),
        description: <ErrorDescription />
      }
    }),
    [intl]
  )

  useDeepCompareEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- Legacy
    const errorStatus: ErrorConfigKeyType =
      error && typeof error === 'object'
        ? // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- Legacy
          error.response?.status || error.status || error.statusCode || error.httpCode
        : undefined
    const errorType: ErrorConfigKeyType = Object.prototype.hasOwnProperty.call(errorTypeConfig, errorStatus)
      ? errorStatus
      : 'unknown'
    const errorConfig = errorTypeConfig[errorType]
    dispatch({
      type: TypeKeys.SetError,
      payload: {
        img: errorConfig.img,
        title: title || title === '' ? title : errorConfig.title,
        description: description || errorConfig.description
      }
    })
  }, [error, title])
  return [state, dispatch]
}

export function GenericError({
  error,
  type = 'component',
  hideErrorIcon = false,
  title,
  buttonText,
  description,
  actions,
  linkElement = 'a',
  redirect = '/',
  style,
  className,
  compact
}: GenericErrorProps) {
  const [errorState] = useError(error, { title, description })
  const intl = useLocaleReceiver('GenericError')

  return (
    <div
      data-testid="generic-error"
      className={cx(
        'app-generic-error',
        compact && 'app-generic-error--compact',
        type === 'page' && 'app-generic-error--full-page',
        className
      )}
      style={style}
    >
      <div className="app-generic-error-header">
        {!hideErrorIcon ? (
          compact ? (
            <FontAwesomeIcon
              icon={faCircleExclamation}
              color={colors.feedbackDestructive400}
              size="1x"
              className="u-margin-top--tiny"
            />
          ) : (
            errorState.img
          )
        ) : null}
        {errorState.title ? <span className="app-generic-error-text">{errorState.title}</span> : null}
      </div>
      {errorState.description}
      {actions ||
        (type === 'page' &&
          createElement(
            linkElement,
            {
              to: redirect,
              href: redirect
            },
            <Button size="middle" type="primary">
              <span>{buttonText || intl.formatMessage({ id: 'goBackHomeButton' })}</span>
            </Button>
          ))}
    </div>
  )
}
