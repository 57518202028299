import moment from 'moment-timezone'
import faker from 'faker/locale/en'
import { times } from 'lodash'
import { MerchantCategories } from '@commutifi/constants/Stripe'
import { AccountWalletStatus, ConfirmationStatus } from '@commutifi/models/AccountsWallets'
import { DaysOfTheWeek, SpendingRuleType } from '@commutifi/models/WalletSpendingRules'
import type { WalletSpendingRuleProps } from '@commutifi/models/WalletSpendingRules'
import { WalletType } from '@commutifi/models/Wallets'
import type { WalletProps } from '@commutifi/models/Wallets'
import { Account } from '@commutifi-fe/interfaces'
import type {
  AccountWallet,
  ExtendedWalletSpendingRule,
  GroupWalletWithMembers,
  WalletBalance
} from 'api/modules/wallets'
import { fakeCommutifiEmail, randomIn } from '../../_utils'
import { getFakeAccount } from '../accounts/data'
import { fakeAccountTimezone } from '../../config/constants'
import { fakeGroups } from '../groups/data'
import { enterprisePlans } from '../../shared/data'
import { CurrencyCode } from 'constants/settings'

export const fakeAccount = getFakeAccount()

export type ExtendedEnrollment = AccountWallet & {
  account: Partial<Account>
  balance?: WalletBalance['balance']
  monthlyTotalAllocation?: number
  type?: WalletType
}

export const fakeSpendingRule = (baseRule: Partial<ExtendedWalletSpendingRule> = {}): WalletSpendingRuleProps => {
  const type = baseRule.type || faker.random.arrayElement(Object.values(SpendingRuleType))
  return {
    ...baseRule,
    id: baseRule.id || faker.datatype.uuid(),
    dailyLimit: Object.prototype.hasOwnProperty.call(baseRule, 'dailyLimit')
      ? baseRule.dailyLimit
      : faker.datatype.boolean()
        ? faker.datatype.number()
        : undefined,
    transactionLimit: Object.prototype.hasOwnProperty.call(baseRule, 'transactionLimit')
      ? baseRule.transactionLimit
      : faker.datatype.boolean()
        ? faker.datatype.number()
        : undefined,
    monthlyLimit: Object.prototype.hasOwnProperty.call(baseRule, 'monthlyLimit')
      ? baseRule.monthlyLimit
      : faker.datatype.boolean()
        ? faker.datatype.number()
        : undefined,
    type,
    weekDaysLimit:
      type === SpendingRuleType.Wallet
        ? baseRule.weekDaysLimit || [DaysOfTheWeek.Wed, DaysOfTheWeek.Thu, DaysOfTheWeek.Fri]
        : undefined,
    value: Object.prototype.hasOwnProperty.call(baseRule, 'value')
      ? baseRule.value
      : type === SpendingRuleType.CommutifiPlan
        ? faker.random.arrayElement(enterprisePlans.map((p) => p.id))
        : faker.random.arrayElement(Object.values(MerchantCategories)),
    createdAt: baseRule.createdAt || faker.date.past().toISOString(),
    updatedAt: baseRule.updatedAt || faker.date.past().toISOString(),
    ...(type === SpendingRuleType.StripeMerchant && {
      cardMerchant: baseRule.cardMerchant || { name: faker.random.words(1) }
    })
  }
}

export const fakeWallet = (
  baseWallet: Partial<WalletProps & { spendingRules?: ExtendedWalletSpendingRule[] }> = {}
): WalletProps & { spendingRules: ExtendedWalletSpendingRule[] } => ({
  ...baseWallet,
  id: baseWallet.id || faker.datatype.uuid(),
  shortDescription: faker.lorem.paragraph(2),
  description: faker.lorem.paragraphs(2),
  enterpriseId: baseWallet.enterpriseId || fakeAccount.enterpriseId || faker.datatype.uuid(),
  isConfirmationRequired: baseWallet.isConfirmationRequired ?? faker.datatype.boolean(),
  offersMobilityCard: baseWallet.offersMobilityCard ?? true,
  name: baseWallet.name || faker.lorem.words(randomIn({ min: 1, max: 3 })),
  // Random between 100$ and 1000$
  monthlyTotalAllocation: baseWallet.monthlyTotalAllocation ?? randomIn({ min: 100, max: 1000 }) * 100,
  createdAt: baseWallet.createdAt || faker.date.past().toISOString(),
  updatedAt: baseWallet.updatedAt || faker.date.past().toISOString(),
  currency: baseWallet.currency || CurrencyCode.USD,
  spendingRules: baseWallet.spendingRules
    ? baseWallet.spendingRules.map(fakeSpendingRule)
    : times(3, () => fakeSpendingRule())
})

export const fakeWalletEnrolment = (
  baseWallet: Partial<
    AccountWallet & {
      account: Partial<Account>
      walletName?: string
    }
  > = {}
): ExtendedEnrollment => {
  const startDate = Object.prototype.hasOwnProperty.call(baseWallet, 'startDate')
    ? baseWallet.startDate
    : faker.date.past().toISOString()
  const endDate = Object.prototype.hasOwnProperty.call(baseWallet, 'endDate')
    ? baseWallet.endDate
    : faker.date.future().toISOString()
  const confirmationStatus =
    baseWallet.confirmationStatus || faker.random.arrayElement(Object.values(ConfirmationStatus))
  const walletId = baseWallet.walletId || faker.datatype.uuid()
  const wallet = fakeWallet({ ...baseWallet.wallet, id: walletId, name: baseWallet.walletName })
  return {
    ...baseWallet,
    id: baseWallet.id || faker.datatype.uuid(),
    startDate,
    endDate,
    confirmationStatus,
    status:
      confirmationStatus === ConfirmationStatus.Confirmed &&
      startDate &&
      (!endDate || moment.tz(fakeAccountTimezone).isBefore(moment.tz(endDate, fakeAccountTimezone)))
        ? AccountWalletStatus.Active
        : confirmationStatus === ConfirmationStatus.Processing
          ? AccountWalletStatus.Processing
          : endDate && moment.tz(endDate, fakeAccountTimezone).isBefore(moment.tz(fakeAccountTimezone))
            ? AccountWalletStatus.Terminated
            : AccountWalletStatus.Rejected,
    timezone: fakeAccountTimezone,
    walletId,
    wallet,
    accountId: baseWallet.accountId || faker.datatype.uuid(),
    account: {
      id: faker.datatype.uuid(),
      name: `${faker.name.firstName()} ${faker.name.lastName()}`,
      email: fakeCommutifiEmail(),
      phoneNumber: faker.phone.phoneNumber('+1 ###-####'),
      enterpriseId: faker.datatype.uuid(),
      timezone: fakeAccountTimezone,
      employeeId: faker.datatype.uuid(),
      isOnboarded: faker.datatype.boolean(),
      hasDashboardAccess: faker.datatype.boolean(),
      isDeactivated: faker.datatype.boolean(),
      lastInvitationDate: faker.date.past().toISOString()
    },
    createdAt: baseWallet.createdAt || faker.date.past().toISOString(),
    updatedAt: baseWallet.updatedAt || faker.date.past().toISOString()
  }
}

export const fakeGroupWallet = (base: Partial<GroupWalletWithMembers> = {}): GroupWalletWithMembers => {
  const group = base.group || {
    ...fakeGroups[randomIn({ min: 0, max: fakeGroups.length - 1 })],
    enterpriseGroupPlans: times(randomIn({ min: 1, max: 4 }), () => ({
      planId: faker.datatype.uuid(),
      enterpriseGroupId: faker.datatype.uuid()
    }))
  }
  return {
    ...base,
    id: base.id || faker.datatype.uuid(),
    membersCount: base.group?.members?.length ?? base.membersCount ?? faker.datatype.number(1000),
    walletId: base.walletId || faker.datatype.uuid(),
    groupId: group.id,
    group
  }
}
