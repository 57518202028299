import types from './types'
import { combineReducers } from 'redux'

const entities = (state = {}, action = {}) => {
  switch (action.type) {
    case types.FETCH_METRICS_SUCCESS:
      return {
        ...state,
        ...action.payload
      }
    default:
      return state
  }
}

const isFetching = (state = false, action = {}) => {
  switch (action.type) {
    case types.FETCH_METRICS_REQUEST:
      return true
    case types.FETCH_METRICS_SUCCESS:
    case types.FETCH_METRICS_FAILURE:
      return false
    default:
      return state
  }
}

export default combineReducers({
  entities,
  isFetching
})
