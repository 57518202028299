import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface IncentiveProgramsState {
  currentProgramId: string | null
}

const initialState: IncentiveProgramsState = {
  currentProgramId: null
}

export const incentiveProgramsSlice = createSlice({
  name: 'incentivePrograms',
  initialState,
  reducers: {
    setCurrentProgramId: (state, action: PayloadAction<string>) => {
      state.currentProgramId = action.payload
    }
  }
})

// Action creators are generated for each case reducer function
export const { setCurrentProgramId } = incentiveProgramsSlice.actions

export default incentiveProgramsSlice.reducer
