import React from 'react'
import { AntdApp, MessageArgsProps, NotificationArgsProps } from '@commutifi-fe/ui'
import { logger } from '@commutifi-fe/logger'
import { Optional } from 'utility-types'
import { IntlMessageKeys, useComponentIntl } from '../locales'
import { useCommutifiErrorMessages } from './useCommutifiErrorMessages'

type UseNotificationApi = Optional<NotificationArgsProps, 'message'>

const typeSafe = (
  config: Optional<MessageArgsProps, 'content'> | React.ReactNode
): config is Optional<MessageArgsProps, 'content'> & {
  contentId?: IntlMessageKeys<'errors'>
} =>
  Boolean(
    config &&
      (Object.prototype.hasOwnProperty.call(config, 'content') ||
        Object.prototype.hasOwnProperty.call(config, 'contentId'))
  )

/**
 * Hook that centralize antd notification and message APIs. The goal is to make it more
 * intuitive and easier to use notification vs. messages (which are really similar; See antd doc).
 *
 * In most error cases we will use what is called a 'notification' that offer the option to be closed
 * and closes itself after a longer time than messages.
 * Vs. Successes where we want a quick 'message' that will never need any user interaction
 *
 * Also, the error notification is wrapped in our function that will define a message prop if
 * none is provider (Default to 'Error'):
 * @example [ICON] Error
 *                 Custom description of the error
 *
 *
 * @returns Antd notification API wrapped in our functions to set some default values
 *          and antd message api
 */
export const useNotifications = () => {
  const intl = useComponentIntl('errors')
  const { message: appMessage, notification } = AntdApp.useApp()
  const { translateCommutifiError, translateCommutifiSuccess, translate } = useCommutifiErrorMessages()
  const getNotificationConfig = React.useCallback(
    ({ message, description, ...config }: UseNotificationApi) => ({
      message,
      description,
      ...config
    }),
    []
  )

  // Uses the antd message API for successes (See main function comment) and NOTE that
  // if you want to use notification.success (from notification API), you will need to defined
  // and expose a new and different function
  const success = React.useCallback(
    // Sending message id (Intl translation key) is also supported
    (
      config?:
        | (Optional<MessageArgsProps, 'content'> & {
            contentId?: IntlMessageKeys<'errors'>
          })
        | React.ReactNode
    ) =>
      appMessage.success(
        typeof config === 'string'
          ? config
          : typeSafe(config)
            ? { ...config, content: config.content || intl.formatMessage({ id: 'global.success' }) }
            : config,
        6
      ),
    [intl, appMessage]
  )

  /**
   * Used specifically to find the URL param names 'success' and use that as a key
   * with the success messages mapping to display a message on screen.
   *
   * Accepts a fallbackMessage (intl translated message) to use if the success param isn't matching
   * any errors in the Map
   */
  const handleUrlSuccessParam = React.useCallback(
    (successText: string, options?: { fallbackMessage?: string }) => {
      appMessage.success(translateCommutifiSuccess(successText, options?.fallbackMessage))
    },
    [appMessage, translateCommutifiSuccess]
  )

  // Error wrapper. Message defaults to 'Error'
  const error = React.useCallback(
    (config: UseNotificationApi) =>
      notification.error(
        getNotificationConfig({
          ...config,
          message: !config.message ? intl.formatMessage({ id: 'global.error' }) : config.message
        })
      ),
    [getNotificationConfig, intl, notification]
  )

  /**
   * Tries to find the best error message match based on commutifi error code or HTTP code
   * and displays the notification on screen.
   *
   * Accepts a fallbackMessage if no good match is found
   */
  const handleError = React.useCallback(
    (err: any, options?: { fallbackMessage?: string; config?: Omit<UseNotificationApi, 'description'> }) => {
      logger.error(err)
      error({
        description: translateCommutifiError(err, options?.fallbackMessage),
        ...options?.config
      })
    },
    [error, translateCommutifiError]
  )
  const handleErrorCode = React.useCallback(
    (code: string | number | undefined) => {
      error({ description: translate(code) })
    },
    [error, translate]
  )

  return {
    success,
    error,
    info: notification.info,
    warning: notification.warning,
    open: notification.open,
    close: (key?: React.Key | undefined) => notification.destroy(key),
    destroy: (key?: React.Key | undefined) => notification.destroy(key),
    messages: appMessage,
    handleError,
    handleErrorCode,
    handleUrlSuccessParam,
    translate
  }
}
