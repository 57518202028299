import { HttpMethod } from '../../constants'

export const subdomainEndpoints = {
  [HttpMethod.Get]: {
    SubdomainData: {
      path: '/subdomains/:subdomain',
      route(subdomain: string): string {
        return this.path.replace(':subdomain', subdomain)
      }
    }
  }
}

export default subdomainEndpoints
