import { HttpMethod } from '../../constants'

function returnPath(): string {
  return this.path
}

export const commuterGuideEndpoints = {
  [HttpMethod.Get]: {
    CommuterGuides: {
      path: '/commuter-guides',
      route: returnPath
    },
    Resources: {
      path: '/resources',
      route: returnPath
    }
  },
  [HttpMethod.Post]: {
    ResourcesSms: {
      path: '/resources/:resourceId/sms',
      route(resourceId: string): string {
        return this.path.replace(':resourceId', resourceId)
      }
    }
  }
}

export default commuterGuideEndpoints
