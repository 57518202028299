import * as React from 'react'
import { IntlFormatters, IntlShape, createIntl, createIntlCache } from '@formatjs/intl'
import type { Locale } from '.'
import { ConfigContext } from '../ConfigProvider/context'
import defaultLocaleData from './default'
import { Overwrite, DeepRequired } from 'utility-types'
import { toFlatPropertyMap } from '@commutifi-fe/utils'

const intlCache = createIntlCache()
// The arguments to the original formatMessage function.
type FormatMessageArgs = Parameters<IntlFormatters['formatMessage']>

export type LocaleComponentName = Exclude<keyof Locale, 'locale' | 'global'>

export type RecursiveKeyOf<TObj extends any> = {
  [TKey in keyof TObj & (string | number)]: TObj[TKey] extends any[]
    ? `${TKey}`
    : TObj[TKey] extends object
      ? `${TKey}` | `${TKey}.${RecursiveKeyOf<TObj[TKey]>}`
      : `${TKey}`
}[keyof TObj & (string | number)]

export type TypedIntl<C extends LocaleComponentName = LocaleComponentName> = Overwrite<
  IntlShape,
  {
    formatMessage: (
      descriptor: {
        id?: RecursiveKeyOf<DeepRequired<Locale>[C]> | `global.${keyof Locale['global']}`
      },
      values?: FormatMessageArgs[1],
      options?: FormatMessageArgs[2]
    ) => string
  }
>

export interface LocaleReceiverProps<C extends LocaleComponentName = LocaleComponentName> {
  componentName?: C
  defaultLocale?: Locale[C] | (() => Locale[C])
  children: (locale: TypedIntl<C>, localeCode: string, fullLocale: Locale) => React.ReactElement
}

const LocaleReceiver = <C extends LocaleComponentName = LocaleComponentName>(props: LocaleReceiverProps<C>) => {
  const { componentName, defaultLocale, children } = props
  const { locale: commutifiLocale } = React.useContext(ConfigContext)
  const getLocale = React.useMemo<NonNullable<Locale[C]> & Pick<Locale, 'global'>>(() => {
    const locale = defaultLocale || (componentName ? defaultLocaleData[componentName] : {})
    const globalLocale = defaultLocale || defaultLocaleData['global']
    const localeFromContext = componentName ? commutifiLocale?.[componentName] ?? {} : {}
    const globalFromContext = commutifiLocale?.['global'] ?? {}

    // Make sure intl is able to read the keys by flattening the TS object ({ one: { two: '' }} becomes { 'one.two': '' })
    return toFlatPropertyMap({
      ...(typeof locale === 'function' ? locale() : locale),
      ...(typeof globalLocale === 'function' ? globalLocale() : globalLocale),
      ...(localeFromContext || {}),
      global: globalFromContext || {}
    })
  }, [componentName, defaultLocale, commutifiLocale])

  const getLocaleCode = React.useMemo<string>(() => {
    const localeCode = commutifiLocale && commutifiLocale.locale
    // Had use ConfigProvider but didn't set locale
    if (commutifiLocale && !localeCode) {
      return defaultLocaleData.locale
    }
    return localeCode!
  }, [commutifiLocale])

  const intl: TypedIntl<C> = React.useMemo(
    () =>
      createIntl(
        {
          locale: getLocaleCode,
          messages: getLocale as Record<string, any>
        },
        intlCache
      ),
    []
  )

  return children(intl, getLocaleCode, commutifiLocale!)
}

export default LocaleReceiver

export const useLocaleReceiver = <C extends LocaleComponentName = LocaleComponentName>(
  componentName?: C,
  defaultLocale?: Locale[C] | (() => Locale[C])
): TypedIntl<C> => {
  const { locale: commutifiLocale } = React.useContext(ConfigContext)
  const getLocale = React.useMemo<NonNullable<Locale[C]> & Pick<Locale, 'global'>>(() => {
    const locale = defaultLocale || (componentName ? defaultLocaleData[componentName] : {})
    const globalLocale = defaultLocale || defaultLocaleData['global']
    const localeFromContext = componentName ? commutifiLocale?.[componentName] ?? {} : {}
    const globalFromContext = commutifiLocale?.['global'] ?? {}
    // Make sure intl is able to read the keys by flattening the TS object ({ one: { two: '' }} becomes { 'one.two': '' })
    return toFlatPropertyMap({
      ...(typeof locale === 'function' ? locale() : locale),
      ...(typeof globalLocale === 'function' ? globalLocale() : globalLocale),
      ...(localeFromContext || {}),
      global: globalFromContext || {}
    })
  }, [componentName, defaultLocale, commutifiLocale])

  const getLocaleCode = React.useMemo<string>(() => {
    const localeCode = commutifiLocale && commutifiLocale.locale
    // Had use ConfigProvider but didn't set locale
    if (commutifiLocale && !localeCode) {
      return defaultLocaleData.locale
    }
    return localeCode!
  }, [commutifiLocale])

  // Make sure to create only ONE intl object per language per component that uses this hook
  const intl: TypedIntl<C> = React.useMemo(
    () =>
      createIntl(
        {
          locale: getLocaleCode,
          messages: getLocale as Record<string, any>
        },
        intlCache
      ),
    [getLocale]
  )

  return intl
}
