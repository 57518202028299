import { GetResponse } from 'shared/interfaces'
import { apiCall, dashboardApiServer } from '../../index'
import { SelfReportedTrips, Trip, TripQueryParams } from './types'
import endpoints from './endpoints'

const apiDashboardServer = apiCall(dashboardApiServer)

export const fetchTrips = (accountId: string, queryParams: TripQueryParams): Promise<GetResponse<Trip>> =>
  apiDashboardServer(endpoints.GET.AccountTrips.route(accountId), {
    method: 'get',
    queryParams
  })

export const postSelfReportedTrips = (data: SelfReportedTrips[]): Promise<Trip[]> =>
  apiDashboardServer(endpoints.POST.SelfReportedTrips.route(), {
    method: 'post',
    data
  })
