import { MutationConfig, QueryConfig, QueryConfigPickRes } from 'api/reactQuery'
import {
  DetailedPlanQueryParams,
  PlanBookingQueryParams,
  fetchOnePlan,
  fetchPlanBookings,
  fetchPlanCheckout,
  postPlanGroups
} from './api'
import { useMutation, useQuery } from '@tanstack/react-query'

export const usePlanBookings = (
  planId: string | undefined,
  queryParams: PlanBookingQueryParams,
  options?: QueryConfigPickRes<typeof fetchPlanBookings, 'records'>
) =>
  useQuery({
    queryKey: ['plans', planId, 'bookings', queryParams],
    queryFn: async () => {
      if (!planId) {
        throw Error('Missing plan id')
      }

      const { records } = await fetchPlanBookings(planId, queryParams)
      return records
    },
    ...options
  })

export const detailedPlanQueryKeyPrefix = 'plans'
export const useDetailedPlan = (
  planId: string,
  queryParams: DetailedPlanQueryParams,
  options?: QueryConfig<typeof fetchOnePlan>
) =>
  useQuery({
    queryKey: [detailedPlanQueryKeyPrefix, planId, queryParams],
    queryFn: async () =>
      fetchOnePlan(planId, {
        ...queryParams,
        relations: ['planFaqs', 'service', 'service.provider', 'regions', 'planBookingFields', 'merchant'],
        fields: [
          'merchant.id',
          'merchant.legalName',
          'merchant.termsOfService',
          'merchant.termsOfServiceLink',
          'merchant.currency'
        ]
      }),
    ...options
  })

export const planCheckoutQueryKeyPrefix = 'plansCheckout'
export const useFetchCheckout = (
  planId: string,
  queryParams: Parameters<typeof fetchPlanCheckout>[1],
  options?: QueryConfigPickRes<typeof fetchPlanCheckout, 'records'>
) =>
  useQuery({
    queryKey: [planCheckoutQueryKeyPrefix, planId, queryParams],
    queryFn: async () => {
      const { records } = await fetchPlanCheckout(planId, queryParams)
      return records
    },
    ...options
  })

export const useCreatePlanGroups = (
  planId: string,
  options?: MutationConfig<typeof postPlanGroups, Parameters<typeof postPlanGroups>[1]>
) =>
  useMutation({
    mutationFn: (body) => postPlanGroups(planId, body),
    ...options
  })
