const pre = 'metrics/user/'

export default {
  // Action types with no side-effects (no api calls, only change state)
  // ...

  // Action types with side-effects
  FETCH_METRICS_REQUEST: `${pre}FETCH_METRICS_REQUEST`,
  FETCH_METRICS_SUCCESS: `${pre}FETCH_METRICS_SUCCESS`,
  FETCH_METRICS_FAILURE: `${pre}FETCH_METRICS_FAILURE`
}
