export enum ModalType {
  SMS = 'sendSms'
}

export enum ModalSize {
  SM = 'small',
  MD = 'medium',
  LG = 'large'
}

interface ModalPayload {
  modalType: `${ModalType}` | null
  modalProps: any
  modalSize: `${ModalSize}` | null
}

const SHOW_MODAL = 'modals/SHOW_MODAL'
const HIDE_MODAL = 'modals/HIDE_MODAL'
const UPDATE_MODAL = 'modals/UPDATE_MODAL'

export const showModal = (modalType: ModalType, modalProps: any, modalSize: ModalSize) => ({
  type: SHOW_MODAL,
  payload: {
    modalType,
    modalProps,
    modalSize
  }
})

export const hideModal = (modal = null) => ({
  type: HIDE_MODAL,
  payload: { modal }
})

export const updateModal = (data: any) => ({
  type: UPDATE_MODAL,
  payload: data
})

interface ShowModalAction {
  type: typeof SHOW_MODAL
  payload: ModalPayload
}
interface HideModalAction {
  type: typeof HIDE_MODAL
}
interface UpdateModalAction {
  type: typeof UPDATE_MODAL
  payload: ModalPayload
}
export type ModalActionTypes = ShowModalAction | HideModalAction | UpdateModalAction

const initialState = {
  modalType: null,
  modalProps: {},
  modalSize: ModalSize.MD
}

export function modalsReducer(state = initialState, action: ModalActionTypes = {} as any) {
  switch (action.type) {
    case SHOW_MODAL: {
      const { payload } = action
      return {
        ...state,
        modalType: payload.modalType,
        modalProps: payload.modalProps || initialState.modalProps,
        modalSize: payload.modalSize || initialState.modalSize
      }
    }
    case UPDATE_MODAL: {
      const { payload } = action

      return {
        ...state,
        modalProps: {
          ...state.modalProps,
          ...payload
        }
      }
    }
    case HIDE_MODAL:
      return {
        ...state,
        modalType: null
      }
    default:
      return state
  }
}

export type ModalState = ReturnType<typeof modalsReducer>
export default modalsReducer
