import { HttpMethod } from '../../constants'

function replaceAccountId(accountId: string): string {
  return this.path.replace(':accountId', accountId)
}
function replaceSurveyId(surveyId: string): string {
  return this.path.replace(':surveyId', surveyId)
}
function returnPath() {
  return this.path
}

export const surveyEndpoints = {
  [HttpMethod.Get]: {
    EnterprisesSurveys: {
      path: '/enterprises-surveys',
      route: returnPath
    },
    DefaultSurvey: {
      path: '/enterprises-surveys/default-survey',
      route: returnPath
    }
  },
  [HttpMethod.Post]: {
    SurveyAnswers: {
      path: '/surveys/:surveyId/answers',
      route: replaceSurveyId
    },
    OnboardSurveyWithAccount: {
      path: '/accounts/:accountId/onboarding',
      route: replaceAccountId
    },
    FollowUpSurvey: {
      path: '/accounts/:accountId/answers',
      route: replaceAccountId
    },
    EnterprisesSurveys: {
      path: '/enterprises-surveys',
      route: returnPath
    }
  },
  [HttpMethod.Patch]: {
    AnonymousSurveyAccount: {
      path: '/surveys/:surveyId/accounts',
      route: replaceSurveyId
    }
  }
}

export default surveyEndpoints
