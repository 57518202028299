import { useMutation, useQuery } from '@tanstack/react-query'
import { MutationConfig, QueryConfig } from '../../reactQuery'
import { activateCard, fetchVirtualCard, patchCardAddresses, postCard } from './api'
import endpoints from './endpoints'

export const cardsQueryKeys = {}

export type VirtualCardQueryOptions = QueryConfig<typeof fetchVirtualCard>
export const useVirtualCard = (cardId: string | undefined, options?: VirtualCardQueryOptions) =>
  useQuery({
    queryKey: [endpoints.GET.CardNumber.route(cardId || '')],
    queryFn: async () => {
      if (!cardId) {
        throw Error('Cannot fetch virtual card information without a cardId parameter')
      }

      return fetchVirtualCard(cardId)
    },
    ...options
  })

export const useCreateCard = (options?: MutationConfig<typeof postCard>) =>
  useMutation({ mutationFn: (body) => postCard(body), ...options })

export const usePatchCardAddresses = (
  cardId: string,
  options?: MutationConfig<typeof patchCardAddresses, Parameters<typeof patchCardAddresses>[1]>
) => useMutation({ mutationFn: (body) => patchCardAddresses(cardId, body), ...options })

export const useActivateCard = (options?: MutationConfig<typeof activateCard, { cardId: string }>) =>
  useMutation({
    mutationFn: ({ cardId }) => activateCard(cardId),
    ...options
  })
