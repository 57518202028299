import {
  Locales,
  LocalesType,
  IMPERIAL_UNITS_BY_MEASURE_TYPE,
  METRIC_UNITS
} from '@commutifi-fe/constants'
import momentDurationFormatSetup from 'moment-duration-format'
import { unit } from './math'
import moment from 'moment-timezone'

// @ts-expect-error -- Legacy
momentDurationFormatSetup(moment)

/**
 * Return unit used in locale. ex. en-us - imperial units
 * @param locale - code for locale
 */
export const getLocaleMeasurementUnits = (locale: LocalesType) => {
  switch (locale) {
    case Locales.enUS:
      return IMPERIAL_UNITS_BY_MEASURE_TYPE
    default:
      return METRIC_UNITS
  }
}

/**
 * This function takes a string value that includes the unit and
 * convert it to the specified measure type unit used in the actual
 * region of the locale (en-us, fr-ca, etc.)
 *
 * @param valueWithUnit - string value that includes the unit: '5 km' or '5km' or '5 kilometers', etc
 * @param toUnit - unit to convert it to
 *
 * @returns Numeric value from the current locale region
 */
export const convertToUnit = (
  valueWithUnit: string,
  toUnit: string | undefined
): number | undefined => {
  if (!valueWithUnit || !toUnit) {
    return undefined
  }
  try {
    return unit(valueWithUnit).toNumber(toUnit)
  } catch (error) {
    return undefined
  }
}

/**
 * Format time
 * @param seconds - number of seconds used as an input to format time
 * @param format - format of the time (default example: 1h 32min or 45min, never 0h 45min)
 */
export const formatToTimeString = (
  seconds: number,
  format = 'h [_] m [_]',
  options: moment.DurationFormatSettings = {}
) => {
  if (isFinite(seconds)) {
    return moment
      .duration(seconds, 'seconds')
      .format(format, { usePlural: false, ...options })
  }
  return undefined
}
