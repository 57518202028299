import { SurveyType } from '@commutifi/models/Surveys'
import difference from 'lodash/difference'
import get from 'lodash/get'
import map from 'lodash/map'
import pick from 'lodash/pick'
import { put, select, takeLatest } from 'redux-saga/effects'

import {
  fetchDefaultSurvey,
  fetchEnterpriseSurvey,
  postFollowUpSurvey,
  postOnboardSurveyWithAccount,
  postOnboardSurveyWithoutAccount
} from 'api/modules/survey'
import {
  FollowUpStorageKeys,
  REF_KEYS_LIST,
  dropOffKidsQId,
  primaryRouteObjectId,
  primaryRoutePath,
  secondaryRouteFrequencyPath,
  secondaryRouteObjectId,
  secondaryRoutePath,
  subQuestionIdPrefix
} from 'constants/onboarding'
import { MonToFri } from 'shared/interfaces/Commute'
import { callApiSaga } from 'store/callApiSaga'
import { accountSelectors } from 'store/modules/accounts'
import { enterpriseSurvey, normalize } from 'store/schemas'
import { formatRouteTransitLegs } from 'utils/formatters'
import { isUuid } from 'utils/helpers'
import { calculateAvailableFrequency, getArrayValue } from 'utils/index'
import logger from 'utils/logRocket'
import { generateStopFromLocation, insertStopsAt } from 'utils/routes'
import storage from 'utils/storage'
import { selectors as surveySelectors } from './index'
import types from './types'

/**
 * ---------------- ACTIONS ----------------
 */
export const upsertSurveyQuestions = (questions) => ({
  type: types.UPSERT_SURVEY_QUESTION,
  payload: { questions: getArrayValue(questions) }
})

export const removeSurveyQuestions = (questionIds) => ({
  type: types.REMOVE_SURVEY_QUESTION,
  payload: { questionIds: getArrayValue(questionIds) }
})

export const updateSurveyAnswers = (stepNumber, answers) => ({
  type: types.UPDATE_SURVEY_ANSWERS,
  payload: { stepNumber, answers }
})

export const resetSurveyAnswers = () => ({
  type: types.RESET_SURVEY_ANSWERS
})

export const createSurveyAnswers = (surveyId, account, promise) => ({
  type: types.CREATE_SURVEY_ANSWERS,
  surveyId,
  account,
  promise
})

export const resetSurvey = () => ({
  type: types.RESET_SURVEY
})

export const loadEnterpriseSurvey = (queryParams, options, promise) => ({
  type: types.FETCH_SURVEY_TRIGGER,
  types: [types.FETCH_SURVEY_REQUEST, types.FETCH_SURVEY_BEFORE_SUCCESS, types.FETCH_SURVEY_FAILURE],
  callAPI: () => fetchEnterpriseSurvey(queryParams),
  payload: { ...queryParams, options },
  promise,
  normalize: (data) => normalize(data?.records?.[0] || [], enterpriseSurvey)
})

export const loadDefaultSurvey = (queryParams, options, promise) => ({
  type: types.FETCH_DEFAULT_SURVEY_TRIGGER,
  types: [types.FETCH_DEFAULT_SURVEY_REQUEST, types.FETCH_DEFAULT_SURVEY_SUCCESS, types.FETCH_DEFAULT_SURVEY_FAILURE],
  callAPI: () => fetchDefaultSurvey(queryParams),
  payload: { ...queryParams, options },
  promise,
  normalize: (data) => normalize(data, enterpriseSurvey)
})

export const _postSurvey = (surveyId, surveyType, body, accountId, promise) => ({
  type: types.POST_SURVEY_TRIGGER,
  types: [types.POST_SURVEY_REQUEST, types.POST_SURVEY_SUCCESS, types.POST_SURVEY_FAILURE],
  callAPI: () => {
    if (!accountId && surveyType === SurveyType.onboarding) {
      return postOnboardSurveyWithoutAccount(surveyId, body)
    } else if (accountId && surveyType === SurveyType.onboarding) {
      return postOnboardSurveyWithAccount(accountId, { surveyId, ...body })
    } else if (accountId && surveyType === SurveyType.followUp) {
      return postFollowUpSurvey(accountId, { surveyId, ...body })
    }
  },
  promise
})

export const updateProfileAddress = () => ({
  type: types.UPDATE_PROFILE_ADDRESS
})
export const updateProfileAddressDone = () => ({
  type: types.UPDATE_PROFILE_ADDRESS_DONE
})

/**
 * ---------------- SAGAS ----------------
 */
export const enterpriseSurveySagas = [
  takeLatest(types.FETCH_SURVEY_TRIGGER, callApiSaga),
  takeLatest(types.FETCH_SURVEY_BEFORE_SUCCESS, resetAnswers),
  takeLatest(types.FETCH_DEFAULT_SURVEY_TRIGGER, callApiSaga),
  takeLatest(types.CREATE_SURVEY_ANSWERS, processSurvey),
  takeLatest(types.POST_SURVEY_TRIGGER, callApiSaga)
]

export function* resetAnswers(res) {
  const previousSurveyId = yield select(surveySelectors.getSurveyId)
  yield put({ ...res, type: types.FETCH_SURVEY_SUCCESS })

  const { payload, response } = res
  // If same survey -> no need to reset answers
  if (payload && !payload.options?.forceReset && previousSurveyId === response?.result?.survey?.id) {
    return
  }
  return yield put(resetSurveyAnswers())
}

export function* processSurvey({ surveyId, account, promise }) {
  // Account id should be available only if user is logged in
  const currentAccountId = yield select(accountSelectors.getCurrentId)
  const surveyType = yield select(surveySelectors.makeGetSurvey('type'))
  try {
    const accountAnswers = account
      ? [
          { referenceKey: REF_KEYS_LIST.accountName, value: account.name },
          { referenceKey: REF_KEYS_LIST.accountEmail, value: account.email },
          { referenceKey: REF_KEYS_LIST.accountPassword, value: account.password },
          { referenceKey: REF_KEYS_LIST.accountPreferredLocale, value: account.preferredLocale }
        ].filter((answer) => answer.value)
      : []
    const idpLogin = pick(account, ['authorizationCode', 'state', 'identityProviderId', 'preferredLocale'])

    // Get survey answer
    const answers = yield select(surveySelectors.getAnswers)

    // Format answers
    // We filter temporary values. (used per example to build routes with activity)
    // Now that the answer formatting is done we can remove it from the
    // answers to make sure we don't send useless information to the API
    const surveyAnswers = yield map(answers, (value, key) => ({
      ...(isUuid(key) ? { questionId: key } : { referenceKey: key }),
      value: _verifyAndFormatAnswer(key, value, answers)
    })).filter(
      (answer) =>
        !(answer.questionId || '').startsWith(subQuestionIdPrefix) &&
        !(answer.referenceKey || '').startsWith(subQuestionIdPrefix)
    )

    // Get shortlink id to give access to some prefilled data
    const enterpriseSurveyId = yield select(surveySelectors.makeGetPrefilledData('id'))
    const surveyPayload = yield { enterpriseSurveyId, answers: [...accountAnswers, ...surveyAnswers], ...idpLogin }

    // Trigger post survey
    yield put(_postSurvey(surveyId, surveyType, surveyPayload, currentAccountId, promise))

    // don't resolve the promise here since we pass it down to the request
    // middleware that will take care of resolving the promise if the call is successful
  } catch (error) {
    logger.error('Error while generating survey answers: ', error)
    promise && promise.reject(error)
  } finally {
    // Clean storage to avoid having the user redoing a flow he already did
    if (currentAccountId && surveyType === SurveyType.onboarding) {
      if (storage.get(FollowUpStorageKeys.SurveyType) === SurveyType.onboarding) {
        Object.values(FollowUpStorageKeys).forEach((storageKey) => storage.remove(storageKey))
      } else {
        storage.remove(FollowUpStorageKeys.ProfileUpdate)
      }
    } else if (currentAccountId && surveyType === SurveyType.followUp) {
      Object.values(FollowUpStorageKeys).forEach((storageKey) => storage.remove(storageKey))
    }
  }
}

/**
 * HELPERS FUNCTIONS for sagas
 */

const _generateCommuteRoutes = (routesValue, allValues) => {
  // Build Primary route with the inferred frequency from the commute profile
  // working frequency, the remote frequency and the secondary commute frequency
  const primaryRouteWithoutFrequency = get(routesValue, primaryRoutePath)
  const remoteFrequency = allValues[REF_KEYS_LIST.commuteProfileRemoteFrequency]
  // Spec: "if I say remote Monday, then my CP will have Tuesday to Friday (even if not entered)"
  const commuteFrequency = allValues[REF_KEYS_LIST.commuteProfileFrequency] || {
    days: difference(MonToFri, remoteFrequency?.days || []),
    weeklyFrequency: difference(MonToFri, remoteFrequency?.days || []).length
  }
  const secondaryRouteFrequency = get(routesValue, secondaryRouteFrequencyPath, {})
  const primaryRouteFrequency = calculateAvailableFrequency(commuteFrequency, [
    remoteFrequency,
    secondaryRouteFrequency
  ])
  const primaryRoute = {
    ...primaryRouteWithoutFrequency,
    ...primaryRouteFrequency
  }

  // Build secondary commute route with frequency
  const secondaryRouteWithoutFrequency = get(routesValue, secondaryRoutePath)
  const secondaryRoute = secondaryRouteWithoutFrequency
    ? {
        ...secondaryRouteWithoutFrequency,
        weeklyFrequency: secondaryRouteFrequency.weeklyFrequency,
        ...(secondaryRouteFrequency.days && { days: secondaryRouteFrequency.days })
      }
    : undefined

  // Format the information we will need for the next steps for easy access.
  // The activity mapper value looks like:
  // { routes: ['primary' | 'secondary'], frequency: Frequency, stopLocations: AddressPoint[], ['primary' | 'secondary']: {legIndex: X} }
  const activityRouteMapping = {
    [primaryRouteObjectId]: { route: primaryRoute, frequency: primaryRouteFrequency },
    [secondaryRouteObjectId]: { route: secondaryRoute, frequency: secondaryRouteFrequency }
  }

  const activityMapperValue = allValues[dropOffKidsQId]
  // If we have a value defined but no targeted routes it means the user has only one route,
  // which is the primary route
  const routesTargetedByActivities = activityMapperValue
    ? activityMapperValue.routes
      ? activityMapperValue.routes
      : [primaryRouteObjectId]
    : []
  const activityMapperDefinedValue = activityMapperValue || {}
  let activityFrequency = activityMapperDefinedValue.frequency
  const stops = (activityMapperDefinedValue.stopLocations || []).map((location) => generateStopFromLocation(location))
  const routes = []

  routesTargetedByActivities.forEach((routeTargetedByActivities) => {
    const target = activityRouteMapping[routeTargetedByActivities]
    const targetRoute = target.route
    const targetFrequency = target.frequency

    // If the activity frequency is higher than the target route frequency we assume
    // an activity frequency that is the same as the target route
    if (activityFrequency.weeklyFrequency > targetFrequency.weeklyFrequency) {
      activityFrequency = targetFrequency
    }

    // The index of the leg needs to be a number so we make sure of that.
    // And, if no leg is specified it means we target the only leg of the route at index 0
    const legIndex = +(activityMapperDefinedValue[routeTargetedByActivities] || {}).legIndex || 0

    // When the frequency of the activity is not the same as the original route we create 2 routes:
    // 1. The original route with a new frequency which is the diff between
    //    the route frequency and the activity frequency
    // 2. A route based on the original one that contains the stops
    const routeNewFrequency =
      activityFrequency && targetRoute.weeklyFrequency !== activityFrequency.weeklyFrequency
        ? calculateAvailableFrequency(targetFrequency, activityFrequency)
        : undefined
    if (routeNewFrequency) {
      // Copy route and change original frequency
      routes.push({
        ...targetRoute,
        ...routeNewFrequency,
        // The highest frequency is the primary route
        isPrimary:
          routeTargetedByActivities === primaryRouteObjectId &&
          routeNewFrequency.weeklyFrequency > activityFrequency.weeklyFrequency
      })
    }

    // In all cases we keep the original route with the added stops
    routes.push({
      ...targetRoute,
      legs: insertStopsAt(targetRoute.legs, stops || [], legIndex),
      // Handles 2 cases:
      // 1. activityFrequency is not defined so we keep the original route frequency
      // 2. activityFrequency = original route frequency so we don't really care which one we keep
      weeklyFrequency: activityFrequency ? activityFrequency.weeklyFrequency : targetRoute.weeklyFrequency,
      days: activityFrequency ? activityFrequency.days : targetRoute.days,
      // If we didn't generate a new route the original route is the primary
      isPrimary:
        routeTargetedByActivities === primaryRouteObjectId &&
        (!routeNewFrequency ||
          (routeNewFrequency && routeNewFrequency.weeklyFrequency < activityFrequency.weeklyFrequency))
    })
  })

  // Handle routes not affected by activities
  const remainingRouteIds = difference([primaryRouteObjectId, secondaryRouteObjectId], routesTargetedByActivities)
  remainingRouteIds.forEach((routeId) => {
    const route = activityRouteMapping[routeId].route
    route &&
      routes.push({
        ...route,
        isPrimary: routeId === primaryRouteObjectId
      })
  })
  return routes.map(formatRouteTransitLegs)
}

const _verifyAndFormatAnswer = (questionId, value, allValues) => {
  if (questionId === REF_KEYS_LIST.commuteProfileRoute) {
    return _generateCommuteRoutes(value, allValues)
  } else if (questionId === REF_KEYS_LIST.accountOfficeId) {
    // Handle office case.
    // For validation purposes we need some information about the address location:
    // { value: string, addressComponents: object[], formattedAddress: string, location: Location }
    // The api request expects an office id only so we select only the value of the address object
    return (value || {}).value
  } else if (questionId === REF_KEYS_LIST.accountEnterpriseId) {
    return (value || {}).key
  } else if (questionId === REF_KEYS_LIST.commuteProfileVehicleId) {
    const vehicle = value || {}
    return vehicle.modelId || vehicle.genericModelId
  } else if (questionId === REF_KEYS_LIST.commuteCostCarpoolDriver) {
    return { ...value, cost: `${-value.cost}` }
  } else {
    return value
  }
}
