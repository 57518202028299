import { Account } from '@commutifi-fe/interfaces'
import { combineReducers } from '@reduxjs/toolkit'
import { AccountActionTypes } from './actions'
import * as types from './types'

const byId = (state: Record<string, Account>, action: AccountActionTypes): Record<string, Account | undefined> => {
  switch (action.type) {
    case types.FETCH_ME_SUCCESS:
      return { ...state, ...action.payload.entities.account }
    case types.FETCH_ACCOUNT_SUCCESS:
    case types.UPDATE_ACCOUNT_SUCCESS:
      return {
        ...state,
        [action.response.result]: {
          ...state[action.response.result],
          ...action.response.entities.account[action.response.result]
        }
      }
    default:
      return state || {}
  }
}

const currentId = (state: string | null, action: AccountActionTypes): string | null => {
  switch (action.type) {
    case types.FETCH_ME_SUCCESS:
      return action.payload.result
    case types.FETCH_ACCOUNT_SUCCESS:
      return action.response.result
    default:
      return state || null
  }
}

const isFetching = (state: boolean, action: AccountActionTypes) => {
  switch (action.type) {
    case types.FETCH_ME_REQUEST:
    case types.FETCH_ACCOUNT_REQUEST:
      return true
    case types.FETCH_ME_FAILURE:
    case types.FETCH_ACCOUNT_FAILURE:
    case types.FETCH_ME_SUCCESS:
    case types.FETCH_ACCOUNT_SUCCESS:
      return false
    default:
      return state ?? false
  }
}

const error = (state: any | null, action: AccountActionTypes) => {
  switch (action.type) {
    case types.FETCH_ME_SUCCESS:
      return null
    case types.FETCH_ME_FAILURE:
      return action.error
    default:
      return state || null
  }
}

export const accountsReducer = combineReducers({
  byId,
  currentId,
  isFetching,
  error
})

export type AccountState = ReturnType<typeof accountsReducer>
export default accountsReducer
