import { createSelector } from 'reselect'
import { getFeatureFlagsByEnterpriseId } from 'store/modules/enterprise/selectors'
import { RootState } from '../../reducer'

export const getById = ({ account }: RootState) => account.byId
export const getCurrentId = ({ account }: RootState) => account.currentId
export const getIsFetching = ({ account }: RootState) => account.isFetching
export const getError = ({ account }: RootState) => account.error
export const getCurrentAccount = createSelector([getById, getCurrentId], (byId, currentId) =>
  currentId ? byId[currentId] : undefined
)
export const getCurrentName = createSelector([getCurrentAccount], (currentAccount) => currentAccount?.name)
export const getCurrentPhoneNumber = createSelector(
  [getCurrentAccount],
  (currentAccount) => currentAccount?.phoneNumber
)
export const getCurrentEmail = createSelector([getCurrentAccount], (currentAccount) => currentAccount?.email)
export const getCurrentAccountFeatureFlags = createSelector(
  [getCurrentAccount, getFeatureFlagsByEnterpriseId],
  (currentAccount, featureFlagsByEnterpriseId) => {
    const orgId = currentAccount?.enterpriseId || currentAccount?.organizationId
    return orgId && Object.keys(featureFlagsByEnterpriseId?.[orgId] || {}).length
      ? featureFlagsByEnterpriseId?.[orgId]
      : null
  }
)
