import types from './types'

export const fetchRoutes = (accountId) => ({
  type: types.FETCH_USER_ROUTES,
  accountId
})

export const fetchRoutesRequest = (accountId) => ({
  type: types.FETCH_USER_ROUTES_REQUEST,
  accountId
})

export const fetchRoutesSuccess = (payload) => ({
  type: types.FETCH_USER_ROUTES_SUCCESS,
  payload
})

export const fetchRoutesFailure = (error) => ({
  type: types.FETCH_USER_ROUTES_FAILURE,
  error
})

export const selectCurrentRoute = (id) => ({
  type: types.SELECT_CURRENT_ROUTE,
  id
})

export const selectTab = (index) => ({
  type: types.SELECT_TAB,
  index
})

export const fetchSuggestionRoutes = (userRouteId) => ({
  type: types.FETCH_SUGGESTION_ROUTES,
  userRouteId
})

export const fetchSuggestionRoutesRequest = (userRouteId) => ({
  type: types.FETCH_SUGGESTION_ROUTES_REQUEST,
  userRouteId
})

export const fetchSuggestionRoutesSuccess = (userRouteId, payload) => ({
  type: types.FETCH_SUGGESTION_ROUTES_SUCCESS,
  userRouteId,
  payload
})

export const fetchSuggestionRoutesFailure = (userRouteId, error) => ({
  type: types.FETCH_SUGGESTION_ROUTES_FAILURE,
  userRouteId,
  error
})

export const fetchSuggestionRoutesCancel = () => ({
  type: types.FETCH_SUGGESTION_ROUTES_CANCEL
})

export const selectSuggestionRoute = (routeId) => ({
  type: types.SELECT_SUGGESTION_ROUTE,
  routeId
})

export const fetchRouteProviders = (routeId) => ({
  type: types.FETCH_ROUTE_PROVIDERS,
  routeId
})

export const fetchRoutePlans = (routeId, promise) => ({
  type: types.FETCH_ROUTE_PLANS,
  routeId,
  promise
})

export const addNewUserRouteRequest = (newRouteId, promise) => ({
  type: types.ADD_NEW_USER_ROUTE_REQUEST,
  newRouteId,
  promise
})

export const addNewUserRouteSuccess = () => ({
  type: types.ADD_NEW_USER_ROUTE_SUCCESS
})

export const addNewUserRouteFailure = (error) => ({
  type: types.ADD_NEW_USER_ROUTE_FAILURE,
  error
})
