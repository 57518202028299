import { ModeCategory, ModeName } from '@commutifi/modes'
import get from 'lodash/get'
import indexOf from 'lodash/indexOf'
import { primaryRoutePath, REF_KEYS_LIST, secondaryRoutePath, subQuestionIdPrefix } from 'constants/onboarding'
import { Route, RouteLeg } from 'shared/interfaces/Commute'
import { getArrayValue } from 'utils/index'
import { PrefilledData, Question } from '@commutifi-fe/interfaces'

const checkConditionalValue = (
  possibleValues: string | string[],
  conditionalQuestionAnswer: string | string[] | { value: string }
) => {
  const possibleValuesArray = getArrayValue<string>(possibleValues)
  const conditionalQuestionAnswerString = get(conditionalQuestionAnswer, 'value', conditionalQuestionAnswer)
  const conditionalQuestionAnswerArray = getArrayValue<string | { value: string }>(conditionalQuestionAnswerString)
  const isInConditionalValues = (answer: string) =>
    possibleValuesArray.some((conditionalValue: string) => conditionalValue === answer)

  // Conditional question id case
  // If the conditional question (qA) depends on another question id (qB) we need to
  // verify if qB answer is part of one of the possibleValues in the array from qA (act as an OR)
  const conditionalQuestionIdMatchesConditionalValue = conditionalQuestionAnswerArray.some(isInConditionalValues)
  return conditionalQuestionIdMatchesConditionalValue
}

const checkConditionalModeAndCategory = (
  conditionalMode: ModeName[] | null,
  conditionalCategory: ModeCategory[] | null,
  routes: Route[]
) => {
  const hasConditionalMode = (leg: RouteLeg, cm: ModeName[] | null) => cm && leg.mode && indexOf(cm, leg.mode) >= 0
  const hasConditionalCategory = (leg: RouteLeg, cc: ModeCategory[] | null) =>
    cc && leg.category && indexOf(cc, leg.category) >= 0

  // Conditional mode case
  // If the conditional question (qA) depends on one or multiple conditionalMode(s) and/or conditionalCategory(ies) we need to verify
  // if any of the route(s) has at least one leg with of of the mode(s)/category(ies) specified
  const conditionalQuestionIdMatchesRouteModeOrCategory = routes.some((route) =>
    (route.legs || []).some(
      (leg) => hasConditionalMode(leg, conditionalMode) || hasConditionalCategory(leg, conditionalCategory)
    )
  )
  return conditionalQuestionIdMatchesRouteModeOrCategory
}

const checkQuestionConditionals = (
  question: Question,
  answersById: Record<string, any> | undefined,
  otherQuestionsById: Record<string, Question>,
  enterpriseSurveyData: PrefilledData
) => {
  if (
    question.enabled === false ||
    ((enterpriseSurveyData.isProfileUpdateRequired || enterpriseSurveyData.isAddressUpdate) &&
      question.hideOnProfileUpdate === true)
  ) {
    return false
  }

  // If the question has no conditions we keep it
  if (
    !question.conditionalQuestionId &&
    (!question.conditionalMode || question.conditionalMode.length === 0) &&
    (!question.conditionalCategory || question.conditionalCategory.length === 0)
  ) {
    return true
  }

  if (
    question.conditionalQuestionId &&
    question.conditionalValue &&
    otherQuestionsById[question.conditionalQuestionId]
  ) {
    const conditionalQuestion = otherQuestionsById[question.conditionalQuestionId]
    const answerKey = conditionalQuestion?.referenceKey || conditionalQuestion?.id
    const answer = get(answersById, answerKey || [])
    if (
      checkConditionalValue(question.conditionalValue, answer) &&
      conditionalQuestion &&
      checkQuestionConditionals(conditionalQuestion, answersById, otherQuestionsById, enterpriseSurveyData)
    ) {
      return true
    }
  }

  const routeAnswer = answersById?.[REF_KEYS_LIST.commuteProfileRoute]
  const primaryRoute = get(routeAnswer, primaryRoutePath)
  const secondaryRoute = get(routeAnswer, secondaryRoutePath)
  if (
    (question.conditionalMode || question.conditionalCategory) &&
    primaryRoute &&
    checkConditionalModeAndCategory(
      question.conditionalMode,
      question.conditionalCategory,
      secondaryRoute ? [primaryRoute, secondaryRoute] : [primaryRoute]
    )
  ) {
    return true
  }

  // If we have an extra sub question depending on another question we need to also remove it.
  // Ex. Commute router -> complex commute will add a question to ask the details of the commute. But
  // the the Commute Router is conditional we also want to remove the question this component generated
  // when the Commute Router don't meet the conditionals
  if (question.id.startsWith(subQuestionIdPrefix) && question.conditionalQuestionId) {
    const conditionalQuestion = otherQuestionsById[question.conditionalQuestionId]
    if (
      conditionalQuestion &&
      checkQuestionConditionals(conditionalQuestion, answersById, otherQuestionsById, enterpriseSurveyData)
    ) {
      return true
    }
  }

  return false
}

/**
 * Go through all questions and verify if the conditional conditions are met. In that case we keep the question,
 * otherwise we skip it. If there is no conditionals, the question is valid and we keep it
 * @param questions - Array of questions with conditionals (have a conditionalQuestionId, or mode, or category, etc.)
 * @param answersById - Cumulated survey answers
 */
export const getConditionals = (
  questions: Question[] = [],
  answersById: Record<string, any> = {},
  questionsById: Record<string, Question> = {},
  enterpriseSurveyData: PrefilledData = {}
) => {
  const additionalQuestions: Question[] = []
  questions.forEach((question) => {
    if (checkQuestionConditionals(question, answersById, questionsById, enterpriseSurveyData)) {
      return additionalQuestions.push(question)
    }
    return null
  })
  return additionalQuestions
}
