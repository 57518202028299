import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DeckGLProps } from '@commutifi-fe/maps/react'
import { MapType, MapMetrics } from 'constants/analytics'

type ViewStateType = Parameters<NonNullable<DeckGLProps['onViewStateChange']>>[0]['viewState']
type MapAnalyticsState = {
  isVisualizationSelectorCollapsed: boolean
  isLegendCollapsed: boolean
  mapType: MapType
  selectedMetric: MapMetrics
  viewState: ViewStateType
}

export const initialState: MapAnalyticsState = {
  isVisualizationSelectorCollapsed: false,
  isLegendCollapsed: false,
  mapType: MapType.Density,
  selectedMetric: MapMetrics.CommuteScore,
  viewState: {
    minZoom: 3,
    maxZoom: 15,
    pitch: 30,
    bearing: -10,
    latitude: 0,
    longitude: 0,
    zoom: 0
  }
}

const mapAnalyticsSlice = createSlice({
  name: 'mapAnalytics',
  initialState,
  reducers: {
    setIsVisualizationSelectorCollapsed(state, action: PayloadAction<boolean>) {
      state.isVisualizationSelectorCollapsed = action.payload
    },
    setIsLegendCollapsed(state, action: PayloadAction<boolean>) {
      state.isLegendCollapsed = action.payload
    },
    setMapType(state, action: PayloadAction<MapType>) {
      state.mapType = action.payload
      if (action.payload === MapType.Density && state.selectedMetric === MapMetrics.CommuteMode) {
        // Density map does not support CommuteMode metric
        state.selectedMetric = MapMetrics.CommuteScore
      }
    },
    setSelectedMetric(state, action: PayloadAction<MapMetrics>) {
      state.selectedMetric = action.payload
    },
    setMapViewState(state, action: PayloadAction<ViewStateType>) {
      state.viewState = { ...state.viewState, ...action.payload }
    }
  }
})

export const {
  setMapType,
  setSelectedMetric,
  setMapViewState,
  setIsLegendCollapsed,
  setIsVisualizationSelectorCollapsed
} = mapAnalyticsSlice.actions

export default mapAnalyticsSlice.reducer
