import { takeEvery, call, put, select } from 'redux-saga/effects'
import * as accountSelectors from 'store/modules/accounts/selectors'
import * as selectors from './selectors'
import * as actions from './actions'
import * as api from 'api/modules/routes'
import routesTypes from 'store/modules/routes/types'
import values from 'lodash/values'
import uniq from 'lodash/uniq'
import without from 'lodash/without'
import flatten from 'lodash/flatten'
import compact from 'lodash/compact'
import FETCH_STATUS from 'constants/global'

export const benefitsSagas = [takeEvery(routesTypes.FETCH_SUGGESTION_ROUTES_SUCCESS, getPlansSubsidiesFromRoute)]

export function* getPlansSubsidiesFromRoute({ payload }) {
  try {
    const accountId = yield select(accountSelectors.getCurrentId)
    const suggestions = values(payload.entities.route)
    const planIdsBySuggestion = suggestions.map((s) => {
      // Get all suggestion plan ids to get the subsidies of those plans
      const legsById = payload.entities.leg
      const planIds = without(
        s.legs.map((legId) => legsById[legId].planId),
        null
      )
      return planIds
    })
    const planIds = flatten(planIdsBySuggestion)
    if (planIds.length <= 0) return

    yield call(fetchPlansSubsidies, { payload: { accountId, planIds: compact(uniq(planIds)) } })
  } catch (error) {
    console.error(error)
  }
}

export function* getPlansSubsidies(planIds) {
  try {
    const accountId = yield select(accountSelectors.getCurrentId)
    const benefitsStatus = yield select(selectors.getFetchStatus)

    const unloadedIds = planIds.filter((id) => !benefitsStatus[id] || benefitsStatus[id] !== FETCH_STATUS.LOADED)
    if (unloadedIds.length <= 0) return

    yield call(fetchPlansSubsidies, {
      payload: { accountId, planIds: compact(uniq(unloadedIds)) }
    })
  } catch (error) {
    console.error(error)
  }
}

export function* fetchPlansSubsidies({ payload }) {
  try {
    if (!payload.accountId || !payload.planIds || !Array.isArray(payload.planIds)) return
    yield put(actions.fetchPlanSubsidiesRequest())
    const res = yield call(api.fetchPlansSubsidies, payload.accountId, compact(uniq(payload.planIds)))
    yield put(actions.fetchPlanSubsidiesSuccess(payload.planIds, res))
  } catch (error) {
    console.error(error)
    yield put(actions.fetchPlanSubsidiesFailure(payload.planIds, error))
  }
}
