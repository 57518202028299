import LogRocket from 'logrocket'
import LogrocketFuzzySanitizer from 'logrocket-fuzzy-search-sanitizer'
import { localStorage } from '@commutifi-fe/utils'
import * as uuid from 'uuid'

let isInitialized = false
let sessionUrl: string | null

export const getSessionUrl = () => {
  return sessionUrl
}

const sensitiveFields = ['password']
const privateFields = [
  'name',
  'email',
  'internalId',
  'phoneNumber',
  'enterpriseId',
  'organizationId',
  'officeId',
  'employeeId',
  'stripeCustomerId',
  'streetNumber',
  'street',
  'postalCode',
  'city',
  'state',
  'country',
  'address',
  'location',
  'timezone'
]
export const init = (
  appId: string,
  { hidePersonalData }: { hidePersonalData: boolean } = {
    hidePersonalData: true
  }
) => {
  // We only initialize once
  if (!isInitialized && appId) {
    const { requestSanitizer, responseSanitizer } =
      LogrocketFuzzySanitizer.setup(
        hidePersonalData
          ? [...privateFields, ...sensitiveFields]
          : sensitiveFields
      )

    try {
      LogRocket.init(appId, {
        console: {
          shouldAggregateConsoleErrors: true
        },
        dom: {
          textSanitizer: hidePersonalData
        },
        shouldCaptureIP: !hidePersonalData,
        network: {
          // Types are not matching even though fuzzy sanitizer was built for log rocket. not worth the time to investigate / fix
          requestSanitizer: requestSanitizer as any,
          responseSanitizer: responseSanitizer as any
        }
      })
    } catch (e: any) {
      if (
        typeof e?.message === 'string' &&
        e.message.includes('LogRocket has already been loaded')
      ) {
        // Maybe the user is doing an oauth process or for some other reason log rocket is initiated a
        // second time we want to keep it tagged as initialized in our app
        isInitialized = true
        return
      }

      warn(
        e,
        'Log Rocket not initiated. Might be because of an ad blocker, firewall, Network issues, etc.'
      )
      isInitialized = false
      return
    }

    LogRocket.getSessionURL((sessionURL) => {
      sessionUrl = sessionURL
    })

    isInitialized = true
  }
}

/*
 * Calling identify adds more data to the current session
 * Keep in mind that only the first identification will appear in LogRocket's main view, but they are all searchable
 * What this means is if you call this function with { email: xxxxxxx } the user will
 * show up with email xxxxx in the dashboard. If later in the session you call { email: yyyyyyyy }
 * the user will still show up as xxxxx in the dashboard, but you'll be able to search for yyyyy and find
 * this session.
 * LogRocket says this is "by design"
 */
export const identify = (payload: {
  [key: string]: string | number | boolean
}) => {
  const computerId = getOrCreateComputerId()
  // We use the userId as some sort of computer ID that encapsulates all of the sessions in this computer
  LogRocket.identify(computerId, payload)
}

/*
 * Private functions
 */

const getOrCreateComputerId = (): string => {
  const STORAGE_KEY = 'logRocketId'
  const storageComputerId = localStorage.get(STORAGE_KEY)
  if (storageComputerId) {
    return storageComputerId
  }
  const newComputerId = uuid.v4()
  localStorage.put(STORAGE_KEY, newComputerId)
  return newComputerId
}

const log: typeof LogRocket.log = (message, details) => {
  !isInitialized
    ? console.log(message, details)
    : LogRocket.log(message, details)
}

const info: typeof LogRocket.info = (message, details) => {
  !isInitialized
    ? console.info(message, details)
    : LogRocket.info(message, details)
}

const warn: typeof LogRocket.warn = (message, details) => {
  !isInitialized
    ? console.warn(message, details)
    : LogRocket.warn(message, details)
}

const debug: typeof LogRocket.debug = (message, details) => {
  !isInitialized
    ? console.debug(message, details)
    : LogRocket.debug(message, details)
}

const error: typeof LogRocket.error = (message, details) => {
  !isInitialized
    ? console.error(message, details)
    : LogRocket.error(message, details)
}

export const captureException: typeof LogRocket.captureException = (
  exception,
  extraPayload
) => {
  if (process.env.NODE_ENV !== 'production') {
    return
  }
  !isInitialized
    ? console.error('Logrocket not initiated', extraPayload)
    : LogRocket.captureException(exception, extraPayload)
}

export default {
  log,
  info,
  warn,
  debug,
  error
}
