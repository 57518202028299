import { HttpMethod } from '../../constants'

function replaceId(id: string): string {
  return this.path.replace(':id', id)
}

export const userEndpoints = {
  [HttpMethod.Get]: {
    LegacyFetchAccountId: {
      path: '/_/users/:id',
      route: replaceId
    },
    EnterpriseDashboard: {
      path: '/enterprises/:id/dashboard',
      route: replaceId
    }
  }
}

export default userEndpoints
