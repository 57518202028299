import { CustomBranding } from '@commutifi-fe/interfaces'
import { apiCall, dashboardApiServer } from '../../index'
import endpoints from './endpoints'

const apiDashboardServer = apiCall(dashboardApiServer)

export interface IdentityProviderProps {
  enable: boolean
  id: string
  type: 'OAUTH2' | 'CBRE'
  name: string
  providerName: string
  subdomain: string
  customBranding?: CustomBranding
}

interface SubdomainData {
  data: {
    identityProvider: IdentityProviderProps
    customBranding?: CustomBranding
  }
}

export const fetchSubdomainData = (subdomain: string): Promise<SubdomainData> =>
  apiDashboardServer(endpoints.GET.SubdomainData.route(subdomain), {
    method: 'get'
  })
