import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isMetric: true
}

export const unitsSlice = createSlice({
  name: 'units',
  initialState,
  reducers: {
    toggleUnit: (state) => {
      state.isMetric = !state.isMetric
    },
    setUnitToMetric: (state) => {
      state.isMetric = true
    },
    setUnitToImperial: (state) => {
      state.isMetric = false
    }
  }
})

const unitsReducer = unitsSlice.reducer
const { toggleUnit, setUnitToMetric, setUnitToImperial } = unitsSlice.actions

export { toggleUnit, setUnitToMetric, setUnitToImperial, unitsReducer }
