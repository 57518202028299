import { HttpMethod } from '../../constants'

function replaceBookingId(bookingId: string): string {
  return this.path.replace(':bookingId', bookingId)
}
function returnPath(): string {
  return this.path
}

export const bookingEndpoints = {
  [HttpMethod.Get]: {
    Booking: {
      path: '/bookings/:bookingId',
      route: replaceBookingId
    },
    BookingInvoices: {
      path: '/bookings/:bookingId/invoices',
      route: replaceBookingId
    },
    AccountPasses: {
      path: '/bookings/:bookingId/account/passes',
      route: replaceBookingId
    }
  },
  [HttpMethod.Patch]: {
    Booking: {
      path: '/bookings/:bookingId',
      route: replaceBookingId
    },
    ManyBookings: {
      path: '/bookings/batch',
      route: returnPath
    }
  },
  [HttpMethod.Post]: {
    BookingCancellation: {
      path: '/bookings/:bookingId/cancellation',
      route: replaceBookingId
    },
    BatchBookingsCancellation: {
      path: '/bookings/cancellation/batch',
      route: returnPath
    },
    Bookings: {
      path: '/bookings',
      route: returnPath
    },
    Subscription: {
      path: '/bookings/:bookingId',
      route: replaceBookingId
    },
    FillPdf: {
      path: '/bookings/:bookingId/document',
      route: replaceBookingId
    }
  },
  [HttpMethod.Put]: {
    BookingAccountVehicles: {
      path: '/bookings/:bookingId/account-vehicles-bookings',
      route: replaceBookingId
    },
    AccountPasses: {
      path: '/bookings/:id/account/passes',
      route: replaceBookingId
    }
  }
}

export default bookingEndpoints
